import React, { Component } from "react";
import {
  Form,
  Input,
  Tab,
  Button,
  Icon,
  Modal,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import moment from "moment";
import { DateItemAdmin, FormButtonGroup } from "../../../styles";
import {
  cancelDate,
  getToken,
  updateKids,
  unsetSub,
  restoreDate,
} from "../../../api";
import { connect } from "react-redux";
import { setMessage } from "../../../actions/messageActions";
import KidsDates from "../../containers/applicationdetail/KidsDates";
import { Col, Row } from "reactstrap";

const yesNoOpts = [
  { key: "0", value: 0, text: "Ne" },
  { key: "1", value: 1, text: "Ano" },
];

class KidsDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        kids_note: "",
        kids_name: "",
        kids_lastname: "",
        kids_birthday: "",
        kids_address: "",
        kids_city: "",
        kids_post_code: "",
        kids_insurance: "",
        kids_pairings: "",
        kids_eating_issues: "",
        kids_health_issues: "",
        can_go_home: "",
        return_to_school: "",
        go_from_school: "",
        go_from_home: "",
        kids_school: "",
        kids_class: "",
      },
      delete: {
        id_date: "",
        id_kids: "",
        token: "",
      },
      modalOpen: false,
      idToDelete: "",
      loading: false,
    };
  }

  componentDidMount() {
    const { kid } = this.props;

    this.setState({
      loading: true,
    });

    this.setState(
      {
        data: {
          id: kid.id_kids,
          kids_note: kid.kids_note ? kid.kids_note : "",
          kids_name: kid.kids_name ? kid.kids_name : "",
          kids_lastname: kid.kids_lastname ? kid.kids_lastname : "",
          kids_birthday: kid.kids_birthday ? kid.kids_birthday : "",
          kids_address: kid.kids_address ? kid.kids_address : "",
          kids_city: kid.kids_city ? kid.kids_city : "",
          kids_post_code: kid.kids_post_code ? kid.kids_post_code : "",
          kids_insurance: kid.kids_insurance ? kid.kids_insurance : "",
          kids_pairings: kid.kids_pairing === undefined ? "" : kid.kids_pairing,
          kids_eating_issues: kid.kids_eating_issues
            ? kid.kids_eating_issues
            : "",
          kids_health_issues: kid.kids_health_issues
            ? kid.kids_health_issues
            : "",
          can_go_home: kid.can_go_home ? kid.can_go_home : "",
          return_to_school: kid.return_to_school ? kid.return_to_school : "",
          go_from_school: kid.go_from_school ? kid.go_from_school : "",
          go_from_home: kid.go_from_home ? kid.go_from_home : "",
          kids_school: kid.kids_school ? kid.kids_school : "",
          kids_class: kid.kids_class ? kid.kids_class : "",
        },
      },
      () => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.kid !== prevProps.kid) {
      const { kid } = this.props;
      this.setState({
        data: {
          id: kid.id_kids,
          kids_note: kid.kids_note,
          kids_name: kid.kids_name,
          kids_lastname: kid.kids_lastname,
          kids_birthday: kid.kids_birthday,
          kids_address: kid.kids_address,
          kids_city: kid.kids_city,
          kids_post_code: kid.kids_post_code,
          kids_insurance: kid.kids_insurance,
          kids_pairings: kid.kids_pairing === undefined ? "" : kid.kids_pairing,
          kids_eating_issues: kid.kids_eating_issues,
          kids_health_issues: kid.kids_health_issues,
          can_go_home: kid.can_go_home,
          return_to_school: kid.return_to_school,
          go_from_school: kid.go_from_school,
          go_from_home: kid.go_from_home,
          kids_school: kid.kids_school,
          kids_class: kid.kids_class,
        },
      });
    }
  }

  updateKidsHandler = () => {
    this.setState({
      loading: true,
    });

    const data = {
      ...this.state.data,
      kids_birthday: moment(this.state.data.kids_birthday).format(
        "YYYY-MM-DD 00:00:00"
      ),
    };

    updateKids(data).then((res) => {
      this.props.setMessage(res);
      this.props.refresh();
      this.setState({
        loading: false,
      });
    });
  };

  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect = (e, data) => {
    this.setState({
      data: {
        ...this.state.data,
        [data.name]: data.value,
      },
    });
  };

  unsetSubHandler = (id_kids, id_date) => {
    getToken().then((token) => {
      const data = {
        token: token,
        id_kids: id_kids,
        id_date: id_date,
      };

      unsetSub(data).then((res) => {
        this.props.setMessage(res);
        this.props.refresh();
      });
    });
  };

  _renderHobbyExtraFields = () => {
    return (
      <>
        <Form.Field>
          <Form.Input
            type="text"
            name="kids_school"
            value={this.state.data.kids_school}
            label="Chodí do školy:"
          />
        </Form.Field>
        <Form.Field>
          <label>Po skončení kroužku může dítě odejít samo</label>
          <Dropdown
            name="can_go_home"
            placeholder="Vyberte možnost"
            options={yesNoOpts}
            value={this.state.data.can_go_home}
            onChange={this.onChangeSelect}
            className={"state-active-" + this.state.data.can_go_home}
            selection
          />
        </Form.Field>
        <Form.Field>
          <label>Po skončení kroužku se dítě vrací do družiny</label>
          <Dropdown
            name="return_to_school"
            placeholder="Vyberte možnost"
            options={yesNoOpts}
            value={this.state.data.return_to_school}
            onChange={this.onChangeSelect}
            className={"state-active-" + this.state.data.return_to_school}
            selection
          />
        </Form.Field>
        <Form.Field>
          <label>Dítě na kroužek převezmeme z družiny</label>
          <Dropdown
            name="go_from_school"
            placeholder="Vyberte možnost"
            options={yesNoOpts}
            value={this.state.data.go_from_school}
            onChange={this.onChangeSelect}
            className={"state-active-" + this.state.data.go_from_school}
            selection
          />
        </Form.Field>
        <Form.Field>
          <label>Dítě na kroužek přijde samo na domluvené místo srazu</label>
          <Dropdown
            name="go_from_home"
            placeholder="Vyberte možnost"
            options={yesNoOpts}
            value={this.state.data.go_from_home}
            onChange={this.onChangeSelect}
            className={"state-active-" + this.state.data.go_from_home}
            selection
          />
        </Form.Field>
      </>
    );
  };

  _renderKidsInfo = () => {
    const { loading, type } = this.props;
    const { data } = this.state;

    return (
      <Form
        loading={loading || this.state.loading}
        onSubmit={this.updateKidsHandler}
      >
        <Form.Field>
          <label>Interní poznámka</label>
          <textarea
            value={data.kids_note}
            name="kids_note"
            onChange={this.onChange}
          />
        </Form.Field>
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Field>
              <label>Jméno</label>
              <Input
                type="text"
                value={data.kids_name}
                onChange={this.onChange}
                name="kids_name"
              />
            </Form.Field>
          </Col>
          <Col sm={6}>
            <Form.Field>
              <label>Příjmení</label>
              <Input
                type="text"
                value={data.kids_lastname}
                onChange={this.onChange}
                name="kids_lastname"
              />
            </Form.Field>
          </Col>
        </Row>

        <div className="row mb-3">
          <div className="col-sm-6">
            <Form.Field>
              <label>Datum narození</label>
              <input
                type="date"
                value={moment(data.kids_birthday).format("YYYY-MM-DD")}
                onChange={this.onChange}
                name="kids_birthday"
              />
            </Form.Field>
          </div>
          <div className="col-sm-6">
            <Form.Field>
              <label>Věk</label>
              <Input
                type="text"
                value={Math.abs(
                  moment(data.kids_birthday).diff(moment(), "years", true)
                ).toFixed(1)}
                onChange={this.onChange}
                name="kids_birthday"
                disabled
              />
            </Form.Field>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm-4">
            <Form.Field>
              <label>Adresa</label>
              <Input
                type="text"
                value={data.kids_address}
                onChange={this.onChange}
                name="kids_address"
              />
            </Form.Field>
          </div>
          <div className="col-sm-4">
            <Form.Field>
              <label>Město</label>
              <Input
                type="text"
                value={data.kids_city}
                onChange={this.onChange}
                name="kids_city"
              />
            </Form.Field>
          </div>
          <div className="col-sm-4">
            <Form.Field>
              <label>PSČ</label>
              <Input
                type="number"
                value={data.kids_post_code}
                onChange={this.onChange}
                name="kids_post_code"
              />
            </Form.Field>
          </div>
        </div>
        <Form.Field>
          <label>Pojišťovna</label>
          <Input
            type="text"
            value={data.kids_insurance}
            onChange={this.onChange}
            name="kids_insurance"
          />
        </Form.Field>

        {type === "camp" && (
          <>
            <Form.Field>
              <label>Chce být ve skupině s...</label>
              <Input
                type="text"
                value={data.kids_pairings}
                onChange={this.onChange}
                name="kids_pairings"
              />
            </Form.Field>
          </>
        )}

        <Form.Field>
          <label>Do které třídy dítě chodí</label>
          <Input
            type="text"
            value={data.kids_class}
            onChange={this.onChange}
            name="kids_class"
          />
        </Form.Field>

        {type === "hobby" &&
          !this.state.loading &&
          this._renderHobbyExtraFields()}

        {type === "course" &&
          !this.state.loading &&
          this._renderHobbyExtraFields()}

        <Form.Field>
          <label>Stravovací omezení</label>
          <textarea
            value={data.kids_eating_issues}
            onChange={this.onChange}
            name="kids_eating_issues"
          />
        </Form.Field>

        <Form.Field>
          <label>Zdravotní omezení</label>
          <textarea
            value={data.kids_health_issues}
            onChange={this.onChange}
            name="kids_health_issues"
          />
        </Form.Field>
        <FormButtonGroup>
          <Button>Uložit změny</Button>
        </FormButtonGroup>
      </Form>
    );
  };

  _renderKidsDates = () => {
    const { dates } = this.props.kid;
    const { kid, loading, loadedDates } = this.props;

    return (
      <div>
        {dates.map((date) => {
          return (
            <Form loading={loading} key={date.id_date}>
              <DateItemAdmin
                state={date.state}
                className={date.is_sub ? "row mb-2 full_date" : "row mb-2"}
              >
                <div className="col-sm-2 camp_name_uppercase">
                  {date.camp_name}
                </div>
                <div className="col-sm-3">
                  {moment(date.date_from).format("D. M.")} -{" "}
                  {moment(date.date_to).format("D. M. YYYY")}
                </div>
                <div className="col-sm-5">{date.location_address}</div>
                <div className="col-sm-2 text-right">
                  {date.state === -1 && (
                    <Popup
                      content="Obnovit termín"
                      position="top center"
                      size="mini"
                      trigger={
                        <Button
                          onClick={() =>
                            this.handleOpenRestoreModal(date.id_date)
                          }
                          icon
                        >
                          <Icon name="check" />
                        </Button>
                      }
                    />
                  )}
                  {date.state === 1 && (
                    <Popup
                      content="Smazat termín"
                      position="top center"
                      size="mini"
                      trigger={
                        <Button
                          onClick={() => this.handleOpenModal(date.id_date)}
                          icon
                        >
                          <Icon name="trash alternate" />
                        </Button>
                      }
                    />
                  )}
                  {date.state === 1 && (
                    <KidsDates
                      loadedDates={loadedDates}
                      action={"change"}
                      refresh={this.props.refresh}
                      oldId={date.id_date}
                      kidsId={kid.id_kids}
                      modalTitle={"Změnit termín"}
                      buttonIcon={"exchange"}
                    />
                  )}
                  {date.is_sub ? (
                    <Popup
                      content="Zrušit náhradníka"
                      position="top center"
                      size="mini"
                      trigger={
                        <Button
                          onClick={() =>
                            this.unsetSubHandler(kid.id_kids, date.id_date)
                          }
                          icon
                        >
                          <Icon name="angle up" />
                        </Button>
                      }
                    />
                  ) : (
                    ""
                  )}
                  {date.state === 0 && (
                    <Button disabled>Stornovaná přihláška</Button>
                  )}
                </div>
              </DateItemAdmin>
            </Form>
          );
        })}
        <FormButtonGroup>
          <KidsDates
            loadedDates={loadedDates}
            action={"new"}
            applicationId={this.props.applicationId}
            refresh={this.props.refresh}
            kidsId={kid.id_kids}
            modalTitle={"Přidat nový termín"}
            buttonIcon={"plus"}
            buttonText="Přidat termín"
          />
        </FormButtonGroup>
      </div>
    );
  };

  handleOpenModal = (id) => {
    this.setState({
      modalOpen: true,
      idToDelete: id,
    });
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      idToDelete: "",
    });
  };

  handleOpenRestoreModal = (id) => {
    this.setState({
      restoreModalOpen: true,
      idToDelete: id,
    });
  };

  handleCloseRestoreModal = () => {
    this.setState({
      restoreModalOpen: false,
      idToDelete: "",
    });
  };

  cancelDateHandler = (id_date, send_email) => {
    const { kid, setMessage, refresh, email } = this.props;

    getToken().then((idToken) => {
      this.setState(
        {
          delete: {
            id_date: id_date,
            id_kids: kid.id_kids,
            send_email: send_email,
            email: email,
            token: idToken,
          },
        },
        () => {
          cancelDate(this.state.delete).then((res) => {
            setMessage(res);
            refresh();
            this.handleCloseModal();
          });
        }
      );
    });
  };

  restoreDateHandler = (id_date) => {
    const { kid, setMessage, refresh, email } = this.props;

    getToken().then((idToken) => {
      this.setState(
        {
          delete: {
            id_date: id_date,
            id_kids: kid.id_kids,
            email: email,
            token: idToken,
          },
        },
        () => {
          restoreDate(this.state.delete).then((res) => {
            setMessage(res);
            refresh();
            this.handleCloseRestoreModal();
          });
        }
      );
    });
  };

  panes = [
    {
      menuItem: "Informace o dítěti",
      render: () => (
        <Tab.Pane attached={false}>{this._renderKidsInfo()}</Tab.Pane>
      ),
    },
    {
      menuItem: "Termíny",
      render: () => (
        <Tab.Pane attached={false}>{this._renderKidsDates()}</Tab.Pane>
      ),
    },
  ];

  render() {
    return (
      <div>
        <Tab menu={{ secondary: true, pointing: true }} panes={this.panes} />
        <Modal open={this.state.modalOpen} onClose={this.handleCloseModal}>
          <Modal.Header>Smazat termín</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>Jste si jistí, že chcete smazat tento termín?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseModal}>Zpět</Button>
            <Button
              onClick={() => this.cancelDateHandler(this.state.idToDelete, 0)}
            >
              Pouze smazat
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Smazat a odeslat email"
              onClick={() => this.cancelDateHandler(this.state.idToDelete, 1)}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.restoreModalOpen}
          onClose={this.handleCloseModal}
        >
          <Modal.Header>Obnovit termín</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>Jste si jistí, že chcete obnovit tento termín?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseRestoreModal}>Zpět</Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Ano, chci obnovit"
              onClick={() => this.restoreDateHandler(this.state.idToDelete)}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setMessage })(KidsDetailForm);
