import React from "react";
import { Row, Col, Container } from "reactstrap";
import { StuckContainer } from "../../styles";
import LanguageSwitcherBig from "./LanguageSwitcherBig";
import { useParams } from "react-router-dom";
import UserBox from "./UserBox";

function EmbedNavigation({ setLang }) {
  let { lang, language } = useParams();

  return (
    <StuckContainer>
      <Container>
        <Row>
          <Col>
            <div className="logo">
              <div className="logo-image">
                <a
                  href={`/embed/prihlasky/sportjoy/${lang ? lang : language}`}
                  title="Sportjoy"
                >
                  <img
                    className="logo-img img-fluid"
                    src={"https://www.sportjoy.cz/images/sportjoy-web-logo.png"}
                    alt="SPORTJOY"
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col sm={4} className="d-none d-sm-block">
            <LanguageSwitcherBig
              marginBottom={0}
              displayHeading={false}
              setLang={setLang}
              position="right"
            />
          </Col>
          <Col sm={2} xs={5}>
            <UserBox />
          </Col>
        </Row>
      </Container>
    </StuckContainer>
  );
}

export default EmbedNavigation;
