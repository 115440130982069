export function roles(role) {
  switch (role) {
    case 1:
      return "Administrátor";
    case 0:
      return "Zaměstnanec";
    case 3:
      return "Lektor";
    case 4:
      return "Rodič";
    default:
      return "Neznámá role";
  }
}

export function convertEmailType(type) {
  if (type === "reminder") {
    return "Upomínka platby";
  } else if (type === "payment-info") {
    return "Pokyny k platbě";
  } else if (type === "confirm-payment") {
    return "Potvrzení platby";
  } else if (type === "thank-you") {
    return "Potvrzení registrace";
  } else if (type === "change-date") {
    return "Změna termínu";
  } else if (type === "canceled-date") {
    return "Zrušení termínu";
  } else if (type === "new-add-payment") {
    return "Doplňková platba";
  } else if (type === "bill") {
    return "Doklad o platbě";
  } else if (type === "price-increase-payment") {
    return "Zdražení";
  } else {
    return "Neznámý typ";
  }
}

export const yesNoMaybeOptions = [
  {
    key: "2",
    text: "Vše",
    value: null,
  },
  {
    key: "-1",
    text: "Nevím",
    value: -1,
  },
  {
    key: "1",
    text: "Ano",
    value: 1,
  },
  {
    key: "0",
    text: "Ne",
    value: 0,
  },
];

export const eatingIssuesOptions = [
  {
    key: "0",
    text: "bez omezení",
    value: "bez omezení",
  },
  {
    key: "1",
    text: "bezlaktózová dieta",
    value: "bezlaktózová dieta",
  },
  {
    key: "2",
    text: "bezlepková dieta",
    value: "bezlepková dieta",
  },
  {
    key: "3",
    text: "vegetariánská dieta",
    value: "vegetariánská dieta",
  },
];
