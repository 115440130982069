import React, { Component } from 'react'
import { Table, Button, Icon } from 'semantic-ui-react'
import _ from 'loadsh'

class KidsSearchTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            items: []
        })
    }

    render() {

        const { items } = this.props

        return (
            <div>
                <Table striped className='main-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell>
                                Jméno dítěte
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_kids, id_link, kids_name }) => (
                    <Table.Row key={id_kids}>
                        <Table.Cell>
                            {Number(id_link)}
                        </Table.Cell>
                        <Table.Cell>
                            {kids_name}
                        </Table.Cell>
                        
                        <Table.Cell>
                            <Button icon href={`${process.env.PUBLIC_URL}/manage/application/detail/` + id_link}><Icon name='eye' /></Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            </div>
        )
    }
}
  
export default KidsSearchTable