import moment from "moment";
import React from "react";
import { Form } from "semantic-ui-react";
import { Card, CardBody, CardHeader } from "../../../styles";
import DatesTableKids from "./DatesTableKids";

const KidsDates = ({ kid, refresh }) => {
  return (
    <Card className="mb-5">
      <CardHeader>
        {kid.kids_name} {kid.kids_lastname}
      </CardHeader>
      <CardBody>
        <Form>
          <Form.Input
            disabled
            readOnly
            label="Datum narození"
            type="text"
            name="kids_birthday"
            value={moment(kid?.kids_birthday).format("DD. MM. YYYY")}
          />
          <Form.Input
            disabled
            readOnly
            label="Adresa"
            type="text"
            name="kids_address"
            value={kid?.kids_address}
          />
          <Form.Input
            disabled
            readOnly
            label="Město"
            type="text"
            name="kids_city"
            value={kid?.kids_city}
          />
          <Form.Input
            disabled
            readOnly
            label="PSČ"
            type="text"
            name="kids_postcode"
            value={kid?.kids_post_code}
          />
          <Form.TextArea
            name="kids_eating_issues"
            label="Stravovací omezení"
            value={kid?.kids_eating_issues}
            readOnly
            disabled
          />
          <Form.TextArea
            name="kids_health_issues"
            label="Zdravotní omezení"
            value={kid?.kids_health_issues}
            readOnly
            disabled
          />
          <Form.TextArea
            name="kids_note"
            label="Poznámka"
            value={kid?.kids_note}
            readOnly
            disabled
          />
        </Form>
      </CardBody>
      <DatesTableKids dates={kid.dates} id_kids={kid.id_kids} refresh={refresh} />
    </Card>
  );
};

export default KidsDates;
