import React from 'react'
import { Card, CardBody, CardHeader } from '../../../styles'
import { FormWrapper, PageWrapper } from '../../../styles/employees'
import ResetPasswordForm from '../../forms/employees/ResetPasswordForm'

function ParentsNewPassword() {

    return (
        <>
        <PageWrapper>
            <FormWrapper>
                <Card>
                    <CardHeader>
                        <span>Zadejte email pro vytvoření hesla</span>
                        <span className='d-block'>Email se musí shodovat s emailem použitým v přihlášce</span>
                    </CardHeader>
                    <CardBody>
                        <ResetPasswordForm button='Zaslat odkaz pro vytvoření hesla' type='new' />
                    </CardBody>
                </Card>
            </FormWrapper>
        </PageWrapper>
        </>
    )
}

export default ParentsNewPassword