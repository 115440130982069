import React from 'react'
import { useParams } from 'react-router'
import { Col, Row } from 'reactstrap'
import { Card, CardHeader } from '../../../styles'
import AttendanceContainer from '../../containers/AttendanceContainer'
import ListOfKidsTable from '../../containers/employees/ListOfKidsTable'

function EmployeeDateDetailView() {

    return (
        <>
            <Row className='mb-3'>
                <Col sm={8}>
                    <div className='mb-5'>
                        <AttendanceContainer />
                    </div>

                    <Card>
                        <CardHeader>
                            Seznam dětí
                        </CardHeader>
                        <ListOfKidsTable />
                    </Card>
                </Col>
            </Row>
        </>
    )

}

export default EmployeeDateDetailView