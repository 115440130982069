import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { jsonToFormData } from "../../../tools";
import { disableUser } from "../../../api";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../actions/messageActions";

function DisableUserModal({ id_user, trigger }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function setTrigger() {
    switch (trigger) {
      case "icon":
        return (
          <Button icon onClick={() => setOpen(true)}>
            <Icon name="trash" />
          </Button>
        );

      default:
        return <></>;
    }
  }

  function onSubmit() {
    setLoading(true);
    const payload = {
      id_user: id_user,
    };
    const fd = jsonToFormData(payload);

    disableUser(fd)
      .then((res) => {
        setLoading(false);
        setOpen(false);
        dispatch(setMessage(res.data));
        console.log(res);
      })
      .catch((err) => {
        dispatch(setMessage({ type: "error", text: err.message }));
        setLoading(false);
        setOpen(false);
      });
  }

  return (
    <Modal
      trigger={setTrigger(trigger)}
      open={open}
      onClose={() => setOpen(false)}
      size={"tiny"}
    >
      <Modal.Header>
        <span>Smazat uživatele</span>
      </Modal.Header>
      <Modal.Content>
        <p>
          Opravdu chcete tohoto uživatele smazat? Nebude se již moci přihlásit.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => onSubmit()}
          loading={loading}
          disabled={loading}
        >
          Smazat uživatele
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DisableUserModal;
