import React, { Component } from "react";
import {
  intToBool,
  togglePaid,
  toggleFlag,
  getToken,
  sendMultipleReminders,
  sendMultipleConfirms,
  multipleDisable,
  toggleInvoice,
  getApplicationDetail,
  formatMoney,
} from "../../api";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Icon,
  Modal,
  Checkbox,
  Popup,
  Loader,
  Dropdown,
  DropdownItem,
} from "semantic-ui-react";
import _ from "loadsh";
import moment from "moment";
import { setMessage } from "../../actions/messageActions";
import { connect } from "react-redux";
import { MultipleActions } from "../../styles";
import { Row, Col } from "reactstrap";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import Dots from "./applicationslist/Dots";
import CancelApplicationModal from "../modals/CancelApplicationModal";
import { ApplicationRow } from "../../styles/applications";
import { Flags } from "../../config/Flags";

class ApplicationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      idToDelete: 2,
      toChangePayment: "",
      modalPayment: false,
      selectedIds: [],
      loading: {
        reminder: false,
        confirm: false,
        disable: false,
      },
      loadingToggle: false,
      kidsDates: [],
      loadingDates: false,
      selectedAll: false,
    };
  }

  setPaidHandler = (id) => {
    this.setState({
      toChangePayment: id,
      modalPayment: true,
    });
  };

  confirmSetPaid = () => {
    if (this.state.toChangePayment !== "") {
      togglePaid(this.state.toChangePayment).then(() => {
        this.props.refresh();
        this.setState({
          toChangePayment: "",
        });
        this.handleCloseModalPayment();
      });
    }
  };

  toggleModal = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      idToDelete: !this.state.showModal ? id : "",
    });
  };

  toggleFlagHandler = (id) => {
    toggleFlag(id).then((res) => {
      this.props.setMessage(res);
      this.props.refresh();
    });
  };

  toggleInvoiceHanlder = (id) => {
    this.setState({
      loadingToggle: true,
    });

    getToken().then((token) => {
      const data = {
        token: token,
        id: id,
      };

      toggleInvoice(data).then((res) => {
        this.props.setMessage(res);
        this.props.refresh();
        this.setState({
          loadingToggle: false,
        });
      });
    });
  };

  handleMultipleSelect = (event, data, id) => {
    if (data.checked) {
      this.setState({
        selectedIds: [...this.state.selectedIds, id],
      });
    } else {
      this.setState({
        selectedIds: this.state.selectedIds.filter((i) => i !== id),
      });
    }
  };

  handleCloseModalPayment = () => this.setState({ modalPayment: false });

  _renderModalPayment = () => {
    return (
      <Modal
        open={this.state.modalPayment}
        onClose={this.handleCloseModalPayment}
      >
        <Modal.Header>Změnit stav platby</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <p>Opravdu chcete změnit stav platby za tuto přihlášku?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={this.handleCloseModalPayment}>
            Zpět
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Ano, chci změnit"
            onClick={() => this.confirmSetPaid()}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  _renderMultipleSelectActions = () => {
    return (
      <MultipleActions>
        <div className="row">
          <div className="col-sm-1">
            <p>Vybráno: {this.state.selectedIds.length}</p>
          </div>
          <div className="col-sm-9">
            <Button
              color="red"
              onClick={() => this.multipleRemindersHandler("reminder")}
              loading={this.state.loading.reminder}
            >
              Odeslat upomínku
            </Button>
            <Button
              color="green"
              onClick={() => this.multipleRemindersHandler("confirm")}
              loading={this.state.loading.confirm}
            >
              Odeslat potvrzení
            </Button>
            <Button
              className="ghost"
              onClick={() => this.multipleRemindersHandler("disable")}
              loading={this.state.loading.disable}
            >
              Stornovat přihlášky
            </Button>
          </div>
          <div className="col-sm-2 text-right">
            <Button onClick={this.clearSelected} className="ghost">
              Vyprázdnit
            </Button>
          </div>
        </div>
      </MultipleActions>
    );
  };

  multipleRemindersHandler = (type) => {
    this.setState({
      loading: {
        ...this.state.loading,
        [type]: true,
      },
    });

    getToken().then((token) => {
      const data = {
        token: token,
        ids: this.state.selectedIds,
      };

      if (type === "reminder") {
        sendMultipleReminders(data).then((res) => {
          this.props.setMessage(res);

          this.setState({
            loading: {
              ...this.state.loading,
              [type]: false,
            },
          });
        });
      } else if (type === "confirm") {
        sendMultipleConfirms(data).then((res) => {
          this.props.setMessage(res);

          this.setState({
            loading: {
              ...this.state.loading,
              [type]: false,
            },
          });
        });
      } else if (type === "disable") {
        multipleDisable(data).then((res) => {
          this.props.setMessage(res);
          this.props.refresh();

          this.setState({
            loading: {
              ...this.state.loading,
              [type]: false,
            },
          });
        });
      }
    });
  };

  _renderDates = () => {
    if (!this.state.loadingDates) {
      return this.state.kidsDates.map((item) => {
        return (
          <>
            <Row>
              <Col sm={12}>
                <p>
                  {item.kids_name} {item.kids_lastname}
                </p>
              </Col>
            </Row>
            <Row>
              {item.dates.map((date) => {
                return (
                  <React.Fragment key={date.id_date}>
                    <Col sm={4}>{date.camp_name}</Col>
                    <Col sm={4}>
                      {moment(date.date_from).format("D. M.")}-
                      {moment(date.date_to).format("D. M.")}
                    </Col>
                    <Col sm={4}>{date.location_name}</Col>
                  </React.Fragment>
                );
              })}
            </Row>
          </>
        );
      });
    } else {
      return (
        <Row>
          <Col sm={12}>
            <div className="popup_loader">
              <Loader active />
            </div>
          </Col>
        </Row>
      );
    }
  };

  _getDates = (id) => {
    this.setState({
      loadingDates: true,
    });

    getApplicationDetail(id).then((res) => {
      this.setState({
        kidsDates: res.kids,
        loadingDates: false,
      });
    });
  };

  clearSelected = () => this.setState({ selectedIds: [] });

  checkAll = (e, data) => {
    var selected = [];

    this.setState(
      {
        selectedAll: data.checked,
      },
      () => {
        if (data.checked) {
          this.props.items.map((item) => {
            return selected.push(item.id_application);
          });

          this.setState({ selectedIds: selected });
        } else {
          this.clearSelected();
        }
      }
    );
  };

  render() {
    const { items, sort, column, direction, disable, enable, refresh } =
      this.props;

    return (
      <div style={isMobile ? { overflowX: "auto" } : { overflowX: "hidden" }}>
        <Table sortable striped className="main-table mobile" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  checked={this.state.selectedAll}
                  onChange={(e, data) => this.checkAll(e, data)}
                />
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === "id_application" ? direction : null}
                onClick={sort("id_application")}
              >
                #
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === "parent_name" ? direction : null}
                onClick={sort("parent_name")}
              >
                Jméno rodiče
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === "created" ? direction : null}
                onClick={sort("created")}
              >
                Vytvořeno
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === "total_price" ? direction : null}
                onClick={sort("total_price")}
              >
                Cena
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === "num_of_dates" ? direction : null}
                onClick={sort("num_of_dates")}
              >
                Termíny
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === "paid" ? direction : null}
                onClick={sort("paid")}
              >
                Zaplaceno
              </Table.HeaderCell>

              <Table.HeaderCell width={1}></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(
              items,
              ({
                id_application,
                lang,
                seen,
                parent_name,
                parent_lastname,
                state,
                parent_email,
                has_sub,
                parent_phone,
                created,
                priority,
                num_of_dates,
                total_price,
                paid,
                invoice_sent,
              }) => (
                <ApplicationRow
                  key={id_application}
                  className={has_sub ? "has_sub" : "no_sub"}
                  seen={seen}
                >
                  <Table.Cell>
                    <Checkbox
                      checked={
                        this.state.selectedIds.includes(id_application)
                          ? true
                          : false
                      }
                      onChange={(event, data) =>
                        this.handleMultipleSelect(event, data, id_application)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>{Number(id_application)}</Table.Cell>
                  <Table.Cell>
                    <strong>
                      {parent_name} {parent_lastname}{" "}
                      <img
                        src={lang ? Flags[lang] : Flags.cs}
                        className="app_flag"
                        alt="vlajka"
                      />
                    </strong>
                    <br />
                    {this.props.user.user_admin === 1 && (
                      <a href={"mailto:" + parent_email}>{parent_email}</a>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(created).format("D/M/YYYY H:mm")}
                  </Table.Cell>
                  <Table.Cell>
                    {formatMoney(total_price, 0, " ", "\xa0")},-
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      content={this._renderDates}
                      wide="very"
                      on="click"
                      pinned
                      position="bottom left"
                      trigger={
                        <Button
                          content={num_of_dates}
                          onClick={() => this._getDates(id_application)}
                        />
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {intToBool(paid) ? (
                      <i
                        className="icofont-check-circled icon-state"
                        onClick={() => this.setPaidHandler(id_application)}
                      ></i>
                    ) : (
                      <i
                        className="icofont-close-circled icon-state"
                        onClick={() => this.setPaidHandler(id_application)}
                      ></i>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Dots id={id_application} />
                  </Table.Cell>
                  <Table.Cell>
                    <BrowserView>
                      <Popup
                        trigger={
                          <Button
                            icon
                            className={
                              priority === 0 ? "ghost" : "priority-flag"
                            }
                            onClick={() =>
                              this.toggleFlagHandler(id_application)
                            }
                          >
                            <Icon name="flag" />
                          </Button>
                        }
                        content="Prioritní přihláška"
                      />
                      {state === 1 && (
                        <Popup
                          trigger={
                            <CancelApplicationModal
                              id={id_application}
                              refresh={refresh}
                              type="button"
                            />
                          }
                          content="Stornovat přihlášku"
                        />
                      )}
                      {state === 0 && (
                        <Popup
                          trigger={
                            <Button
                              icon
                              className="ghost"
                              onClick={() => enable(id_application)}
                            >
                              <Icon name="check" />
                            </Button>
                          }
                          content="Obnovit přihlášku"
                        />
                      )}
                      <Popup
                        trigger={
                          <Button
                            icon
                            href={
                              `${process.env.PUBLIC_URL}/manage/application/detail/` +
                              id_application
                            }
                          >
                            <Icon name="pencil alternate" />
                          </Button>
                        }
                        content="Detail přihlášky"
                      />
                    </BrowserView>
                    <MobileView>
                      <Dropdown
                        multiple
                        icon="ellipsis vertical"
                        pointing="top right"
                      >
                        <Dropdown.Menu>
                          <DropdownItem>
                            <Link
                              to={`${process.env.PUBLIC_URL}/manage/application/detail/${id_application}`}
                            >
                              Upravit záznam
                            </Link>
                          </DropdownItem>
                          <Dropdown.Divider />
                          {state === 1 && (
                            <CancelApplicationModal
                              id={id_application}
                              refresh={refresh}
                              type="dropdown"
                            />
                          )}
                          {state === 1 && (
                            <DropdownItem
                              text="Obnovit přihlášku"
                              onClick={() => enable(id_application)}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </MobileView>
                  </Table.Cell>
                </ApplicationRow>
              )
            )}
          </Table.Body>
        </Table>
        {this._renderModalPayment()}
        {this.state.selectedIds.length !== 0 &&
          this._renderMultipleSelectActions()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { setMessage })(ApplicationsTable);
