import moment from "moment";
import React from "react";
import { Dropdown, Table } from "semantic-ui-react";
import ChangeDate from "../../modals/ChangeDate/ChangeDate";

const DatesTableKids = ({ dates, id_kids, refresh }) => {
  return (
    <Table striped className="main-table mobile" unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>Aktivita</Table.HeaderCell>
          <Table.HeaderCell width={4}>Termín</Table.HeaderCell>
          <Table.HeaderCell>Lokalita</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dates &&
          dates.length !== 0 &&
          dates.map((item) => {
            var date_from = moment(item.date_from);
            var next_week = moment().add(14, "days");
            var can_switch = date_from.diff(next_week, "days");
            return (
              <Table.Row key={item.id_date}>
                <Table.Cell width={4}>
                  <strong>{item.camp_name}</strong>
                </Table.Cell>
                <Table.Cell width={4}>
                  {moment(item.date_from).format("DD.")}-
                  {moment(item.date_to).format("DD.MM.YYYY")}
                </Table.Cell>
                <Table.Cell>{item.location_name}</Table.Cell>
                <Table.Cell>
                  <Dropdown text="Akce">
                    <Dropdown.Menu>
                      {can_switch && (
                        <ChangeDate
                          id_kids={id_kids}
                          id_date={item.id_date}
                          type={item.type}
                          refresh={refresh}
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};

export default DatesTableKids;
