import React, { Component } from "react";
import {
  dateFilterFrom,
  getCampsFilter,
  getDatesAdmin,
  getEmbedDatesFilter,
  getLocationsFilter,
  getDates,
  getToken,
} from "../../api";
import { DatesTable } from "../containers";
import { Card, CardControls, CardHeader, Button } from "../../styles";
import { Link } from "react-router-dom";
import _ from "loadsh";
import { Dropdown, Dimmer, Loader } from "semantic-ui-react";
import queryString from "query-string";
import { connect } from "react-redux";
import { jsonToFormData } from "../../tools";

const capacityOptions = [
  { key: "1", value: null, text: "Vše" },
  { key: "2", value: 1, text: "Zaplněné" },
  { key: "3", value: 0, text: "Volné" },
];

const stateOptions = [
  { key: "1", value: null, text: "Vše" },
  { key: "2", value: 1, text: "Zveřejněno" },
  { key: "3", value: 0, text: "Nezvěřejněno" },
];

const yearOptions = [
  { key: "0", value: "2020", text: "2020" },
  { key: "1", value: "2021", text: "2021" },
  { key: "2", value: "2022", text: "2022" },
  { key: "3", value: "2023", text: "2023" },
  { key: "4", value: "2024", text: "2024" },
  { key: "5", value: "2025", text: "2025" },
  { key: "6", value: "2026", text: "2026" },
  { key: "7", value: "2027", text: "2027" },
  { key: "8", value: "2028", text: "2028" },

];

const dateCampTypeOptions = [
  { key: "0", value: null, text: "Vše" },
  { key: "1", value: 1, text: "Tábory" },
  { key: "2", value: 2, text: "Kroužky" },
  { key: "3", value: 3, text: "Kurzy" },
  { key: "4", value: 4, text: "Školka" },
];

class DatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [
        {
          id_date: 0,
          organization_name: "Kidsjoy",
          date_date: "2019-05-08",
          date_capacity: 300,
          location_name: "Praha 6",
          date_state: 1,
        },
      ],
      baseData: [],
      org: [{ key: "0", value: null, text: "Vše" }],
      locOptions: [{ key: "0", value: null, text: "Vše" }],
      campsOptions: [{ key: "0", value: null, text: "Vše" }],
      datesFilter: [{ key: "0", value: null, text: "Vše" }],
      hasSome: true,
      column: "id_date",
      direction: "descending",
      filter: false,
      loading: true,
    };
  }

  componentDidMount() {
    if (!this.props.loadingUser) {
      this.getDatesHandler();
    }

    this.getFilterValues();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loadingUser !== this.props.loadingUser &&
      !this.props.loadingUser
    ) {
      this.getDatesHandler();
    }
  }

  getFilterValues = () => {
    const parsed = queryString.parse(this.props.location.search);

    const toSend = {
      filters: parsed,
      activity_type: parsed.type,
      admin: 1,
    };

    const fd = jsonToFormData(toSend);

    getLocationsFilter(fd).then((data) => {
      this.setState(
        {
          locOptions: [{ key: "0", value: null, text: "Vše" }],
        },
        () => {
          this.setState({
            locOptions: this.state.locOptions.concat(data.data),
          });
        }
      );
    });

    getCampsFilter(parsed.type, 1).then((data) => {
      this.setState(
        {
          campsOptions: [{ key: "0", value: null, text: "Vše" }],
        },
        () => {
          this.setState({
            campsOptions: this.state.campsOptions.concat(data.data),
          });
        }
      );
    });

    getEmbedDatesFilter(fd).then((data) => {
      this.setState(
        {
          datesFilter: [{ key: "0", value: null, text: "Vše" }],
        },
        () => {
          this.setState({
            datesFilter: this.state.datesFilter.concat(data.data),
          });
        }
      );
    });
  };

  getDatesHandler = () => {
    const parsed = queryString.parse(this.props.location.search);

    var year = parsed.year ? parsed.year : "2022";

    if (this.props.user.user_admin !== 2) {
      getDatesAdmin(year)
        .then((data) => {
          if (parsed.is_full) {
            const data = {
              name: "is_full",
              value: Number(parsed.is_full),
            };
            this.filterByQuery(data);
          }

          if (parsed.id_location) {
            const data = {
              name: "id_location",
              value: Number(parsed.id_location),
            };
            this.filterByQuery(data);
          }

          if (parsed.id_camp) {
            const data = {
              name: "id_camp",
              value: Number(parsed.id_camp),
            };
            this.filterByQuery(data);
          }

          if (parsed.date_from) {
            this.getFilteredHandler(parsed.date_from);
          }

          if (parsed.type) {
            const data = {
              name: "type",
              value: Number(parsed.type),
            };
            this.filterByQuery(data);
          }

          if (parsed.date_state) {
            const data = {
              name: "date_state",
              value: Number(parsed.date_state),
            };
            this.filterByQuery(data);
          }

          if (data.length !== 0) {
            if (_.keys(this.state.filters).length !== 0) {
              this.setState({
                dates: _.filter(data, this.state.filters),
                baseData: data,
                hasSome: true,
                loading: false,
              });
            } else {
              this.setState({
                dates: data,
                baseData: data,
                hasSome: true,
                loading: false,
              });
            }
          } else {
            this.setState({
              hasSome: false,
              loading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            hasSome: true,
          });
          alert("Nepodařilo se získat data s chybou: " + err.message);
        });
    } else {
      getDates(year)
        .then((data) => {
          if (parsed.is_full) {
            const data = {
              name: "is_full",
              value: Number(parsed.is_full),
            };
            this.filterByQuery(data);
          }

          if (parsed.id_location) {
            const data = {
              name: "id_location",
              value: Number(parsed.id_location),
            };
            this.filterByQuery(data);
          }

          if (parsed.id_camp) {
            const data = {
              name: "id_camp",
              value: Number(parsed.id_camp),
            };
            this.filterByQuery(data);
          }

          if (parsed.date_from) {
            this.getFilteredHandler(parsed.date_from);
          }

          if (parsed.type) {
            const data = {
              name: "type",
              value: Number(parsed.type),
            };
            this.filterByQuery(data);
          }

          if (parsed.date_state) {
            const data = {
              name: "date_state",
              value: Number(parsed.date_state),
            };
            this.filterByQuery(data);
          }

          if (data.length !== 0) {
            if (_.keys(this.state.filters).length !== 0) {
              this.setState({
                dates: _.filter(data, this.state.filters),
                baseData: data,
                hasSome: true,
                loading: false,
              });
            } else {
              this.setState({
                dates: data,
                baseData: data,
                hasSome: true,
                loading: false,
              });
            }
          } else {
            this.setState({
              hasSome: false,
              loading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            hasSome: true,
          });
          alert("Nepodařilo se získat data s chybou: " + err.message);
        });
    }
  };

  filterByQuery = (data) => {
    if (data.value !== null) {
      this.setState(
        {
          filter: true,
          filters: {
            ...this.state.filters,
            [data.name]: data.value,
          },
        },
        () => {
          this.setState({
            dates: _.filter(this.state.baseData, this.state.filters),
          });
        }
      );
    } else {
      this.setState(
        {
          filters: _.omit(this.state.filters, [data.name]),
        },
        () => {
          this.setState({
            dates:
              _.keys(this.state.filters).length !== 0
                ? _.filter(this.state.baseData, this.state.filters)
                : this.state.baseData,
            filter: _.keys(this.state.filters).length !== 0 ? true : false,
          });
        }
      );
    }
  };

  getFilteredHandler = (date) => {
    var admin = 1;
    const parsed = queryString.parse(this.props.location.search);

    dateFilterFrom(date, admin, parsed.type).then((dates) => {
      if (dates.length !== 0) {
        if (_.keys(this.state.filters).length !== 0) {
          this.setState({
            dates: _.filter(dates, this.state.filters),
            baseData: dates,
            hasSome: true,
          });
        } else {
          this.setState({
            dates: dates,
            baseData: dates,
            hasSome: true,
          });
        }
      } else {
        this.setState({
          hasSome: false,
        });
      }
    });
  };

  onChangeSelect = (e, data) => {
    if (data.value !== null) {
      this.setState(
        {
          filter: true,
          filters: {
            ...this.state.filters,
            [data.name]: data.value,
          },
        },
        () => {
          this.setState({
            dates: _.filter(this.state.baseData, this.state.filters),
          });
        }
      );
    } else {
      this.setState(
        {
          filters: _.omit(this.state.filters, [data.name]),
        },
        () => {
          this.setState({
            dates:
              _.keys(this.state.filters).length !== 0
                ? _.filter(this.state.baseData, this.state.filters)
                : this.state.baseData,
            filter: _.keys(this.state.filters).length !== 0 ? true : false,
          });
        }
      );
    }
  };

  onChangeDate = (e, data) => {
    this.setState(
      {
        [data.name]: data.value,
      },
      () => {
        if (
          this.state.filter_date_from !== "" &&
          this.state.filter_date_from !== null
        ) {
          this.getFilteredHandler(this.state.filter_date_from);
        } else {
          this.getDatesHandler();
        }
      }
    );
  };

  handleSort = (clickedColumn) => () => {
    const { column, dates, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        dates: _.sortBy(dates, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: dates.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  onChangeFilter = (e, data) => {
    const parsed = queryString.parse(this.props.location.search);

    const params = {
      ...parsed,
      [data.name]: data.value || data.value === 0 ? [data.value] : null,
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    this.props.history.push(`${process.env.PUBLIC_URL}/manage/dates?${url}`);
  };

  exportList = (type) => {
    var url = "https://api.sportjoy.cz/export/export?type=" + type;
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(url + "&token=" + token, "_blank");

      this.setState({
        loadingExport: false,
      });
    });
  };

  _renderExportDropdown = () => {
    return (
      <Dropdown
        text="Export"
        icon="download"
        floating
        labeled
        button
        className="icon"
        loading={this.state.loadingExport}
      >
        <Dropdown.Menu>
          <Dropdown.Header icon="file outline" content="Typ exportu" />
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.exportList("dates")}
            icon="users"
            text="Termíny 2021"
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  render() {
    const parsed = queryString.parse(this.props.location.search);

    return (
      <div>
        <Card>
          <CardHeader>
            <div className="row">
              <div className="col-sm-8 text-left">
                <h2>Termíny</h2>
              </div>
              {!this.props.loadingUser && this.props.user.user_admin !== 2 && (
                <div className="col-sm-4 text-right d-flex justify-content-end align-items-center">
                  {this._renderExportDropdown()}
                  <Dropdown
                    name="year"
                    options={yearOptions}
                    placeholder="Rok"
                    selection
                    onChange={this.onChangeFilter}
                    fluid
                    value={parsed.year ? parsed.year : "2023"}
                    style={{ marginRight: 10 }}
                  />
                  <Link to={`${process.env.PUBLIC_URL}/manage/dates/edit/new`}>
                    <Button icon="\efc2">Nový</Button>
                  </Link>
                </div>
              )}
            </div>
          </CardHeader>
          <CardControls>
            <div className="row">
              <div className="col-sm-2 text-left">
                <Dropdown
                  name="type"
                  options={dateCampTypeOptions}
                  placeholder="Vyberte typ"
                  selection
                  search
                  onChange={this.onChangeFilter}
                  fluid
                  value={parsed.type ? Number(parsed.type) : null}
                  clearable
                />
              </div>
              <div className="col-sm-2 text-left">
                <Dropdown
                  name="id_camp"
                  options={this.state.campsOptions}
                  placeholder="Vyberte tábor"
                  selection
                  search
                  onChange={this.onChangeFilter}
                  fluid
                  value={parsed.id_camp ? Number(parsed.id_camp) : null}
                  clearable
                />
              </div>
              <div className="col-sm-2 text-left">
                <Dropdown
                  name="id_location"
                  options={this.state.locOptions}
                  placeholder="Vyberte lokalitu"
                  selection
                  search
                  onChange={this.onChangeFilter}
                  fluid
                  value={parsed.id_location ? Number(parsed.id_location) : null}
                  clearable
                />
              </div>
              <div className="col-sm-2 text-left">
                <Dropdown
                  name="date_from"
                  options={this.state.datesFilter}
                  placeholder="Vyberte termín"
                  selection
                  onChange={this.onChangeFilter}
                  fluid
                  value={parsed.date_from ? parsed.date_from : null}
                  clearable
                />
              </div>
              <div className="col-sm-2 text-left">
                <Dropdown
                  name="is_full"
                  options={capacityOptions}
                  placeholder="Zaplněné / volné"
                  selection
                  onChange={this.onChangeFilter}
                  fluid
                  value={parsed.is_full ? Number(parsed.is_full) : null}
                  clearable
                />
              </div>
              <div className="col-sm-2 text-left">
                <Dropdown
                  name="date_state"
                  options={stateOptions}
                  placeholder="Stav"
                  selection
                  onChange={this.onChangeFilter}
                  fluid
                  value={parsed.date_state ? Number(parsed.date_state) : null}
                  clearable
                />
              </div>
            </div>
          </CardControls>
          {!this.props.loadingUser && (
            <DatesTable
              items={this.state.dates}
              role={this.props.user.user_admin}
              type={parsed.type}
              refresh={this.getDatesHandler}
              sort={this.handleSort}
              column={this.state.column}
              direction={this.state.direction}
            />
          )}

          <Dimmer active={this.state.loading}>
            <Loader>Načítám data</Loader>
          </Dimmer>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default connect(mapStateToProps, {})(DatesView);
