const firebaseConfigData = {
    apiKey: "AIzaSyBP0nfQIp2esEawzMk5J01DBGqvNBMCyCU",
    authDomain: "sportjoy-87ac9.firebaseapp.com",
    databaseURL: "https://sportjoy-87ac9.firebaseio.com",
    projectId: "sportjoy-87ac9",
    storageBucket: "sportjoy-87ac9.appspot.com",
    messagingSenderId: "250624948045",
    appId: "1:250624948045:web:9fed143bb705d6597b6904"
};

export default firebaseConfigData