import React, { Component } from 'react'
import { getCamps, getUsersOrganization } from '../../api'
import EmptyTable from '../containers/EmptyTable'
import { CampsTable } from '../containers'
import { Card, CardControls, CardHeader, Button } from '../../styles'
import { Link } from 'react-router-dom'
import _ from 'loadsh'
import { Dropdown, Input } from 'semantic-ui-react'

const stateOptions = [
    {key: '1', value: null, text: 'Vše'},
    {key: '2', value: 0, text: 'Nezveřejněno'},
    {key: '3', value: 1, text: 'Zveřejněno'}
]

class CampsView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            camps: [],
            baseData: [],
            org: [
                {key: '0', value: null, text: 'Vše'},
            ],
            hasSome: false,
            column: 'id_camp',
            direction: 'descending',
            filter: false
        })
    }

    componentDidMount() {
        this.getCampsHandler()
        getUsersOrganization()
        .then((data) => {
            this.setState({ 
                org: this.state.org.concat(data) 
            })
        })
    }

    getCampsHandler = () => {
        getCamps()
        .then((data) => {
            if(data.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        camps: _.filter(data, this.state.filters),
                        baseData: data,
                        hasSome: true
                    })
                } else {
                    this.setState({
                        camps: data,
                        baseData: data,
                        hasSome: true
                    })
                }
            } else {
                this.setState({
                    hasSome: false
                })
            }
        })
    }

    handleSort = (clickedColumn) => () => {
        const { column, camps, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            camps: _.sortBy(camps, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
          data: camps.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onChangeSelect = (e, data) => {
        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    camps: _.filter(this.state.baseData, this.state.filters)
                })
            })
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    camps: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                })
            })
        }
    }

    render() {
        return (
            <div>
            {!this.state.hasSome ? 
                <EmptyTable section={"Tábory"} text={"Nemáte vytvořený žádný tábor. Vytvořte nejdříve první záznam."} link={'/manage/camps/edit/new'}/>
                :
                <Card>
                    <CardHeader>
                        <div className='row'>
                            <div className='col-8 text-left'>
                                <h2>Tábory</h2>
                            </div>
                            <div className='col-4 text-right'>
                                <Link to={`${process.env.PUBLIC_URL}/manage/camps/edit/new`}><Button icon="\efc2">Nový</Button></Link>
                            </div>
                        </div>
                    </CardHeader>
                    <CardControls>
                    <div className='row'>
                            <div className='col-sm-6 text-left'>
                                <Input 
                                    placeholder='Hledat...'
                                    fluid
                                    icon='search'
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='id_organization'
                                    options={this.state.org}
                                    placeholder='Vyberte organizaci'
                                    selection
                                    onChange={this.onChangeSelect}
                                    fluid
                                    search
                                />
                            </div>
                            <div className='col-sm-3 text-left'>
                                <Dropdown
                                    name='camp_state'
                                    options={stateOptions}
                                    placeholder='Vyberte stav'
                                    selection
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </div>
                        </div>
                    </CardControls>
                    <CampsTable items={this.state.camps} refresh={this.getCampsHandler} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                </Card>
            }
            </div>
        )
    }

}

export default CampsView