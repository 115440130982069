import React from "react";
import { Modal } from "semantic-ui-react";

function DefaultModal({ heading, children, trigger, size }) {
  return (
    <Modal trigger={trigger} size={size}>
      <Modal.Header>{heading}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
}

export default DefaultModal;
