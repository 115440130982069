import React from "react";
import { Col, Row } from "reactstrap";
import { Card, CardHeader } from "../../../styles";
import ApplicationsTableDashboard from "../../containers/parents/ApplicationsTableDashboard";

const ParentsApplicationsListView = () => {

    document.title = `Moje přihlášky - SPORTJOY`;

    return (
        <>
        <Row className='mb-5'>
            <Col sm={12}>
                <Card>
                    <CardHeader>
                        Moje přihlášky
                    </CardHeader>
                    <ApplicationsTableDashboard />
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default ParentsApplicationsListView