import React, { useState, useEffect } from 'react'
import { BrowserView, isMobile } from 'react-device-detect';
import { Col, Row } from 'reactstrap';
import { Button, Dropdown } from 'semantic-ui-react';
import { getToken, isAppPaid, sendConfirm, sendReminder, setPaid } from '../../../api';
import { useDispatch } from "react-redux";
import { setMessage } from '../../../actions/messageActions';

function MainPayment({ id, confirmSent, refresh }) {

    const [paidVal, setPaidVal] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingMail, setLoadingMail] = useState(false);
    const dispatch = useDispatch();

    const paidOpt = [
        {key: '1', value: 0, text: 'Nezaplaceno'},
        {key: '2', value: 1, text: 'Zaplaceno'},
    ];

    useEffect(() => {

        setLoading(true)
        getData()
        
    }, [id]);

    function getData() {
        isAppPaid(id)
        .then((res) => {
            setLoading(false)
            setPaidVal(res.data.data)
        });
    }

    function changePaid(val, id) {
        setLoading(true)
        getToken().then((token) => {
            const data = {
                token: token,
                paid: val,
                id: id
            }

            setPaid(data)
            .then((res) => {
                dispatch(setMessage(res))
                setLoading(false)
            })
            .catch((err) => {
                alert('Při změně stavu se vyskytla chyba: ' + err.message)
            })
        })
    }

    function onChangeSelect(e, data) {
        setPaidVal(data.value)
        changePaid(data.value, id)
    }

    function handleSendPaymentEmail() {

        setLoadingMail(true)

        getToken().then((token) => {
            if(paidVal === 0) {
                sendReminder(id, token)
                .then((res) => {
                    dispatch(setMessage(res))
                    setLoadingMail(false)
                    refresh()
                })
            } else {
                sendConfirm(id, token)
                .then((res) => {
                    dispatch(setMessage(res))
                    setLoadingMail(false)
                    refresh()
                })
            }
        })

        
    }

    return (
        <Row>
            <Col sm={6}>
                <label>Stav platby</label>
                {!loading &&
                <Dropdown
                    name='paid'
                    options={paidOpt}
                    placeholder='Zaplaceno / nezaplaceno'
                    selection
                    onChange={onChangeSelect}
                    fluid
                    value={paidVal}
                    className={'state-active-' + paidVal}
                    loading={loading}
                />
                }
            </Col>
            <Col sm={6}>
                <BrowserView>
                    <label>&nbsp;</label>
                </BrowserView>
                <Button 
                    onClick={handleSendPaymentEmail} 
                    negative={paidVal === 0 ? true : false} 
                    positive={paidVal !== 0 && !confirmSent ? true : false} 
                    style={isMobile ? {marginTop: 10} : {height: 38}}
                    loading={loadingMail}
                    fluid={isMobile}
                >
                    {(paidVal) === 0 ? 'Odeslat upomínku' : 'Odeslat potvrzení'}
                </Button>
            </Col>
        </Row>
    )
}

export default MainPayment