import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { getToken, updateDot } from "../../../api";
import { AppDotBtn } from "../../../styles";
import { setMessage } from "../../../actions/messageActions";
import { useDispatch } from "react-redux";

function Dot({ value, name, id, refresh }) {
  const [dotColor, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    color();
  }, [name]);

  function color() {
    switch (name) {
      case "invoice_sent":
        return setColor("#2dce89");
      case "dot1":
        return setColor("#f5365c");
      case "dot2":
        return setColor("#fb6340");
      case "dot3":
        return setColor("#5e72e4");
      default:
        return setColor("#2dce89");
    }
  }

  function onclick() {
    setLoading(true);
    getToken().then((token) => {
      let data = {
        token: token,
        id_app: id,
        name: name,
        value: value === 1 ? 0 : 1,
      };

      updateDot(data)
        .then((res) => {
          dispatch(setMessage(res));
          refresh();
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setMessage({
              text: "Nepodařilo se aktualizovat tečku. Zkuste to prosím později.",
              type: "error",
            })
          );
          setLoading(false);
        });
    });
  }

  function iconName() {
    if (!loading && value === 1) {
      return "circle";
    } else if (!loading && !value) {
      return "circle outline";
    } else if (loading) {
      return "spinner";
    }
  }

  return (
    <>
      <AppDotBtn icon onClick={() => onclick()}>
        <Icon
          className={loading ? "spinning" : ""}
          style={{ color: dotColor }}
          name={iconName()}
        />
      </AppDotBtn>
    </>
  );
}

export default Dot;
