import moment from "moment";
import React, { useState, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import { getEmailsList, getToken } from "../../../api";
import { EmailPane, EmailPaneItem } from "../../../styles";
import { jsonToFormData } from "../../../tools";
import DateEmailForm from "../../forms/DateEmailForm";

function DateEmails({ id }) {
  const [token, setToken] = useState("");
  const [loadingToken, setLoadingToken] = useState(true);
  const [emailsList, setEmailsList] = useState([]);
  const [loadingEmails, setLoadingEmails] = useState(true);
  const [panes, setPanes] = useState([
    {
      menuItem: (
        <EmailPaneItem key="newemail" new={true}>
          <span className="d-block small mb-2">Vytvořit</span>
          <strong>Nový email</strong>
        </EmailPaneItem>
      ),
      render: () => (
        <EmailPane>
          <DateEmailForm
            id={0}
            token={token}
            id_date={id}
            refresh={getEmails}
          />
        </EmailPane>
      ),
    },
  ]);

  useEffect(() => {
    getEmails();
  }, [id]);

  function getEmails() {
    setLoadingEmails(true);
    setLoadingToken(true);

    getToken()
      .then((token) => {
        const data = {
          id_date: id,
          token: token,
        };

        setToken(token);
        setLoadingToken(false);

        const toSend = jsonToFormData(data);

        getEmailsList(toSend)
          .then((res) => {
            setEmailsList(res.data.data);
            preparePanes(res.data.data, token);
          })
          .catch((err) => {
            alert(
              "Nepodařilo se získat historii emailů. Zkuste to prosím později."
            );
            setLoadingEmails(false);
          });
      })
      .catch((err) => {
        setLoadingToken(false);
        alert(
          "Nepodařilo se získat bezpečnostní token. Zkuste prosím aktualizovat stránku."
        );
      });
  }

  function preparePanes(data, userToken) {
    var oldPanes = [];

    oldPanes.push({
      menuItem: (
        <EmailPaneItem key="newemail" new={true}>
          <span className="d-block small mb-2">Vytvořit</span>
          <strong>Nový email</strong>
        </EmailPaneItem>
      ),
      render: () => (
        <EmailPane>
          <DateEmailForm
            id={0}
            token={userToken}
            id_date={id}
            refresh={getEmails}
          />
        </EmailPane>
      ),
    });

    data.map((item) => {
      var pane = {
        menuItem: (
          <EmailPaneItem key={item.id_email}>
            <span className="d-block small mb-2">
              {moment(item.updated).format("DD. MM. YYYY H:mm")}
            </span>
            <strong>{item.subject}</strong>
          </EmailPaneItem>
        ),
        render: () => (
          <EmailPane>
            <DateEmailForm
              id={item.id_email}
              token={userToken}
              id_date={id}
              refresh={getEmails}
            />
          </EmailPane>
        ),
      };
      oldPanes.push(pane);

      setPanes(oldPanes);
    });

    setLoadingEmails(false);
  }

  return (
    <>
      {!loadingEmails && (
        <Tab panes={panes} menu={{ fluid: true, vertical: true }} />
      )}
    </>
  );
}

export default DateEmails;
