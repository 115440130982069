import React, { Component } from "react";
import { connect } from "react-redux";
import { setMessage } from "../../actions/messageActions";
import {
  Card,
  CardBody,
  CardHeader,
  DateItem,
  EmailItem,
  FormButtonGroup,
} from "../../styles";
import {
  getApplicationDetail,
  sendPaymentInfo,
  intToBool,
  createAddPayment,
  confirmAddPayment,
  getToken,
  getDates,
  sendReminderAdd,
  changeKidsDate,
  updatePrice,
  addKidsDate,
  itemEmailHistory,
  toggleNewsletter,
  togglePhotos,
  getAddPayments,
  shortText,
  formatMoney,
  deleteAddPayment,
  sendConfirmAdd,
  updateCoupon,
  getDatesHobby,
  getApplicationBills,
  createBill,
  getApplicationInvoices,
} from "../../api";
import { ParentsForm, KidsDetailForm } from "../forms";
import {
  Dropdown,
  Modal,
  Form,
  Input,
  Button,
  Icon,
  Label,
  Message,
} from "semantic-ui-react";
import moment from "moment";
import AddKidForm from "../forms/AddKidForm";
import { Col } from "reactstrap";
import { getCoupon } from "../../api";
import BillsListContainer from "../containers/BillsListContainer";
import { jsonToFormData } from "../../tools";
import BillCustomTextModal from "../modals/BillCustomTextModal";
import MainPayment from "../containers/applicationdetail/MainPayment";
import Dots from "../containers/applicationdetail/Dots";
import CancelApplicationModal from "../modals/CancelApplicationModal";
import InvoiceDetailsForm from "../forms/invoicing/InvoiceDetailsForm";
import NewInvoiceModal from "../modals/NewInvoiceModal";
import InvoicesList from "../containers/invoices/InvoicesList";

class ApplicationDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dates: [],
      payments: [],
      toChange: {
        old: "",
        new: "",
        kid: "",
      },
      toAdd: {
        new: "",
        kid: "",
      },
      newPayment: {
        subject: "",
        message: "",
        amount: "",
      },
      coupon: {},
      showModal: false,
      addDateModal: false,
      disableModalOpen: false,
      loading: true,
      loadingPayment: false,
      loadingForms: false,
      loadingPrice: false,
      loadingMail: false,
      price: "",
      emailHistory: [],
      emailModal: false,
      hasHistory: false,
      loadingHistory: true,
      addKidModal: false,
      showHasSub: true,
      loadingPayments: true,
      paymentsModal: false,
      hasPayments: false,
      loadingPaymentForm: false,
      loadingBills: true,
      hasBills: false,
      bills: [],
      billCustom: {
        custom_text: "",
        payment_date: new Date(),
        payment_date_formatted: moment().format("D. M. YYYY"),
      },
      loadingPaymentInfo: false,
      invoices: [],
    };
  }

  componentDidMount() {
    this.refresh();
    //this.emailHistoryHandler()
    getDates(1).then((data) => {
      this.setState(
        {
          dates: data,
        },
        () => {
          getDatesHobby("hobby").then((data) => {
            if (data.length !== 0) {
              this.setState({
                dates: this.state.dates.concat(data),
              });
            }
          });
          getDatesHobby("kurzy").then((data) => {
            if (data.length !== 0) {
              this.setState({
                dates: this.state.dates.concat(data),
              });
            }
          });
          getDatesHobby("skolka").then((data) => {
            if (data.length !== 0) {
              this.setState({
                dates: this.state.dates.concat(data),
              });
            }
          });
        }
      );
    });
  }

  refresh = () => {
    this.setState({
      loadingForms: true,
    });

    const { id } = this.props.match.params;

    getApplicationDetail(id)
      .then((data) => {
        this.setState({
          data: data,
          type: data.parents.type,
          loading: false,
          loadingForms: false,
          price: data.parents.total_price,
        });
        this.getAddPaymentsHandler();
        this.emailHistoryHandler();
        this.getBills();
        this.getInvoices();
      })
      .catch((err) => {
        alert("Error while reloading data");
      });
  };

  getBills = () => {
    const { id } = this.props.match.params;

    getToken().then((token) => {
      getApplicationBills(id, token)
        .then((res) => {
          if (res.data.type === "success") {
            this.setState({
              bills: res.data.data,
              loadingBills: false,
              hasBills: res.data.data.length !== 0 ? true : false,
            });
          } else {
            this.setState({
              bills: [],
              loadingBills: false,
              hasBills: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loadingBills: false,
            hasBills: false,
          });
          console.log(err);
          alert("Nepodařilo se získat seznam pokladních dokladů");
        });
    });
  };

  getInvoices = () => {
    const { id } = this.props.match.params;

    getToken().then((token) => {
      getApplicationInvoices(id, token)
        .then((res) => {
          if (res.data.type === "success") {
            this.setState({
              invoices: res.data.data,
              loadingInvoices: false,
              hasInvoices: res.data.data.length !== 0 ? true : false,
            });
          } else {
            this.setState({
              invoices: [],
              loadingInvoices: false,
              hasInvoices: false,
            });
          }
        })
        .catch((err) => {
          alert("Vyskytla se chyba při stahování faktur");
        });
    });
  };

  _createBill = () => {
    this.setState({
      loadingBills: true,
    });

    const { id } = this.props.match.params;

    var data = {
      id_app: id,
      custom_text: this.state.billCustom.custom_text,
      payment_date: this.state.billCustom.payment_date_formatted,
    };

    var fd = jsonToFormData(data);

    createBill(fd)
      .then((res) => {
        this.props.setMessage(res.data);
        this.refresh();
        this.setState({
          loadingBills: false,
          billCustom: {
            custom_text: "",
            payment_date: new Date(),
            payment_date_formatted: moment().format("D. M. YYYY"),
          },
        });
        this.closeCustomBillModal();
      })
      .catch((err) => {
        this.props.setMessage({
          type: "error",
          message:
            "Pokladní doklad se nepodařilo vytvořit. Zkuste to prosím později.",
        });
        this.setState({
          loadingBills: false,
          billCustom: {
            custom_text: "",
            payment_date: new Date(),
            payment_date_formatted: moment().format("D. M. YYYY"),
          },
        });
        this.closeCustomBillModal();
      });
  };

  emailHistoryHandler = () => {
    const { id } = this.props.match.params;

    this.setState({
      loadingHistory: true,
    });

    getToken().then((token) => {
      const data = {
        token: token,
        id_send_to: id,
      };

      itemEmailHistory(data).then((data) => {
        this.setState({
          emailHistory: data,
          hasHistory: data.type && data.type === "error" ? false : true,
          loadingHistory: false,
        });
      });
    });
  };

  getAddPaymentsHandler = () => {
    const { id } = this.props.match.params;

    this.setState({
      loadingPayments: true,
    });

    getToken().then((token) => {
      const data = {
        token: token,
        id_application: id,
      };

      getAddPayments(data).then((data) => {
        this.setState({
          payments: data.data,
          hasPayments: data.type !== "error" ? true : false,
          loadingPayments: false,
        });
      });
    });
  };

  toggleNewsletterHandler = () => {
    getToken().then((token) => {
      const data = {
        token: token,
        id: this.state.data.parents.id_parents,
      };

      toggleNewsletter(data).then((res) => {
        this.props.setMessage(res);
        this.refresh();
      });
    });
  };

  togglePhotosHandler = () => {
    getToken().then((token) => {
      const data = {
        token: token,
        id: this.state.data.parents.id_parents,
      };

      togglePhotos(data).then((res) => {
        this.props.setMessage(res);
        this.refresh();
      });
    });
  };

  changeDate = (date, kid) => {
    this.setState({
      toChange: {
        ...this.state.toChange,
        old: date,
        kid: kid,
      },
      showModal: true,
    });
  };

  addDate = (kid) => {
    this.setState({
      toAdd: {
        kid: kid,
      },
      addDateModal: true,
    });
  };

  setAddNewDate = (date) => {
    this.setState(
      {
        toAdd: {
          ...this.state.toAdd,
          new: date,
        },
        loadingForms: true,
      },
      () => {
        this.addDateHandler();
      }
    );
  };

  setNewDate = (date, kid) => {
    this.setState(
      {
        toChange: {
          ...this.state.toChange,
          new: date,
        },
        loadingForms: true,
      },
      () => {
        this.changeDateHandler();
        this.handleHideModal();
      }
    );
  };

  addDateHandler = () => {
    const { id } = this.props.match.params;

    getToken().then((token) => {
      const data = {
        token: token,
        kid: this.state.toAdd.kid,
        new: this.state.toAdd.new,
        id: id,
      };

      addKidsDate(data).then((res) => {
        this.props.setMessage(res);
        this.setState({
          toAdd: {
            new: "",
            kid: "",
          },
        });
        this.handleAddHideModal();
        this.refresh();
      });
    });
  };

  changeDateHandler = () => {
    changeKidsDate(this.state.toChange).then((res) => {
      this.props.setMessage(res);
      this.setState({
        toChange: {
          new: "",
          old: "",
          kid: "",
        },
      });

      this.refresh();
    });
  };

  addNewPaymentHandler = () => {
    const { newPayment } = this.state;
    const { id } = this.props.match.params;

    if (
      newPayment.subject !== "" &&
      newPayment.amount !== "" &&
      newPayment.message !== ""
    ) {
      this.setState({
        loadingPaymentForm: true,
      });
      getToken().then((token) => {
        const data = {
          token: token,
          subject: newPayment.subject,
          amount: Number(newPayment.amount),
          message: newPayment.message,
          id_application: id,
        };

        createAddPayment(data)
          .then((res) => {
            if (res.type === "success") {
              this.refresh();
              this.clearNewPayment();
            }
            this.props.setMessage(res);
          })
          .then((res) => {
            this.setState({
              loadingPaymentForm: false,
            });
          });
      });
    }
  };

  deleteAddPaymentHandler = (id) => {
    getToken().then((token) => {
      const data = {
        token: token,
        id_payment: id,
      };

      deleteAddPayment(data).then((res) => {
        this.props.setMessage(res);
        this.getAddPaymentsHandler();
      });
    });
  };

  confirmAddPaymentHandler = (id_payment) => {
    const { id } = this.props.match.params;
    getToken().then((token) => {
      const data = {
        token: token,
        id_payment: id_payment,
        id_application: id,
      };

      confirmAddPayment(data).then((res) => {
        this.props.setMessage(res);
        this.getAddPaymentsHandler();
      });
    });
  };

  clearNewPayment = () =>
    this.setState({ newPayment: { subject: "", message: "", amount: "" } });

  handleOpenModal = () => this.setState({ showModal: true });
  handleHideModal = () =>
    this.setState({ showModal: false, loadingForms: false });
  handleAddOpenModal = () => this.setState({ addDateModal: true });
  handleAddHideModal = () =>
    this.setState({ addDateModal: false, loadingForms: false });
  openAddKid = () => this.setState({ addKidModal: true, loadingForms: false });
  closeAddKid = () =>
    this.setState({ addKidModal: false, loadingForms: false });
  handleEmailOpenModal = () => {
    this.setState({ emailModal: true });
    this.emailHistoryHandler();
  };
  handleEmailCloseModal = () => this.setState({ emailModal: false });
  handlePaymentsCloseModal = () => this.setState({ paymentsModal: false });
  handlePaymentsOpenModal = () => {
    this.setState({ paymentsModal: true });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onChangePayment = (e) =>
    this.setState({
      newPayment: {
        ...this.state.newPayment,
        [e.target.name]: e.target.value,
      },
    });

  updatePriceHandler = () => {
    this.setState({
      loadingPrice: true,
    });

    const { id } = this.props.match.params;

    getToken().then((token) => {
      const data = {
        token: token,
        price: this.state.price,
        id: id,
      };

      updatePrice(data).then((res) => {
        this.props.setMessage(res);

        this.setState({
          loadingPrice: false,
        });

        this.refresh();
      });
    });
  };

  _sendConfirmAdd = (id, payment_num) => {
    const data = {
      id_payment: id,
      code: payment_num,
      email: this.state.data.parents.parent_email,
    };

    sendConfirmAdd(data).then((res) => {
      this.props.setMessage(res);
      this.getAddPaymentsHandler();
      this.emailHistoryHandler();
    });
  };

  _sendReminderAdd = (id, payment_num, price) => {
    const data = {
      id_payment: id,
      code: payment_num,
      price: price,
      email: this.state.data.parents.parent_email,
    };

    sendReminderAdd(data).then((res) => {
      this.props.setMessage(res);
      this.getAddPaymentsHandler();
      this.emailHistoryHandler();
    });
  };

  convertEmailType = (type) => {
    if (type === "reminder") {
      return "Upomínka platby";
    } else if (type === "payment-info") {
      return "Pokyny k platbě";
    } else if (type === "confirm-payment") {
      return "Potvrzení platby";
    } else if (type === "thank-you") {
      return "Potvrzení registrace";
    } else if (type === "change-date") {
      return "Změna termínu";
    } else if (type === "canceled-date") {
      return "Zrušení termínu";
    } else if (type === "new-add-payment") {
      return "Doplňková platba";
    } else if (type === "bill") {
      return "Doklad o platbě";
    } else if (type === "price-increase-payment") {
      return "Zdražení";
    } else {
      return "Neznámý typ";
    }
  };

  onChangeCoupon = (e) => {
    getCoupon(e.target.value).then((res) => {
      console.log(res);
      if (res.isCoupon) {
        this.setState({
          coupon: {
            is_voucher: res.is_voucher,
            id_voucher: res.is_voucher ? res.id_voucher : null,
            coupon_value: res.coupon_value,
            id_coupon: res.id_coupon,
          },
          coupon_message: "",
          is_coupon: true,
        });
      } else {
        this.setState({
          coupon_message: "Toto není platný kód",
          is_coupon: false,
        });
      }
    });
  };

  _updateCoupon = () => {
    this.setState({
      loadingUpdateCoupon: true,
    });

    const { id_coupon, id_voucher, is_voucher, coupon_value } =
      this.state.coupon;
    const { id } = this.props.match.params;

    getToken().then((token) => {
      const data = {
        token: token,
        coupon_value: coupon_value,
        id_coupon: id_coupon,
        id_voucher: id_voucher,
        is_voucher: is_voucher,
        id_item: id,
      };

      updateCoupon(data).then((res) => {
        this.props.setMessage(res);
        this.refresh();
        this.setState({
          coupon: {},
          loadingUpdateCoupon: false,
          coupon_message: "",
        });
      });
    });
  };

  _renderKids = () => {
    const { id } = this.props.match.params;

    return this.state.data.kids.map((kid) => {
      return (
        <div className="row" key={kid.id_kids}>
          <div className="col-sm-12">
            <Card className="mt-3">
              <CardHeader>
                <div className="row">
                  <div className="col-sm-6 text-left">
                    <h2>
                      {kid.kids_name} {kid.kids_lastname}
                    </h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <KidsDetailForm
                  applicationId={id}
                  kid={kid}
                  type={this.state.type}
                  refresh={this.refresh}
                  email={this.state.data.parents.parent_email}
                  changeDate={this.changeDate}
                  addDate={this.addDate}
                  loadedDates={this.state.dates}
                  loading={this.state.loadingForms}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      );
    });
  };

  _renderEmails = () => {
    return this.state.emailHistory.map((item) => {
      return (
        <EmailItem key={item.id_email}>
          <div className="row align-items-center">
            <div
              className={
                this.props.user.user_admin === 1 ? "col-sm-3" : "col-sm-6"
              }
            >
              <p className="bold">{this.convertEmailType(item.email_type)}</p>
            </div>
            {this.props.user.user_admin === 1 && (
              <div className="col-sm-5">
                <p>{item.email_send_to}</p>
              </div>
            )}
            <div
              className={
                this.props.user.user_admin === 1 ? "col-sm-4" : "col-sm-6"
              }
            >
              <p>{moment(item.created).format("LLL")}</p>
            </div>
          </div>
        </EmailItem>
      );
    });
  };

  _renderBills = () => {
    const { id } = this.props.match.params;
    return (
      <BillsListContainer
        id={id}
        bills={this.state.bills}
        hasBills={this.state.hasBills}
        loading={this.state.loadingBills}
        refresh={this.getBills}
      />
    );
  };

  _renderPayments = () => {
    const { id } = this.props.match.params;

    return this.state.payments.map((item) => {
      var payment_num = id + "00" + item.id_payment;
      return (
        <EmailItem key={item.id_payment}>
          <div className="row">
            <Col
              sm={2}
              className="d-flex justify-content-between align-items-start"
            >
              <Dropdown icon="ellipsis vertical">
                <Dropdown.Menu>
                  {item.paid !== 1 &&
                    this._sureConfirmPaymentModal(item.id_payment, payment_num)}
                  {item.paid !== 1 && <Dropdown.Divider />}
                  {item.paid === 0 && (
                    <Dropdown.Item
                      text="Odeslat upomínku"
                      onClick={() =>
                        this._sendReminderAdd(
                          item.id_payment,
                          payment_num,
                          item.amount
                        )
                      }
                    />
                  )}
                  <Dropdown.Item
                    disabled={item.send_confirm === 1 ? true : false}
                    text={
                      item.send_confirm === 1
                        ? "Potvrzení odesláno"
                        : "Odeslat potvrzení"
                    }
                    onClick={() =>
                      this._sendConfirmAdd(item.id_payment, payment_num)
                    }
                  />
                  <Dropdown.Divider />
                  {this._sureDeletePaymentModal(item.id_payment, payment_num)}
                </Dropdown.Menu>
              </Dropdown>
              <Icon
                name={
                  item.paid === 1
                    ? "check circle outline"
                    : "times circle outline"
                }
                className="add_payment_state"
              />
            </Col>
            <Col sm={10}>
              <div className="d-flex justify-content-between mb-2 payment_header">
                <div>
                  <p>{formatMoney(item.amount, ",", ".")} Kč</p>
                </div>
                <div>
                  <p>#{payment_num}</p>
                </div>
                <div>
                  <p>{moment(item.created).format("D.M.YYYY H:mm")}</p>
                </div>
              </div>
              <div className="payment_text">
                <div>
                  <p>{shortText(item.subject, 15)}</p>
                </div>
                <div>
                  <p>{shortText(item.message, 80)}</p>
                </div>
              </div>
            </Col>
          </div>
        </EmailItem>
      );
    });
  };

  _sureDeletePaymentModal = (id, num) => {
    return (
      <Modal trigger={<Dropdown.Item text="Smazat platbu" />}>
        <Modal.Header>Opravdu chcete platbu #{num} smazat?</Modal.Header>
        <Modal.Content>
          <p>Smazání platby je nevratné. Informujte o zrušení platby rodiče.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button danger onClick={() => this.deleteAddPaymentHandler(id)}>
            Smazat platbu
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  _sureConfirmPaymentModal = (id, num) => {
    return (
      <Modal trigger={<Dropdown.Item text="Potvrdit zaplacení" />}>
        <Modal.Header>Opravdu byla platba #{num} zaplacena?</Modal.Header>
        <Modal.Content>
          <p>
            Po potvrzení platby bude rodičovi odeslán email přijetí platby
            emailem.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => this.confirmAddPaymentHandler(id)}>
            Potvrdit platbu
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  _renderAdditinalPaymentsModal = () => {
    return (
      <Modal
        open={this.state.paymentsModal}
        onClose={() => this.handlePaymentsCloseModal()}
        closeIcon
        size="large"
      >
        <Modal.Header>
          <div className="row">
            <div className="col-sm-6">Doplňkové platby</div>
            <div className="col-sm-6 justify-content-end">
              {this._renderCreatePaymentModal()}
            </div>
          </div>
        </Modal.Header>
        <Modal.Content>
          {!this.state.loadingPayments &&
            this.state.hasPayments &&
            this._renderPayments()}
          {this.state.loadingPayments && (
            <EmailItem>
              <div className="row">
                <div className="col-sm-12 text-center">
                  <p>Načítám historii</p>
                </div>
              </div>
            </EmailItem>
          )}
          {!this.state.loadingPayments && !this.state.hasPayments && (
            <EmailItem>
              <div className="row">
                <div className="col-sm-12 text-center">
                  <p>Tato přihláška nemá žádné doplňkové platby</p>
                </div>
              </div>
            </EmailItem>
          )}
        </Modal.Content>
      </Modal>
    );
  };

  _renderAdditionalPayments = () => {
    return (
      <div>
        {!this.state.loadingPayments &&
          this.state.hasPayments &&
          this._renderPayments()}
        {this.state.loadingPayments && (
          <EmailItem>
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>Načítám historii</p>
              </div>
            </div>
          </EmailItem>
        )}
        {!this.state.loadingPayments && !this.state.hasPayments && (
          <EmailItem>
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>Tato přihláška nemá žádné doplňkové platby</p>
              </div>
            </div>
          </EmailItem>
        )}
      </div>
    );
  };

  _renderCreatePaymentModal = () => {
    return (
      <Modal
        trigger={
          <Button icon>
            <Icon name="plus" />
          </Button>
        }
      >
        <Modal.Header>Nová doplňková platba</Modal.Header>
        <Form
          onSubmit={this.addNewPaymentHandler}
          loading={this.state.loadingPaymentForm}
        >
          <Modal.Content>
            <Form.Field>
              <Form.Input
                label="Předmět platby"
                type="text"
                placeholder="Zadejte předmět patby"
                name="subject"
                value={this.state.newPayment.subject}
                onChange={this.onChangePayment}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Částka"
                type="number"
                placeholder="Zadejte částku"
                name="amount"
                value={this.state.newPayment.amount}
                onChange={this.onChangePayment}
              />
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                label="Zpráva"
                placeholder="Zadejte zprávu pro rodiče"
                name="message"
                value={this.state.newPayment.message}
                onChange={this.onChangePayment}
              />
            </Form.Field>
          </Modal.Content>
          <Modal.Actions>
            <Button primary>Vytvořit platbu</Button>
          </Modal.Actions>
        </Form>
      </Modal>
    );
  };

  _renderNewKidModal = () => {
    return (
      <Modal
        open={this.state.addKidModal}
        onClose={() => this.closeAddKid()}
        closeIcon
        size="large"
      >
        <Modal.Header>Přidat dítě</Modal.Header>
        <Modal.Content>
          <AddKidForm
            id={this.state.data.parents.id_parents}
            close={this.closeAddKid}
            refresh={this.refresh}
          />
        </Modal.Content>
      </Modal>
    );
  };

  _renderDates = ({ action }) => {
    return this.state.dates.map((date) => {
      return (
        <DateItem className="row" key={date.id_date}>
          <div className="col-sm-2 camp_name_uppercase">
            {date.camp_name}
            <br />
            <span
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                color: "#fff",
                fontWeight: "700",
                fontSize: 12,
                textTransform: "none",
                background: "#FF9630",
                borderRadius: 500,
                display: "inline-block",
                marginTop: 10,
              }}
            >
              {date.full_price},- Kč
            </span>
          </div>
          <div className="col-sm-2">
            {moment(date.date_from).format("D. M.")} -{" "}
            {moment(date.date_to).format("D. M. YYYY")}
          </div>
          <div className="col-sm-4">{date.location_address}</div>
          <div className="col-sm-2">
            volné {date.date_capacity - date.date_signed}/{date.date_capacity}
          </div>
          <div className="col-sm-2 text-right">
            {action === "change" && (
              <Button fluid onClick={() => this.setNewDate(date.id_date)}>
                Vybrat
              </Button>
            )}
            {action === "new" && (
              <Button fluid onClick={() => this.setAddNewDate(date.id_date)}>
                Přidat
              </Button>
            )}
          </div>
        </DateItem>
      );
    });
  };

  _renderAgreements = (data) => {
    const { id } = this.props.match.params;

    return (
      <div className="row agreements">
        <div className="col-sm-2 col-12">
          <p>ID</p>
          <span className="as-icon">#{id}</span>
        </div>
        <div className="col-sm-3 col-6">
          <p>Obchodní podmínky</p>
          {intToBool(data.parent_tos) ? (
            <i className="icofont-check-circled icon-paid"></i>
          ) : (
            <i className="icofont-close-circled icon-paid"></i>
          )}
        </div>
        <div className="col-sm-2 col-6">
          <p>Osobní údaje</p>
          {intToBool(data.parent_gdpr) ? (
            <i className="icofont-check-circled icon-paid"></i>
          ) : (
            <i className="icofont-close-circled icon-paid"></i>
          )}
        </div>
        <div className="col-sm-2 col-6">
          <p>Focení</p>
          {intToBool(data.parent_photos) ? (
            <i
              className="icofont-check-circled icon-state icon-paid"
              onClick={this.togglePhotosHandler}
            ></i>
          ) : (
            <i
              className="icofont-close-circled icon-state icon-paid"
              onClick={this.togglePhotosHandler}
            ></i>
          )}
        </div>
        <div className="col-sm-3 col-6">
          <p>Newsletter</p>
          {intToBool(data.parent_newsletter) ? (
            <i
              className="icofont-check-circled icon-state icon-paid"
              onClick={this.toggleNewsletterHandler}
            ></i>
          ) : (
            <i
              className="icofont-close-circled icon-state icon-paid"
              onClick={this.toggleNewsletterHandler}
            ></i>
          )}
        </div>
      </div>
    );
  };

  _renderPriceLabel = () => {
    return <div className="ui label label">Kč</div>;
  };

  _renderEmailsHistory = () => {
    return (
      <div>
        {!this.state.loadingHistory &&
          this.state.hasHistory &&
          this._renderEmails()}
        {this.state.loadingHistory && (
          <EmailItem>
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>Načítám historii</p>
              </div>
            </div>
          </EmailItem>
        )}
        {!this.state.loadingHistory && !this.state.hasHistory && (
          <EmailItem>
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>U této přihlášky není historie zpráv dostupná</p>
              </div>
            </div>
          </EmailItem>
        )}
      </div>
    );
  };

  _renderPriceOptions = () => {
    const { id } = this.props.match.params;

    return (
      <div className="row">
        <div className="col-sm-12">
          <Form loading={this.state.loadingPayment}>
            <Form.Field>
              {!this.state.loadingForms && (
                <MainPayment
                  id={id}
                  confirmSent={this.state.data.parents.send_confirm}
                  refresh={this.refresh}
                />
              )}
            </Form.Field>
            <Form.Field className="relative">
              <label>Celková cena</label>
              <Input
                type="text"
                name="price"
                value={this.state.price}
                label={this._renderPriceLabel()}
                labelPosition="right"
                onChange={this.onChange}
              />
              <Button
                positive
                onClick={this.updatePriceHandler}
                className="inside_input"
                loading={this.state.loadingPrice}
              >
                Uložit cenu
              </Button>
            </Form.Field>
            <Form.Field className="relative">
              {this.state.data.parents.id_voucher === null && (
                <Form.Input
                  type="text"
                  label="Použitý kód"
                  defaultValue={
                    this.state.data.parents.coupon_text !== null
                      ? this.state.data.parents.coupon_text
                      : ""
                  }
                  onChange={this.onChangeCoupon}
                />
              )}
              {this.state.data.parents.id_voucher !== null && (
                <Form.Input
                  type="text"
                  label="Použitý kód"
                  defaultValue={
                    this.state.data.parents.code !== null
                      ? this.state.data.parents.code
                      : ""
                  }
                  onChange={this.onChangeCoupon}
                />
              )}
              <p className="small">{this.state.coupon_message}</p>
              <Button
                positive
                onClick={this._updateCoupon}
                loading={this.state.loadingUpdateCoupon}
                disabled={!this.state.is_coupon}
                className={
                  this.state.is_coupon
                    ? "green inside_input no_label"
                    : "inside_input no_label"
                }
              >
                Uložit kód
              </Button>
            </Form.Field>
          </Form>
          <div>
            <div className="row mt-4 d-flex align-items-center">
              <div className="col-sm-9">
                <h4 className="heading_separator">Doplňkové platby</h4>
              </div>
              <div className="col-sm-3 justify-content-end align-items-center">
                {this._renderCreatePaymentModal()}
              </div>
            </div>
            {this._renderAdditionalPayments()}
          </div>
        </div>
      </div>
    );
  };

  _renderSubMessage = () => {
    return (
      <Label className="has_sub_message">
        Tato přihláška obsahuje náhradníka
        <Icon name="delete" onClick={this.closeHasSub} />
      </Label>
    );
  };

  closeHasSub = () => {
    this.setState({ showHasSub: false });
  };

  openCustomBillModal = () => {
    this.setState({ showCustomBillModal: true });
  };

  closeCustomBillModal = () => {
    this.setState({ showCustomBillModal: false });
  };

  onChangeCustomText = (e) =>
    this.setState({
      billCustom: {
        ...this.state.billCustom,
        [e.target.name]: e.target.value,
      },
    });

  onChangeBillCustomDate = (date) =>
    this.setState(
      {
        billCustom: {
          ...this.state.billCustom,
          payment_date: date,
          payment_date_formatted: moment(date).format("D. M. YYYY"),
        },
      },
      () => console.log("bill custom", this.state.billCustom)
    );

  _sendPaymentInfo = () => {
    const { id } = this.props.match.params;

    this.setState({
      loadingPaymentInfo: true,
    });

    getToken()
      .then((token) => {
        sendPaymentInfo(id, token)
          .then((res) => {
            this.props.setMessage(res);
            this.refresh();
            this.setState({
              loadingPaymentInfo: false,
            });
          })
          .catch((err) => {
            this.props.setMessage({
              type: "error",
              text: "Pokyny k platbě se nepodařilo odeslat. Zkuste to prosím později.",
            });
            this.refresh();
            this.setState({
              loadingPaymentInfo: false,
            });
          });
      })
      .catch((err) => {
        this.props.setMessage({
          type: "error",
          text:
            "Nepodařilo se získat bezpečnostní token z důvodu:" +
            err.message +
            ". Zkuste to prosím později.",
        });
      });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <Card className="mb-3">
              <CardBody>
                {!this.state.loading &&
                  this._renderAgreements(this.state.data.parents)}
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-12">
                {!this.state.loading && this.state.data.parents.state === 0 && (
                  <Message negative>
                    <Message.Header>
                      Tato přihláška byla stornována
                    </Message.Header>
                  </Message>
                )}
                <Card>
                  <CardHeader>
                    <div className="row">
                      <div className="col-sm-6 text-left">
                        <h2>Rodiče</h2>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {!this.state.loading && (
                      <ParentsForm
                        parents={this.state.data.parents}
                        isAdmin={
                          this.props.user.user_admin === 1 ? true : false
                        }
                        loading={this.state.loadingForms}
                        refresh={this.refresh}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
            {!this.state.loading && this._renderKids()}
            <FormButtonGroup>
              <Button onClick={this.openAddKid}>Přidat dítě</Button>
            </FormButtonGroup>
          </div>
          <div className="col-sm-4">
            <Card className="mb-3">
              <CardBody>
                <Dots id={id} />
              </CardBody>
            </Card>
            {!this.state.loading && this.state.data.parents.state === 1 && (
              <Card className="mb-3">
                <CardBody>
                  <CancelApplicationModal
                    type="application-detail"
                    id={id}
                    refresh={this.refresh}
                  />
                </CardBody>
              </Card>
            )}
            <Card className="mb-3">
              <CardHeader className="d-flex justify-content-between align-items-center">
                Platby
                <Button
                  onClick={this._sendPaymentInfo}
                  loading={this.state.loadingPaymentInfo}
                >
                  Odeslat pokyny k platbě
                </Button>
              </CardHeader>
              <CardBody>
                {!this.state.loading && this._renderPriceOptions()}
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardHeader className="d-flex justify-content-between align-items-center">
                Doklady o platbě{" "}
                <div>
                  <BillCustomTextModal
                    loading={this.state.loadingBills}
                    payment_date={this.state.billCustom.payment_date}
                    onChangeDate={this.onChangeBillCustomDate}
                    onChange={this.onChangeCustomText}
                    value={this.state.billCustom.custom_text}
                    open={this.state.showCustomBillModal}
                    onClose={this.closeCustomBillModal}
                    onOpen={this.openCustomBillModal}
                    submit={this._createBill}
                  />{" "}
                  <Button
                    disabled={this.state.loadingBills}
                    onClick={() => this._createBill()}
                  >
                    Vytvořit
                  </Button>
                </div>
              </CardHeader>
              <CardBody>{this._renderBills()}</CardBody>
            </Card>

            <Card className="mb-3">
              <CardHeader className="d-flex justify-content-between align-items-center">
                <>Faktury</>{" "}
                {!this.state.loading && (
                  <NewInvoiceModal
                    refresh={this.getInvoices}
                    invoiceDetails={this.state.data.invoice_details}
                    idApplication={id}
                  />
                )}
              </CardHeader>
              <CardBody>
                <InvoicesList
                  invoices={this.state.invoices}
                  loading={this.state.loadingInvoices}
                  hasBills={this.state.hasInvoices}
                />
                {!this.state.loading && (
                  <InvoiceDetailsForm
                    refresh={this.refresh}
                    invoiceDetailsPayload={this.state.data.invoice_details}
                    invoiceCompanyPayload={this.state.data.invoice_company}
                  />
                )}
              </CardBody>
            </Card>

            <Card className="mb-3">
              <CardHeader>Historie emailů</CardHeader>
              <CardBody>
                {!this.state.loading && this._renderEmailsHistory()}
              </CardBody>
            </Card>
          </div>
        </div>
        {!this.state.loading && this._renderNewKidModal()}
        {this.state.data.has_sub &&
          this.state.showHasSub &&
          this._renderSubMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { setMessage })(ApplicationDetailView);
