import axios from "axios";
import { url } from "../config";

export function getList(token) {
  return axios({
    method: "get",
    url: `${
      url.api
    }referrals/get-list?token=${token}&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}
