import React, { useEffect, useState } from "react";
import { getList } from "../../../api/referrals";
import { Card, CardHeader } from "../../../styles";
import ReferralsLogTable from "../../tables/Referrals/ReferralsLogTable";

function ReferralsLogView() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getList().then((res) => {
      setData(res.data);
    });
  }, []);
  return (
    <Card>
      <CardHeader>
        <span>Seznam doporučení</span>
      </CardHeader>
      <ReferralsLogTable data={data} />
    </Card>
  );
}

export default ReferralsLogView;
