import React from "react";
import { Col, Row } from "reactstrap";
import { DateItem, DateItemLabel } from "../../../styles";
import { applicationsLang as lang } from "../../../lang/lang";
import { Button, Popup, Icon } from "semantic-ui-react";
import moment from "moment";

function DateItemMobile({ date, isWeekly }) {
  function renderPriceCol(lower_price, full_price) {
    if (full_price !== lower_price) {
      return (
        <>
          <Popup
            content={lang.price_for_individuals}
            position="top center"
            trigger={<Icon name="user" />}
          />{" "}
          <span className="bold">{full_price},-&nbsp;Kč</span>
          <br />
          <Popup
            content={lang.price_for_siblings}
            position="top center"
            trigger={<Icon name="users" />}
          />{" "}
          <span className="bold">{lower_price},-&nbsp;Kč</span>
        </>
      );
    } else if (
      full_price === lower_price &&
      full_price !== 0 &&
      lower_price !== 0
    ) {
      return <span className="bold">{full_price},-&nbsp;Kč</span>;
    } else if (full_price === 0 || lower_price === 0) {
      return <span className="bold">{lang.free}</span>;
    }
  }

  return (
    <DateItem
      key={date.id_date}
      className={
        date.date_capacity - date.date_signed <= 0 ? "row full_date" : "row"
      }
    >
      <Row className="m-0">
        <Col className="p-0 col-5">
          <img
            className="img-fluid"
            alt={date.camp_name}
            src={
              "https://api.sportjoy.cz/media/upload/camp/" +
              date.id_camp +
              "/" +
              date.camp_logo
            }
          />
        </Col>
        <Col className="d-flex align-items-center pt-2">
          <div>
            <Row>
              <Col className="col-2 pr-0 pl-2">
                <Icon name="calendar outline" />
              </Col>
              <Col className="col-10 p-0">
                <span className="bold d-block">
                  {moment(date.date_from).format("D. M.")} -{" "}
                  {moment(date.date_to).format("D. M. YYYY")}
                  {isWeekly && (
                    <>
                      <br /> {moment(date.date_from).format("dddd")}{" "}
                      {moment(date.start_time).format("H:mm")}
                      {date.end_time && (
                        <> - {moment(date.end_time).format("H:mm")}</>
                      )}
                    </>
                  )}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="col-2 pr-0 pl-2">
                <Icon name="map marker alternate" />
              </Col>
              <Col className="col-10 p-0">
                <span className="bold d-block">{date.location_name}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="w-100 m-0 mt-2">
        <Col className="mb-3 mb-sm-0 col-3">
          <DateItemLabel>Věk dítěte</DateItemLabel>
          <span className="bold">
            {date.min_age}-{date.max_age} let
          </span>
        </Col>
        <Col className="mb-4 mb-sm-0 col-4">
          <DateItemLabel>Obsazenost</DateItemLabel>
          <span className="bold">
            {date.is_full === 0 ? "Volné" : "Obsazené"}
          </span>
        </Col>
        <Col className="mb-3 mb-sm-0">
          <DateItemLabel>{lang.price}</DateItemLabel>
          {renderPriceCol(date.lower_price, date.full_price)}
        </Col>
      </Row>
      <Col sm={12} className="d-sm-flex justify-content-end pl-0 pr-0 pr-sm-4">
        <Button
          as="a"
          className={date.is_full === 0 ? "free" : "full"}
          href={
            isWeekly
              ? "https://app.sportjoy.cz/embed/prihlaska/hobby/"
              : "https://app.sportjoy.cz/embed/signup/camps"
          }
          target="_blank"
        >
          {date.date_capacity - date.date_signed <= 0
            ? lang.as_substitute
            : "Přihláška"}
        </Button>
      </Col>
      {/*<Row>
                <Col sm={2} className='camp_name_uppercase p-0'>
                    <img className='img-fluid' alt={date.camp_name} src={'https://api.sportjoy.cz/media/upload/camp/' + date.id_camp + '/' + date.camp_logo} />
                </Col>
            </Row>
            <Row>
                <Col sm={3} className='pl-sm-5 col-sm-3 mb-3 mb-sm-0 mt-3 mt-sm-0'>
                    <span className='bold d-block'>{moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}</span>
                    {date.location_address}
                </Col>
            </Row>
            <Row>
                <Col className='mb-3 mb-sm-0'>
                    <DateItemLabel>{lang.date}</DateItemLabel>
                    <span className='bold'>{moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}</span>
                </Col>
                <Col className='mb-3 mb-sm-0'>
                    <DateItemLabel>{lang.price}</DateItemLabel>
                    {renderPriceCol(date.lower_price, date.full_price)}
                </Col>
            </Row>
            <Row>
                <Col sm={12} className='d-sm-flex justify-content-end pl-0 pr-0 pr-sm-4'>
                    <Button as='a' href='#'>{date.date_capacity - date.date_signed <= 0 ? lang.as_substitute : lang.select_session}</Button>
                </Col>
            </Row>*/}
    </DateItem>
  );
}

export default DateItemMobile;
