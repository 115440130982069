import React, { Component } from 'react'
import { Form, TextArea, Button, Dropdown } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';
import { getToken, addKid, getMinAge, getMaxAge } from '../../api'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'

const classes = [
    {key: '0', value: 0, text: 'Žádná'},
    {key: '1', value: 1, text: '1. třída'},
    {key: '2', value: 2, text: '2. třída'},
    {key: '3', value: 3, text: '3. třída'},
    {key: '4', value: 4, text: '4. třída'},
    {key: '5', value: 5, text: '5. třída'},
    {key: '6', value: 6, text: '6. třída'},
    {key: '7', value: 7, text: '7. třída'},
    {key: '8', value: 8, text: '8. třída'},
    {key: '9', value: 9, text: '9. třída'}
]

class AddKidForm extends Component {

    constructor(props) {
        super(props)
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = ({
            buttonActive: true,
            isFilled: false,
            min_age: '',
            max_age: '',
            loading: false,
            basicKid: [{
                label: 'Jméno',
                type: 'text',
                name: 'kids_name',
                placeholder: 'Jméno',
                id: 'kids_name',
                required: true
            },
            {
                label: 'Příjmení',
                type: 'text',
                name: 'kids_lastname',
                placeholder: 'Příjmení',
                id: 'kids_lastname',
                required: true
            },
            {
                label: 'Datum narození',
                type: 'date',
                name: 'kids_birthday',
                placeholder: 'Datum narození',
                id: 'kids_birthday',
                required: true
            },
            {
                label: 'Do které třídy dítě chodí',
                type: 'dropdown',
                name: 'kids_class',
                placeholder: 'Vyberte třídu',
                id: 'kids_class',
                required: true,
                cookie_only: true,
                options: classes
            },
            {
                label: 'Adresa',
                type: 'text',
                name: 'kids_address',
                placeholder: 'Adresa',
                id: 'kids_address',
                required: true
            },
            {
                label: 'Město',
                type: 'text',
                name: 'kids_city',
                placeholder: 'Město',
                id: 'kids_city',
                required: true
            },
            {
                label: 'PSČ',
                type: 'number',
                name: 'kids_post_code',
                placeholder: 'PSČ',
                id: 'kids_post_code',
                required: true,
                onlyNumbers: true
            },
            {
                label: 'Zdravotní pojišťovna',
                type: 'text',
                name: 'kids_insurance',
                placeholder: 'Zdravotní pojišťovna',
                id: 'kids_insurance'
            },
            {
                label: 'Zdravotní omezení',
                type: 'textarea',
                name: 'kids_health_issues',
                placeholder: 'Zdravotní omezení',
                id: 'kids_health_issues'
            },
            {
                label: 'Stravovací omezení',
                type: 'textarea',
                name: 'kids_eating_issues',
                placeholder: 'Stravovací omezení',
                id: 'kids_eating_issues'
            },
            {
                label: 'S kým chce být ve skupině',
                type: 'text',
                name: 'kids_pairing',
                placeholder: 'Napšite jména jeho kamarádů',
                id: 'kids_pairing'
            }],
            basicData: {
                kids_name: '',
                kids_lastname: '',
                kids_birthday: formatDate(new Date()),
                kids_address: '',
                kids_city: '',
                kids_post_code: '',
                kids_insurance: '',
                kids_health_issues: '',
                kids_pairing: '',
                kids_camps: [],
                kids_can_select: [],
                kids_class: ''
            },
            formFields: [
                [{
                    label: 'Jméno',
                    type: 'text',
                    name: 'kids_name',
                    placeholder: 'Jméno',
                    id: 'kids_name',
                    required: true
                },
                {
                    label: 'Jméno',
                    type: 'text',
                    name: 'kids_lastname',
                    placeholder: 'Příjmení',
                    id: 'kids_lastname',
                    required: true
                },
                {
                    label: 'Datum narození ve tvaru (DD/MM/RRRR)',
                    type: 'date',
                    name: 'kids_birthday',
                    placeholder: 'Datum narození',
                    id: 'kids_birthday',
                    required: true
                },
                {
                    label: 'Do které třídy dítě chodí',
                    type: 'dropdown',
                    name: 'kids_class',
                    placeholder: 'Vyberte třídu',
                    id: 'kids_class',
                    required: true,
                    cookie_only: true,
                    options: classes
                },
                {
                    label: 'Adresa',
                    type: 'text',
                    name: 'kids_address',
                    placeholder: 'Adresa',
                    id: 'kids_address',
                    required: true
                },
                {
                    label: 'Město',
                    type: 'text',
                    name: 'kids_city',
                    placeholder: 'Město',
                    id: 'kids_city',
                    required: true
                },
                {
                    label: 'PSČ',
                    type: 'number',
                    name: 'kids_post_code',
                    placeholder: 'PSČ',
                    id: 'kids_post_code',
                    required: true,
                    onlyNumbers: true
                },
                {
                    label: 'Zdravotní pojišťovna',
                    type: 'text',
                    name: 'kids_insurance',
                    placeholder: 'Zdravotní pojišťovna',
                    id: 'kids_insurance'
                },
                {
                    label: 'Zdravotní omezení',
                    type: 'textarea',
                    name: 'kids_health_issues',
                    placeholder: 'Alergie na pyl, včelí bodnutí, skolióza, silné dipotrické brýle, ...',
                    id: 'kids_health_issues'
                },
                {
                    label: 'Stravovací omezení',
                    type: 'textarea',
                    name: 'kids_eating_issues',
                    placeholder: 'Alergie na mléko, bezlepková dieta, alergie na ořechy, ...',
                    id: 'kids_eating_issues'
                },
                {
                    label: 'S kým chce být ve skupině',
                    type: 'text',
                    name: 'kids_pairing',
                    placeholder: 'Napšite jména jeho kamarádů',
                    id: 'kids_pairing'
                }]
            ],
            errors: [
                {
                    kids_name: '',
                    kids_lastname: '',
                    kids_birthday: '',
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_pairing: '',
                    kids_class: ''
                }
            ],
            data: [
                {
                    kids_name: '',
                    kids_lastname: '',
                    kids_birthday: new Date(),
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_pairing: '',
                    kids_camps: [],
                    kids_class: ''
                }
            ]
        })
    }

    componentDidMount() {
        getMinAge(1)
        .then((res) => {
            this.setState({
                min_age: res
            })
        })
        .catch((err) => {
            alert('Nepodařilo se správně získat data, zkuste to prosím později.')
        })

        getMaxAge(1)
        .then((res) => {
            this.setState({
                max_age: res
            })
        })
        .catch((err) => {
            alert('Nepodařilo se správně získat data, zkuste to prosím později.')
        })
    }

    checkInputs = () => {

        var minimum_age = moment().subtract(3, 'year');
        var maximum_age = moment().subtract(15, 'year');

        this.state.data.map((item, index) => {
            if(item.kids_name !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_name'] = ''
            }

            if(item.kids_name !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_lastname'] = ''
            }

            if(item.kids_birthday !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_birthday'] = ''
            }

            if(item.kids_address !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_address'] = ''
            }

            if(item.kids_city !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_city'] = ''
            }

            if(item.kids_post_code !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_post_code'] = ''
            }

            if(item.kids_class !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_class'] = ''
            }
        })

        for (var i = 0; i < this.state.data.length; i++) {

            var diff = moment(this.state.data[i]['kids_birthday']).diff('2020-07-06', 'years', true);
            var interDiff = -diff

            if (this.state.data[i]['kids_name'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_name'] = 'Vyplňte jméno dítěte'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;

            } else if (this.state.data[i]['kids_lastname'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_lastname'] = 'Vyplňte příjmení dítěte'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;

            } else if (this.state.data[i]['kids_birthday'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = 'Vyplňte rok narození dítěte'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (interDiff <= this.state.min_age) {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = 'Dítě nesmí být mladší než '+this.state.min_age+' roky'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (interDiff >= this.state.max_age) {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = 'Dítě nesmí být starší než '+this.state.max_age+' let'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_class'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_class'] = 'Vyberte jednu z možností'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_address'] === '') {
                const errors = [...this.state.errors]
                errors[i]['kids_address'] = 'Vyplňte adresu'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_city'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_city'] = 'Vyplňte město'

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_post_code'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_post_code'] = 'Vyplňte PSČ'

                this.setState({
                    isFilled: false,
                    errors: errors
                })

              break;
            } else {
                this.setState({
                    isFilled: true
                })
            }
        }

    }

    nextHandler = () => {

        this.setState({
            loading: true
        })

        const { id, close, refresh, setMessage } = this.props

        getToken().then((token) => {
            const data = {
                token: token,
                id_parents: id,
                kids_name: this.state.data[0].kids_name,
                kids_lastname: this.state.data[0].kids_lastname,
                kids_birthday: this.state.data[0].kids_birthday,
                kids_address: this.state.data[0].kids_address,
                kids_city: this.state.data[0].kids_city,
                kids_post_code: this.state.data[0].kids_post_code,
                kids_insurance: this.state.data[0].kids_insurance,
                kids_health_issues: this.state.data[0].kids_health_issues,
                kids_eating_issues: this.state.data[0].kids_eating_issues,
                kids_pairing: this.state.data[0].kids_pairing,
                kids_class: this.state.data[0].kids_class ? this.state.data[0].kids_class : 0,
            }

            addKid(data)
            .then((res) => {
                this.setState({
                    loading: false
                })
                setMessage(res)
                close()
                refresh()
            })
            .catch((err) => {
                setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později.'})
                close()
                refresh()
                this.setState({
                    loading: false
                })
            })
            
        })
    }

    handleChange = (e) => {
        if (["kids_name", "kids_lastname", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_pairing"].includes(e.target.className) ) {
          const kids = [...this.state.data]
          kids[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({ data: kids })
          this.checkInputs()
        }
    }

    onChangeSelect = (e, data) => {
        const kids = [...this.state.data]
        if (["kids_class"].includes(e.target.parentNode.parentNode.id)) {
            kids[e.target.parentNode.parentNode.dataset.id][e.target.parentNode.parentNode.id] = data.value
            this.setState({ data: kids })
            this.checkInputs()
        } else if (["kids_class"].includes(e.target.parentNode.parentNode.parentNode.id)) {
            kids[e.target.parentNode.parentNode.parentNode.dataset.id][e.target.parentNode.parentNode.parentNode.id] = data.value
            this.setState({ data: kids })
            this.checkInputs()
        }
    }

    handleDayChange(value, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        if (["kids_name", "kids_lastname", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_pairing"].includes(input.name) ) {
            const kids = [...this.state.data]
            kids[input.id][input.name] = moment(value).format('YYYY-MM-DD')
            this.setState({ data: kids }, () => {
                this.checkInputs()
            })
        }
    }

    renderFields = () => {

        const format = "DD/MM/YYYY";

        return (
            this.state.formFields.map((item, index) => {
                return (
                    item.map((field) => {
                        return (
                            <Form.Field key={field.id} className={this.state.errors[index][field.name] !== '' ? 'error' : 'success'}>
                                <label>{field.label} {field.required ? '*' : ''}</label>
                                {(field.type !== 'textarea' && field.name !== 'kids_birthday' && field.type !== 'dropdown') ?
                                <input 
                                    type={field.type}
                                    name={field.name + (index+1)}
                                    placeholder={field.placeholder}
                                    id={field.id}
                                    data-id={index}
                                    value={field.onlyNumbers ? this.state.data[index]['kids_post_code'].replace(/\s/g,'') : this.state.data[index][field.name]}
                                    onChange={(e) => this.handleChange(e)}
                                    className={field.name}
                                    required={field.required ? true : false}
                                />
                                : (field.type === 'textarea') ?
                                <TextArea
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    id={field.id}
                                    data-id={index}
                                    //value={this.state.data[field.name]}
                                    onChange={this.handleChange}
                                    className={field.name}
                                />
                                : (field.type === 'date') ?
                                <>
                                <DayPickerInput 
                                onDayChange={this.handleDayChange} 
                                inputProps={{ 
                                    id: index, 
                                    name: 'kids_birthday',
                                    locale: 'cs',
                                    localeUtils: MomentLocaleUtils,
                                }}
                                dayPickerProps={{ 
                                    locale: 'cs',
                                    localeUtils: MomentLocaleUtils,
                                    format: 'DD-MM-YYYY',
                                    formatDate: 'DD-MM-YYYY',
                                    parseDate: 'DD-MM-YYYY'
                                }}
                                format={'DD/MM/YYYY'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder={new Date()}
                                value={formatDate(this.state.data[index][field.name], format)}
                            />
                            </>
                            : (field.type === 'dropdown') ?
                            <Dropdown 
                                name={field.name}
                                placeholder={field.placeholder}
                                options={field.options}
                                onChange={this.onChangeSelect}
                                selection
                                id={field.id}
                                data-id={index}
                                value={this.state.data[index][field.name]}
                                className={field.name}
                            />
                            :
                            <></>
                            }
                            {field.required && field.name === 'kids_birthday' &&
                            <p className='form-desc'>Dítěti musí být alespoň {this.state.min_age} roky a ne více než {this.state.max_age} let v době konce táborů (31. 8. 2021)</p>
                            }
                            <p className='form-error-text'>{this.state.errors[index][field.name]}</p>
                            </Form.Field>
                        )
                    })
                )
            })
        )
    }

    render() {
        return (
            <Form>
                <this.renderFields />
                <FormButtonGroup>
                    <Button onClick={this.nextHandler} disabled={!this.state.isFilled || this.state.loading} loading={this.state.loading} className='btn_end'>Přidat dítě</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(AddKidForm)