import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

function ReferralsLogTable({ data }) {
  return (
    <Table striped className="main-table mobile" unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>ID přihlášky</Table.HeaderCell>
          <Table.HeaderCell width={5}>Doporučující</Table.HeaderCell>
          <Table.HeaderCell width={5}>Doporučený</Table.HeaderCell>
          <Table.HeaderCell width={3}>Datum</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((item) => {
          return (
            <Table.Row>
              <Table.Cell><Link to={`/manage/application/detail/${item.id_application}`}>{item.id_application}</Link></Table.Cell>
              <Table.Cell>
                {item.user_first_name} {item.user_last_name}
              </Table.Cell>
              <Table.Cell>
                {item.parent_name} {item.parent_lastname}
              </Table.Cell>
              <Table.Cell>
                {moment(item.created).format("D. M. YYYY H:mm")}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default ReferralsLogTable;
