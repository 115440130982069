import React from 'react';
import { Row, Col } from 'reactstrap';
import { EventTitle, EventRow } from '../../styles/calendar';
import { Button } from 'semantic-ui-react';

function EventInfo({ title, event, type }) {
    return (
        <>
            <EventRow>
                <Col sm={12}>
                    <EventTitle>{title}</EventTitle>
                </Col>
            </EventRow>
            <EventRow>
                <Col sm={12} className='mb-3'>
                    <p>{event.description}</p>
                </Col>
                <Col sm={6}>
                    <p className='fw-bold'>Věk od:</p>
                    <p>{event.min_age} let</p>
                </Col>
                <Col sm={6}>
                    <p className='fw-bold'>Věk do:</p>
                    <p>{event.max_age} let</p>
                </Col>
            </EventRow>
            <EventRow>
                <Col sm={12}>
                    <p className='fw-bold'>{event.location_name}</p>
                    <p>{event.location_address}</p>
                </Col>
            </EventRow>
            <Row>
                <Col sm={12}>
                    <Button as='a' href={`/embed/prihlaska/${type}`} fluid primary>Nová přihláška</Button>
                </Col>
            </Row>
        </>
    )
}

export default EventInfo