import React, { Component } from 'react'
import { deleteDate, getToken, intToBool, shortText, toggleDateState } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Button, Dropdown, DropdownItem, Icon, Table } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'

class DatesTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2,
            loadingDelete: false,
            doneDelete: false
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    _countColumn = (key) => {
        let sum = 0
        if(this.props.items.length !== 0) {
            /*for (let i = 0; i <= this.props.items.length; i++) {
                sum += this.props.items[i].date_signed
                console.log(this.props.items)
            }*/
            this.props.items.map((item, index) => {
                if(item.date_state !== 0) {
                    sum += item[key]
                }
            })
    
            return sum
        }
        
    }

    _deleteDate = (id) => {
        this.setState({
            loadingDelete: true,
            doneDelete: false
        })
        getToken().then((token) => {
            deleteDate(id, token)
            .then((res) => {
                this.props.refresh()
                this.setState({
                    loadingDelete: false,
                    doneDelete: true
                })
                this.props.setMessage(res)
            })
            .catch((err) => {
                this.setState({
                    loadingDelete: false,
                    doneDelete: true
                })
                this.props.setMessage({type: 'error', text: 'Termín se nepodařilo smazat, zkuste to prosím později.'})
            })
        })
        .catch((err) => {
            this.setState({
                loadingDelete: false,
                doneDelete: true
            })
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, zkuste to prosím později.'})
        })
    }

    render() {

        const { items, sort, column, direction, type } = this.props
    
        return (
            <div style={isMobile ? {overflowX: 'auto'} : { overflowX: 'hidden' }}>
                <Table sortable striped className='main-table mobile' unstackable>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_date' ? direction : null}
                                onClick={sort('id_date')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_name' ? direction : null}
                                onClick={sort('camp_name')}
                            >
                                Tábor
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                            >
                                Od
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                            >
                                Do
                            </Table.HeaderCell>
                            {Number(type) === 2 &&
                                <Table.HeaderCell>Čas</Table.HeaderCell>
                            }
                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                            >
                                Lokace
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_signed' ? direction : null}
                                onClick={sort('date_signed')}
                            >
                                Přihlášeno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_capacity' ? direction : null}
                                onClick={sort('date_capacity')}
                            >
                                Počet míst
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_state' ? direction : null}
                                onClick={sort('date_state')}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_date, date_signed, camp_name, min_age, max_age, date_from, date_to, is_full, location_name, date_capacity, date_state, start_time }) => (
                    <Table.Row key={id_date} className={is_full === 1 && 'full_date'}>
                        <Table.Cell>
                            {id_date}
                        </Table.Cell>
                        <Table.Cell>
                            {camp_name}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(date_from).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(date_to).format("DD/MM/YYYY")}
                        </Table.Cell>
                        {Number(type) === 2 &&
                            <Table.Cell>{moment(start_time).format("H:mm")}</Table.Cell>
                        }
                        <Table.Cell>
                            {location_name}
                        </Table.Cell>
                        <Table.Cell>
                            {date_signed}
                        </Table.Cell>
                        <Table.Cell>
                            {(date_capacity - date_signed) + '/' + date_capacity}
                        </Table.Cell>
                        <Table.Cell>
                            {intToBool(date_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_date)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_date)}></i>}
                        </Table.Cell>
                        <Table.Cell>
                            {this.props.role !== 2 &&
                            <>
                            <BrowserView>
                                <AreYouSureModal
                                    action={this._deleteDate}
                                    actionText='Smazat termín'
                                    headline='Smazat termín'
                                    message='Opravdu chcete smazat tento termín?'
                                    icon='trash'
                                    id={id_date}
                                    loading={this.state.loadingDelete}
                                    done={this.state.doneDelete}
                                />
                                <Link to={`${process.env.PUBLIC_URL}/manage/dates/edit/` + id_date}>
                                    <Button icon><Icon name='edit'></Icon></Button>
                                </Link>
                            </BrowserView>
                            <MobileView>
                                <Dropdown multiple icon='ellipsis vertical' pointing='top right'>
                                    <Dropdown.Menu>
                                        <DropdownItem><Link to={`${process.env.PUBLIC_URL}/manage/dates/edit/` + id_date}>Upravit záznam</Link></DropdownItem>
                                        <Dropdown.Divider />
                                        <DropdownItem text='Smazat záznam' onClick={() => this.toggleStateHandler(id_date)} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </MobileView>
                            </>
                            }
                        </Table.Cell>
                    </Table.Row>
                ))}
                    <Table.Row >
                        <Table.Cell>
                  
                        </Table.Cell>
                        <Table.Cell>
                         
                        </Table.Cell>
                        <Table.Cell>
                            
                        </Table.Cell>
                        <Table.Cell>
                            
                        </Table.Cell>
                        <Table.Cell>
                         
                        </Table.Cell>
                        <Table.Cell>
                            Celkem: {this._countColumn('date_signed')}/{this._countColumn('date_capacity')}
                        </Table.Cell>
                        <Table.Cell>
                            
                        </Table.Cell>
                        <Table.Cell>
                            
                        </Table.Cell>
                        <Table.Cell>
                           
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(DatesTable)