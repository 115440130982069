import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import { setMessage } from '../../../actions/messageActions';
import { getToken, sendInvoice } from '../../../api';
import { url } from '../../../config';
import { BillsEmptyContainer } from '../../../styles/bills';

function InvoicesList({ invoices, hasBills, loading, showAppId = false }) {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [loadingSend, setLoadingSend] = useState(false);

    useEffect(() => {
        setData(invoices)
    }, [invoices])

    function _sendBill(id) {
        setLoadingSend(true)
        getToken().then((token) => {
            sendInvoice(id, token)
            .then((res) => {
                dispatch(setMessage(res.data))
                setLoadingSend(false)
            })
            .catch((err) => {
                alert(err.message)
                setLoadingSend(false)
            })
        })
        .catch((err) => {
            alert(err.message)
            setLoadingSend(false)
        })
    }

    function renderList() {
        return (
            data.map((item) => {
                return (
                    <Row className='mb-3 align-items-center'>
                        {!showAppId ?
                        <>
                        <Col sm={8}>
                            <span style={{fontSize: 10, display: 'block'}}>{moment(item.created).format('DD.MM.YYYY H:mm')}</span>
                            faktura-{item.invoice_num}.pdf
                        </Col>
                        <Col sm={4} className='text-right'>
                            <Popup position='top right' trigger={<a href={url.api + 'pdf/download-invoice/' + item.invoice_num}><Button icon className='mr-1'><Icon name='download' /></Button></a>} content='Stáhnout fakturu' />
                            <Popup position='top right' trigger={<Button icon onClick={() => _sendBill(item.invoice_num)} loading={loadingSend}><Icon name='envelope' /></Button>} content='Odeslat fakturu' />
                        </Col>
                        </>
                        :
                        <>
                        <Col sm={3}>
                            <span style={{fontSize: 10, display: 'block'}}>{moment(item.created).format('DD.MM.YYYY H:mm')}</span>
                            faktura-{item.invoice_num}.pdf
                        </Col>
                        <Col sm={5}>
                            <span style={{fontSize: 10, display: 'block'}}>ID přihlášky</span>
                            <Link to={`/manage/application/detail/${item.id_application}`}>{item.id_application}</Link>
                        </Col>
                        <Col sm={4} className='text-right'>
                            <Popup position='top right' trigger={<a href={url.api + 'pdf/download-invoice/' + item.invoice_num}><Button icon className='mr-1'><Icon name='download' /></Button></a>} content='Stáhnout fakturu' />
                            <Popup position='top right' trigger={<Button icon onClick={() => _sendBill(item.invoice_num)} loading={loadingSend}><Icon name='envelope' /></Button>} content='Odeslat fakturu' />
                        </Col>
                        </>}
                    </Row>
                )
            })
        )
    }

    function renderLoading() {
        return (
            <BillsEmptyContainer>
                <Loader active />
            </BillsEmptyContainer>
        )
    }

    function renderEmpty() {
        return (
            <BillsEmptyContainer>
                Přihláška nemá žádné faktury
            </BillsEmptyContainer>
        )
    }

    if(loading) {
        return (
            <>
                {renderLoading()}
            </>
        )
    } else if (!loading && !hasBills) {
        return (
            <>
                {renderEmpty()}
            </>
        )
    } else if(!loading && hasBills) {
        return (
            <>
                {renderList()}
            </>
        )
    }
    return (
        <></>
    )

}

export default InvoicesList