import React, { useState } from "react";
import { Col } from "reactstrap";
import { Button, Icon } from "semantic-ui-react";
import { deleteAttachment, getToken } from "../../../api";
import { EmailAttchItem, EmailAttchName } from "../../../styles";
import { jsonToFormData } from "../../../tools";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../actions/messageActions";
import { url } from "../../../config";

function AttachmentsViewer({
  attachments,
  id_email,
  refresh,
  remove,
  oldAttachments,
}) {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dispatch = useDispatch();

  function _deleteAttachment(path) {
    setLoadingDelete(true);

    if (id_email !== 0) {
      getToken()
        .then((token) => {
          const data = {
            token: token,
            path: path,
          };

          const toSend = jsonToFormData(data);

          deleteAttachment(toSend)
            .then((res) => {
              dispatch(setMessage(res.data));
              refresh();
              setLoadingDelete(false);
            })
            .catch((err) => {
              alert(
                "Při mazání přílohy se vyskytla chyba. Zkuste to prosím později."
              );
              setLoadingDelete(false);
            });
        })
        .catch((err) => {
          alert(
            "Nepodařilo se získat bezpečnostní token. Zkuste to prosím později."
          );
          setLoadingDelete(false);
        });
    }
  }

  function removeAttachment(index) {
    remove(index);
  }

  function downloadAttachment(path) {
    getToken().then((token) => {
      window.open(
        url.api + "dates/download-attachment?path=" + path + "&token=" + token,
        "_blank"
      );
    });
  }

  return (
    <>
      {attachments &&
        attachments.length !== 0 &&
        attachments.map((item, index) => {
          return (
            <EmailAttchItem key={index}>
              <Col sm={1}>
                <Button
                  style={{ padding: 8 }}
                  icon
                  loading={loadingDelete}
                  onClick={() => removeAttachment(index)}
                  as="span"
                >
                  <Icon name="trash" />
                </Button>
              </Col>
              <Col sm={11}>
                <p>{item.name}</p>
              </Col>
            </EmailAttchItem>
          );
        })}
      {oldAttachments &&
        oldAttachments.length !== 0 &&
        oldAttachments.map((item, index) => {
          return (
            <EmailAttchItem key={index}>
              <div>
                <Button
                  style={{ padding: 8 }}
                  icon
                  loading={loadingDelete}
                  onClick={() => _deleteAttachment(item.path)}
                  as="span"
                >
                  <Icon name="trash" />
                </Button>
                <Button
                  style={{ padding: 8 }}
                  onClick={() => downloadAttachment(item.path)}
                  as="span"
                  icon
                >
                  <Icon name="cloud download" />
                </Button>
              </div>
              <EmailAttchName>
                <span>{item.name}</span>
              </EmailAttchName>
            </EmailAttchItem>
          );
        })}
    </>
  );
}

export default AttachmentsViewer;
