import React, { useState, useEffect, Component } from 'react'
import { Col, Row } from 'reactstrap';
import { Card, CardBody, CardHeader } from '../../../styles';
import DatesTable from '../../containers/employees/DatesTable';
import SelectedDatesTable from '../../containers/employees/SelectedDatesTable';
import { useDispatch, useSelector } from "react-redux";
import { jsonToFormData } from '../../../tools';
import { selectDate, getDates } from '../../../api/employees';
import EmployeeProfileForm from '../../forms/employees/EmployeeProfileForm';
import { setMessage } from '../../../actions/messageActions';

function EmployeeDatePickerView() {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const { id_user } = user;

    function _selectDate(id_date) {

        const data = {
            id_date: id_date,
            id_user: id_user
        }

        const payload = jsonToFormData(data);

        selectDate(payload)
        .then((res) => {
            dispatch(setMessage(res.data))
        })
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            Termíny    
                        </CardHeader>
                        <DatesTable 
                            action={_selectDate} 
                            getData={getDates} 
                            filterPath='employee/dates'
                            displayFilter={true}
                            datePicker={true}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )

}

export default EmployeeDatePickerView

/*
class EmployeeDatePickerView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            selectedDates: []
        })
    }

    _selectDate = (id_date) => {

        const data = {
            id_date: id_date,
            id_user: 
        }
       
    }

    _removeDate = (item) => {
        var arr = this.state.selectedDates;
        var newArr = arr.filter((i) => i.id_date !== item.id_date);

        this.setState({
            selectedDates: newArr
        })
    }

    render() {
        return (
            <>
            <Row>
                <Col sm={8}>
                    <Card>
                        <CardHeader>
                            Termíny    
                        </CardHeader>
                        <DatesTable action={this._selectDate} selected={this.state.selectedDates} />
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardHeader>
                            Vybrané termíny
                        </CardHeader>
                        <CardBody>
                            <SelectedDatesTable selected={this.state.selectedDates} remove={this._removeDate} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </>
        )
    }

}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { setMessage })(EmployeeDatePickerView)

export default EmployeeDatePickerView */