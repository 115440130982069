import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('cs', cs);

function CustomDate({ onChange, value, dataid, id, fieldname }) {

    return (
        <DatePicker 
            selected={value}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            locale="cs"
            dateFormat="dd.MM.yyyy"
            id={id}
            onChange={date => onChange(date, id, dataid)}
        />
    )
};

export default CustomDate;