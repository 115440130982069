import React, { useState, useEffect, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getCalendar } from "../../api";
import { jsonToFormData } from "../../tools";
import { Event, EventPopup, MoreArrow } from "../../styles/calendar";
import EventInfo from "./EventInfo";
import { useParams } from "react-router-dom";

function DefaultCalendar() {
  const [dates, setDates] = useState([]);
  const [scroll, setScroll] = useState("2021-12-31 09:00:00");
  const [lowest_pos, setLowest] = useState("");
  const [eventsRefs, setEventsRefs] = useState([]);
  const calendarRef = useRef();
  const { campType } = useParams();

  useEffect(() => {
    getCalendarDates(d);
    setEarliestEvent();
  }, []);

  function getCalendarDates(date) {
    const toSend = {
      date: moment(date).format("YYYY-MM-DD"),
      type: "kurzy",
    };

    const fd = jsonToFormData(toSend);

    getCalendar(fd)
      .then((res) => {
        if (res.data.length !== 0) {
          let newDates = [];

          res.data.map((item) => {
            let newDate = {
              title: item.title,
              description: item.description,
              min_age: item.min_age,
              max_age: item.max_age,
              start: moment(item.start).toDate(),
              end: moment(item.end).toDate(),
              location_name: item.location_name,
              location_address: item.location_address,
              id: item.id,
            };
            newDates = [...newDates, newDate];
            setEarliestEvent();
          });

          setDates(newDates);
        }

        return res;
      })
      .then((res) => {
        setEarliestEvent();
      });
  }

  const localizer = momentLocalizer(moment);

  let components = {
    event: myEvent,
  };

  function myEvent(props) {
    return (
      <EventPopup
        on="click"
        content={
          <EventInfo title={props.title} event={props.event} type={campType} />
        }
        trigger={
          <Event
            color={props.event.color}
            state={props.event.state}
            ref={(eventsRef) => {
              eventsRefs[props.event.id] = eventsRef;
            }}
          >
            <p>{props.title}</p>
          </Event>
        }
        wide
      />
    );
  }

  function eventStyleGetter(event, start, end, isSelected) {
    var style = {
      backgroundColor: event.color,
      opacity: event.is_full ? 0.3 : 1,
      borderRadius: "0.25rem",
      color: "#fff",
      border: "0px",
    };
    return {
      style: style,
      className:
        event.state === 0 ? "not-active" : "active " + event.scheduleCat,
    };
  }

  function setEarliestEvent() {
    var earliest = moment("2021-12-31 23:59:59").hours();
    var firstItem = {};

    dates.map((item, index) => {
      var newTime = moment(item.start).hours();
      if (newTime < earliest) {
        earliest = moment(item.start).hours();
        firstItem = item.start;
      }
    });

    setScroll(firstItem);
    setEventsTop();
  }

  function setEventsTop() {
    var lowest = 0;

    eventsRefs.map((event) => {
      if (event !== null) {
        var position = event.parentElement.parentElement.offsetTop;
        var height = document.getElementsByClassName("rbc-events-container")[0]
          .clientHeight;
        var eventHeight =
          document.getElementsByClassName("rbc-event")[0].clientHeight;
        var pixels = eventHeight;
        var percentage = (pixels / height) * 100;
        var multiply = event.parentElement.parentElement.classList[2];
        var item = event.parentElement.parentElement;
        var newHeight = event.parentElement.parentElement.style.top.replace(
          "%",
          ""
        );
        var newHeightNum = Number(newHeight) + percentage * multiply;

        item.style.top = newHeightNum + "%";

        if (position > lowest) {
          lowest = position;

          setLowest(lowest);
        }
      }
    });
  }

  const d = moment();

  var min_time = new Date();
  if (campType === "skolka") {
    min_time.setHours(9);
  } else {
    min_time.setHours(14);
  }
  min_time.setMinutes(0);
  min_time.setMilliseconds(0);

  var max_time = new Date();
  max_time.setHours(19);
  max_time.setMinutes(0);
  max_time.setMilliseconds(0);

  return (
    <>
      <Calendar
        localizer={localizer}
        events={dates}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        style={{
          minHeight: 800,
        }}
        onNavigate={(date) => getCalendarDates(date)}
        components={components}
        min={min_time}
        max={max_time}
        defaultDate={d}
        messages={{
          month: "Měsíc",
          day: "Den",
          today: "Dnes",
          previous: "Zpět",
          next: "Další",
          week: "Týden",
          date: "Datum",
          time: "Čas",
          event: "Lekce",
          showMore: (total) => `+ Zobrazit další (${total})`,
        }}
        defaultView="week"
        views={{ week: true }}
        //scrollToTime={moment(this.state.scroll).toDate()}
        ref={calendarRef}
      />
    </>
  );
}

export default DefaultCalendar;
