import React, { useState, useEffect } from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import { getDates, getEmployeesDates, deleteDate } from '../../../api/employees';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from "react-router-dom";
import DatesTableFilter from './DatesTableFilter';
import { jsonToFormData } from '../../../tools';
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from '../../../actions/messageActions';
import EmptyTableMessage from './EmptyTableMessage';

function DatesTableDashboard({ state }) {

    let dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(true);

    const user = useSelector((state) => state.user.user);
    const { id_user } = user;

    const parsed = queryString.parse(location.search);

    useEffect(() => {

        _getData()

    }, []);
    
    function _getData() {

        setLoading(true);

        const data = {
            query: parsed,
            id_user: id_user,
            state: state
        }

        const payload = jsonToFormData(data);

        getEmployeesDates(payload)
        .then((res) => {
            setLoading(false);
            setDates(res.data)
        });
    }

    function _deleteDate(id_date) {
        const data = {
            id_date: id_date,
            id_user: id_user
        }

        const payload = jsonToFormData(data);

        deleteDate(payload)
        .then((res) => {
            dispatch(setMessage(res.data))
            _getData()
        })
    }

    return (
        <>
        {dates.length === 0 ?
        <EmptyTableMessage 
            message='Nejsou k dispozici žádné termíny k zobrazení' 
            loading={loading}
            buttonText='Přidat termín'
            link='/employee/dates'
            showButton={true}
        />
        :
        <Table striped className='main-table mobile' unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Druh
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Začátek
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Konec
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Lokalita
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {dates.map((item) => {

                    var date_from = moment(item.date_from);
                    var next_week = moment().add(7, 'days');
                    var remaining = date_from.diff(next_week, 'days');

                    return (
                        <Table.Row key={item.id_date} className={item.is_full === 1 && 'full_date'}>
                            <Table.Cell>
                                {item.camp_name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.date_from).format("DD/MM/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.date_to).format("DD/MM/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                                <strong>{item.location_name}</strong><br />
                                {item.location_address}
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                {state === 1 && remaining > 7 && <Popup position='top right' on='hover' trigger={<Button as='span'>Detail</Button>} content='Detail termínu bude dostupný těsně před jeho konáním' />}
                                <Popup position='top right' on='hover' trigger={<Link to={`/employee/date/${item.id_date}`}><Button as='span'>Detail</Button></Link>} content='Zobrazit více informací' />
                                {state === -1 && remaining > 7 && <Button onClick={() => _deleteDate(item.id)}>Zrušit žádost</Button>}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
        }
        </>
    )
}

export default DatesTableDashboard