import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import { Col, Row } from "reactstrap";
import {
  Button,
  Form,
  Dropdown,
  Accordion,
  Label,
} from "semantic-ui-react";
import {
  getEmailRecipientsHistory,
  getPreviousEmail,
  getToken,
  sendDateEmails,
} from "../../api";
import { jsonToFormData } from "../../tools";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/messageActions";
import { useDropzone } from "react-dropzone";
import { CustomDropzone } from "../../styles";
import AttachmentsViewer from "../containers/date-email/AttachmentsViewer";

function DateEmailForm({ id, id_date, token, refresh, history }) {
  const [data, setData] = useState({
    content: RichTextEditor.createEmptyValue(),
    subject: "",
    attachments: [],
    oldAttachments: [],
    group: 1,
  });
  const [prevRecipients, setPrevRecipients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAccordion, setShowAccordion] = useState(false);

  const groupOpts =
    id !== 0
      ? [
          { key: "1", value: 1, text: "Zkušební email" },
          { key: "0", value: 0, text: "Odeslat všem" },
          { key: "2", value: 2, text: "Odeslat chybějícím" },
        ]
      : [
          { key: "1", value: 1, text: "Zkušební email" },
          { key: "0", value: 0, text: "Odeslat všem" },
        ];

  const dispatch = useDispatch();

  useEffect(() => {
    id !== 0 && getData();
    id === 0 && setLoading(false);
    id === 0 &&
      setData({
        content: RichTextEditor.createEmptyValue(),
        subject: "",
        attachments: [],
        oldAttachments: [],
        group: 1,
      });
  }, [id]);

  function getData() {
    setLoading(true);
    const data = {
      id_email: id,
      id_date: id_date,
      token: token,
    };

    const toSend = jsonToFormData(data);

    getPreviousEmail(toSend).then((res) => {
      setData({
        subject: res.data.data.subject,
        content: RichTextEditor.createValueFromString(
          res.data.data.content,
          "html"
        ),
        oldAttachments: res.data.data.attachments,
        attachments: [],
        group: 1,
      });
      setLoading(false);
    });

    getEmailRecipientsHistory(toSend).then((res) => {
      setPrevRecipients(res.data.data);
    });
  }

  function sendEmail() {
    setLoading(true);
    getToken().then((token) => {
      const emailData = {
        content: data.content.toString("html"),
        subject: data.subject,
        id_email: id,
        id_date: id_date,
        group: data.group,
        test_email: data.test_email,
        attachments: data.attachments,
        token: token,
      };

      const toSend = jsonToFormData(emailData);

      sendDateEmails(toSend)
        .then((res) => {
          setLoading(false);
          dispatch(setMessage(res.data));
          data.group !== 1 && refresh();
        })
        .catch((err) => {
          setLoading(false);
          alert("Nepodařilo se odeslat emaily. Zkuste to prosím později.");
        });
    });
  }

  function onChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function onChangeRte(value) {
    setData({ ...data, content: value });
  }

  function onChangeSelect(e, selection) {
    setData({
      ...data,
      [selection.name]: selection.value,
    });
  }

  function onDrop(acceptedFiles) {
    acceptedFiles.map((file) => {
      var oldAttachments = data.attachments;
      oldAttachments.push(file);

      setData({
        ...data,
        attachments: oldAttachments,
      });
    });
  }

  function removeAttachment(del) {
    var oldAttachments = data.attachments;
    oldAttachments.splice(del, 1);

    setData({
      ...data,
      attachments: oldAttachments,
    });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".png, .jpg, .gif, .pdf, .doc, .jpeg, .webp, .docx",
    multiple: true,
  });

  return (
    <Form loading={loading} onSubmit={() => sendEmail()}>
      <Form.Input
        type="text"
        name="subject"
        placeholder="Zadejte předmět emailu"
        label="Předmět"
        value={data.subject}
        required
        onChange={onChange}
      />
      <Form.Field>
        <label>Zpráva</label>
        <RichTextEditor
          value={data.content}
          editorClassName="demo-editor"
          required
          onChange={onChangeRte}
        />
      </Form.Field>
      <Form.Input
        type="email"
        name="test_email"
        label="Zkušební email"
        placeholder="Zadejte adresu pro odeslání zkušebního emailu"
        onChange={onChange}
      />
      <Form.Field>
        <label>Přílohy</label>
        <CustomDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Přetáhněte soubory sem ...</p>
          ) : (
            <p>
              Přetáhněte sem soubory, nebo klikněte pro vybrání z počítače
              <br />
              <span class="small">
                Podporované soubory: .png, .jpg, .gif, .pdf, .doc, .jpeg, .webp,
                .docx (max. 9 MB)
              </span>
            </p>
          )}
        </CustomDropzone>
      </Form.Field>
      <div>
        <AttachmentsViewer
          attachments={data.attachments}
          refresh={getData}
          id_email={id}
          remove={removeAttachment}
          oldAttachments={data.oldAttachments}
        />
      </div>
      <Row>
        <Col sm={3}>
          {id !== 0 ? (
            <Accordion>
              <Accordion.Title
                active={showAccordion}
                index={0}
                onClick={() => setShowAccordion(!showAccordion)}
              >
                <Label>Předchozí příjemci</Label>
              </Accordion.Title>
              <Accordion.Content active={showAccordion}>
                {prevRecipients.length !== 0 ? (
                  prevRecipients.map((item) => {
                    return <p>{item}</p>;
                  })
                ) : (
                  <p>Žádní předchozí příjemci</p>
                )}
              </Accordion.Content>
            </Accordion>
          ) : (
            <Label>Žádní předchozí příjemci</Label>
          )}
        </Col>
        <Col sm={9} className="">
          <Dropdown
            placeholder="Komu odeslat?"
            onChange={onChangeSelect}
            selection
            options={groupOpts}
            value={data.group}
            name="group"
          />
          <Button className="ml-2" primary={data.group !== 1}>
            {data.group === 1
              ? "Odeslat zkušební email"
              : "Odeslat email účastníkům"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default DateEmailForm;
