import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Checkbox } from "semantic-ui-react";
import { getToken } from "../../../api";
import { addCredits } from "../../../api/parents";
import { jsonToFormData } from "../../../tools";
import { setMessage } from "../../../actions/messageActions";

const AddCreditForm = ({ id_user, refresh, close, type }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [onlyFuture, setOnlyFuture] = useState(false);
  const [futureDate, setFutureDate] = useState('');

  function submit() {
    setLoading(true);

    getToken().then((token) => {
      const toSend = {
        token: token,
        amount: amount,
        id_user: id_user,
        type: type,
        future_date: futureDate
      };

      const payload = jsonToFormData(toSend);

      addCredits(payload)
        .then((res) => {
          dispatch(setMessage(res.data));
          setLoading(false);
          close();
          refresh();
        })
        .catch((err) => {
          dispatch(setMessage(err.message));
          setLoading(false);
        });
    });
  }

  return (
    <Form onSubmit={submit} loading={loading}>
      <Form.Input
        label="Počet kreditů"
        placeholder={
          type !== "remove"
            ? "Zadejte počet kreditů pro připsání"
            : "Zadejte počet kreditů pro odebrání"
        }
        name="Amount"
        type="number"
        min="0"
        step="1"
        onChange={(e) => setAmount(e.target.value)}
        value={amount}
      />
      <Form.Field>
        <Checkbox
          name="future_credit"
          label="Může být použito pouze v budoucnu?"
          checked={onlyFuture}
          onChange={(e, value) => setOnlyFuture(value.checked)}
        />
      </Form.Field>

      {onlyFuture && (
        <Form.Input
          type="date"
          name="future_date"
          label="Dostupné od"
          placeholder="Vyberte datum"
          value={futureDate}
          onChange={(e, target) => setFutureDate(e.target.value)}
        />
      )}

      <Button primary fluid>
        {type !== "remove" ? "Připsat kredity" : "Odebrat kredity"}
      </Button>
    </Form>
  );
};

export default AddCreditForm;
