import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Icon, Table } from "semantic-ui-react";
import { getEmployees, updateDots } from "../../../api/employees";
import { EmptyDatesList, StateIcon } from "../../../styles";
import { jsonToFormData } from "../../../tools";
import SendEmailModal from "../../modals/SendEmailModal";
import Dots from "./Dots";
import LeaderSwitcher from "./LeaderSwitcher";
import queryString from "query-string";

function EmployeesTable({
  id_date,
  showRejected,
  filters = {},
  leaderSwitcher = true,
}) {
  const [employees, setEmployees] = useState([]);
  const [loadingDots, setLoadingDots] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    let id = id_date ? id_date : 0;
    const url = queryString.stringify(filters, {
      skipNull: true,
    });

    getEmployees(id, showRejected, url).then((res) => {
      setEmployees(res.data);
    });
  }

  function onChangeDot(id_user, dot, value) {
    setLoadingDots(true);
    const data = {
      id_user: id_user,
      dot: dot,
      value: value,
    };
    const payload = jsonToFormData(data);
    updateDots(payload)
      .then((res) => {
        getData();
        setLoadingDots(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingDots(false);
      });
  }

  return (
    <>
      {employees.length === 0 ? (
        <EmptyDatesList>Nemáte zde žádného instruktora</EmptyDatesList>
      ) : (
        <Table striped className="main-table mobile" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Jméno</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Telefon</Table.HeaderCell>
              {leaderSwitcher && <Table.HeaderCell>Vedoucí</Table.HeaderCell>}
              <Table.HeaderCell>Pokračování</Table.HeaderCell>
              <Table.HeaderCell>Poslední aktualizace</Table.HeaderCell>
              <Table.HeaderCell style={{ width: 6 + "%" }}></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {employees.map((item) => {
              return (
                <Table.Row
                  key={item.id_user}
                  className={"employee_item_state_" + item.state}
                >
                  <Table.Cell>
                    {item.user_first_name} {item.user_last_name}
                  </Table.Cell>
                  <Table.Cell>{item.user_email}</Table.Cell>
                  <Table.Cell>{item.phone}</Table.Cell>
                  {leaderSwitcher && (
                    <Table.Cell>
                      <LeaderSwitcher
                        refresh={getData}
                        value={item.is_leader}
                        id_user={item.id_user}
                        id_date={id_date}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    <StateIcon state={item.want_continue} />
                  </Table.Cell>
                  <Table.Cell>
                    {item.last_update
                      ? moment(item.last_update).format("DD.MM.YYYY H:mm")
                      : "Nemá data"}
                  </Table.Cell>
                  <Table.Cell>
                    <Dots
                      onChange={onChangeDot}
                      data={item.dots}
                      id_user={item.id_user}
                      loadingDots={loadingDots}
                    />
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    <SendEmailModal displayType="icon" id={item.id_user} />
                    <Link to={"/manage/employees/detail/" + item.id_user}>
                      <Button icon>
                        <Icon name="eye" />
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default EmployeesTable;
