import React from 'react'
import { Form, TextArea, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const EditLocation = ({ location, onChange, loading, submit }) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <label>Název lokace</label>
                <input
                    type='text'
                    name='location_name'
                    id='location_name'
                    value={location.location_name}
                    onChange={onChange}  
                />
            </Form.Field>
            <Form.Field>
                <label>Popis lokace</label>
                <TextArea
                    name='location_description' 
                    value={location.location_description}
                    onChange={onChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Adresa lokace</label>
                <input
                    type='text'
                    name='location_address'
                    id='location_address'
                    value={location.location_address}
                    onChange={onChange}  
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditLocation