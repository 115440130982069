import React from "react";
import { Card, CardHeader, CardBody } from "../../../styles";
import { Row, Col } from "reactstrap";
import DatesTableDashboard from "../../containers/employees/DatesTableDashboard";
import FinishProfileMessage from "../../containers/employees/FinishProfileMessage";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { QRModal } from "../../modals/QRModal";

function EmployeeDashboardView() {
  const user = useSelector((state) => state.user.user);
  const loadingUser = useSelector((state) => state.user.loading);

  return (
    <>
      {!loadingUser && (
        <>
          {!user.profile_details && <FinishProfileMessage />}
          <Row className="mb-5">
            <Col sm={12}>
              <Card>
                <CardHeader>Odkaz pro doporučení</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={2}>
                      <Form>
                        <Form.Input
                          label="Kód pro doporučení"
                          type="text"
                          name="referral"
                          readOnly
                          value={user.referral}
                        />
                      </Form>
                    </Col>
                    <Col sm={8}>
                      <Form>
                        <Form.Input
                          label="Odkaz pro doporučení"
                          type="text"
                          name="referral"
                          readOnly
                          value={
                            "https://app.sportjoy.cz/embed/signup/camps/cs?referral=" +
                            user.referral
                          }
                        />
                      </Form>
                    </Col>
                    <Col
                      sm={2}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <QRModal referral={user.referral} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={12}>
              <Card>
                <CardHeader>Moje termíny</CardHeader>
                <DatesTableDashboard state={1} />
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={12}>
              <Card>
                <CardHeader>Čeká na schválení</CardHeader>
                <DatesTableDashboard state={-1} />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default EmployeeDashboardView;
