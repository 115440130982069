import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Form } from 'semantic-ui-react'
import { setMessage } from '../../../actions/messageActions';
import { createInvoice } from '../../../api';
import { jsonToFormData } from '../../../tools'

function NewInvoiceForm({ invoiceText, closeModal, idApplication, refresh }) {

    const [ data, setData ] = useState({});
    const [ loading, setLoading ] = useState(false);

    let dispatch = useDispatch();

    useEffect(() => {
        setData({...data, invoice_text: invoiceText})
    }, [invoiceText])

    function onChange(e, val) {
        setData({...data, [e.target.name]: e.target.value})
    }

    function submit() {

        setLoading(true);

        const toSend = {
            text: data.invoice_text,
            due_date: data.due_date,
            id_app: idApplication
        }

        let payload = jsonToFormData(toSend);

        createInvoice(payload)
        .then((res) => {
            setLoading(false);
            closeModal();
            dispatch(setMessage(res.data));
            refresh();
        })

    }

    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Input 
                type="text"
                name="invoice_text"
                label="Text faktury"
                value={data.invoice_text}
                onChange={onChange}
                required
            />
            <Form.Input 
                type="date"
                name="due_date"
                label="Datum splatnosti"
                value={data.due_date}
                onChange={onChange}
                required
            />
            <Button primary fluid>Vytvořit fakturu</Button>
        </Form>
    )

}

export default NewInvoiceForm