import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, Icon } from 'semantic-ui-react'
import _ from 'loadsh'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'

class AddressBookTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            
        })
    }

    render() {

        const { items, sort, column, direction } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_contact' ? direction : null}
                                onClick={sort('id_contact')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'name' ? direction : null}
                                onClick={sort('name')}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'email' ? direction : null}
                                onClick={sort('email')}
                            >
                                Email
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'phone' ? direction : null}
                                onClick={sort('phone')}
                            >
                                Telefon
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_contact, name, email, phone }) => (
                    <Table.Row key={id_contact}>
                        <Table.Cell>
                            {Number(id_contact)}
                        </Table.Cell>
                        <Table.Cell>
                            {name}
                        </Table.Cell>
                        <Table.Cell>
                            {email}
                        </Table.Cell>
                        <Table.Cell>
                            {phone}
                        </Table.Cell>
                        <Table.Cell>
                            <Button icon href={`${process.env.PUBLIC_URL}/manage/applications/detail/`}><Icon name='pencil alternate' /></Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>

            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(AddressBookTable)