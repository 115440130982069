import React, { Component, useEffect } from 'react'
import firebase from 'firebase'
import { useDispatch, useSelector } from 'react-redux';
import { saveUser } from '../../actions/userActions';
import { useHistory } from 'react-router';

function LoadingView() {

    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector((state) => state.user.user);
    const loadingUser = useSelector((state) => state.user.loading);
    const { id_user, user_admin } = user;

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(saveUser(user.uid));
                
                if(!loadingUser) {
                    if(user_admin === 3) {
                        history.push('/employee/dashboard')
                    } if(user_admin === 4) {
                        history.push('/parents/dashboard')
                    } else {
                        history.push('/dashboard')
                    }
                }
                
            } else {
                history.push('/login')
            }
        });
    }, []);

    return (
        <p>Načítám administraci</p>
    )
}

export default LoadingView