import React, { Component } from 'react'
import { getDates, selectCamp, getDatesHobby, getCampsFilter, getLocationsFilter, getEmbedDatesFilter, dateFilterFrom } from '../../../api'
import { DateItem, DateHead, DateSelected, EmbedMessage, FormButtonGroup, TermModal, FormSection, FormSectionHead, FormSectionBody, ModalHead, DateItemLabel } from '../../../styles'
import moment from 'moment'
import { Button, Modal, Message, Dropdown } from 'semantic-ui-react'
import { Col, Row } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import _ from 'loadsh'
import { jsonToFormData } from '../../../tools'
import { applicationsLang as lang } from '../../../lang/lang';

const activityTypes = [
    { key: '1', text: 'Kroužky', value: 'hobby' },
    { key: '2', text: 'Kurzy', value: 'kurzy' },
    { key: '3', text: 'Školka', value: 'skolka' }
]

class TermsForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            dates: [],
            datesByKids: [],
            kids: [],
            selected: '',
            selectedItems: [],
            selectLoading: false,
            isSelected: false,
            alternate: false,
            canSelect: false,
            modals: {},
            campsOptions: [
                {key: '0', value: null, text: lang.all},
            ],
            locOptions: [
                {key: '0', value: null, text: lang.all},
            ],
            datesFilter: [
                {key: '0', value: null, text: lang.all},
            ],
            filter: false,
            filters: {},
            campType: props.isHobby
        })
    }

    componentDidMount() {

        const { isHobby } = this.props

        this.setState({
            campType: isHobby
        })

        getEmbedDatesFilter(isHobby)
        .then((data) => {
            this.setState({
                datesFilter: this.state.datesFilter.concat(data) 
            })
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat data. Zaktualizujte prosím stránku.')
        })

        this.getFilterValues()

        getDatesHobby(isHobby)
        .then((data) => {
            this.setState({
                dates: data,
                baseData: data,
                kids: this.props.kids,
                loadingDates: false
            }, () => {
                this.setDatesData()
            })
        })
    
        this.setState({
            kids: this.props.kids
        }, () => {
            this.checkSelected()
            this.setDatesData()
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.kids !== prevProps.kids) {
            this.setState({
                kids: this.props.kids
            }, () => {
                this.checkSelected()
                this.setDatesData()
            })
        }
    }

    getFilterValues = () => {
        const toSend = {
            filters: this.state.filters,
            activity_type: this.state.campType
        }

        const fd = jsonToFormData(toSend)

        getLocationsFilter(fd)
        .then((data) => {
            this.setState({
                locOptions: [
                    {key: '0', value: null, text: lang.all},
                ]
            }, () => {
                this.setState({
                    locOptions: this.state.locOptions.concat(data.data)
                })
            })
        })

        getCampsFilter(this.state.campType)
        .then((data) => {
            this.setState({
                campsOptions: [
                    {key: '0', value: null, text: lang.all},
                ]
            }, () => {
                this.setState({
                    campsOptions: this.state.campsOptions.concat(data.data)
                })
            })
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat druhy táborů. Zaktualizujte prosím stránku.')
        })
    }

    getFilteredHandler = (date) => {
        this.setState({
            loadingDates: true
        })
        dateFilterFrom(date)
        .then((dates) => {
            if(dates.length !== 0) {
                if(_.keys(this.state.filters).length !== 0) {
                    this.setState({
                        dates: _.filter(dates, this.state.filters),
                        loadingDates: false,
                        baseData: dates,
                        hasSome: true
                    }, () => this.setDatesData())
                } else {
                    this.setState({
                        dates: dates,
                        loadingDates: false,
                        baseData: dates,
                        hasSome: true
                    }, () => this.setDatesData())
                }
            } else {
                this.setState({
                    hasSome: false,
                    loadingDates: false
                }, () => this.setDatesData())
            }
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat data. Zaktualizujte prosím stránku.')
        })
    }

    onChangeActivityType = (e, data) => {
        this.setState({
            campType: data.value,
            campsOptions: [
                {key: '0', value: null, text: lang.all},
            ],
        }, () => {
            this.getFilterValues()
            this.getDatesHandler(data.value);
        })
    }

    onChangeSelect = (e, data) => {

        this.setState({
            loadingDates: true
        })

        if(data.value !== null) {
            this.setState({
                filter: true,
                filters: {
                    ...this.state.filters,
                    [data.name]: data.value
                }
            }, () => {
                this.setState({
                    dates: _.filter(this.state.baseData, this.state.filters),
                    loadingDates: false
                }, () => {
                    this.setDatesData()
                    this.getFilterValues()
                })
            })
            
        } else {
            this.setState({
                filters: _.omit(this.state.filters, [data.name])
            }, () => {
                this.setState({
                    dates: _.keys(this.state.filters).length !== 0 ? _.filter(this.state.baseData, this.state.filters) : this.state.baseData,
                    loadingDates: false,
                    filter: _.keys(this.state.filters).length !== 0 ? true : false
                }, () => {
                    this.setDatesData()
                    this.getFilterValues()
                })
            })
        }
    }

    selectHandler = (date, index) => {
        this.setState({
            selectLoading: true
        })

        let kids = [...this.state.kids]
        kids[index]['kids_camps'] = kids[index]['kids_camps'].concat(date)
        kids[index]['kids_can_select'] = kids[index]['kids_can_select'].filter(i => i.id_date !== date.id_date)

        this.setState({
            selected: date,
            kids: kids
        }, () => {
            this.toggleModal(index)
            this.setState({
                selectLoading: false
            })
            this.checkSelected()
            this.removeAfterSelect()
        })
    }

    checkSelected = () => {

        for (var i = 0; i < this.state.kids.length; i++) {
            if (this.state.kids[i]['kids_camps'].length === 0) {
                this.setState({
                    isSelected: false
                })
              break;
            } else {
                this.setState({
                    isSelected: true
                })
            }
        }

    }

    toggleModal = (index) => {
        this.setState({
            modals: {
                ['modal-' + index]: this.state.modals['modal-' + index] !== undefined ? !this.state.modals['modal-' + index] : false
            }
        })
    }

    closeModal = ({index}) => {
        this.setState({
            modals: {
                ['modal-' + index]: this.state.modals['modal-' + index] !== undefined && false
            }
        }, () => this.removeAfterSelect())
    }

    removeAfterSelect = () => {

        this.setState({
            loadingDates: true
        })

        this.setState({
            filters: {},
            filter_date_from: '',
            dates: this.state.baseData,
            loadingDates: false,
            filter: false
        }, () => this.getDatesHandler())
    }

    getDatesHandler = (camp = null) => {

        const { isHobby } = this.props

        getDatesHobby(isHobby, camp)
            .then((data) => {
                this.setState({
                    dates: data,
                    loadingDates: false,
                    baseData: data
                }, () => {
                    this.setDatesData()
                })
            })
            .catch((err) => {
                alert('Nepodařilo se nám získat data. Zaktualizujte prosím stránku.')
            })
    
            this.setState({
                kids: this.props.kids
            }, () => {
                this.checkSelected()
                this.setDatesData()
            })
    }


    setDatesData = () => {
        const { isHobby } = this.props

        let kids = [...this.state.kids]
    
            this.state.kids.map((item, index) => {

                var today = new Date();
                
                var diff = moment(kids[index]['kids_birthday']).diff(today, 'years', true);
                var interDiff = -diff
    
                kids[index]['kids_can_select'] = this.state.dates.filter(i => interDiff >= i.min_age && interDiff <= i.max_age)
            })
    
            this.setState({
                kids: kids,
                canSelect: true
            })
        
    }

    renderKids = () => {

        return this.state.kids.map((kid, index) => {

            return (
                <FormSection key={index}>
                    <FormSectionHead>
                        <h5>{kid.kids_name} {kid.kids_lastname}</h5>
                    </FormSectionHead>

                    <FormSectionBody>

                        {kid.kids_camps.map((camp, idx) => {
                            return (
                                this.selectedItem(camp, index)
                            )
                        })}

                        <Button onClick={() => this.toggleModal(index)}>{lang.add_session}</Button>
                    </FormSectionBody>
                    
                    {this.datesList(index, this.state.modals['modal-' + index] !== undefined ? !this.state.modals['modal-' + index] : false)}
                </FormSection>
            )
        })
    }

    removeHandler = (index, item) => {
        const itemToDelete = item
        let kids = [...this.state.kids]
        
        kids[index]['kids_camps'] = kids[index]['kids_camps'].filter(i => i !== itemToDelete)
        kids[index]['kids_can_select'] = kids[index]['kids_can_select'].concat(itemToDelete)

        this.setState({
            kids: kids
        }, () => {
            this.checkSelected()
        })
    }

    nextHandler = () => {
        this.props.setTerm(this.state.selected)
    }

    renderDates = (index) => {

        const { campType } = this.props

        if(this.state.canSelect) {
            if(this.state.kids[index].kids_can_select.length !== 0) {
                return this.state.kids[index].kids_can_select.map((date) => {
                    return (
                        <DateItem className={date.date_capacity - date.date_signed <= 0 ? 'row full_date' : 'row'} camp={campType}>
                            <Col sm={2} className='camp_name_uppercase p-0'>
                                <img className='img-fluid' alt={date.camp_name} src={'https://api.sportjoy.cz/media/upload/camp/' + date.id_camp + '/' + date.camp_logo} />
                            </Col>
                            <Col sm={3} className='pl-sm-5 col-sm-3 mb-3 mb-sm-0 mt-3 mt-sm-0'>
                                <span className='bold d-block'>{date.camp_name}</span>
                                {date.location_address}
                            </Col>
                            <Col sm={2} className='mb-3 mb-sm-0'>
                                <DateItemLabel>{lang.date}</DateItemLabel>
                                <span style={{fontSize: 22}}>{moment(date.date_from).format('dddd')} {moment(date.start_time).format('H:mm')}</span><br />
                                {moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}
                            </Col>
                            <Col sm={1} className='mb-3 mb-sm-0'>
                                <DateItemLabel>{lang.availability}</DateItemLabel>
                                <span className='bold'>{date.is_full === 0 ? lang.available : lang.full}</span>
                            </Col>
                            <Col sm={2} className='d-sm-flex justify-content-end pl-0 pr-0 pr-sm-4'>
                                <Button onClick={() => this.selectHandler(date, index)}>{date.date_capacity - date.date_signed <= 0 ? lang.as_substitute : lang.select_session}</Button>
                            </Col>
                        </DateItem>
                    )
                })
            } else {
                return (
                    <div style={{minHeight: 300}} className='d-flex align-items-center justify-content-center'>
                        <p>{lang.filter_no_dates}</p>
                    </div>
                )
            }
            
        } else {
            return (
                <p>Načítám...</p>
            )
        }
    }

    selectedItem = (camp, index) => {
        return (
            <div>
            <DateSelected loading={this.state.selectLoading} full={this.state.kids[index].kids_camps.length !== 0 && camp.date_capacity - camp.date_signed <= 0}>
                {this.state.kids[index].kids_camps.length === 0 ? <p>{lang.use_button_below}</p> 
                :
                <div className='row'>
                    <div className='col-sm-1 camp_name_uppercase'>
                        {camp.camp_name}
                    </div>
                    <div className='col-sm-3'>
                        {moment(camp.date_from).format('D. M.')} - {moment(camp.date_to).format('D. M. YYYY')}
                    </div>
                    <div className='col-sm-4'>
                        {camp.location_address}
                    </div>
                    <div className='col-sm-2'>
                        {(camp.date_capacity - camp.date_signed) !== 0 ? lang.available : lang.full}
                    </div>
                    <div className='col-sm-2'>
                        <Button onClick={() => this.removeHandler(index, camp)}>{lang.remove}</Button>
                    </div>
                </div>
                }
            </DateSelected>
            {this.state.kids[index].kids_camps.length !== 0 && camp.date_capacity - camp.date_signed <= 0 &&
                <EmbedMessage type='error'><p>{lang.session_full}</p></EmbedMessage>
            }
            </div>
        )
    }

    datesList = (index, show) => {
        return (
            <div>
                <Modal open={show} onClose={() => this.closeModal(index)} size={'fullscreen'} closeIcon>
                    <Modal.Header>{lang.select_session}</Modal.Header>
                    <Modal.Content>
                        <Row className='mb-4'>
                            <Col sm={4}>
                                <label>{lang.activity_type}</label>
                                <Dropdown
                                    name='activity_type'
                                    options={activityTypes}
                                    placeholder={lang.select_activity_type}
                                    selection
                                    search={isMobile === false ? true : false}
                                    onChange={this.onChangeActivityType}
                                    fluid
                                />
                            </Col>
                            <Col sm={4}>
                                <label>{lang.activity}</label>
                                <Dropdown
                                    name='id_camp'
                                    options={this.state.campsOptions}
                                    placeholder={lang.select_activity}
                                    selection
                                    search={isMobile === false ? true : false}
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </Col>
                            <Col sm={4}>
                                <label>{lang.location}</label>
                                <Dropdown
                                    name='id_location'
                                    options={this.state.locOptions}
                                    placeholder={lang.select_location}
                                    selection
                                    search={isMobile === false ? true : false}
                                    onChange={this.onChangeSelect}
                                    fluid
                                />
                            </Col>
                        </Row>
                        {this.renderDates(index)}
                    </Modal.Content>
                </Modal> 
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderKids()}
                <FormButtonGroup>
                    {!this.state.isSelected &&
                    <p>{lang.select_one_session}</p>}
                    <Button className='btn_end' onClick={this.nextHandler} disabled={!this.state.isSelected}>{lang.next}</Button>
                </FormButtonGroup>
            </div>
            
        )
    }

}

export default TermsForm