import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { Col, Row } from 'reactstrap'
import { Button, Icon, Modal } from 'semantic-ui-react'

function LocationInfoModal({ lat, long, loc_name }) {

    const [modalOpen, setModalOpen] = useState(false);

    const zoom = 12
    const defaultProps = {
        center: {
            lat: Number(lat),
            lng: Number(long)
        }
    };

    useEffect(() => {
        document.body.classList.add('dates-with-modal');
    })

    return (
        <>
            <div style={{width: 400}}>
                <Row>
                    <Col sm='12' style={{ height: '300px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyBiasnDNIrGN_qSLIfcOCgoiG5g_Vz7rr4' }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={zoom}
                        >
                            <img src="https://www.sportjoy.cz/images/map_marker_sportjoy.png" style={{maxWidth: 40, position: 'absolute', transform: 'translate(-50%, -50%)'}} lat={lat} lng={long} />
                        </GoogleMapReact>
                    </Col>
                </Row>
            </div>
        </>
    )

}

export default LocationInfoModal