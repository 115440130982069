import React from "react";
import { Col, Row } from "reactstrap";
import Dot from "./Dot";

function Dots({ data, onChange, id_user, loadingDots }) {
  return (
    <>
      <Row>
        <Col sm={6}>
          <Dot
            value={data?.dot1}
            id_user={id_user}
            name="dot1"
            onChange={onChange}
            loadingDots={loadingDots}
          />
        </Col>
        <Col sm={6}>
          <Dot
            value={data?.dot2}
            id_user={id_user}
            name="dot2"
            onChange={onChange}
            loadingDots={loadingDots}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Dot
            value={data?.dot3}
            id_user={id_user}
            name="dot3"
            onChange={onChange}
            loadingDots={loadingDots}
          />
        </Col>
        <Col sm={6}>
          <Dot
            value={data?.dot4}
            id_user={id_user}
            name="dot4"
            onChange={onChange}
            loadingDots={loadingDots}
          />
        </Col>
      </Row>
    </>
  );
}

export default Dots;
