import React from 'react'
import { Form, Input, Button, Dropdown, TextArea } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'

const yesNoOpts = [
    {key: '0', value: 0, text: 'Ne'},
    {key: '1', value: 1, text: 'Ano'}
]


const EditCampForm = ({ loading, camp, onChange, onChangeSelect, values, submit, typesOpts, loadingTypes }) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <Form.Input 
                    label='Název táboru / kroužku'
                    type='text'
                    name='camp_name'
                    placeholder='Název táboru'
                    value={camp.camp_name}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <label>Tábor / kroužek</label>
                <Dropdown 
                    name='type'
                    placeholder='Vyberte možnost'
                    options={typesOpts}
                    value={camp.type}
                    onChange={onChangeSelect}
                    selection
                    search
                    loading={loadingTypes}
                />
            </Form.Field>
            <Form.Field>
                <label>Organizace</label>
                <Dropdown 
                    name='id_organization'
                    placeholder='Vyberte možnost'
                    options={values}
                    value={camp.id_organization}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <Form.Field>
                <Form.TextArea
                    label='Popis'
                    name='camp_description'
                    placeholder='Popis'
                    value={camp.camp_description}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    label='Plná cena'
                    type='number'
                    name='full_price'
                    placeholder='Plná cena'
                    value={camp.full_price}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    label='Cena pro sourozence'
                    type='number'
                    name='lower_price'
                    placeholder='Cena pro sourozence'
                    value={camp.lower_price}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    label='Minimální věk'
                    type='number'
                    name='min_age'
                    placeholder='Minimální věk'
                    value={camp.min_age}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    label='Maximální věk'
                    type='number'
                    name='max_age'
                    placeholder='Maximální věk'
                    value={camp.max_age}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    label='Název ve filteru'
                    type='text'
                    name='filter_title'
                    placeholder='Zadejte název ve filteru'
                    value={camp.filter_title}
                    onChange={onChange}
                    required
                />
            </Form.Field>
            <Form.Field>
                <label>Tajné</label>
                <Dropdown 
                    name='private'
                    placeholder='Vyberte možnost'
                    options={yesNoOpts}
                    value={camp.private}
                    onChange={onChangeSelect}
                    selection
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditCampForm