import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Modal, Button, Icon, Dropdown } from 'semantic-ui-react'
import { changeKidsDate, getToken, addKidsDate, getCampsFilter, getLocationsFilter, getEmbedDatesFilterAdmin, dateFilterFrom } from '../../../api'
import { DateItem } from '../../../styles'
import { useDispatch } from "react-redux";
import { setMessage } from '../../../actions/messageActions';
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import _ from 'loadsh'
import { jsonToFormData } from '../../../tools'

function KidsDates({ applicationId, loadedDates, action, refresh, oldId, kidsId, modalTitle, buttonIcon, buttonText }) {

    const [dates, setDates] = useState([])
    const [filteredDates, setFilteredDates] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [campType, setCampType] = useState(null)
    const [campsOptions, setCampsOptions] = useState([{key: '0', value: null, text: 'Vyberte nejdříve typ'}])
    const [locationsOptions, setLocationsOptions] = useState([{key: '0', value: null, text: 'Vyberte nejdříve typ'}])
    const [typeOptions, setTypeOptions] = useState([{key: "1", value: 1, text: 'Tábory'}, {key: "2", value: 2, text: 'Kroužky'}, {key: "3", value: 3, text: 'Kurzy'}, {key: "4", value: 4, text: 'Skolka'}])
    const [defaultType, setDefaultType] = useState(1)
    const [datesOptions, setDatesOptions] = useState([{key: '0', value: null, text: 'Vyberte nejdříve typ'}])
    const [filters, setFilters] = useState({})
    const [filterDateFrom, setFilterDateFrom] = useState('')

    const dispatch = useDispatch();

    useEffect(() => {
        setDates([])
        console.log('change')
        if(_.keys(filters).length === 0) {
            setDates(filterDateFrom ? filteredDates : loadedDates)
        } else {
            setDates(_.filter(filterDateFrom ? filteredDates : loadedDates, filters))
        }

        if(defaultType === 1) {
            setCampType(defaultType)
            getCampsOptions(defaultType)
            getLocationsOptions(defaultType)
            getDatesOptions(defaultType)
        }

    }, [filters])

    function changeDateHandler(id) {
        const toChange = {
            old: oldId,
            new: id,
            kid: kidsId
        }

        changeKidsDate(toChange)
        .then((res) => {
            dispatch(setMessage(res))
            refresh()
            setModalOpen(false)
        })
    }

    function addDateHandler(newId) {

        getToken().then((token) => {
            const data = {
                token: token,
                kid: kidsId,
                new: newId,
                id: applicationId
            }

            addKidsDate(data)
            .then((res) => {
                dispatch(setMessage(res))
                refresh()
                setModalOpen(false)
            })
        })
        
    }

    function getCampsOptions(campType) {
        getCampsFilter(campType)
        .then((data) => {
            setCampsOptions(data.data)
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat druhy táborů. Zaktualizujte prosím stránku.')
        })
    }

    function getLocationsOptions(campType) {

        var type = '';
        if(campType === 1) {
            type = 'camps'
        } else if(campType === 2) {
            type = 'hobby'
        } else if(campType === 3) {
            type = 'kurzy'
        } else if(campType === 4) {
            type = 'skolka'
        }

        const toSend = {
            filters: filters,
            activity_type: type
        }

        const fd = jsonToFormData(toSend)

        getLocationsFilter(fd)
        .then((data) => {
            setLocationsOptions(data.data)
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat lokality. Zaktualizujte prosím stránku.')
        })
    }

    function getDatesOptions(campType) {
        getEmbedDatesFilterAdmin(campType)
        .then((data) => {
            setDatesOptions(data)
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat data. Zaktualizujte prosím stránku.')
        })
    }

    function getFilteredHandler(date) {
        dateFilterFrom(date)
        .then((data) => {
            if(data.length !== 0) {
                if(_.keys(filters).length !== 0) {
                    setDates(_.filter(data, filters))
                    setFilteredDates(_.filter(data, filters))
                } else {
                    setDates(data)
                    setFilteredDates(data)
                }
            }
        })
        .catch((err) => {
            alert('Nepodařilo se nám získat data. Zaktualizujte prosím stránku.')
        })
    }

    function onChangeSelect(e, data) {
        if(data.value) {
            setFilters({...filters, [data.name]: data.value})
        } else {
            setFilters(_.omit(filters, [data.name]))
        }
    }

    function onChangeDate(e, data) {
        if(data.value) {
            getFilteredHandler(data.value)
            setFilterDateFrom(true)
        } else {
            if(_.keys(filters).length === 0) {
                setDates(loadedDates)
            } else {
                setDates(_.filter(loadedDates, filters))
            }
            setFilterDateFrom(false)
        }
    }

    function onChangeType(e, data) {
        onChangeSelect(e, data)
        setDefaultType(data.value)
        setCampType(data.value)
        getCampsOptions(data.value)
        getLocationsOptions(data.value)
        getDatesOptions(data.value)
    }

    function renderDates() {
        return dates.map((date, index) => {
            return (
                <DateItem className='row' key={index}>
                    <div className='col-sm-2 camp_name_uppercase'>
                        {date.camp_name}<br />
                        <span style={{
                            paddingTop: 5, 
                            paddingBottom: 5, 
                            paddingLeft: 10, 
                            paddingRight: 10, 
                            color: '#fff', 
                            fontWeight: '700', 
                            fontSize: 12,
                            textTransform: 'none',
                            background: '#FF9630',
                            borderRadius: 500,
                            display: 'inline-block',
                            marginTop: 10
                            }}>{date.full_price},- Kč</span>
                    </div>
                    <div className='col-sm-2'>
                        {moment(date.date_from).format('D. M.')} - {moment(date.date_to).format('D. M. YYYY')}
                    </div>
                    <div className='col-sm-4'>
                        {date.location_address}
                    </div>
                    <div className='col-sm-2'>
                        volné {date.date_capacity - date.date_signed}/{date.date_capacity}
                    </div>
                    <div className='col-sm-2 text-right'>
                        {action === 'change' && <Button fluid onClick={() => changeDateHandler(date.id_date)}>Vybrat</Button>}
                        {action === 'new' && <Button fluid onClick={() => addDateHandler(date.id_date)}>Přidat</Button>}
                    </div>
                </DateItem>
            )
        })
    }

    return (
        <>
        <Modal size='large' trigger={<Button icon onClick={() => setModalOpen(true)}><Icon name={buttonIcon} />{buttonText}</Button>} onClose={() => setModalOpen(false)} open={modalOpen}>
            <Modal.Header>{modalTitle}</Modal.Header>
            <Modal.Content>
                <Row className='mb-4'>
                    <Col sm={2}>
                        <label>Typ</label>
                        <Dropdown
                            name='type'
                            options={typeOptions}
                            placeholder='Vyberte typ'
                            selection
                            onChange={onChangeType}
                            fluid
                            defaultValue={defaultType}
                        />
                    </Col>
                    <Col sm={3}>
                        <label>Druh aktivity</label>
                        <Dropdown
                            name='id_camp'
                            options={campsOptions}
                            placeholder='Vyberte aktivitu'
                            selection
                            search
                            onChange={onChangeSelect}
                            fluid
                            clearable
                        />
                    </Col>
                    <Col sm={3}>
                        <label>Lokalita</label>
                        <Dropdown
                            name='id_location'
                            options={locationsOptions}
                            placeholder='Vyberte lokalitu'
                            selection
                            search
                            onChange={onChangeSelect}
                            fluid
                            clearable
                        />
                    </Col>
                    <Col sm={3}>
                        <label>Termín</label>
                        <Dropdown
                            name='filter_date_from'
                            options={datesOptions}
                            placeholder='Vyberte termín'
                            selection
                            search
                            onChange={onChangeDate}
                            fluid
                            clearable
                        />
                    </Col>
                </Row>
                {renderDates()}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalOpen(false)}>Zavřít</Button>
            </Modal.Actions>
        </Modal>
        </>
    )
}

export default KidsDates