import React, { useEffect, useState } from 'react'
import { Icon, Modal, Button } from 'semantic-ui-react'

function AreYouSureModal({ icon, headline, message, action, actionText, loading, id, done }) {

    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        if(done === true) {
            setModalOpen(false)   
        }
    }, [done])

    return (
        <Modal trigger={<Button icon onClick={() => setModalOpen(true)}><Icon name={icon} /></Button>} onClose={() => setModalOpen(false)} open={modalOpen}>
            <Modal.Header>
                {headline}
            </Modal.Header>
            <Modal.Content>
                {message}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalOpen(false)}>Zavřít</Button>
                <Button color='red' onClick={() => action(id)} loading={loading}>{actionText}</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AreYouSureModal