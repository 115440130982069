import React, { Component } from 'react'
import { intToBool, toggleOrganizationState, shortText } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import _ from 'loadsh'

class OrganizationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2
        })
    }

    toggleStateHandler = (id) => {
        toggleOrganizationState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    render() {

        const { items, column, direction, sort } = this.props

        return (
            <div>
                <Table sortable striped className='main-table'>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_organization' ? direction : null}
                                onClick={sort('id_organization')}
                                width={1}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'organization_name' ? direction : null}
                                onClick={sort('organization_name')}
                                width={3}
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'organization_person' ? direction : null}
                                onClick={sort('organization_person')}
                                width={3}
                            >
                                Osoba
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'organization_address' ? direction : null}
                                onClick={sort('organization_address')}
                                width={6}
                            >
                                Adresa
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'organization_state' ? direction : null}
                                onClick={sort('organization_state')}
                                width={1}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell width={1}></Table.HeaderCell>
                            <Table.HeaderCell width={1}></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_organization, organization_name, organization_person, organization_address, organization_state }) => (
                        <Table.Row key={id_organization}>
                            <Table.Cell>
                                {id_organization}
                            </Table.Cell>
                            <Table.Cell>
                                {organization_name}
                            </Table.Cell>
                            <Table.Cell>
                                {organization_person}
                            </Table.Cell>
                            <Table.Cell>
                                {shortText(organization_address, 60)}
                            </Table.Cell>
                            <Table.Cell>
                                {intToBool(organization_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_organization)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_organization)}></i>}
                            </Table.Cell>
                            <Table.Cell>
                                <i class="icofont-trash" onClick={() => this.toggleModal(id_organization)}></i>
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`${process.env.PUBLIC_URL}/manage/organizations/edit/` + id_organization}><i class="icofont-ui-edit"></i></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>

                <AreYouSureModal show={this.state.showModal} toggle={this.toggleModal} id={this.state.idToDelete} refresh={this.props.refresh}/>

            </div>
        )
    }
}

export default OrganizationsTable