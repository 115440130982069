import React, { Component } from "react";
import { Icon, Step, Button } from "semantic-ui-react";
import { ParentForm, KidsForm, ConfirmForm } from "../forms";
import TermsForm from "../forms/application/TermsForm";
import { StepCard, UserId } from "../../styles";
import { createApplication, requestPaymentLink } from "../../api";
import { EmbedNavigation } from "../containers";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import { applicationsLang as lang } from "../../lang/lang";
import { connect } from "react-redux";

//smartlookClient.init('ed27b3b7554d5863cd0b4f8b121addd0a77cae2a')

class EmbedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: {
        parent: true,
        kids: false,
        term: false,
        confirm: false,
      },
      completed: {
        parent: false,
        kids: false,
        term: false,
        confirm: false,
      },
      disabled: {
        parent: false,
        kids: true,
        term: true,
        confirm: true,
      },
      data: {
        parents: [],
        kids: [],
        term: "",
      },
      res: {
        type: "",
        message: "",
        id: "",
      },
      paymentLink: "",
      loading: false,
      logged: false,
      price: 0,
      isFree: false,
      loadingLang: true,
    };
  }

  componentDidMount() {
    const { language, utm } = this.props.match.params;

    if (language) {
      lang.setLanguage(language);
      this.setState({
        loadingLang: false,
      });
    } else {
      this.setState({
        loadingLang: false,
      });
    }

    document.body.classList.add(utm);
    document.body.classList.add("body-application");

    ReactGA.initialize("UA-155260757-1", {
      debug: false,
      cookieDomain: "kidsjoy.cz",
    });

    ReactGA.pageview("/prihlaska");

    const advancedMatching = {};
    const options = {
      autoConfig: true, // set pixel's autoConfig
    };
    ReactPixel.init("1745468775681735", advancedMatching, options);
    ReactPixel.track("Lead");
  }

  setLang = (lang) => {
    this.props.history.push(`/embed/signup/sportjoy/${lang}`);
  };

  setParent = (data) => {
    this.setState({
      steps: {
        ...this.state.steps,
        parent: false,
        kids: true,
      },
      completed: {
        ...this.state.completed,
        parent: true,
      },
      disabled: {
        ...this.state.disabled,
        kids: false,
      },
      data: {
        ...this.state.data,
        parents:
          this.state.data.parents.length === 0
            ? this.state.data.parents.concat(data)
            : data,
      },
    });
    ReactGA.event({
      category: "User",
      action: "Vyplněný rodič",
    });
  };

  setKids = (data) => {
    this.setState({
      steps: {
        ...this.state.steps,
        kids: false,
        term: true,
      },
      completed: {
        ...this.state.completed,
        kids: true,
      },
      disabled: {
        ...this.state.disabled,
        term: false,
      },
      data: {
        ...this.state.data,
        kids:
          this.state.data.kids.length === 0
            ? this.state.data.kids.concat(data)
            : data,
      },
    });
    ReactGA.event({
      category: "User",
      action: "Vyplněné dítě",
    });
  };

  setTerm = (data) => {
    this.setState({
      steps: {
        ...this.state.steps,
        term: false,
        confirm: true,
      },
      completed: {
        ...this.state.completed,
        term: true,
      },
      disabled: {
        ...this.state.disabled,
        confirm: false,
      },
      data: {
        ...this.state.data,
        term: data,
      },
    });
    ReactGA.event({
      category: "User",
      action: "Vybrané termíny",
    });
  };

  setConfirm = (data, price, isFree) => {
    const { language } = this.props.match.params;

    let parents = [...this.state.data.parents];
    parents[0] = { ...parents[0], additional: data };

    this.setState(
      {
        data: {
          ...this.state.data,
          parents: parents,
          price: price,
          lang: language,
        },
        price: price,
        isFree: isFree,
      },
      () => {
        this.submitHandler();
      }
    );

    ReactGA.event({
      category: "User",
      action: "Pokus o přihlášení",
    });
  };

  submitHandler = () => {
    const { utm } = this.props.match.params;

    this.setState({
      loading: true,
    });

    createApplication(this.state.data, "camp")
      .then((res) => {
        this.setState({
          res: {
            type: res.type,
            message: res.message,
            id: res.id,
            vs: res.vs,
          },
          loading: false,
        });

        const data = {
          order_num: res.id,
          amount: this.state.price,
          utm: utm,
        };

        if (!this.state.isFree || this.state.price !== 0) {
          requestPaymentLink(data)
            .then((res) => {
              this.setState({
                paymentLink: res.link,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }

        ReactGA.event({
          category: "User",
          action: "Úspěšné přihlášení",
        });

        ReactPixel.track("Purchase", {
          currency: "CZK",
          value: this.state.price,
        });
        ReactPixel.trackCustom("Purchase 2021", {
          currency: "CZK",
          value: this.state.price,
        });

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        alert(
          "Omlouváme se, přihlášku se nepodařilo odeslat. Zkuste to prosím znovu."
        );
        console.log("error", err);
        this.setState({
          loading: false,
        });
      });
  };

  showParent = () => {
    this.setState({
      steps: {
        parent: true,
        kids: false,
        term: false,
        confirm: false,
      },
    });
  };

  showKids = () => {
    this.setState({
      steps: {
        parent: false,
        kids: true,
        term: false,
        confirm: false,
      },
    });
  };

  showTerms = () => {
    this.setState({
      steps: {
        parent: false,
        kids: false,
        term: true,
        confirm: false,
      },
    });
  };

  showConfirm = () => {
    this.setState({
      steps: {
        parent: false,
        kids: false,
        term: false,
        confirm: true,
      },
    });
  };

  _renderSteps = () => {
    const { utm, campType } = this.props.match.params;

    return (
      <>
        {!this.state.loadingLang && !this.props.loadingUser && (
          <div className="push_from_nav application">
            <Step.Group>
              <Step
                active={this.state.steps.parent}
                completed={this.state.completed.parent}
                disabled={this.state.disabled.parent}
                onClick={this.showParent}
              >
                <Icon name="user" />
                <Step.Content>
                  <Step.Title>{lang.parents}</Step.Title>
                  {/*<Step.Description>{lang.parents_desc}</Step.Description>*/}
                </Step.Content>
              </Step>

              <Step
                active={this.state.steps.kids}
                completed={this.state.completed.kids}
                disabled={this.state.disabled.kids}
                onClick={this.showKids}
              >
                <Icon name="child" />
                <Step.Content>
                  <Step.Title>{lang.child}</Step.Title>
                  {/*<Step.Description>{lang.child_desc}</Step.Description>*/}
                </Step.Content>
              </Step>

              <Step
                active={this.state.steps.term}
                completed={this.state.completed.term}
                disabled={this.state.disabled.term}
                onClick={this.showTerms}
              >
                <Icon name="calendar alternate outline" />
                <Step.Content>
                  <Step.Title>{lang.date}</Step.Title>
                  {/*<Step.Description>{lang.date_desc}</Step.Description>*/}
                </Step.Content>
              </Step>

              <Step
                active={this.state.steps.confirm}
                onClick={this.showConfirm}
                disabled={this.state.disabled.confirm}
              >
                <Icon name="check" />
                <Step.Content>
                  <Step.Title>{lang.application_complete}</Step.Title>
                </Step.Content>
              </Step>
            </Step.Group>

            <StepCard active={this.state.steps.parent}>
              <ParentForm setParent={this.setParent} isHobby={campType} />
            </StepCard>

            <StepCard active={this.state.steps.kids}>
              <KidsForm setKids={this.setKids} isHobby={campType} />
            </StepCard>

            {this.state.data.kids.length !== 0 && (
              <StepCard active={this.state.steps.term}>
                <TermsForm
                  setTerm={this.setTerm}
                  kids={this.state.data.kids}
                  campType={utm}
                  isHobby={campType}
                />
              </StepCard>
            )}

            {this.state.data.kids.length !== 0 &&
              this.state.data.kids[0].kids_camps.length !== 0 && (
                <StepCard active={this.state.steps.confirm}>
                  <ConfirmForm
                    setConfirm={this.setConfirm}
                    data={this.state.data}
                    loading={this.state.loading}
                    isHobby={campType}
                  />
                </StepCard>
              )}
          </div>
        )}
      </>
    );
  };

  _renderMessage = ({ type, message, id }) => {
    const { utm } = this.props.match.params;

    return (
      <div className="push_from_nav application application_message">
        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML:
                "gtag('event', 'conversion', {'send_to': 'AW-760604231/jqzTCIuIzPIBEMfM1-oC', 'value': " +
                this.state.price +
                ", 'currency': 'CZK', 'transaction_id': ''});",
            },
          ]}
        />
        <Icon
          className="application_icon"
          name={type === "success" ? "thumbs up" : "thumbs down"}
        />
        <h1>{message}</h1>
        <div className="application_success_info">
          <p className="text-center">{lang.your_id}</p>
          <UserId>{id}</UserId>

          {!this.state.isFree || this.state.price !== 0 ? (
            <>
              <p className="big_text">{lang.confirm_message}</p>
              <p>{lang.payment_deadline}</p>
            </>
          ) : (
            <>
              <p className="big_text">{lang.confirm_message}</p>
            </>
          )}

          {!this.state.isFree || this.state.price !== 0 ? (
            <>
              <div className="row card-payment">
                <div className="col-sm-6">
                  <h2>{this.state.price},- Kč</h2>
                </div>
                <div className="col-sm-6 text-center">
                  <Button
                    size="massive"
                    color="green"
                    href={this.state.paymentLink}
                  >
                    {lang.pay_by_card}
                  </Button>
                </div>
              </div>

              <div className="row banktransfer-payment">
                <div className="col-sm-6">
                  <h3>{lang.bank_transfer}</h3>
                  <p>
                    {lang.total_price}: <strong>{this.state.price},- Kč</strong>
                  </p>
                  <p>
                    {lang.bank_acc_num_label} <strong>5669472379/0800</strong>
                  </p>
                  <p>
                    {lang.variable_symbol} <strong>{id}</strong>
                  </p>
                </div>
                <div className="col-sm-6 qr">
                  <img
                    src={
                      "https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=5669472379&bankCode=0800&amount=" +
                      this.state.price +
                      "&currency=CZK&vs=" +
                      id
                    }
                    className="qr_payment img-fluid"
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="col-sm-12">
              <Button href={"https://www.sportjoy.cz"} target="_parent">
                {lang.back_to_sportjoy}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  _renderSubMessage = ({ id, type }) => {
    return (
      <div className="push_from_nav application application_message">
        <Icon
          className="application_icon"
          name={type === "success" ? "thumbs up" : "thumbs down"}
        />
        <h1>{lang.application_success_substitute}</h1>
        <div className="application_success_info">
          <p className="text-center">{lang.your_id}</p>
          <UserId>{id}</UserId>

          <p className="big_text">{lang.confirm_message}</p>
          <p>{lang.once_place_for_child}</p>
          <p>{lang.payment_after_conf}</p>

          <div className="row">
            <div className="col-sm-12">
              <Button href={"https://www.sportjoy.cz"} target="_parent">
                {lang.back_to_sportjoy}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { utm } = this.props.match.params;

    return (
      <div className="embed" id="iFrameSizer">
        <EmbedNavigation
          utm={utm}
          type="camps"
          text={lang.camps_application}
          setLang={this.setLang}
        />
        <div className="container">
          {this.state.res.type === "" ? (
            this._renderSteps()
          ) : this.state.price !== 0 || this.state.isFree ? (
            <this._renderMessage
              type={this.state.res.type}
              message={this.state.res.message}
              id={this.state.res.vs}
            />
          ) : (
            <this._renderSubMessage
              type={this.state.res.type}
              id={this.state.res.vs}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default connect(mapStateToProps, {})(EmbedView);
