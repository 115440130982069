import styled from "styled-components";

export const StyledInterestValue = styled.div`
  width: 100%;
  padding: 1rem;
  background: #f6f6f6;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
