import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Form, Icon, Modal } from 'semantic-ui-react'
import { setMessage } from '../../actions/messageActions';
import { sendEmail } from '../../api/employees';
import { jsonToFormData } from '../../tools';

function SendEmailModal({ displayType, id }) {

    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState({ subject: '', email_body: '', id_user: id });

    function onChange(e, val) {
        setData({...data, [e.target.name]: e.target.value});
    }

    function _send() {
        setLoading(true);

        const payload = jsonToFormData(data);

        sendEmail(payload)
        .then((res) => {
            dispatch(setMessage(res.data));
            setLoading(false);
            setOpen(false);
        })
        .catch((err) => {
            dispatch(setMessage({type: 'error', text: 'Email se nepodařilo odeslat: ' + err.message}));
            setLoading(false);
            setOpen(false);
        })
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} trigger={displayType === 'icon' ? <Button loading={loading} icon onClick={() => setOpen(true)}><Icon name='at' /></Button> : <Button fluid primary loading={loading} onClick={() => setOpen(true)}>Napsat email</Button>}>
            <Modal.Header>
                Email pro lektora
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input 
                        name="subject"
                        type="text"
                        label="Předmět"
                        placeholder="Zadejte předmět emailu"
                        value={data.subject}
                        onChange={onChange}
                    />
                    <Form.TextArea 
                        name="email_body"
                        label="Zpráva"
                        placeholder="Napište Vaši zprávu"
                        value={data.email_body}
                        onChange={onChange}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Zavřít</Button>
                <Button primary loading={loading} onClick={_send}>Odeslat email</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default SendEmailModal