import React, { useState } from 'react'
import { Dropdown, Form, Modal, Button } from 'semantic-ui-react'
import { getToken } from '../../api'

function ExportModal(props) {

    const [ modalOpen, setModalOpen ] = useState(false)
    const [ range, setRange ] = useState({ from: '', to: '' })
    const [ loadingExport, setLoadingExport ] = useState(false)

    function exportList(state) {

        const { type, free } = props.match.params
        let paidFree = '';

        if(Number(free) === 1) {
            paidFree = '_free';
        } else {
            paidFree = '_paid';
        }

        var url = '';
        url = 'https://api.sportjoy.cz/export/export?type='+state+'&app_type='+type+paidFree+ '&app_range=' + range.from + ';' + range.to;
        
        setLoadingExport(true)

        getToken().then((token) => {
            window.open(url + '&token=' + token, "_blank")
            setModalOpen(false)
            setLoadingExport(false)
        })
        .catch((err) => {
            alert('Nepodařilo se získat bezpečnostní certifikát s chybou:' + err.message +'. Zkuste to prosím znovu.')
            setLoadingExport(false)
        })
    }

    function onChange(e) {
        setRange({
            ...range,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)} trigger={<Dropdown.Item onClick={() => setModalOpen(true)} icon='list' text='Export od - do' />}>
            <Modal.Header>
                Export v rozmezí...
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input 
                        name="from"
                        type="number"
                        label="ID přihlášky (od)"
                        placeholder="Zadejte ID od kterého chcete export"
                        onChange={onChange}
                    />
                    <Form.Input 
                        name="to"
                        type="number"
                        label="ID přihlášky (do)"
                        placeholder="Zadejte ID do kterého chcete export"
                        onChange={onChange}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => setModalOpen(false)}>Zavřít</Button>
                <Button primary onClick={() => exportList('range')} loading={loadingExport}>Exportovat</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ExportModal