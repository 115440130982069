import React, { useState } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import NewInvoiceForm from '../forms/invoicing/NewInvoiceForm'

function NewInvoiceModal({ invoiceDetails, idApplication, refresh }) {

    const [ isOpen, setOpen ] = useState(false);

    function closeModal() {
        setOpen(false)
    }

    return (
        <Modal trigger={<Button icon onClick={() => setOpen(true)}><Icon name='plus' /></Button>} open={isOpen} onClose={closeModal}>
            <Modal.Header>
                Nová faktura
            </Modal.Header>
            <Modal.Content>
                <NewInvoiceForm closeModal={closeModal} idApplication={idApplication} refresh={refresh} invoiceText={invoiceDetails.text ? invoiceDetails.text : ''} />
            </Modal.Content>
        </Modal>
    )
}

export default NewInvoiceModal