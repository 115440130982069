import React, { useEffect, useState } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { getNote, updateNote } from '../../../api/employees';
import { jsonToFormData } from '../../../tools';

function EmployeeNoteForm({ id_user }) {

    const [ data, setData ] = useState({ note: ''});
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getData();
    }, [])

    function onChange(e, target) {
        setData({...data, [e.target.name]: e.target.value})
    }

    function getData() {
        setLoading(true);

        getNote(id_user)
        .then((res) => {
            setData(res.data);
            setLoading(false);
            console.log(res)
        })

    }

    function onSubmit() {
        setLoading(true);
        const toSend = {
            note: data.note,
            id_user: id_user
        }

        const payload = jsonToFormData(toSend);

        updateNote(payload)
        .then((res) => {
            getData();
            setLoading(false);
        })

    }

    return (
        <Form loading={loading} onSubmit={onSubmit}>
            <Form.TextArea 
                name='note'
                label='Poznámka k uživateli'
                placeholder='Zadejte poznámku k uživateli'
                onChange={onChange}
                value={data.note}
            />
            <Button primary>Uložit poznámku</Button>
        </Form>
    )
}

export default EmployeeNoteForm