import React from "react";
import { Col, Row } from "reactstrap";
import { Button } from "semantic-ui-react";
import { formatMoney } from "../../../api";

const PaymentContainer = ({ payment, loading }) => {

    if(!loading && payment.paid === 1) {
        return (
            <div className="payment-box success"><span>Přihláška byla zaplacena</span></div>
        )
    }

    if(!loading && payment.paid === 0) {
        return (
            <div className="payment_instructions">
                <div className="payment-box error"><span>Přihláška není zaplacena</span></div>
                <Row className="mb-3">
                    <Col sm={12}>
                        <Button primary fluid as='a' href={payment.paygate} target='_blank'>Zaplatit kartou</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <p className="text-center my-3">- nebo -</p>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col sm={8}>
                        <p>Č. účtu: <strong>5669472379/0800</strong></p>
                        <p>VS: <strong>{payment.vs}</strong></p>
                        <p>Částka: <strong>{formatMoney(payment.total_price, 0, ',', ' ')},- Kč</strong></p>
                    </Col>
                    <Col sm={4}>
                        <img src={payment.qr_code} alt='qr platba' />
                    </Col>
                </Row>
            </div>
            
        )
    }

    if(loading) {
        return (
            <div>Načítám...</div>
        )
    }
    

}

export default PaymentContainer