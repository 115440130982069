import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Row, Col } from "reactstrap";
import { getItem } from "../../../api/instructors";
import { Card, CardBody, CardHeader } from "../../../styles";
import InstructorSecondaryForm from "../../forms/instructors/InstructorSecondaryForm";
import InstructorsSignupForm from "../../forms/instructors/InstructorsSignupForm";
import DeleteInstructorsApplication from "../../modals/DeleteInstructorsApplication";

function InstructorsApplicationDetail() {
  let { id } = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [secondaryData, setSecondaryData] = useState({
    created: "",
    id: 0,
    state: 1,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getItem(id).then((res) => {
      setData(res.data.data);
      setSecondaryData({
        created: res.data.data.created,
        id: res.data.data.id,
        state: res.data.data.state,
      });

      setLoading(false);
    });
  }

  return (
    <>
      <Row>
        <Col sm={8}>
          {!loading && (
            <InstructorsSignupForm
              submitType="update"
              withRedirect={false}
              initData={data}
              refresh={getData}
            />
          )}
        </Col>
        <Col sm={4}>
          <Card className="mb-3">
            <CardHeader>Další informace</CardHeader>
            <CardBody>
              {!loading && (
                <InstructorSecondaryForm
                  initData={secondaryData}
                  refresh={getData}
                />
              )}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <DeleteInstructorsApplication
                displayType="button"
                id={id}
                refresh={history.goBack}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default InstructorsApplicationDetail;
