import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import { setMessage } from '../../../actions/messageActions';
import { getToken } from '../../../api';
import { changePassword } from '../../../api/parents';
import { jsonToFormData } from '../../../tools';

function ChangePasswordForm() {

    let dispatch = useDispatch();
    let history = useHistory();
    const [data, setData] = useState({});
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    function onChange(e, target) {
        setData({...data, [e.target.name]: e.target.value})
    }

    function submit() {

        setLoading(true);

        if(data.password1 === data.password2) {
            getToken().then((token) => {
                const toSend = {
                    token: token,
                    password1: data.password1,
                    password2: data.password2
                }
                
                const payload = jsonToFormData(toSend);
        
                changePassword(payload)
                .then((res) => {
                    history.push('/login');
                    setLoading(false);
                    dispatch(setMessage(res.data));
                })
                .catch((err) => {
                    dispatch(setMessage(err.data));
                })
            })
        } else {
            setLoading(false);
            setError('Zadaná hesla se neshodují');
        }
    }

    return (
        <Form onSubmit={submit} loading={loading}>
            <Form.Input 
                type='password'
                name='password1'
                label='Nové heslo'
                placeholder='Zadejte nové heslo'
                value={data.password1}
                onChange={onChange}
                required
            />
            <Form.Input 
                type='password'
                name='password2'
                label='Nové heslo znovu'
                placeholder='Zadejte nové heslo znovu'
                value={data.password2}
                onChange={onChange}
                required
            />
            {error && <p className='error'>{error}</p>}
            <Button primary fluid>Změnit heslo</Button>
        </Form>
    )
}

export default ChangePasswordForm