import React, { Component } from 'react'
import { Form, Button, TextArea } from 'semantic-ui-react'
import { FormButtonGroup, FormSection, FormSectionHead, FormSectionBody } from '../../../styles'
import { applicationsLang as lang } from '../../../lang/lang';
import { connect } from 'react-redux';

class ParentForm extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            buttonActive: true,
            isFilled: false,
            basicParent: [{
                label: lang.legal_guardian_name,
                type: 'text',
                name: 'parent_name',
                placeholder: lang.legal_guardian_name,
                id: 'parent_name',
                required: true
            },
            {
                label: lang.legal_guardian_last_name,
                type: 'text',
                name: 'parent_lastname',
                placeholder: lang.legal_guardian_last_name,
                id: 'parent_lastname',
                required: true
            },
            {
                label: lang.legal_guardian_email,
                type: 'email',
                name: 'parent_email',
                placeholder: lang.legal_guardian_email,
                id: 'parent_email',
                required: true
            },
            {
                label: lang.legal_guardian_phone,
                type: 'tel',
                name: 'parent_phone',
                placeholder: lang.legal_guardian_phone,
                id: 'parent_phone',
                required: true
            }],
            formFields: [
                [{
                    label: lang.legal_guardian_name,
                    type: 'text',
                    name: 'parent_name',
                    placeholder: lang.legal_guardian_name,
                    id: 'parent_name',
                    required: true
                },
                {
                    label: lang.legal_guardian_last_name,
                    type: 'text',
                    name: 'parent_lastname',
                    placeholder: lang.legal_guardian_last_name,
                    id: 'parent_lastname',
                    required: true
                },
                {
                    label: lang.legal_guardian_email,
                    type: 'email',
                    name: 'parent_email',
                    placeholder: lang.legal_guardian_email,
                    id: 'parent_email',
                    required: true
                },
                {
                    label: lang.legal_guardian_phone,
                    type: 'tel',
                    name: 'parent_phone',
                    placeholder: lang.legal_guardian_phone,
                    id: 'parent_phone',
                    required: true
                }]
            ],
            basicData: {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: '',
                    parent_lastname: ''
            },
            data: [
                {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: '',
                    parent_lastname: ''
                }
            ],
            errors: [
                {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: '',
                    parent_lastname: ''
                },
                {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: '',
                    parent_lastname: ''
                }
            ]
        })
    }

    componentDidMount() {
        if(!this.props.loadingUser && this.props.user) {
            this.presetParent();
        }
    }

    presetParent = () => {
        this.setState({
            data: [{
                parent_name: this.props.user.user_first_name,
                parent_lastname: this.props.user.user_last_name,
                parent_email: this.props.user.user_email,
                parent_phone: this.props.user.phone
            }]
        }, () => {
            this.checkInputs()
        })
    }

    onChange = (e) => this.setState({
        data: {
            ...this.state.data,
            [e.target.name]: e.target.value
        }
    })

    removeParent = () => {
        this.setState({
            data: this.state.data.filter(i => i !== this.state.data[1]),
            formFields: [
                ...this.state.formFields.filter(i => i !== this.state.formFields[1])
            ],
            buttonActive: true
        }, () => {
            this.checkInputs()
        })
    }

    validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    checkInputs = () => {

        this.state.data.map((item, index) => {
            if(item.parent_name !== '') {
                let errors = [...this.state.errors]
                errors[index]['parent_name'] = ''
            }

            if(item.parent_lastname !== '') {
                let errors = [...this.state.errors]
                errors[index]['parent_lastname'] = ''
            }

            if(item.parent_email !== '' && this.validateEmail(item.parent_email)) {
                let errors = [...this.state.errors]
                errors[index]['parent_email'] = ''
            }

            if(item.parent_phone !== '') {
                let errors = [...this.state.errors]
                errors[index]['parent_phone'] = ''
            }
        })

        for (var i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i]['parent_name'] === '') {

                let errors = [...this.state.errors]
                errors[i]['parent_name'] = lang.enter_legal_guardian_name

                this.setState({
                    isFilled: false,
                    errors: errors
                })

              break;
            } else if (this.state.data[i]['parent_lastname'] === '') {

                let errors = [...this.state.errors]
                errors[i]['parent_lastname'] = lang.enter_legal_guardian_last_name

                this.setState({
                    isFilled: false,
                    errors: errors
                })

              break;
            } else if (this.state.data[i]['parent_email'] === '') {

                let errors = [...this.state.errors]
                errors[i]['parent_email'] = lang.enter_legal_guardian_email

                this.setState({
                    isFilled: false,
                    errors: errors
                })

              break;
            } else if (!this.validateEmail(this.state.data[i]['parent_email'])) {
                let errors = [...this.state.errors]
                errors[i]['parent_email'] = lang.email_bad_format

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['parent_phone'] === '') {
                let errors = [...this.state.errors]
                errors[i]['parent_phone'] = lang.enter_legal_guardian_phone

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else {
                this.setState({
                    isFilled: true
                })
            }
        }

    }

    renderFields = () => {
        return (
            this.state.formFields.map((item, index) => {
                return (
                    <FormSection key={index}>
                        <FormSectionHead>
                            <h5>{lang.legal_guardian} #{index + 1}</h5>
                            {index !== 0 && <Button className='button_card_head' onClick={this.removeParent}>X</Button>}
                        </FormSectionHead>
                        <FormSectionBody>
                            {item.map((field) => {
                                return (
                                    <Form.Field key={field.id} className={this.state.errors[index][field.name] !== '' ? 'error' : 'success'}>
                                        <label>{field.label} {field.required ? '*' : ''}</label>
                                        {field.type !== 'textarea' ?
                                        <input 
                                            type={field.type}
                                            name={field.name + (index+1)}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            value={this.state.data[index][field.name]}
                                            onChange={this.handleChange}
                                            className={field.name}
                                        />
                                        :
                                        <TextArea
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            //value={this.state.data[field.name]}
                                            onChange={this.handleChange}
                                            className={field.name}
                                        />
                                        }
                                        <p className='form-error-text'>{this.state.errors[index][field.name]}</p>
                                    </Form.Field>
                                )
                            })}
                        </FormSectionBody>
                    </FormSection>
                )
            })
        )
        }

    nextHandler = () => {
        this.props.setParent(this.state.data)
    }

    addParentHandler = () => {
        this.setState({
            buttonActive: false,
            formFields: [
                ...this.state.formFields,
                this.state.basicParent
            ],
            data: [
                ...this.state.data,
                {
                    parent_name: '',
                    parent_email: '',
                    parent_phone: '',
                    parent_lastname: '',
                }
            ]
        }, () => {
            this.checkInputs()
        })
    }

    handleChange = (e) => {
        if (["parent_name", "parent_lastname", "parent_email", "parent_phone"].includes(e.target.className) ) {
          let parents = [...this.state.data]
          parents[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({ data: parents })
          this.checkInputs()
        }
    }

    render() {
        return (
            <Form>
                <this.renderFields />
                <FormButtonGroup>
                    {this.state.buttonActive &&
                        <Button className='ghost btn_end' onClick={this.addParentHandler}>{lang.legal_guardian_add}</Button>
                    }
                    <Button className='btn_end' onClick={this.nextHandler} disabled={!this.state.isFilled}>{lang.next}</Button>
                </FormButtonGroup>
                
            </Form>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, {})(ParentForm)