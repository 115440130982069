import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getAttendanceList } from '../../api';
import { Card, CardBody, CardHeader } from '../../styles';
import AttendanceItemForm from '../forms/AttendanceItemForm';
import EmptyTableMessage from './employees/EmptyTableMessage';

function AttendanceContainer() {

    const { id } = useParams();

    const [ list, setList ] = useState([]);
    const [ loadingList, setLoadingList ] = useState(true);

    useEffect(() => {
        getList()
    }, [])

    function getList() {

        setLoadingList(true);

        getAttendanceList(id)
        .then((res) => {
            setList(res);
            setLoadingList(false);
        })
        .catch((err) => {
            alert('Nepodařilo se získat data o docházce: ', err.message);
            setLoadingList(false);
        });


    }

    return (
        <Card>
            <CardHeader>
                Docházka
            </CardHeader>
            <CardBody>
                {!loadingList &&
                    list.map((item) => {
                        return ( <AttendanceItemForm item={item} refresh={getList} /> )
                    })
                }
                {list.length === 0 &&
                    <EmptyTableMessage 
                        message='Nejsou k dispozici žádné děti' 
                        loading={loadingList}
                        showButton={false}
                    />
                }
            </CardBody>
        </Card>
    )
}

export default AttendanceContainer