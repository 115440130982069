import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { getDots } from '../../../api';
import Dot from '../applicationslist/Dot'

function Dots({ id }) {

    const [dotsData, setDots] = useState({ dot1: 0, dot2: 0, dot3: 0, invoice_sent: 0 });

    useEffect(() => {
        getData()
    }, [id])
    
    function getData() {
        getDots(id)
        .then((res) => {
            setDots(res.data.data)
        })
        .catch((err) => {
            alert('Nepodařilo se získat tečky. Zkuste to prosím později');
        })
    }

    return (
        <>
            <Row className='app_details_dots'>
                <Col sm={3}>
                    <Dot value={dotsData.dot1} name='dot1' id={id} refresh={getData} />
                </Col>
                <Col sm={3}>
                    <Dot value={dotsData.dot2} name='dot2' id={id} refresh={getData} />
                </Col>
                <Col sm={3}>
                    <Dot value={dotsData.dot3} name='dot3' id={id} refresh={getData} />
                </Col>
                <Col sm={3}>
                    <Dot value={dotsData.invoice_sent} name='invoice_sent' id={id} refresh={getData} />
                </Col>
            </Row>
        </>
    )
}

export default Dots