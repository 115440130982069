import React, { useState } from "react";
import { Modal, Button, Icon, DropdownItem } from "semantic-ui-react";
import { disableApplication, getToken } from "../../api";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/messageActions";

function CancelApplicationModal({ id, refresh, type }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const dispatch = useDispatch();

  function disableHandler(email) {
    if (email === 1) {
      setLoadingEmail(true);
    } else {
      setLoading(true);
    }

    getToken().then((token) => {
      const data = {
        id_application: id,
        token: token,
        email: email,
      };

      disableApplication(data).then((res) => {
        dispatch(setMessage(res));
        setOpen(false);
        refresh();
        setLoadingEmail(false);
        setLoading(false);
      });
    });
  }

  function renderTrigger() {
    if (type === "button") {
      return (
        <Button icon className="ghost" onClick={() => setOpen(true)}>
          <Icon name="trash" />
        </Button>
      );
    } else if (type === "dropdown") {
      return (
        <DropdownItem
          text="Stornovat přihlášku"
          onClick={() => setOpen(true)}
        />
      );
    } else if (type === "application-detail") {
      return (
        <Button fluid color="red" onClick={() => setOpen(true)}>
          Stornovat přihlášku
        </Button>
      );
    } else {
      return <p>Not specified type</p>;
    }
  }

  return (
    <Modal
      size="small"
      open={open}
      onClose={() => setOpen(false)}
      trigger={renderTrigger()}
    >
      <Modal.Header>Jste si jistí?</Modal.Header>
      <Modal.Content>
        Opravdu chcete tuto přihlášku (#{id}) stornovat?
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => disableHandler(0)} loading={loading}>
          Stornovat
        </Button>
        <Button
          onClick={() => disableHandler(1)}
          loading={loadingEmail}
          color="red"
        >
          Stornovat a odeslat email
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CancelApplicationModal;
