import React, { Component } from "react";
import { getUsers, getUsersOrganization } from "../../api";
import { Card, CardControls, CardHeader, Button } from "../../styles";
import { UsersTable } from "../containers";
import { Link } from "react-router-dom";
import _ from "loadsh";
import { Form } from "semantic-ui-react";
import queryString from "query-string";

class UsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      baseData: [],
      org: [{ key: "0", value: null, text: "Vše" }],
      column: "id_user",
      direction: "descending",
      hasSome: false,
      filter: false,
      search: "",
    };
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);

    this.setState({
      search: parsed.query,
    });

    this.getUsersHandler();

    getUsersOrganization().then((data) => {
      this.setState({
        org: this.state.org.concat(data),
      });
    });
  }

  getUsersHandler = () => {
    const parsed = queryString.parse(this.props.location.search);

    const url = queryString.stringify(parsed, {
      skipNull: true,
    });

    getUsers(url).then((data) => {
      if (data.length !== 0) {
        if (_.keys(this.state.filters).length !== 0) {
          this.setState({
            users: _.filter(data, this.state.filters),
            baseData: data,
            hasSome: true,
          });
        } else {
          this.setState({
            users: data,
            baseData: data,
            hasSome: true,
          });
        }
      } else {
        this.setState({
          hasSome: false,
        });
      }
    });
  };

  handleSort = (clickedColumn) => () => {
    const { column, users, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        users: _.sortBy(users, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: users.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  onChangeSelect = (e, data) => {
    if (data.value !== null) {
      this.setState(
        {
          filter: true,
          filters: {
            ...this.state.filters,
            [data.name]: data.value,
          },
        },
        () => {
          this.setState({
            users: _.filter(this.state.baseData, this.state.filters),
          });
        }
      );
    } else {
      this.setState(
        {
          filters: _.omit(this.state.filters, [data.name]),
        },
        () => {
          this.setState({
            users:
              _.keys(this.state.filters).length !== 0
                ? _.filter(this.state.baseData, this.state.filters)
                : this.state.baseData,
            filter: _.keys(this.state.filters).length !== 0 ? true : false,
          });
        }
      );
    }
  };

  onChangeSearch = (e, target) => {
    this.setState({
      search: e.target.value,
    });
  };

  submitSearch = () => {
    const parsed = queryString.parse(this.props.location.search);

    const params = {
      ...parsed,
      query: this.state.search,
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    this.props.history.push(`${process.env.PUBLIC_URL}/manage/users?${url}`);
  };

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="row">
            <div className="col-sm-9 text-left">
              <h2>Správa uživatelů</h2>
            </div>
            <div className="col-sm-3 text-right">
              <Link to={`${process.env.PUBLIC_URL}/manage/users/edit/new`}>
                <Button icon="\efc2">Nový</Button>
              </Link>
            </div>
          </div>
        </CardHeader>
        <CardControls>
          <Form onSubmit={this.submitSearch}>
            <Form.Input
              value={this.state.search}
              icon="search"
              placeholder="Hledat..."
              onChange={this.onChangeSearch}
            />
          </Form>
        </CardControls>

        <UsersTable
          items={this.state.users}
          refresh={this.getUsersHandler}
          sort={this.handleSort}
          column={this.state.column}
          direction={this.state.direction}
        />
      </Card>
    );
  }
}

export default UsersView;
