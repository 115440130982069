import React, { Component } from 'react'
import { intToBool, shortText, toggleDateState, getToken, unsetSub, sendReminder } from '../../api'
import { Link } from 'react-router-dom'
import { Table, Button, Icon, Popup, DropdownItem, Dropdown } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import RemoveKidModal from '../modals/RemoveKidModal'

class DateApplicationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2,
            loadingUnsetSub: false
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    unsetSubHandler = (id_date, id_kids) => {

        this.setState({
            loadingUnsetSub: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                id_kids: id_kids,
                id_date: Number(id_date)
            }

            unsetSub(data)
            .then((res) => {
                this.props.setMessage(res)
                this.props.refresh()
                this.setState({
                    loadingUnsetSub: false
                })
            })
            .catch((err) => {
                alert('Nepodařilo se přesunout dítě mezi účatníky. Zkuste to prosím později');
                this.setState({
                    loadingUnsetSub: false
                })
            })
        })
        .catch((err) => {
            alert('Nepodařilo se získat bezpečnostní token. Aktualizujte prosím stránku');
            this.setState({
                loadingUnsetSub: false
            })
        })
    }

    reminder = (id) => {

        this.setState({
            loadingMail: true
        })

        getToken().then((token) => {
            sendReminder(id, token)
            .then((res) => {
                this.props.setMessage(res)
                this.setState({
                    loadingMail: false
                })
            })
            .catch((err) => {
                alert('Nepodařilo se odeslat upomínku. Zkuste to prosím později');
                this.setState({
                    loadingMail: false
                })
            })
        })
        .catch((err) => {
            alert('Nepodařilo se získat bezpečností certifikát. Aktualizujte prosím stránku.')
            this.setState({
                loadingMail: false
            })
        })

        
    }

    render() {

        const { items, sort, column, direction, date, sub, id_date, userAdmin, refresh } = this.props

        return (
            <div style={isMobile ? {overflowX: 'auto'} : { overflowX: 'hidden' }}>
                <Table sortable striped className='main-table mobile' unstackable>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_kids' ? direction : null}
                                onClick={sort('id_kids')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'kids_name' ? direction : null}
                                onClick={sort('kids_name')}
                            >
                                Jméno
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'kids_birthday' ? direction : null}
                                onClick={sort('kids_birthday')}
                            >
                                Věk
                            </Table.HeaderCell>

                            {userAdmin !== 0 &&
                            <Table.HeaderCell
                                sorted={column === 'parent_email' ? direction : null}
                                onClick={sort('parent_email')}
                            >
                                Email
                            </Table.HeaderCell>
                            }

                            <Table.HeaderCell
                                sorted={column === 'parent_email' ? direction : null}
                                onClick={sort('parent_email')}
                            >
                                Telefon
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'paid' ? direction : null}
                                onClick={sort('paid')}
                            >
                                Zaplaceno
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_kids, kids_name, kids_lastname, kids_birthday, parent_phone, paid, id_link, parent_email }) => (
                    <Table.Row key={id_kids}>
                        <Table.Cell>
                            {id_link}
                        </Table.Cell>
                        <Table.Cell>
                            {kids_name} {kids_lastname}
                        </Table.Cell>
                        <Table.Cell>
                            {Math.abs(moment(kids_birthday).diff(moment(date), 'years', true)).toFixed(1)}
                        </Table.Cell>
                        {userAdmin !== 0 &&
                        <Table.Cell>
                            {parent_email}
                        </Table.Cell>
                        }
                        <Table.Cell>
                            {parent_phone}
                        </Table.Cell>
                        <Table.Cell>
                        {intToBool(paid) ? <i class="icofont-check-circled icon-paid"></i> : <i class="icofont-close-circled icon-paid"></i>}
                        </Table.Cell>
                        <Table.Cell>
                            <BrowserView>
                                {!intToBool(paid) &&
                                <Popup 
                                    trigger={
                                        <Button icon onClick={() => this.reminder(id_link)} loading={this.state.loadingMail}><Icon name='clock' /></Button>
                                    }
                                    content='Odeslat upomínku'
                                    position='top right'
                                />
                                }
                                <Popup 
                                    trigger={
                                        <RemoveKidModal type='button' buttonIcon='trash' applicationId={id_link} dateId={id_date} kidsName={kids_name} kidsId={id_kids} email={parent_email} refresh={refresh} />
                                    }
                                    content='Odebrat dítě z termínu'
                                    position='top right'
                                />
                                <Popup 
                                    trigger={
                                        <Button icon href={`${process.env.PUBLIC_URL}/manage/application/detail/` + id_link}><Icon name='eye' /></Button>
                                    }
                                    content='Detail přihlášky'
                                    position='top right'
                                />
                                {sub &&
                                <Popup 
                                    trigger={
                                        <Button icon onClick={() => this.unsetSubHandler(id_date, id_kids)} loading={this.state.loadingUnsetSub}><Icon name='angle up' /></Button>
                                    }
                                    content='Přesunout mezi aktivní účastníky'
                                    position='top right'
                                />
                                }
                            </BrowserView>
                            <MobileView>
                                <Dropdown multiple icon='ellipsis vertical' pointing='top right'>
                                    <Dropdown.Menu>
                                        <DropdownItem><Link to={`${process.env.PUBLIC_URL}/manage/application/detail/` + id_link}>Detail přihlášky</Link></DropdownItem>
                                        <Dropdown.Divider />
                                        {sub &&
                                        <DropdownItem text='Přesunout mezi aktivní účastníky' onClick={() => this.unsetSubHandler(id_date, id_kids)} />
                                        }
                                        {!intToBool(paid) && 
                                        <DropdownItem text='Odeslat upomínku' onClick={() => this.reminder(id_link)} />
                                        }
                                        <Dropdown.Divider />
                                        <RemoveKidModal type='dropdown' buttonIcon='trash' applicationId={id_link} dateId={id_date} kidsName={kids_name} kidsId={id_kids} email={parent_email} refresh={refresh} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </MobileView>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>

            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(DateApplicationsTable)