import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Table } from 'semantic-ui-react';
import { getListOfKids } from '../../../api/employees';

import EmptyTableMessage from './EmptyTableMessage';

function ListOfKidsTable() {

    const { id } = useParams();

    const [kids, setKids] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        _getData()

    }, []);
    
    function _getData() {

        setLoading(true);

        getListOfKids(id)
        .then((res) => {
            setLoading(false);
            setKids(res.data)
            console.log(res)
        });
    }

    return (
        <>
        {kids.length === 0 ?
        <EmptyTableMessage 
            message='Nejsou k dispozici žádné děti' 
            loading={loading}
            showButton={false}
        />
        :
        <Table striped className='main-table mobile' unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Jméno
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Věk
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Jméno rodiče
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Telefon
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {kids.map((item) => {
                    return (
                        <Table.Row key={item.id_date} className={item.is_full === 1 && 'full_date'}>
                            <Table.Cell>
                                {item.kids_name} {item.kids_lastname}
                            </Table.Cell>
                            <Table.Cell>
                                {item.age}
                            </Table.Cell>
                            <Table.Cell>
                                {item.parent_name} {item.parent_lastname}
                            </Table.Cell>
                            <Table.Cell>
                                <a href={`tel:${item.parent_phone}`}>{item.parent_phone}</a>
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
        }
        </>
    )
}

export default ListOfKidsTable