import React, { Component } from "react";
import {
  getDates,
  getLocationsFilter,
  dateFilterFrom,
  getDatesHobby,
  getEmbedDatesFilter,
  getCampsFilter,
} from "../../api";
import { DateItems } from "../containers";
import { Dropdown } from "semantic-ui-react";
import _ from "loadsh";
import DateItemsHobby from "../containers/DateItemsHobby";
import queryString from "query-string";
import { jsonToFormData } from "../../tools";

const typesOpts = [
  {
    key: "0",
    value: "camps",
    text: "Příměstské tábory",
  },
  {
    key: "1",
    value: "hobby_dates",
    text: "Kroužky",
  },
  {
    key: "2",
    value: "kurzy_dates",
    text: "Kurzy",
  },
  {
    key: "3",
    value: "skolka_dates",
    text: "Školka",
  },
];

class DatesEmbedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      baseData: [],
      column: "id_date",
      direction: "descending",
      locOptions: [{ key: "0", value: null, text: "Vše" }],
      campsOptions: [{ key: "0", value: null, text: "Vše" }],
      datesFilter: [{ key: "0", value: null, text: "Vše" }],
      filter: false,
      filter_date_from: "",
      utm: "",
      filters: {},
    };
  }

  componentDidMount() {
    const { utm } = this.props.match.params;

    this.setState({
      utm: utm !== undefined ? utm : "",
    });

    document.body.classList.add("dates");

    this.getFilterValues();

    this.getDatesHandler();
  }

  handleSort = (clickedColumn) => () => {
    const { column, dates, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        dates: _.sortBy(dates, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: dates.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  onChangeSelect = (e, data) => {
    if (data.value !== null) {
      this.setState(
        {
          filter: true,
          filters: {
            ...this.state.filters,
            [data.name]: data.value,
          },
        },
        () => {
          this.setState({
            dates: _.filter(this.state.baseData, this.state.filters),
          });
          this.getFilterValues();
        }
      );
    } else {
      this.setState(
        {
          filters: _.omit(this.state.filters, [data.name]),
        },
        () => {
          this.setState({
            dates:
              _.keys(this.state.filters).length !== 0
                ? _.filter(this.state.baseData, this.state.filters)
                : this.state.baseData,
            filter: _.keys(this.state.filters).length !== 0 ? true : false,
          });
          this.getFilterValues();
        }
      );
    }
  };

  getFilterValues = () => {
    const { campType } = this.props.match.params;

    const toSend = {
      filters: this.state.filters,
      activity_type: campType,
    };

    const fd = jsonToFormData(toSend);

    getLocationsFilter(fd).then((data) => {
      this.setState(
        {
          locOptions: [{ key: "0", value: null, text: "Vše" }],
        },
        () => {
          this.setState({
            locOptions: this.state.locOptions.concat(data.data),
          });
        }
      );
    });

    getCampsFilter(campType).then((data) => {
      this.setState(
        {
          campsOptions: [{ key: "0", value: null, text: "Vše" }],
        },
        () => {
          this.setState({
            campsOptions: this.state.campsOptions.concat(data.data),
          });
        }
      );
    });

    getEmbedDatesFilter(fd).then((data) => {
      this.setState(
        {
          datesFilter: [{ key: "0", value: null, text: "Vše" }],
        },
        () => {
          this.setState({
            datesFilter: this.state.datesFilter.concat(data.data),
          });
        }
      );
    });
  };

  onChangeType = (e, data) => {
    const { utm, type } = this.props.match.params;
    this.props.history.push(`/embed/dates/${type}/${utm}/${data.value}/`);
  };

  onChangeDate = (e, data) => {
    this.setState(
      {
        [data.name]: data.value,
      },
      () => {
        if (
          this.state.filter_date_from !== "" &&
          this.state.filter_date_from !== null
        ) {
          this.getFilteredHandler(this.state.filter_date_from);
        } else {
          this.getDatesHandler();
        }
      }
    );
  };

  /*onChangeDate = (e) => this.setState({
        [e.target.name]: e.target.value
    }, () => {
        if(this.state.filter_date_from !== '') {
            this.getFilteredHandler(this.state.filter_date_from)
        } else {
            this.getDatesHandler()
        }
        
    })*/

  getFilteredHandler = (date) => {
    dateFilterFrom(date).then((dates) => {
      if (dates.length !== 0) {
        if (_.keys(this.state.filters).length !== 0) {
          this.setState({
            dates: _.filter(dates, this.state.filters),
            baseData: dates,
            hasSome: true,
          });
        } else {
          this.setState({
            dates: dates,
            baseData: dates,
            hasSome: true,
          });
        }
      } else {
        this.setState({
          hasSome: false,
        });
      }
    });
  };

  getDatesHandler = () => {
    const { campType } = this.props.match.params;

    if (campType === "camps") {
      getDates().then((dates) => {
        if (dates.length !== 0) {
          if (_.keys(this.state.filters).length !== 0) {
            this.setState({
              dates: _.filter(dates, this.state.filters),
              baseData: dates,
              hasSome: true,
            });
          } else {
            this.setState({
              dates: dates,
              baseData: dates,
              hasSome: true,
            });
          }
        } else {
          this.setState({
            hasSome: false,
          });
        }

        const parsed = queryString.parse(this.props.location.search);
        if (parsed.preset) {
          var data = {
            value: Number(parsed.preset),
            name: "id_camp",
          };
          this.onChangeSelect(null, data);
        }
      });
    } else {
      getDatesHobby(campType).then((dates) => {
        if (dates.length !== 0) {
          console.log("dates", dates);
          if (_.keys(this.state.filters).length !== 0) {
            this.setState({
              dates: _.filter(dates, this.state.filters),
              baseData: dates,
              hasSome: true,
            });
          } else {
            this.setState({
              dates: dates,
              baseData: dates,
              hasSome: true,
            });
          }
        } else {
          this.setState({
            hasSome: false,
          });
        }

        const parsed = queryString.parse(this.props.location.search);
        if (parsed.preset) {
          var data = {
            value: Number(parsed.preset),
            name: "id_camp",
          };
          this.onChangeSelect(null, data);
        }
      });
    }
  };

  typeFilter = () => {
    const { campType } = this.props.match.params;

    switch (campType) {
      case "camps":
        return 1;
      case "hobby_dates":
        return 2;
      case "kurzy_dates":
        return 3;
      case "skolka_dates":
        return 4;
      default:
        return 1;
    }
  };

  typeLabel = () => {
    const { campType } = this.props.match.params;

    switch (campType) {
      case "camps":
        return "Vyberte tábor";
      case "hobby_dates":
        return "Vyberte kroužek";
      case "kurzy_dates":
        return "Vyberte kurz";
      case "skolka_dates":
        return "Vyberte školku";

      default:
        return "Vyberte";
    }
  };

  render() {
    const { type, campType, utm } = this.props.match.params;
    const parsed = queryString.parse(this.props.location.search);

    return (
      <div className={utm + " dates-container"}>
        {Number(parsed.preset) === 7 ? (
          <></>
        ) : (
          <div className="row dates-filters">
            <div className={campType !== "camps" ? "col-sm-4" : "col-sm-3"}>
              <label>Aktivita</label>
              <Dropdown
                name="type"
                options={typesOpts}
                placeholder="Vyberte aktivitu"
                selection
                search
                onChange={this.onChangeType}
                fluid
                value={campType}
              />
            </div>
            <div className={campType !== "camps" ? "col-sm-4" : "col-sm-3"}>
              <label>Druh</label>
              <Dropdown
                name="id_camp"
                options={this.state.campsOptions}
                placeholder="Vyberte druh"
                selection
                search
                onChange={this.onChangeSelect}
                fluid
                defaultValue={parsed.preset ? Number(parsed.preset) : null}
              />
            </div>
            <div className={campType !== "camps" ? "col-sm-4" : "col-sm-3"}>
              <label>Lokalita</label>
              <Dropdown
                name="id_location"
                options={this.state.locOptions}
                placeholder="Vyberte lokalitu"
                selection
                search
                onChange={this.onChangeSelect}
                fluid
              />
            </div>
            {campType === "camps" && (
              <div className="col-sm-3">
                <label>Termín</label>
                <Dropdown
                  name="filter_date_from"
                  options={this.state.datesFilter}
                  placeholder="Vyberte termín"
                  selection
                  onChange={this.onChangeDate}
                  fluid
                />
              </div>
            )}
          </div>
        )}

        {this.state.hasSome &&
        campType === "camps" &&
        this.state.dates.length !== 0 ? (
          <DateItems
            items={this.state.dates}
            refresh={this.getDatesHandler}
            sort={this.handleSort}
            column={this.state.column}
            direction={this.state.direction}
            type={type}
            campType={campType}
          />
        ) : this.state.hasSome &&
          campType !== "camps" &&
          this.state.dates.length !== 0 ? (
          <DateItemsHobby
            items={this.state.dates}
            refresh={this.getDatesHandler}
            sort={this.handleSort}
            column={this.state.column}
            direction={this.state.direction}
            type={type}
            campType={campType}
            utm={utm}
          />
        ) : (
          <p style={{ fontWeight: "700", textAlign: "center", fontSize: 22 }}>
            Pro vybraný filtr nemáme žádné termíny
          </p>
        )}
      </div>
    );
  }
}

export default DatesEmbedView;
