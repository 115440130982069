import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Form, Button, Divider } from 'semantic-ui-react';
import { getToken, prefillCompanyInfo, updateInvoiceInfo } from '../../../api';
import { setMessage } from '../../../actions/messageActions';
import { useParams } from 'react-router';
import { jsonToFormData } from '../../../tools';

function InvoiceDetailsForm({ invoiceDetailsPayload, invoiceCompanyPayload, refresh }) {

    let dispatch = useDispatch();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [invoiceCompany, setInvoiceCompany] = useState({});
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        setLoading(true)
        setInvoiceDetails(invoiceDetailsPayload)
        setInvoiceCompany(invoiceCompanyPayload)
        setLoading(false)
    }, [invoiceDetailsPayload]);

    function onChange(e, val, type) {

        if(type === 'invoiceDetails')
        {
            setInvoiceDetails({...invoiceDetails, [e.target.name]: e.target.value});
        }

        if(type === 'invoiceCompany')
        {
            setInvoiceCompany({...invoiceCompany, [e.target.name]: e.target.value});
        }
        
    }

    function _prefillCompanyInfo() {

        setLoading(true)

        if(invoiceDetails.reg_num) {
            getToken().then((token) => {

                const data = {
                    id_application: id,
                    reg_num: invoiceDetails.reg_num,
                    token: token
                }

                const payload = jsonToFormData(data);

                prefillCompanyInfo(payload)
                .then((res) => {
                    dispatch(setMessage(res.data));
                    setLoading(false);
                    refresh();
                })
                .catch((err) => {
                    dispatch(setMessage({type: 'error', message: 'Nepodařilo se vyplnit údaje'}))
                    setLoading(false)
                    refresh();
                })
    
            })
        } else {
            dispatch(setMessage({type: 'error', message: 'Vyplňte IČO'}))
            setLoading(false)
        }

    }

    function submit() {
        setLoading(true)

        getToken().then((token) => {

            let invoice_company_new = {...invoiceCompany, reg_num: invoiceDetails.reg_num}

            const data = {
                invoice_details: invoiceDetails,
                invoice_company: invoice_company_new,
                id_application: id
            }

            const payload = jsonToFormData(data);

            updateInvoiceInfo(payload)
            .then((res) => {
                dispatch(setMessage(res.data));
                setLoading(false);
                refresh();
            })
            .catch((err) => {
                dispatch(setMessage({type: 'error', message: 'Nepodařilo se uložit data'}));
                setLoading(false);
                refresh();
            })

        })
        .catch((err) => {
            dispatch(setMessage({type: 'error', message: 'Nepodařilo se získat bezpečnostní token'}));
            setLoading(false);
            refresh();
        })
    }

    return (
        <Form loading={loading} onSubmit={submit}>
            <Row className='mb-3 mt-3'>
                <Col sm='8'>
                    <Form.Input 
                        name='reg_num'
                        type='text'
                        label='IČO'
                        placeholder='Zadejte IČO'
                        onChange={(e, val) => onChange(e, val, 'invoiceDetails')}
                        value={invoiceDetails.reg_num}
                        required
                    />
                </Col>
                <Col sm={2} className='pl-0 d-flex align-items-end'>
                    <Button onClick={_prefillCompanyInfo} as='span'>Předvyplnit</Button>
                </Col>
            </Row>
            
            <Form.Input 
                name='due_date'
                type='text'
                label='Splatnost'
                placeholder='Zadejte splatnost'
                onChange={(e, val) => onChange(e, val, 'invoiceDetails')}
                value={invoiceDetails.due_date}
            />
            <Form.Input 
                name='text'
                type='text'
                label='Text faktury'
                placeholder='Zadejte text'
                onChange={(e, val) => onChange(e, val, 'invoiceDetails')}
                value={invoiceDetails.text}
            />
            <Divider />
            <Form.Input 
                name='company'
                type='text'
                label='Společnost'
                placeholder='Zadejte název společnosti'
                onChange={(e, val) => onChange(e, val, 'invoiceCompany')}
                value={invoiceCompany.company}
                required
            />
            <Form.Input 
                name='address'
                type='text'
                label='Adresa'
                placeholder='Zadejte adresu společnosti'
                onChange={(e, val) => onChange(e, val, 'invoiceCompany')}
                value={invoiceCompany.address}
                required
            />
            <Form.Input 
                name='city'
                type='text'
                label='Město'
                placeholder='Zadejte město'
                onChange={(e, val) => onChange(e, val, 'invoiceCompany')}
                value={invoiceCompany.city}
                required
            />
            <Form.Input 
                name='post_code'
                type='text'
                label='PSČ'
                placeholder='Zadejte PSČ'
                onChange={(e, val) => onChange(e, val, 'invoiceCompany')}
                value={invoiceCompany.post_code}
                required
            />
            <Form.Input 
                name='vat_num'
                type='text'
                label='DIČ'
                placeholder='Zadejte DIČ'
                onChange={(e, val) => onChange(e, val, 'invoiceCompany')}
                value={invoiceCompany.vat_num}
            />
            <Form.Input 
                name='reg_num'
                type='hidden'
                value={invoiceDetails.reg_num}
            />
            <Button primary fluid>Uložit</Button>
        </Form>
    )
}

export default InvoiceDetailsForm