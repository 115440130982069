import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Checkbox, Dropdown } from "semantic-ui-react";
import { setMessage } from "../../../actions/messageActions";
import { getEmployeesProfile, updateProfile } from "../../../api/employees";
import { jsonToFormData } from "../../../tools";
import EmployeesUploader from "./EmployeesUploader";

function EmployeeProfileForm({ id_user }) {
  let dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [id_user]);

  function onChange(e, target) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function getData() {
    setLoading(true);

    const payload = jsonToFormData({ id_user: id_user });

    getEmployeesProfile(payload).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }

  function submit() {
    setLoading(true);

    const toSend = {
      id_user: id_user,
      data: data,
    };

    const payload = jsonToFormData(toSend);

    updateProfile(payload).then((res) => {
      setLoading(false);
      dispatch(setMessage(res.data));
      getData();
    });
  }

  function onChangeCheckbox(e, val) {
    setData({
      ...data,
      [val.name]: val.checked,
    });
  }

  function onChangeSelect(e, val) {
    setData({
      ...data,
      [val.name]: val.value,
    });
  }

  function stringToBoolean(string) {
    switch (string) {
      case "true":
      case "yes":
      case "1":
        return true;
      case "false":
      case "no":
      case "0":
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }

  const continueOptions = [
    {
      key: -1,
      text: "Nevím",
      value: -1,
    },
    {
      key: 1,
      text: "Ano",
      value: 1,
    },
    {
      key: 0,
      text: "Ne",
      value: 0,
    },
  ];

  return (
    <Form onSubmit={submit} loading={loading}>
      <Form.Field>
        <label>Chci pokračovat</label>
        <Dropdown
          placeholder="Vyberte možnost"
          options={continueOptions}
          value={data.want_continue}
          name="want_continue"
          onChange={onChangeSelect}
          className={"state-active-" + data.want_continue}
          selection
          required={id_user ? false : true}
        />
      </Form.Field>
      <Form.Input
        type="text"
        name="user_first_name"
        label="Jméno"
        placeholder="Zadejte Vaše jméno"
        value={data.user_first_name}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="user_last_name"
        label="Příjmení"
        placeholder="Zadejte Vaše příjmení"
        value={data.user_last_name}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="maiden_name"
        label="Rodné příjmení"
        placeholder="Zadejte rodné přijmení"
        value={data.maiden_name}
        onChange={onChange}
      />
      <Form.Input
        type="text"
        name="address"
        label="Trvalé bydliště"
        placeholder="Zadejte adresu trvalého bydliště"
        value={data.address}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="place_of_birth"
        label="Místo narození"
        placeholder="Zadejte místo narození"
        value={data.place_of_birth}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="nationality"
        label="Národnost"
        placeholder="Zadejte Vaši národnost"
        value={data.nationality}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="citizenship"
        label="Občanství"
        placeholder="Zadejte Vaše občanství"
        value={data.citizenship}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="bank_acc"
        label="Číslo bankovního účtu"
        placeholder="Zadejte číslo bankovního účtu"
        value={data.bank_acc}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="insurance_company"
        label="Zdravotní pojišťovna"
        placeholder="Zadejte Vaši zdravotní pojišťovnu"
        value={data.insurance_company}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="food_restrictions"
        label="Stravovací omezení"
        placeholder="Zadejte Vaše stravovací omezení"
        value={data.food_restrictions}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Input
        type="text"
        name="tshirt_size"
        label="Velikost trika"
        placeholder="Zadejte Vaši velikost"
        value={data.tshirt_size}
        onChange={onChange}
        required={id_user ? false : true}
      />
      <Form.Field>
        <Checkbox
          label="Podepisuji růžové prohlášení"
          name="pinksheet"
          checked={stringToBoolean(data.pinksheet)}
          onChange={(e, val) => onChangeCheckbox(e, val)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Jsem student"
          name="student"
          checked={stringToBoolean(data.student)}
          onChange={(e, val) => onChangeCheckbox(e, val)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Patřím do kategorie osob celodenně, osobně a řádně pečujících alespoň o jedno dítě do 7 let věku nebo nejméně o dvě děti do 15 let věku"
          name="have_kids"
          checked={stringToBoolean(data.have_kids)}
          onChange={(e, val) => onChangeCheckbox(e, val)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Mám další příjmy s odvody alespoň z min. mzdy"
          name="other_income"
          checked={stringToBoolean(data.other_income)}
          onChange={(e, val) => onChangeCheckbox(e, val)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Jsem aktuálně na ÚP"
          name="up"
          checked={stringToBoolean(data.up)}
          onChange={(e, val) => onChangeCheckbox(e, val)}
        />
      </Form.Field>
      <EmployeesUploader
        multiple={false}
        accept=".png, .jpg, .jpeg, .webp"
        id_user={id_user}
        type="photo"
        label="Fotografie"
      />
      <EmployeesUploader
        multiple={false}
        accept=".png, .jpg, .jpeg, .webp"
        id_user={id_user}
        type="student_certificate"
        label="Potvrzení o studiu"
      />
      <Form.TextArea
        label="Medailonek"
        onChange={onChange}
        name="about_me"
        value={data.about_me}
      />
      <Button primary fluid>
        Uložit změny
      </Button>
    </Form>
  );
}

export default EmployeeProfileForm;
