import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "semantic-ui-react";
import { setMessage } from "../../../actions/messageActions";
import { getEmployeesProfile, updateProfile } from "../../../api/employees";
import { jsonToFormData } from "../../../tools";

function ParentsProfileForm({ id_user }) {
  let dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function onChange(e, target) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function getData() {
    setLoading(true);

    const payload = jsonToFormData({ id_user: id_user });

    getEmployeesProfile(payload).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }

  function submit() {
    setLoading(true);

    const toSend = {
      id_user: id_user,
      data: data,
    };

    const payload = jsonToFormData(toSend);

    updateProfile(payload).then((res) => {
      setLoading(false);
      dispatch(setMessage(res.data));
      getData();
    });
  }

  return (
    <Form onSubmit={submit} loading={loading}>
      <Form.Input
        type="email"
        name="user_email"
        label="Email"
        placeholder="Zadejte Váš email"
        value={data.user_email}
        onChange={onChange}
        disabled
      />
      <Form.Input
        type="text"
        name="user_first_name"
        label="Jméno"
        placeholder="Zadejte Vaše jméno"
        value={data.user_first_name}
        onChange={onChange}
        required
      />
      <Form.Input
        type="text"
        name="user_last_name"
        label="Příjmení"
        placeholder="Zadejte Vaše příjmení"
        value={data.user_last_name}
        onChange={onChange}
        required
      />

      <Form.Input
        type="text"
        name="phone"
        label="Telefon"
        placeholder="Zadejte Váš telefon"
        value={data.phone}
        onChange={onChange}
        required
      />
      <Button primary fluid>
        Uložit změny
      </Button>
    </Form>
  );
}

export default ParentsProfileForm;
