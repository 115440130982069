import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import {
  getCampsFilter,
  getLocationsFilter,
  getEmbedDatesFilter,
} from "../../../api";
import { jsonToFormData } from "../../../tools";

function DatesTableFilter({ path }) {
  let history = useHistory();
  let location = useLocation();
  let campType = "camps";

  const [locOptions, setLocOptions] = useState([
    { key: "0", value: null, text: "Vše" },
  ]);
  const [campOptions, setCampOptions] = useState([
    { key: "0", value: null, text: "Vše" },
  ]);
  const [datesOptions, setDatesOptions] = useState([
    { key: "0", value: null, text: "Vše" },
  ]);

  const parsed = queryString.parse(location.search);

  useEffect(() => {
    getValues();
  }, []);

  function onChangeSelect(e, data) {
    const params = {
      ...parsed,
      [data.name]: [data.value],
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    history.push({
      pathname: "/" + path,
      search: "?" + url,
      state: {},
    });
  }

  function getValues() {
    const toSend = {
      filters: parsed,
      activity_type: campType,
    };

    const fd = jsonToFormData(toSend);

    getLocationsFilter(fd).then((data) => {
      setLocOptions([{ key: "0", value: null, text: "Vše" }]);
      setLocOptions(locOptions.concat(data.data));
    });

    getCampsFilter(campType).then((data) => {
      setCampOptions([{ key: "0", value: null, text: "Vše" }]);
      setCampOptions(locOptions.concat(data.data));
    });

    getEmbedDatesFilter(fd).then((data) => {
      setDatesOptions([{ key: "0", value: null, text: "Vše" }]);
      setDatesOptions(locOptions.concat(data.data));
    });
  }

  return (
    <Row className="filters_row">
      <Col sm={4}>
        <Dropdown
          name="id_camp"
          options={campOptions}
          placeholder="Tábor"
          selection
          onChange={onChangeSelect}
          fluid
          value={parsed.id_camp ? Number(parsed.id_camp) : null}
        />
      </Col>
      <Col sm={4}>
        <Dropdown
          name="id_location"
          options={locOptions}
          placeholder="Lokalita"
          selection
          onChange={onChangeSelect}
          fluid
          value={parsed.id_location ? Number(parsed.id_location) : null}
        />
      </Col>
      <Col sm={4}>
        <Dropdown
          name="date_from"
          options={datesOptions}
          placeholder="Termín"
          selection
          onChange={onChangeSelect}
          fluid
          value={parsed.date_from ? Number(parsed.date_from) : null}
        />
      </Col>
    </Row>
  );
}

export default DatesTableFilter;
