import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Col, Row } from 'reactstrap'
import { Button } from 'semantic-ui-react'
import { ProfileMessage } from '../../../styles'

function FinishProfileMessage() {
    return (
        <>
            <ProfileMessage>
                <Row className='d-flex align-items-center'>
                    <Col sm={8}>
                        <span className='message'>Pro účast jako lektor musíte dokončit svůj profil</span>
                    </Col>
                    <Col sm={4}>
                        <Link to='/employee/profile'><Button fluid>Dokončit profil</Button></Link>    
                    </Col>
                </Row>
            </ProfileMessage>
        </>
    )
}

export default FinishProfileMessage