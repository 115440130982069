import { url } from "../config";
import axios from "axios";

export function getInvoicesList() {
  return axios({
    method: "get",
    url: url.api + `invoicing/get-list/?timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function deleteBill(data) {
  return axios({
    method: "post",
    url: url.api + "pdf/delete-bill/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}
