import React from 'react'
import { useParams } from 'react-router'
import { Card, CardBody, CardHeader } from '../../../styles'
import { FormWrapper, PageWrapper } from '../../../styles/employees'
import EmployeeCreateAccountForm from '../../forms/employees/EmployeeCreateAccountForm'

function EmployeeCreateAccountView() {

    let { token } = useParams();

    return (
        <>
        <PageWrapper>
            <FormWrapper>
                <Card>
                    <CardHeader>
                        <span>Zvolte své heslo</span>
                    </CardHeader>
                    <CardBody>
                        <EmployeeCreateAccountForm token={token} />
                    </CardBody>
                </Card>
            </FormWrapper>
        </PageWrapper>
        </>
    )
}

export default EmployeeCreateAccountView