import React, { useState, useEffect } from "react";
import { Button, Dropdown, Table } from "semantic-ui-react";
import { getDates } from "../../../api/parents";
import moment from "moment";
import { Link } from "react-router-dom";
import { jsonToFormData } from "../../../tools";
import EmptyTableMessage from "./EmptyTableMessage";
import { getToken } from "../../../api";
import { StateIcon } from "../../../styles";
import ChangeDate from "../../modals/ChangeDate/ChangeDate";

function DatesTableDashboard({ state, type }) {
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    _getData();
  }, []);

  function _getData() {
    setLoading(true);

    getToken().then((token) => {
      const data = {
        token: token,
        type: type,
      };

      const payload = jsonToFormData(data);

      getDates(payload).then((res) => {
        setLoading(false);
        setDates(res.data);
      });
    });
  }

  return (
    <>
      {dates.length === 0 ? (
        <EmptyTableMessage
          message="Nejsou k dispozici žádné termíny k zobrazení"
          loading={loading}
          buttonText="Vytvořit přihlášku"
          link="/embed/prihlasky"
          target="_blank"
          showButton={true}
        />
      ) : (
        <div className="mobile-overflow">
          <Table striped className="main-table mobile" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Jméno dítěte</Table.HeaderCell>
                <Table.HeaderCell>Druh</Table.HeaderCell>
                <Table.HeaderCell>Termín</Table.HeaderCell>
                <Table.HeaderCell>Lokalita</Table.HeaderCell>
                <Table.HeaderCell>Zaplaceno</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dates.map((item) => {
                var date_from = moment(item.date_from);
                var next_week = moment().add(14, "days");
                var can_switch = date_from.diff(next_week, "days");

                return (
                  <Table.Row
                    key={item.itemkey}
                    className={item.is_full === 1 ? "full_date" : ""}
                  >
                    <Table.Cell>
                      {item.kids_name} {item.kids_lastname}
                    </Table.Cell>
                    <Table.Cell>{item.camp_name}</Table.Cell>
                    <Table.Cell>
                      {moment(item.date_from).format("DD.MM.")}-
                      {moment(item.date_to).format("DD.MM.YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{item.location_name}</strong>
                      <br />
                      {item.location_address}
                    </Table.Cell>
                    <Table.Cell>
                      <StateIcon state={item.paid} />
                    </Table.Cell>
                    <Table.Cell className="text-right">
                      <Dropdown text="Akce">
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link
                              to={`/parents/applications/${item.id_application}`}
                            >
                              Zobrazit přihlášku
                            </Link>
                          </Dropdown.Item>
                          {can_switch > 14 && (
                            <ChangeDate
                              id_kids={item.id_kids}
                              id_date={item.id_date}
                              type={type}
                              refresh={_getData}
                            />
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </>
  );
}

export default DatesTableDashboard;
