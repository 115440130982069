import React, { Component } from "react";
import {
  Form,
  Input,
  TextArea,
  Button,
  Message,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import {
  FormButtonGroup,
  FormSection,
  FormSectionBody,
  FormSectionHead,
} from "../../../styles";
import moment from "moment";
import "react-day-picker/lib/style.css";
import "moment/locale/cs";
import { getMaxAge, getMinAge } from "../../../api";
import { withCookies } from "react-cookie";
import CustomDate from "../fields/CustomDate";
import { applicationsLang as lang } from "../../../lang/lang";
import { eatingIssuesOptions } from "../../../helpers";

const classes = [
  { key: "0", value: 0, text: "Žádná" },
  { key: "1", value: 1, text: "1. třída" },
  { key: "2", value: 2, text: "2. třída" },
  { key: "3", value: 3, text: "3. třída" },
  { key: "4", value: 4, text: "4. třída" },
  { key: "5", value: 5, text: "5. třída" },
  { key: "6", value: 6, text: "6. třída" },
  { key: "7", value: 7, text: "7. třída" },
  { key: "8", value: 8, text: "8. třída" },
  { key: "9", value: 9, text: "9. třída" },
];

class KidsForm extends Component {
  constructor(props) {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.state = {
      buttonActive: true,
      isFilled: false,
      lastError: [],
      basicKid: [
        {
          label: lang.name,
          type: "text",
          name: "kids_name",
          placeholder: lang.name_placeholder,
          id: "kids_name",
          required: true,
        },
        {
          label: lang.last_name,
          type: "text",
          name: "kids_lastname",
          placeholder: lang.last_name_placeholder,
          id: "kids_lastname",
          required: true,
        },
        {
          label: lang.birthday,
          type: "date",
          name: "kids_birthday",
          placeholder: lang.birthday,
          id: "kids_birthday",
          required: true,
        },
        {
          label: lang.address,
          type: "text",
          name: "kids_address",
          placeholder: lang.address_placeholder,
          id: "kids_address",
          required: true,
        },
        {
          label: lang.city,
          type: "text",
          name: "kids_city",
          placeholder: lang.city_placeholder,
          id: "kids_city",
          required: true,
        },
        {
          label: lang.zip_code,
          type: "number",
          name: "kids_post_code",
          placeholder: lang.zip_code_placeholder,
          id: "kids_post_code",
          required: true,
          onlyNumbers: true,
        },
        {
          label: lang.health_insurance_company,
          type: "text",
          name: "kids_insurance",
          placeholder: lang.health_insurance_company_placeholder,
          id: "kids_insurance",
        },
        {
          label: lang.health_restrictions,
          type: "textarea",
          name: "kids_health_issues",
          placeholder: lang.health_restrictions_placeholder,
          id: "kids_health_issues",
        },
        {
          label: lang.dietary_restrictions,
          type: "dropdown",
          name: "kids_eating_issues",
          placeholder: lang.dietary_restrictions_placeholder,
          id: "kids_eating_issues",
          options: eatingIssuesOptions,
          description:
            "Jiné diety nemáme možnost zajistit. Pokud má vaše dítě jiné stravovací omezení, prosím kontaktujte nás.",
        },
        {
          label: lang.desire_group,
          type: "text",
          name: "kids_pairing",
          placeholder: lang.desire_group_placeholder,
          id: "kids_pairing",
        },
      ],
      basicData: {
        kids_name: "",
        kids_lastname: "",
        kids_birthday: new Date(),
        kids_address: "",
        kids_city: "",
        kids_post_code: "",
        kids_insurance: "",
        kids_health_issues: "",
        kids_pairing: "",
        kids_camps: [],
        kids_can_select: [],
      },
      formFields: [
        [
          {
            label: lang.name,
            type: "text",
            name: "kids_name",
            placeholder: lang.name_placeholder,
            id: "kids_name",
            required: true,
          },
          {
            label: lang.last_name,
            type: "text",
            name: "kids_lastname",
            placeholder: lang.last_name_placeholder,
            id: "kids_lastname",
            required: true,
          },
          {
            label: lang.birthday,
            type: "date",
            name: "kids_birthday",
            placeholder: lang.birthday,
            id: "kids_birthday",
            required: true,
          },
          {
            label: lang.address,
            type: "text",
            name: "kids_address",
            placeholder: lang.address_placeholder,
            id: "kids_address",
            required: true,
          },
          {
            label: lang.city,
            type: "text",
            name: "kids_city",
            placeholder: lang.city_placeholder,
            id: "kids_city",
            required: true,
          },
          {
            label: lang.zip_code,
            type: "number",
            name: "kids_post_code",
            placeholder: lang.zip_code_placeholder,
            id: "kids_post_code",
            required: true,
            onlyNumbers: true,
          },
          {
            label: lang.health_insurance_company,
            type: "text",
            name: "kids_insurance",
            placeholder: lang.health_insurance_company_placeholder,
            id: "kids_insurance",
          },
          {
            label: lang.health_restrictions,
            type: "textarea",
            name: "kids_health_issues",
            placeholder: lang.health_restrictions_placeholder,
            id: "kids_health_issues",
          },
          {
            label: lang.dietary_restrictions,
            type: "dropdown",
            name: "kids_eating_issues",
            placeholder: lang.dietary_restrictions_placeholder,
            id: "kids_eating_issues",
            options: eatingIssuesOptions,
            description:
              "Jiné diety nemáme možnost zajistit. Pokud má vaše dítě jiné stravovací omezení, prosím kontaktujte nás.",
          },
          {
            label: lang.desire_group,
            type: "text",
            name: "kids_pairing",
            placeholder: lang.desire_group_placeholder,
            id: "kids_pairing",
          },
        ],
      ],
      errors: [
        {
          kids_name: "",
          kids_lastname: "",
          kids_birthday: "",
          kids_address: "",
          kids_city: "",
          kids_post_code: "",
          kids_insurance: "",
          kids_health_issues: "",
          kids_eating_issues: "",
          kids_pairing: "",
        },
      ],
      data: [
        {
          kids_name: "",
          kids_lastname: "",
          kids_birthday: new Date(),
          kids_address: "",
          kids_city: "",
          kids_post_code: "",
          kids_insurance: "",
          kids_health_issues: "",
          kids_eating_issues: "bez omezení",
          kids_pairing: "",
          kids_camps: [],
          kids_can_select: [],
        },
      ],
    };
  }

  componentDidMount() {
    getMinAge(1)
      .then((res) => {
        this.setState({
          min_age: res,
        });
      })
      .catch((err) => {
        alert("Nepodařilo se správně získat data, zkuste to prosím později.");
      });

    getMaxAge(1)
      .then((res) => {
        this.setState({
          max_age: res,
        });
      })
      .catch((err) => {
        alert("Nepodařilo se správně získat data, zkuste to prosím později.");
      });
  }

  checkInputs = () => {
    var minimum_age = moment().subtract(3, "year");
    var maximum_age = moment().subtract(15, "year");
    const privtoken = this.props.cookies.get("sj_priv_token");

    this.state.data.map((item, index) => {
      if (item.kids_name !== "") {
        let errors = [...this.state.errors];
        let lastErrors = this.state.lastError.filter(
          (i) => i !== "Vyplňte jméno dítěte."
        );
        errors[index]["kids_name"] = "";
        this.setState({
          isFilled: false,
          errors: errors,
          lastError: lastErrors,
        });
      }

      if (item.kids_lastname !== "") {
        let errors = [...this.state.errors];
        let lastErrors = this.state.lastError.filter(
          (i) => i !== "Vyplňte příjmení dítěte."
        );
        errors[index]["kids_lastname"] = "";
        this.setState({
          isFilled: false,
          errors: errors,
          lastError: lastErrors,
        });
      }

      if (item.kids_birthday !== "") {
        let errors = [...this.state.errors];
        errors[index]["kids_birthday"] = "";
      }

      if (item.kids_address !== "") {
        let errors = [...this.state.errors];
        errors[index]["kids_address"] = "";
      }

      if (item.kids_city !== "") {
        let errors = [...this.state.errors];
        errors[index]["kids_city"] = "";
      }

      if (item.kids_post_code !== "") {
        let errors = [...this.state.errors];
        errors[index]["kids_post_code"] = "";
      }
    });

    for (var i = 0; i < this.state.data.length; i++) {
      var diff = moment(this.state.data[i]["kids_birthday"]).diff(
        "2023-08-31",
        "years",
        true
      );
      var interDiff = -diff;

      if (this.state.data[i]["kids_name"] === "") {
        const errors = [...this.state.errors];
        errors[i]["kids_name"] = lang.name_placeholder;

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (this.state.data[i]["kids_lastname"] === "") {
        const errors = [...this.state.errors];
        errors[i]["kids_lastname"] = lang.last_name_placeholder;

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (this.state.data[i]["kids_birthday"] === "") {
        const errors = [...this.state.errors];
        errors[i]["kids_birthday"] = lang.birthday_placeholder;

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (interDiff < this.state.min_age) {
        const errors = [...this.state.errors];
        errors[i]["kids_birthday"] = lang.formatString(lang.too_young, {
          years: this.state.min_age,
        });

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (interDiff > this.state.max_age) {
        const errors = [...this.state.errors];
        errors[i]["kids_birthday"] = lang.formatString(lang.too_old, {
          years: this.state.max_age,
        });

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (this.state.data[i]["kids_address"] === "") {
        const errors = [...this.state.errors];
        errors[i]["kids_address"] = lang.address_placeholder;

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (this.state.data[i]["kids_city"] === "") {
        const errors = [...this.state.errors];
        errors[i]["kids_city"] = lang.city_placeholder;

        this.setState({
          isFilled: false,
          errors: errors,
        });
        break;
      } else if (this.state.data[i]["kids_post_code"] === "") {
        const errors = [...this.state.errors];
        errors[i]["kids_post_code"] = lang.zip_code_placeholder;

        this.setState({
          isFilled: false,
          errors: errors,
        });

        break;
      } else {
        this.setState({
          isFilled: true,
        });
      }
    }
  };

  addKidHandler = (address, city, post_code) => {
    this.setState(
      {
        formFields: [...this.state.formFields, this.state.basicKid],
        data: [
          ...this.state.data,
          {
            kids_name: "",
            kids_lastname: "",
            kids_birthday: new Date(),
            kids_address: address,
            kids_city: city,
            kids_post_code: post_code,
            kids_insurance: "",
            kids_health_issues: "",
            kids_eating_issues: "bez omezení",
            kids_pairing: "",
            kids_camps: [],
            kids_can_select: [],
          },
        ],
        errors: [
          ...this.state.errors,
          {
            kids_name: "",
            kids_lastname: "",
            kids_birthday: "",
            kids_address: "",
            kids_city: "",
            kids_post_code: "",
            kids_insurance: "",
            kids_health_issues: "",
            kids_eating_issues: "",
            kids_pairing: "",
          },
        ],
      },
      () => {
        this.checkInputs();
      }
    );
  };

  nextHandler = () => {
    this.props.setKids(this.state.data);
  };

  handleChange = (e) => {
    if (
      [
        "kids_name",
        "kids_lastname",
        "kids_birthday",
        "kids_address",
        "kids_city",
        "kids_post_code",
        "kids_insurance",
        "kids_eating_issues",
        "kids_health_issues",
        "kids_pairing",
      ].includes(e.target.className)
    ) {
      const kids = [...this.state.data];
      kids[e.target.dataset.id][e.target.className] = e.target.value;
      this.setState({ data: kids });
      this.checkInputs();
    }
  };

  setDate = (date, fieldname, index) => {
    if (
      [
        "kids_name",
        "kids_lastname",
        "kids_birthday",
        "kids_address",
        "kids_city",
        "kids_post_code",
        "kids_insurance",
        "kids_health_issues",
        "kids_pairing",
      ].includes(fieldname)
    ) {
      const kids = [...this.state.data];
      kids[index][fieldname] = date;
      this.setState({ data: kids });
      this.checkInputs();
    }
  };

  onChangeSelect = (e, data) => {
    const kids = [...this.state.data];
    if (
      ["kids_class", "kids_eating_issues"].includes(
        e.target.parentNode.parentNode.id
      )
    ) {
      kids[e.target.parentNode.parentNode.dataset.id][
        e.target.parentNode.parentNode.id
      ] = data.value;
      this.setState({ data: kids });
      this.checkInputs();
    } else if (
      ["kids_class", "kids_eating_issues"].includes(
        e.target.parentNode.parentNode.parentNode.id
      )
    ) {
      kids[e.target.parentNode.parentNode.parentNode.dataset.id][
        e.target.parentNode.parentNode.parentNode.id
      ] = data.value;
      this.setState({ data: kids });
      this.checkInputs();
    }
  };

  handleDayChange(value, modifiers, dayPickerInput) {
    console.log(modifiers);
    const input = dayPickerInput.getInput();
    if (
      [
        "kids_name",
        "kids_lastname",
        "kids_birthday",
        "kids_address",
        "kids_city",
        "kids_post_code",
        "kids_insurance",
        "kids_eating_issues",
        "kids_health_issues",
        "kids_pairing",
      ].includes(input.name)
    ) {
      const kids = [...this.state.data];
      kids[input.id][input.name] = moment(value).format("YYYY-MM-DD");
      this.setState({ data: kids }, () => {
        this.checkInputs();
      });
    }
  }

  removeKid = (index) => {
    var array = [...this.state.data];
    var form = [...this.state.formFields];
    if (index !== -1) {
      array.splice(index, 1);
      form.splice(index, 1);
      this.setState({ data: array, formFields: form }, () =>
        this.checkInputs()
      );
    }
  };

  renderFields = () => {
    const format = "DD/MM/YYYY";

    const privtoken = this.props.cookies.get("sj_priv_token");

    return this.state.formFields.map((item, index) => {
      return (
        <FormSection key={index}>
          <FormSectionHead className="d-flex justify-content-between align-items-center">
            <h5>
              {lang.child} #{index + 1}
            </h5>
            <Button
              style={{
                width: 40,
                height: 40,
                padding: 0,
                fontSize: 22,
                lineHeight: "40px",
                borderRadius: "500rem",
                backgroundColor: "#f5365c",
                border: 0,
              }}
              onClick={() => this.removeKid(index)}
              disabled={this.state.formFields.length <= 1}
              icon
            >
              <Icon name="times" />
            </Button>
          </FormSectionHead>
          <FormSectionBody>
            {item.map((field) => {
              return (
                <Form.Field
                  key={field.id}
                  className={
                    this.state.errors[index][field.name] !== ""
                      ? "error"
                      : "success"
                  }
                >
                  <label
                    style={
                      field.cookie_only &&
                      privtoken !== "xCvaZQeJwycNadh7.VKOqRX2re0TnD3PyF9jt8"
                        ? { display: "none" }
                        : { display: "inherit" }
                    }
                  >
                    {field.label} {field.required ? "*" : ""}
                  </label>
                  {field.type !== "textarea" &&
                  field.name !== "kids_birthday" &&
                  field.type !== "dropdown" ? (
                    <input
                      type={field.type}
                      name={field.name + (index + 1)}
                      placeholder={field.placeholder}
                      id={field.id}
                      data-id={index}
                      value={
                        field.onlyNumbers
                          ? this.state.data[index]["kids_post_code"].replace(
                              /\s/g,
                              ""
                            )
                          : this.state.data[index][field.name]
                      }
                      onChange={(e) => this.handleChange(e)}
                      className={field.name}
                      required={field.required ? true : false}
                    />
                  ) : field.type === "textarea" ? (
                    <TextArea
                      name={field.name}
                      placeholder={field.placeholder}
                      id={field.id}
                      data-id={index}
                      //value={this.state.data[field.name]}
                      onChange={this.handleChange}
                      className={field.name}
                    />
                  ) : field.type === "date" ? (
                    <>
                      <CustomDate
                        value={this.state.data[index][field.name]}
                        onChange={this.setDate}
                        id={field.id}
                        dataid={index}
                        fieldname={field.name}
                      />
                      {/*
                                        <DayPickerInput 
                                            onDayChange={this.handleDayChange} 
                                            inputProps={{ 
                                                id: index, 
                                                name: 'kids_birthday',
                                                locale: 'cs',
                                                localeUtils: MomentLocaleUtils,
                                            }}
                                            dayPickerProps={{ 
                                                locale: 'cs',
                                                localeUtils: MomentLocaleUtils,
                                                format: 'DD-MM-YYYY',
                                                formatDate: 'DD-MM-YYYY',
                                                parseDate: 'DD-MM-YYYY'
                                            }}
                                            format={'DD/MM/YYYY'}
                                            formatDate={formatDate}
                                            parseDate={parseDate}
                                            placeholder={new Date()}
                                            value={formatDate(this.state.data[index][field.name], format)}
                                        />
                                        */}
                    </>
                  ) : field.type === "dropdown" ? (
                    <>
                      <Dropdown
                        name={field.name}
                        placeholder={field.placeholder}
                        options={field.options}
                        onChange={this.onChangeSelect}
                        selection
                        id={field.id}
                        data-id={index}
                        value={this.state.data[index][field.name]}
                        className={field.name}
                        style={
                          field.cookie_only &&
                          privtoken !== "xCvaZQeJwycNadh7.VKOqRX2re0TnD3PyF9jt8"
                            ? { display: "none" }
                            : { display: "inherit" }
                        }
                      />
                      {field.description && (
                        <p className="form-desc">{field?.description}</p>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {field.required && field.name === "kids_birthday" && (
                    <p className="form-desc">
                      {lang.formatString(lang.age_info, {
                        young: this.state.min_age,
                        old: this.state.max_age,
                      })}
                    </p>
                  )}
                  <p className="form-error-text">
                    {this.state.errors[index][field.name]}
                  </p>
                </Form.Field>
              );
            })}
          </FormSectionBody>
        </FormSection>
      );
    });
  };

  renderErrorMessages = () => {
    return this.state.errors.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <span
            className="bold"
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "red",
              display: "block",
            }}
          >
            {item.kids_name}
          </span>
          <span
            className="bold"
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "red",
              display: "block",
            }}
          >
            {item.kids_lastname}
          </span>
          <span
            className="bold"
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "red",
              display: "block",
            }}
          >
            {item.kids_birthday}
          </span>
          <span
            className="bold"
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "red",
              display: "block",
            }}
          >
            {item.kids_address}
          </span>
          <span
            className="bold"
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "red",
              display: "block",
            }}
          >
            {item.kids_city}
          </span>
          <span
            className="bold"
            style={{
              textAlign: "center",
              fontWeight: "900",
              color: "red",
              display: "block",
            }}
          >
            {item.kids_post_code}
          </span>
        </React.Fragment>
      );
    });
  };

  render() {
    return (
      <Form>
        <Message
          color="red"
          icon="warning sign"
          header={lang.child_message_headline}
          content={lang.child_message}
        />
        <this.renderFields />
        {this.renderErrorMessages()}
        <FormButtonGroup>
          <Button
            className="ghost btn_end"
            onClick={() =>
              this.addKidHandler(
                this.state.data[0].kids_address,
                this.state.data[0].kids_city,
                this.state.data[0].kids_post_code
              )
            }
          >
            {lang.add_sibling}
          </Button>
          <Button
            onClick={this.nextHandler}
            disabled={!this.state.isFilled}
            className={
              this.state.isFilled
                ? "btn_end pulsing next next_kids"
                : "btn_end next next_kids"
            }
          >
            {lang.next}
          </Button>
        </FormButtonGroup>
      </Form>
    );
  }
}

export default withCookies(KidsForm);
