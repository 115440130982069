import { url } from "../config"
import axios from 'axios'

export function addInstructor(data) {
    return axios({
        method: 'post',
        url: url.api + 'instructors/add-instructor/',
        data: data,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function updateInstructor(data) {
    return axios({
        method: 'post',
        url: url.api + 'instructors/update-instructor/',
        data: data,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function updateSecondary(data) {
    return axios({
        method: 'post',
        url: url.api + 'instructors/update-secondary/',
        data: data,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function getList(search) {
    return axios({
        method: 'get',
        url: url.api + 'instructors/get-list/' + search + `&timestamp=${new Date().getTime()}`,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function getItem(id, is_employee = 0) {
    return axios({
        method: 'get',
        url: url.api + 'instructors/get-item?id_item=' + id + '&is_employee=' + is_employee + `&timestamp=${new Date().getTime()}`,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function bulkAction(data) {
    return axios({
        method: 'post',
        url: url.api + 'instructors/bulk/',
        data: data,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function getCheckboxes(group) {
    return axios({
        method: 'get',
        url: url.api + 'instructors/get-checkboxes/' + group + `?timestamp=${new Date().getTime()}`,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function deleteApplication(id) {
    return axios({
        method: 'get',
        url: url.api + 'instructors/delete-application/' + id + `?timestamp=${new Date().getTime()}`,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function getCheckboxesApp() {
    return axios({
        method: 'get',
        url: `${url.api}instructors/get-checkboxes-application?timestamp=${new Date().getTime()}`,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}

export function updateCheckboxes(data) {
    return axios({
        method: 'post',
        url: url.api + 'instructors/update-checkboxes/',
        data: data,
        headers: {Token: 'XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4'}
    })
}