import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/icofont.min.css";
import "./styles/styles.css";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducers } from './rootReducer';

import { CookiesProvider } from "react-cookie";

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);

if (process.env.NODE_ENV !== "development") console.log = () => {};

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter basename={""}>
      <Provider store={store}>
        <Route component={App} />
      </Provider>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
