import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Modal, Button, Icon, DropdownItem } from 'semantic-ui-react'
import { useDispatch } from "react-redux";
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import _ from 'loadsh'
import { cancelDate, getToken } from '../../api';
import { setMessage } from '../../actions/messageActions';

function RemoveKidModal({ type, buttonIcon, buttonText, kidsName, kidsId, applicationId, dateId, email, refresh }) {

    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    function removeKidFromDate() {
        setLoading(true)
        getToken().then((token) => {

            const deleteDate = {
                id_date: dateId,
                id_kids: kidsId,
                email: email,
                token: token
            }

            cancelDate(deleteDate)
            .then((res) => {
                refresh()
                dispatch(setMessage(res))
                setModalOpen(false)
                setLoading(false)
            })
            .catch((err) => {
                alert('Nepodařilo se odebrat dítě. Zkuste to prosím později')
                setLoading(false)
            })
        })
    }

    return (
        <>
        <Modal size='small' trigger={type === 'button' ? <Button icon onClick={() => setModalOpen(true)}><Icon name={buttonIcon} />{buttonText}</Button> : <DropdownItem text='Odebrat dítě z termínu' onClick={() => setModalOpen(true)} />} onClose={() => setModalOpen(false)} open={modalOpen}>
            <Modal.Header>Odebrat dítě z termínu</Modal.Header>
            <Modal.Content>
                <Row>
                    <Col sm='12'>
                        <p>Opravdu chcete odebrat dítě <strong>{kidsName}</strong> z příhlášky <strong>#{applicationId}</strong> z tohoto termínu?</p>
                        <p>Rodič bude o zrušení termínu informován emailem.</p>
                    </Col>
                </Row>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalOpen(false)}>Zavřít</Button>
                <Button color='red' onClick={() => removeKidFromDate()} loading={loading}>Odebrat dítě</Button>
            </Modal.Actions>
        </Modal>
        </>
    )
}

export default RemoveKidModal