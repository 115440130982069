import React from "react";
import { Card, CardHeader, CardBody } from "../../../styles";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import ParentsProfileForm from "../../forms/parents/ParentsProfileForm";
import ChangePasswordForm from "../../forms/parents/ChangePasswordForm";
import CreditsBox from "../../containers/parents/CreditsBox";
import { Form } from "semantic-ui-react";

function ParentsProfileView() {
  const user = useSelector((state) => state.user.user);
  const { id_user } = user;

  document.title = `Můj profil - SPORTJOY`;

  return (
    <>
      <Row>
        <Col sm={8}>
          <Row>
            <Col sm={12} className="mb-5">
              <Card>
                <CardHeader>Odkaz pro doporučení</CardHeader>
                <CardBody>
                  <Form>
                    <Form.Input
                      label="Odkaz pro doporučení"
                      type="text"
                      name="referral"
                      disabled
                      readonly
                      value={
                        "https://app.sportjoy.cz/embed/signup/camps/cs?referral=" +
                        user.referral
                      }
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-5">
              <Card>
                <CardHeader>Profil uživatele</CardHeader>
                <CardBody>
                  <ParentsProfileForm id_user={id_user} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mb-5">
              <Card>
                <CardHeader>Změna hesla</CardHeader>
                <CardBody>
                  <ChangePasswordForm />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <CreditsBox />
        </Col>
      </Row>
    </>
  );
}

export default ParentsProfileView;
