import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ButtonContent, Form, Select, Button, Divider } from 'semantic-ui-react';
import { updateSecondary } from '../../../api/instructors';
import { jsonToFormData } from '../../../tools';
import { useDispatch } from "react-redux";
import { setMessage } from '../../../actions/messageActions'
import { createEmployee } from '../../../api/employees';
import { useParams } from 'react-router';

function InstructorSecondaryForm({ initData, refresh }) {

    const dispatch = useDispatch();
    let { id } = useParams();

    const stateOptions = [
        { key: '1', value: 1, text: 'Aktivní' },
        { key: '-1', value: -1, text: 'Možná' },
        { key: '0', value: 0, text: 'Stornováno' }
    ]

    const [data, setData] = useState({
        created: '2021-11-03 12:55:00',
        state: 1
    });

    const [ isLoading, setLoading ] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    function onChangeSelect(e, val) {
        setData({
            ...data,
            [val.name]: val.value
        })
    }

    function getData() {
        setData(initData)
    }

    function onSubmit() {

        setLoading(true)

        const prepData = {
            ...data
        }

        const dataToSend = {
            data: [
                prepData
            ]
        }

        const fd = jsonToFormData(dataToSend);

        updateSecondary(fd)
        .then((res) => {
            setLoading(false)
            refresh()
            dispatch(setMessage(res.data));
        })
        .catch((err) => {
            alert('Přihláška nebyla uložen z důvodu: ' + err.message);
            setLoading(false)
            refresh()
        })
    }

    function _createEmployee() {
        setLoading(true);

        createEmployee(id)
        .then((res) => {
            setLoading(false)
            refresh()
            dispatch(setMessage(res.data));
        });
    }

    return (
        
        <Form loading={isLoading} onSubmit={onSubmit}>
            <Form.Input 
                type='text'
                label='Vytvořeno'
                value={moment(data.created).format('D.M.YYYY H:mm')}
                disabled
            />
            <Form.Field>
                <label>Stav přihlášky</label>
                <Select 
                    name='state' 
                    placeholder='Vyberte stav' 
                    options={stateOptions} 
                    value={data.state}
                    onChange={onChangeSelect}
                    className={'state-active-' + data.state}
                />
            </Form.Field>
            <Divider />
                <Button as='span' fluid primary onClick={_createEmployee}>Vytvořit účet</Button>
            <Divider />
            <Button fluid primary>Uložit změny</Button>
        </Form>
    )
}

export default InstructorSecondaryForm