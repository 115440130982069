import React from "react";
import { Col, Row } from "reactstrap";
import { Button, Form, Modal, Icon } from "semantic-ui-react";
import { Card, CardBody, CardHeader } from "../../../styles";
import DatesTableDashboard from "../../containers/parents/DatesTableDashboard";
import { useSelector } from "react-redux";
import CreditsBox from "../../containers/parents/CreditsBox";
import { QRModal } from "../../modals/QRModal";

const ParentsDashboardView = () => {
  document.title = `Nástěnka - SPORTJOY`;
  const user = useSelector((state) => state.user.user);

  const CreditsInfoModal = () => {
    return (
      <Modal
        trigger={
          <Button icon>
            <Icon name="question circle" />
          </Button>
        }
        size="small"
      >
        <Modal.Header>Doporučte příměstské tábory svým známým!</Modal.Header>
        <Modal.Content>
          <p>
            Každý, kdo se od 1. 3. 2023 přihlásí pomocí vašeho osobního odkazu
            nebo v přihlášce zadá váš kód, získá 100 Kč slevu z ceny tábora. Vy
            za každou takovou přihlášku získáváte 100 kreditů, které můžete
            využít k zaplacení aktivit!
          </p>
          <p>
            Kredity za doporučení je možné využít na aktivity v roce 2024.
            Kredity se automaticky připisují za každou řádně zaplacenou
            přihlášku, a to po odchození příměstského tábora v plném rozsahu.
          </p>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <>
      <Row>
        <Col sm={8}>
          <Row className="mb-5">
            <Col sm={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <span>Doporučte příměstské tábory svým známým!</span>
                  <CreditsInfoModal />
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={2}>
                      <Form>
                        <Form.Input
                          label="Kód"
                          type="text"
                          name="referral"
                          readOnly
                          value={user.referral}
                        />
                      </Form>
                    </Col>
                    <Col sm={7}>
                      <Form>
                        <Form.Input
                          label="Odkaz pro doporučení"
                          type="text"
                          name="referral"
                          readOnly
                          value={
                            "https://app.sportjoy.cz/embed/signup/camps/cs?referral=" +
                            user.referral
                          }
                        />
                      </Form>
                    </Col>
                    <Col sm={3} className="d-flex align-items-end justify-content-end">
                      <QRModal referral={user.referral} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={12}>
              <Card>
                <CardHeader>Moje termíny táborů</CardHeader>
                <DatesTableDashboard type="camp" />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <CreditsBox />
        </Col>
      </Row>
    </>
  );
};

export default ParentsDashboardView;
