import React, { Component } from "react";
import "./App.css";
import firebase from "firebase";
import { firebaseConfigData, url } from "./config";
import { Route, withRouter } from "react-router-dom";
import {
  Dashboard,
  LoadingView,
  ApplicationsView,
  AddressBookView,
  VouchersStoreView,
  NewsletterView,
  PaymentFinish,
  UnsubscribeView,
  RecommendationsView,
  CouponsView,
  ApplicationDetailView,
  LoginView,
  LocationsView,
  EditLocationView,
  OrganizationsView,
  EditOrganizationView,
  UsersView,
  EditUserView,
  CampsView,
  EditCampView,
  DatesView,
  EditDatesView,
  EmbedView,
  DatesEmbedView,
  VouchersOrdersView,
} from "./components/views";
import { LoginWrapper, Wrapper } from "./styles";
import {
  SidebarContainer,
  MessagesContainer,
  NavbarContainer,
} from "./components/containers";
import { connect } from "react-redux";
import { saveUser, setNotLogged } from "./actions/userActions";
import * as Sentry from "@sentry/browser";
import ApplicationHobbyView from "./components/views/ApplicationHobbyView";
import ApplicationsLandingView from "./components/views/ApplicationsLandingView";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import NavbarContainerMobile from "./components/containers/mobile/NavbarContainerMobile";
import VersionChecker from "./components/containers/VersionChecker";
import { withCookies } from "react-cookie";
import queryString from "query-string";
import InstructorSignupView from "./components/views/instructors/InstructorSignupView";
import InstructorSuccessView from "./components/views/instructors/InstructorSuccessView";
import InstructorsListView from "./components/views/instructors/InstructorsListView";
import InstructorsApplicationDetail from "./components/views/instructors/InstructorsApplicationDetail";
import TypesEmailsView from "./components/views/types/TypesEmailsView";
import CalendarView from "./components/views/CalendarView";
import EmployeeDatePickerView from "./components/views/employees/EmployeeDatePickerView";
import EmployeesListView from "./components/views/employees/EmployeesListView";
import EmployeeDetailView from "./components/views/employees/EmployeeDetailView";
import EmployeeProfileView from "./components/views/employees/EmployeeProfileView";
import EmployeeDashboardView from "./components/views/employees/EmployeeDashboardView";
import EmployeeCreateAccountView from "./components/views/employees/EmployeeCreateAccountView";
import EmployeeForgotPasswordView from "./components/views/employees/EmployeeForgotPasswordView";
import EmployeeNewPasswordView from "./components/views/employees/EmployeeNewPasswordView";
import EmployeeDateDetailView from "./components/views/employees/EmployeeDateDetailView";
import InvoicesListView from "./components/views/invoices/InvoicesListView";
import PriceIncreasesView from "./components/views/PriceIncreasesView";
import ParentsDashboardView from "./components/views/parents/ParentsDashboardView";
import ParentsProfileView from "./components/views/parents/ParentsProfileView";
import ParentsApplicationsListView from "./components/views/parents/ParentsApplicationsListView";
import ParentsApplicationDetailView from "./components/views/parents/ParentApplicationDetailView";
import ParentsNewPassword from "./components/views/parents/ParentsNewPassword";
import EmailsListView from "./components/views/emails/EmailsListView";
import EmailsEditView from "./components/views/emails/EmailsEditView";
import ReferralsLogView from "./components/views/parents/ReferralsLogView";
import InterestSettingsView from "./components/views/instructors/InterestSettingsView";

Sentry.init({
  dsn: "https://52a3b854adb440fe9ad4544ec2b9067d@sentry.io/1984772",
});
firebase.initializeApp(firebaseConfigData);

class App extends Component {
  constructor() {
    super();

    this.state = {
      logged: false,
      embedView: false,
      newVer: false,
    };
  }

  componentDidMount() {
    const { cookies } = this.props;
    const priv_token = "xCvaZQeJwycNadh7.VKOqRX2re0TnD3PyF9jt8";

    const parsed = queryString.parse(this.props.location.search);

    if (parsed.privshow === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9") {
      cookies.set("sj_priv_token", priv_token, {
        path: "/",
        domain: url.cookies,
      });
    }

    if (parsed.referral) {
      cookies.set("sj_referral_token", parsed.referral, {
        path: "/",
        domain: url.cookies,
        maxAge: 2592000,
      });
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.props.saveUser(user.uid);
        this.setState(
          {
            logged: true,
          },
          () => {
            if (this.state.logged && !this.props.loadingUser) {
              if (this.props.user.user_admin === 2) {
                this.props.history.push(
                  `${process.env.PUBLIC_URL}/manage/dates`
                );
              }
              if (this.props.user.user_admin === 3) {
                this.props.history.push(
                  `${process.env.PUBLIC_URL}/employee/dashboard`
                );
              }
              if (this.props.user.user_admin === 4) {
                this.props.history.push(
                  `${process.env.PUBLIC_URL}/parents/dashboard`
                );
              }
            }
          }
        );
        cookies.set("sj_priv_token", priv_token, {
          path: "/",
          domain: ".sportjoy.cz",
        });
      } else {
        this.props.setNotLogged();
        if (
          this.props.location.pathname.substring(0, 7) !== "/embed/" &&
          this.props.location.pathname.substring(0, 11) !== "/prihlaska/" &&
          this.props.location.pathname.substring(0, 12) !== "/newsletter/" &&
          this.props.location.pathname.substring(0, 9) !== "/payment/"
        ) {
          this.setState({
            logged: false,
          });
          this.props.history.push(`${process.env.PUBLIC_URL}/login`);
        }
      }
    });

    if (
      this.props.location.pathname.substring(0, 7) === "/embed/" ||
      this.props.location.pathname.substring(0, 11) === "/prihlaska/" ||
      this.props.location.pathname.substring(0, 12) === "/newsletter/" ||
      this.props.location.pathname.substring(0, 9) === "/payment/"
    ) {
      this.setState({
        embedView: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loadingUser !== this.props.loadingUser &&
      this.state.logged &&
      !this.props.loadingUser
    ) {
      if (this.props.user.user_admin === 2) {
        this.props.history.push(`${process.env.PUBLIC_URL}/manage/dates`);
      }
    }
  }

  render() {
    return (
      <div className="App">
        {this.state.logged &&
          !this.state.embedView &&
          !this.props.loadingUser && (
            <div>
              <BrowserView>
                <SidebarContainer userRole={this.props.user.user_admin} />
                <NavbarContainer />
              </BrowserView>
              <MobileView>
                <NavbarContainerMobile userRole={this.props.user.user_admin} />
              </MobileView>
              {this.props.user.user_admin !== 2 &&
              this.props.user.user_admin !== 3 &&
              this.props.user.user_admin !== 4 &&
                this.props.user.role !== "instructor" &&
                this.props.user.role !== "parent" &&
                !this.props.loadingUser && (
                  <Wrapper isMobile={isMobile}>
                    <Route path="/" exact component={withRouter(LoadingView)} />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/dates`}
                      exact
                      component={withRouter(EmployeeDatePickerView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/profile`}
                      exact
                      component={withRouter(EmployeeProfileView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/dashboard`}
                      exact
                      component={withRouter(EmployeeDashboardView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/employees`}
                      exact
                      component={withRouter(EmployeesListView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/employees/detail/:id`}
                      exact
                      component={withRouter(EmployeeDetailView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/dashboard`}
                      exact
                      component={withRouter(Dashboard)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/applications/:type?/:free?`}
                      exact
                      component={withRouter(ApplicationsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/application/detail/:id`}
                      exact
                      component={withRouter(ApplicationDetailView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/instructors`}
                      exact
                      component={withRouter(InstructorsListView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/instructors/detail/:id`}
                      exact
                      component={withRouter(InstructorsApplicationDetail)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/user/contacts`}
                      exact
                      component={withRouter(AddressBookView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/user/contacts/newsletter`}
                      exact
                      component={withRouter(NewsletterView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/locations`}
                      exact
                      component={withRouter(LocationsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/locations/edit/:id`}
                      exact
                      component={withRouter(EditLocationView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/organizations`}
                      exact
                      component={withRouter(OrganizationsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/organizations/edit/:id`}
                      exact
                      component={withRouter(EditOrganizationView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/users`}
                      exact
                      component={withRouter(UsersView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/users/edit/:id`}
                      exact
                      component={withRouter(EditUserView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/camps`}
                      exact
                      component={withRouter(CampsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/camps/edit/:id`}
                      exact
                      component={withRouter(EditCampView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/dates`}
                      exact
                      component={withRouter(DatesView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/dates/edit/:id`}
                      exact
                      component={withRouter(EditDatesView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/vouchers`}
                      exact
                      component={withRouter(VouchersOrdersView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/embed/signup/:utm/:campType?`}
                      exact
                      component={withRouter(EmbedView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/settings/coupons`}
                      exact
                      component={withRouter(CouponsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/settings/recommendations`}
                      exact
                      component={withRouter(RecommendationsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/emails`}
                      exact
                      component={withRouter(EmailsListView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/emails/edit/:id`}
                      exact
                      component={withRouter(EmailsEditView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/invoices`}
                      exact
                      component={withRouter(InvoicesListView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/increases`}
                      exact
                      component={withRouter(PriceIncreasesView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/interests`}
                      exact
                      component={withRouter(InterestSettingsView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/manage/referrals`}
                      exact
                      component={withRouter(ReferralsLogView)}
                    />
                  </Wrapper>
                )}
              {this.props.user.user_admin === 3 &&
                this.props.user.role === "instructor" &&
                !this.props.loadingUser && (
                  <Wrapper isMobile={isMobile}>
                    <Route path="/" exact component={withRouter(LoadingView)} />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/dates`}
                      exact
                      component={withRouter(EmployeeDatePickerView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/profile`}
                      exact
                      component={withRouter(EmployeeProfileView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/dashboard`}
                      exact
                      component={withRouter(EmployeeDashboardView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/employee/date/:id`}
                      exact
                      component={withRouter(EmployeeDateDetailView)}
                    />
                  </Wrapper>
                )}
              {this.props.user.user_admin === 4 &&
                this.props.user.role === "parent" &&
                !this.props.loadingUser && (
                  <Wrapper isMobile={isMobile}>
                    <Route path="/" exact component={withRouter(LoadingView)} />
                    <Route
                      path={`${process.env.PUBLIC_URL}/parents/dashboard`}
                      exact
                      component={withRouter(ParentsDashboardView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/parents/applications`}
                      exact
                      component={withRouter(ParentsApplicationsListView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/parents/profile`}
                      exact
                      component={withRouter(ParentsProfileView)}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/parents/applications/:id`}
                      exact
                      component={withRouter(ParentsApplicationDetailView)}
                    />
                  </Wrapper>
                )}
              {this.props.user.user_admin === 2 && !this.props.loadingUser && (
                <Wrapper isMobile={isMobile}>
                  <Route
                    path={`${process.env.PUBLIC_URL}/manage/dates`}
                    exact
                    component={withRouter(DatesView)}
                  />
                </Wrapper>
              )}
            </div>
          )}

        {!this.state.logged && !this.state.embedView && (
          <LoginWrapper>
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              exact
              component={withRouter(LoginView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/parents/new-password`}
              exact
              component={withRouter(ParentsNewPassword)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/employee/forgot-password`}
              exact
              component={withRouter(EmployeeForgotPasswordView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/employee/reset/:token/:code`}
              exact
              component={withRouter(EmployeeNewPasswordView)}
            />
          </LoginWrapper>
        )}

        {this.state.embedView && (
          <div>
            <Route
              path={`${process.env.PUBLIC_URL}/embed/signup/:campType?/:language?`}
              exact
              component={withRouter(EmbedView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/prihlaska/hobby/:utm?`}
              exact
              component={withRouter(ApplicationHobbyView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/instruktor`}
              exact
              component={withRouter(InstructorSignupView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/instruktor/uspech`}
              exact
              component={withRouter(InstructorSuccessView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/prihlasky/:utm?/:lang?`}
              exact
              component={withRouter(ApplicationsLandingView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/prihlaska/:campType/:utm?/:language?`}
              exact
              component={withRouter(ApplicationHobbyView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/vouchers/:utm?`}
              exact
              component={withRouter(VouchersStoreView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/dates/:type/:utm?/:campType?`}
              exact
              component={withRouter(DatesEmbedView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/kalendar/:type/:utm?/:campType?`}
              exact
              component={withRouter(CalendarView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/employee/create/:token`}
              exact
              component={withRouter(EmployeeCreateAccountView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/employee/forgot-password`}
              exact
              component={withRouter(EmployeeForgotPasswordView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/employee/reset/:token/:code`}
              exact
              component={withRouter(EmployeeNewPasswordView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/newsletter/unsubscribe/:id/:email`}
              exact
              component={withRouter(UnsubscribeView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/payment/:id/:type/:utm/:code/:confirm?`}
              exact
              component={withRouter(PaymentFinish)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/prihlaska/instruktor`}
              exact
              component={withRouter(InstructorSignupView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/prihlaska/instruktor/uspech`}
              exact
              component={withRouter(InstructorSuccessView)}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/embed/parents/new-password`}
              exact
              component={withRouter(ParentsNewPassword)}
            />
            <Route path="/" exact component={withRouter(LoadingView)} />
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              exact
              component={withRouter(LoginView)}
            />
          </div>
        )}

        <MessagesContainer />

        <VersionChecker />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default withCookies(
  connect(mapStateToProps, { saveUser, setNotLogged })(App)
);
