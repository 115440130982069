import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import { FormButtonGroup } from '../../../styles'
import RichTextEditor from 'react-rte'

const EmailsForm = ({ onChange, val, submit, loading }) => {
    return (
        <Form onSubmit={submit} loading={loading}>
            <Form.Field>
                <label>Potvrzovací email</label>
                <RichTextEditor
                    value={val}
                    onChange={onChange}
                    editorClassName="demo-editor"
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EmailsForm