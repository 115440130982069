import React, { Component } from 'react'
import { Form, Input, TextArea, Button, Message, Dropdown } from 'semantic-ui-react'
import { FormButtonGroup, FormSection, FormSectionBody, FormSectionHead } from '../../../styles'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import 'moment/locale/cs';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';
import { getMaxAge, getMinAge } from '../../../api'
import CustomDate from '../fields/CustomDate'
import { applicationsLang as lang } from '../../../lang/lang';

const classes = [
    {key: '0', value: 0, text: 'Žádná'},
    {key: '1', value: 1, text: '1. třída'},
    {key: '2', value: 2, text: '2. třída'},
    {key: '3', value: 3, text: '3. třída'},
    {key: '4', value: 4, text: '4. třída'},
    {key: '5', value: 5, text: '5. třída'},
    {key: '6', value: 6, text: '6. třída'},
    {key: '7', value: 7, text: '7. třída'},
    {key: '8', value: 8, text: '8. třída'},
    {key: '9', value: 9, text: '9. třída'}
]

class KidsForm extends Component {

    constructor(props) {
        super(props)
        //this.handleDayChange = this.handleDayChange.bind(this);
        this.state = ({
            buttonActive: true,
            isFilled: false,
            errors: [
                {
                    kids_name: '',
                    kids_lastname: '',
                    kids_birthday: '',
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_class: '',
                    kids_pairing: '',
                    kids_school: '',
                    can_go_home: '',
                    return_to_school: '',
                    go_from_school: '',
                    go_from_home: ''
                }
            ],
            data: [
                {
                    kids_name: '',
                    kids_lastname: '',
                    kids_birthday: new Date(),
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_eating_issues: '',
                    kids_class: '',
                    kids_pairing: '',
                    kids_camps: [],
                    kids_school: '',
                    can_go_home: '',
                    return_to_school: '',
                    go_from_school: '',
                    go_from_home: ''
                }
            ],
            loadingForm: true
        })
    }

    componentDidMount() {

        const { isHobby } = this.props;

        var type = 0;

        switch(isHobby) {
            case 'hobby':
                type = 2;
            break;
            case 'kurzy':
                type = 3;
            break;
            case 'skolka':
                type = 4;
            break;
        }

        this.prepareForm()

        getMinAge(type)
        .then((res) => {
            this.setState({
                min_age: res
            })
        })
        .catch((err) => {
            alert('Nepodařilo se správně získat data, zkuste to prosím později.')
        })

        getMaxAge(type)
        .then((res) => {
            this.setState({
                max_age: res
            })
        })
        .catch((err) => {
            alert('Nepodařilo se správně získat data, zkuste to prosím později.')
        })
    }

    prepareForm = () => {
        const { isHobby } = this.props;
            this.setState({
                basicKid: [{
                    label: lang.name,
                    type: 'text',
                    name: 'kids_name',
                    placeholder: lang.name_placeholder,
                    id: 'kids_name',
                    required: true
                },
                {
                    label: lang.last_name,
                    type: 'text',
                    name: 'kids_lastname',
                    placeholder: lang.last_name_placeholder,
                    id: 'kids_lastname',
                    required: true
                },
                {
                    label: lang.birthday,
                    type: 'date',
                    name: 'kids_birthday',
                    placeholder: lang.birthday,
                    id: 'kids_birthday',
                    required: true
                },
                {
                    label: lang.address,
                    type: 'text',
                    name: 'kids_address',
                    placeholder: lang.address_placeholder,
                    id: 'kids_address',
                    required: true
                },
                {
                    label: lang.city,
                    type: 'text',
                    name: 'kids_city',
                    placeholder: lang.city_placeholder,
                    id: 'kids_city',
                    required: true
                },
                {
                    label: lang.zip_code,
                    type: 'number',
                    name: 'kids_post_code',
                    placeholder: lang.zip_code_placeholder,
                    id: 'kids_post_code',
                    required: true,
                    onlyNumbers: true
                },
                {
                    label: lang.health_insurance_company,
                    type: 'text',
                    name: 'kids_insurance',
                    placeholder: lang.health_insurance_company_placeholder,
                    id: 'kids_insurance'
                },
                {
                    label: lang.health_restrictions,
                    type: 'textarea',
                    name: 'kids_health_issues',
                    placeholder: lang.health_restrictions_placeholder,
                    id: 'kids_health_issues'
                },
                {
                    label: lang.dietary_restrictions,
                    type: 'textarea',
                    name: 'kids_eating_issues',
                    placeholder: lang.dietary_restrictions_placeholder,
                    id: 'kids_eating_issues'
                }],
                basicData: {
                    kids_name: '',
                    kids_birthday: new Date(),
                    kids_address: '',
                    kids_city: '',
                    kids_post_code: '',
                    kids_insurance: '',
                    kids_health_issues: '',
                    kids_pairing: '',
                    kids_camps: [],
                },
                formFields: [
                    [{
                        label: lang.name,
                        type: 'text',
                        name: 'kids_name',
                        placeholder: lang.name_placeholder,
                        id: 'kids_name',
                        required: true
                    },
                    {
                        label: lang.last_name,
                        type: 'text',
                        name: 'kids_lastname',
                        placeholder: lang.last_name_placeholder,
                        id: 'kids_lastname',
                        required: true
                    },
                    {
                        label: lang.birthday,
                        type: 'date',
                        name: 'kids_birthday',
                        placeholder: lang.birthday,
                        id: 'kids_birthday',
                        required: true
                    },
                    {
                        label: lang.address,
                        type: 'text',
                        name: 'kids_address',
                        placeholder: lang.address_placeholder,
                        id: 'kids_address',
                        required: true
                    },
                    {
                        label: lang.city,
                        type: 'text',
                        name: 'kids_city',
                        placeholder: lang.city_placeholder,
                        id: 'kids_city',
                        required: true
                    },
                    {
                        label: lang.zip_code,
                        type: 'number',
                        name: 'kids_post_code',
                        placeholder: lang.zip_code_placeholder,
                        id: 'kids_post_code',
                        required: true,
                        onlyNumbers: true
                    },
                    {
                        label: lang.health_insurance_company,
                        type: 'text',
                        name: 'kids_insurance',
                        placeholder: lang.health_insurance_company_placeholder,
                        id: 'kids_insurance'
                    },
                    {
                        label: lang.health_restrictions,
                        type: 'textarea',
                        name: 'kids_health_issues',
                        placeholder: lang.health_restrictions_placeholder,
                        id: 'kids_health_issues'
                    },
                    {
                        label: lang.dietary_restrictions,
                        type: 'textarea',
                        name: 'kids_eating_issues',
                        placeholder: lang.dietary_restrictions_placeholder,
                        id: 'kids_eating_issues'
                    }]
                ],
                loadingForm: false
            })
    }

    checkInputs = () => {

        var minimum_age = moment().subtract(3, 'year');
        var maximum_age = moment().subtract(15, 'year');
        const { isHobby } = this.props;

        this.state.data.map((item, index) => {
            if(item.kids_name !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_name'] = ''
            }

            if(item.kids_lastname !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_lastname'] = ''
            }

            if(item.kids_birthday !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_birthday'] = ''
            }

            if(item.kids_address !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_address'] = ''
            }

            if(item.kids_city !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_city'] = ''
            }

            if(item.kids_post_code !== '') {
                let errors = [...this.state.errors]
                errors[index]['kids_post_code'] = ''
            }

            if(isHobby !== 'skolka' && isHobby !== 'kurzy' && isHobby !== 'hobby') {
                if(item.kids_class !== '') {
                    let errors = [...this.state.errors]
                    errors[index]['kids_class'] = ''
                }
    
                if(item.can_go_home !== '') {
                    let errors = [...this.state.errors]
                    errors[index]['can_go_home'] = ''
                }
                if(item.return_to_school !== '') {
                    let errors = [...this.state.errors]
                    errors[index]['return_to_school'] = ''
                }
                if(item.go_from_school !== '') {
                    let errors = [...this.state.errors]
                    errors[index]['go_from_school'] = ''
                }
                if(item.go_from_home !== '') {
                    let errors = [...this.state.errors]
                    errors[index]['go_from_home'] = ''
                }
            }
            
        })

        for (var i = 0; i < this.state.data.length; i++) {

            var diff = moment(this.state.data[i]['kids_birthday']).diff(Date(), 'years', true);
            var interDiff = -diff

            if (this.state.data[i]['kids_name'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_name'] = lang.name_placeholder

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;

            } else if (this.state.data[i]['kids_lastname'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_lastname'] = lang.last_name_placeholder

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;

            } else if (this.state.data[i]['kids_birthday'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = lang.birthday_placeholder

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (interDiff < this.state.min_age) {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = lang.formatString(lang.too_young, { years: this.state.min_age })

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (interDiff > this.state.max_age) {

                const errors = [...this.state.errors]
                errors[i]['kids_birthday'] = lang.formatString(lang.too_old, { years: this.state.max_age })

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_address'] === '') {
                const errors = [...this.state.errors]
                errors[i]['kids_address'] = lang.address_placeholder

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_city'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_city'] = lang.city_placeholder

                this.setState({
                    isFilled: false,
                    errors: errors
                })
              break;
            } else if (this.state.data[i]['kids_post_code'] === '') {

                const errors = [...this.state.errors]
                errors[i]['kids_post_code'] = lang.zip_code_placeholder

                this.setState({
                    isFilled: false,
                    errors: errors
                })

              break;
            } else {
                this.setState({
                    isFilled: true
                })
            }
        }

    }

    addKidHandler = (address, city, post_code) => {

        const { isHobby } = this.props;

        if(isHobby === 'false') {
            this.setState({
                formFields: [
                    ...this.state.formFields,
                    this.state.basicKid
                ],
                data: [
                    ...this.state.data,
                    {
                        kids_name: '',
                        kids_lastname: '',
                        kids_birthday: new Date(),
                        kids_address: address,
                        kids_city: city,
                        kids_post_code: post_code,
                        kids_insurance: '',
                        kids_health_issues: '',
                        kids_eating_issues: '',
                        kids_pairing: '',
                        kids_class: '',
                        kids_camps: [],
                        kids_pairing: '',
                        can_go_home: '',
                        return_to_school: '',
                        go_from_school: '',
                        go_from_home: ''
                    }
                ],
                errors: [
                    ...this.state.errors,
                    {
                        kids_name: '',
                        kids_lastname: '',
                        kids_birthday: '',
                        kids_address: '',
                        kids_city: '',
                        kids_post_code: '',
                        kids_insurance: '',
                        kids_health_issues: '',
                        kids_eating_issues: '',
                        kids_pairing: '',
                        kids_class: '',
                        can_go_home: '',
                        return_to_school: '',
                        go_from_school: '',
                        go_from_home: ''
                    }
                ]
            }, () => {
                this.checkInputs()
            })
        } else {
            this.setState({
                formFields: [
                    ...this.state.formFields,
                    this.state.basicKid
                ],
                data: [
                    ...this.state.data,
                    {
                        kids_name: '',
                        kids_lastname: '',
                        kids_birthday: new Date(),
                        kids_address: address,
                        kids_city: city,
                        kids_post_code: post_code,
                        kids_insurance: '',
                        kids_health_issues: '',
                        kids_eating_issues: '',
                        kids_camps: [],
                        kids_pairing: '',
                    }
                ],
                errors: [
                    ...this.state.errors,
                    {
                        kids_name: '',
                        kids_lastname: '',
                        kids_birthday: '',
                        kids_address: '',
                        kids_city: '',
                        kids_post_code: '',
                        kids_insurance: '',
                        kids_health_issues: '',
                        kids_eating_issues: '',
                    }
                ]
            }, () => {
                this.checkInputs()
            })
        }
    }

    nextHandler = () => {
        this.props.setKids(this.state.data)
    }

    handleChange = (e) => {
        if (["kids_name", "kids_lastname", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_school"].includes(e.target.className) ) {
          const kids = [...this.state.data]
          kids[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({ data: kids })
          this.checkInputs()
        }
    }

    setDate = (date, fieldname, index) => {
        if (["kids_name", "kids_lastname", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_pairing"].includes(fieldname) ) {
          const kids = [...this.state.data]
          kids[index][fieldname] = date
          this.setState({ data: kids })
          this.checkInputs()
        }
    }

    /*
    handleDayChange(value, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        if (["kids_name", "kids_lastname", "kids_birthday", "kids_address", "kids_city", "kids_post_code", "kids_insurance", "kids_eating_issues", "kids_health_issues", "kids_school"].includes(input.name) ) {
            const kids = [...this.state.data]
            kids[input.id][input.name] = moment(value).format('YYYY-MM-DD')
            this.setState({ data: kids }, () => {
                this.checkInputs()
            })
        }
    }
    */

    onChangeSelect = (e, data) => {
        const kids = [...this.state.data]
        if (['kids_class', "can_go_home", "return_to_school", "go_from_school", "go_from_home"].includes(e.target.parentNode.parentNode.id)) {
            kids[e.target.parentNode.parentNode.dataset.id][e.target.parentNode.parentNode.id] = data.value
            this.setState({ data: kids })
            this.checkInputs()
        } else if (['kids_class', "can_go_home", "return_to_school", "go_from_school", "go_from_home"].includes(e.target.parentNode.parentNode.parentNode.id)) {
            kids[e.target.parentNode.parentNode.parentNode.dataset.id][e.target.parentNode.parentNode.parentNode.id] = data.value
            this.setState({ data: kids })
            this.checkInputs()
        }
    }

    renderFields = () => {

        const format = "DD/MM/YYYY";

        return (
            this.state.formFields.map((item, index) => {
                return (
                    <FormSection key={index}>
                        <FormSectionHead>
                            <h5>{lang.child} #{index + 1}</h5>
                        </FormSectionHead>
                        <FormSectionBody>
                            {item.map((field) => {
                                return (
                                    <Form.Field key={field.id} className={this.state.errors[index][field.name] !== '' ? 'error' : 'success'}>
                                        <label>{field.label} {field.required ? '*' : ''}</label>
                                        {(field.type !== 'textarea' && field.name !== 'kids_birthday') && field.type !== 'select' ?
                                        <input 
                                            type={field.type}
                                            name={field.name + (index+1)}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            value={field.onlyNumbers ? this.state.data[index]['kids_post_code'].replace(/\s/g,'') : this.state.data[index][field.name]}
                                            onChange={(e) => this.handleChange(e)}
                                            className={field.name}
                                        />
                                        : (field.type === 'textarea') ?
                                        <TextArea
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            id={field.id}
                                            data-id={index}
                                            //value={this.state.data[field.name]}
                                            onChange={this.handleChange}
                                            className={field.name}
                                        />
                                        : (field.type === 'select') ? 
                                            <Dropdown 
                                                name={field.name}
                                                placeholder={field.placeholder}
                                                id={field.id}
                                                data-id={index}
                                                options={field.options}
                                                value={this.state.data[index][field.name]}
                                                onChange={this.onChangeSelect}
                                                className={field.name}
                                                selection
                                            />
                                        :
                                        <>
                                        <CustomDate 
                                            value={this.state.data[index][field.name]}
                                            onChange={this.setDate}
                                            id={field.id}
                                            dataid={index}
                                            fieldname={field.name}
                                        />
                                        </>
                                        }
                                        <p className='form-error-text'>{this.state.errors[index][field.name]}</p>
                                    </Form.Field>
                                )
                            })}
                        </FormSectionBody>
                    </FormSection>
                )
            })
        )
    }

    render() {
        return (
            <Form>
                <Message
                    color='red'
                    icon='warning sign'
                    header={lang.child_message_headline}
                    content={lang.child_message}
                />
                {!this.state.loadingForm &&
                <this.renderFields />
                }
                <FormButtonGroup>
                    <Button className='ghost btn_end' onClick={() => this.addKidHandler(this.state.data[0].kids_address, this.state.data[0].kids_city, this.state.data[0].kids_post_code)}>{lang.add_sibling}</Button>
                    <Button onClick={this.nextHandler} disabled={!this.state.isFilled} className='btn_end'>{lang.next}</Button>
                </FormButtonGroup>
            </Form>
        )
    }

}

export default KidsForm