import React, { Component } from 'react'
import { EmbedNavigation } from '../containers'
import { UserId } from '../../styles'
import { Button, Icon } from 'semantic-ui-react'

class PaymentFinish extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            yourId: 'Vaše ID'
        })
    }

    componentDidMount() {
        const { confirm } = this.props.match.params

        if(confirm === 'voucher') {
            this.setState({
                yourId: 'Kód voucheru'
            })
        }
    }

    renderMessage = (code) => {

        const { confirm } = this.props.match.params

        if(code === '0' && confirm !== 'voucher') {
            return 'Přihláška byla úspěšně zaplacena!'
        } else if(code === '0' && confirm === 'voucher') {
            return 'Voucher byl úspěšně zaplacen!'
        } else if(code === '5') {
            return 'Chybý povinné pole'
        } else if(code === '14') {
            return 'Duplikátní číslo platby'
        } else if(code === '15') {
            return 'Objekt nenalezen'
        } else if(code === '16') {
            return 'Částka k autorizaci překročila původní částku platby'
        } else if(code === '17') {
            return 'Částka k zaplacení překročila povolenou (autorizovanou) částku'
        } else if(code === '18') {
            return 'Součet vracených částek překročil zaplacenou částku'
        } else if(code === '25') {
            return 'Uživatel není oprávněn k provedení operace'
        } else if(code === '26') {
            return 'Technický problém při spojení s autorizačním centrem'
        } else if(code === '27') {
            return 'Chybný typ platby'
        } else if(code === '28') {
            return 'Zamítnuto v 3D ověření'
        } else if(code === '30') {
            return 'Zamítnuto v autorizačním centru'
        } else if(code === '31') {
            return 'Chybný podpis'
        } else if(code === '32') {
            return 'Expirovaná karta'
        } else if(code === '35') {
            return 'Platnost odkazu této platby již vypršela'
        } else if(code === '38') {
            return 'Nepodporovaná karta'
        } else if(code === '40') {
            return 'Zamítnuto ve Fraud detection system'
        } else if(code === '50') {
            return 'Držitel karty zrušil platbu'
        } else if(code === '83') {
            return 'Operace zrušena vydavatelem'
        } else if(code === '85') {
            return 'Zakázáno na základě pravidel obchodníka'
        } else if(code === '200') {
            return 'Žádost o doplňující informace'
        } else if(code === '300') {
            return 'Podmíněně zamítnuto – vydavatel požaduje SCA'
        } else if(code === '1000') {
            return 'Technický problém'
        }
    }

    render() {

        const { id, utm, type, code } = this.props.match.params

        return (
            <div>
                <EmbedNavigation utm={utm} />
                <div className='container'>
                    <div className='push_from_nav application application_message'>
                        <Icon className={type === 'success' ? 'application_icon' : 'application_icon error'} name={type === 'success' ? 'thumbs up' : 'thumbs down'} />
                        <h1>{this.renderMessage(code)}</h1>
                        <p>{code === '14' && 'Tuto platbu jste pravděpodobně již provedl/a. Pokud ne, kontaktujte nás prosím na info@sportjoy.cz'}</p>
                        <div className='application_success_info mt-5'>
                            <p className='text-center'>{this.state.yourId}</p>
                            <UserId>
                                {id}
                            </UserId>

                            <div className='row'>
                                <div className='col-sm-12'>
                                    <Button href={'https://www.sportjoy.cz'} target='_parent'>Zpět na SPORTJOY</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export default PaymentFinish