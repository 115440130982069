import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { Button, Form, Icon, Modal, Popup } from 'semantic-ui-react'
import CustomDate from '../forms/fields/CustomDate';

const BillCustomTextModal = ({ onChange, onChangeDate, payment_date, value, open, onClose, onOpen, submit, loading }) => {
    return (
        <Modal open={open} onClose={onClose} trigger={
            <Popup content='Vlastní text' trigger={<Button icon onClick={onOpen}><Icon name='setting' /></Button>} />
        }>
            <Modal.Header>
                Zadejte vlastní text
            </Modal.Header>
            <Modal.Content>
                <Row>
                    <Col sm={7}>
                        <Form>
                            <Form.Field>
                                <label>Datum platby</label>
                                <CustomDate
                                    onChange={onChangeDate}
                                    value={payment_date}
                                />
                            </Form.Field>
                            <Form.TextArea 
                                type="text"
                                value={value}
                                name="custom_text"
                                placeholder="Zadejte vlastní text"
                                label="Vlastní text"
                                onChange={onChange}
                            />
                        </Form>
                    </Col>
                    <Col sm={5}>
                        <p>Značky pro automatické doplnění informací:</p>
                        <p><strong>[ID_PRIHLASKA]</strong> - ID přihlášky</p>
                        <p><strong>[DATUM_OD]</strong> - Datum začátku tábora</p>
                        <p><strong>[DATUM_DO]</strong> - Datum konce tábora</p>
                        <p><strong>[DETI]</strong> - Seznam přihlášených děti</p>
                        <p>Např. "Letní tábor - [DATUM_OD]-[DATUM_DO] ([DETI]). Platba za přihlášku č. [ID_PRIHLASKA]"</p>
                    </Col>
                </Row>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Zavřít</Button>
                <Button primary onClick={() => submit()} loading={loading}>Vytvořit vlastní doklad</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default BillCustomTextModal