import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setMessage } from '../../actions/messageActions'
import { Card, CardControls, CardBody, CardHeader } from '../../styles'
import EditCampForm from '../forms/EditCampForm'
import { getCamp, getUsersOrganization, createCamp, getToken, updateCamp, uploadCampImage, updateCampSecondary, getTypesOpts } from '../../api'
import { CampSecondaryForm } from '../forms'
import { Tab } from 'semantic-ui-react';

class EditCampView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            loading: true,
            camp: {
                camp_name: '',
                id_organization: '',
                camp_description: '',
                camp_logo: '',
                camp_state: 0,
                filter_title: '',
                private: 0
            },
            organizationsValues: [],
            toUpload: []
        })
    }

    componentDidMount() {
        this.refresh()
        this._getTypesOpts()

        getUsersOrganization()
        .then((data) => this.setState({ organizationsValues: data }))
    }

    refresh = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {
            getCamp(id)
            .then((data) => {
                this.setState({
                    camp: data
                })
            })
            .then(() => {
                this.setState({
                    loading: false
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    onChange = (e) => this.setState({
        camp: {
            ...this.state.camp,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        camp: {
            ...this.state.camp,
            [data.name]: data.value
        }
    })

    _getTypesOpts = () => {
        this.setState({
            loadingTypes: true
        })

        getTypesOpts()
        .then((res) => {
            this.setState({
                typesOpts: res.data,
                loadingTypes: false
            })
        })
    }

    submit = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {

            getToken().then((idToken) => {
                this.setState({
                    camp: {
                        ...this.state.camp,
                        token: idToken
                    }
                }, () => {
                    updateCamp(id, this.state.camp)
                    .then((res) => {
                        this.props.setMessage(res)
                        this.setState({
                            loading: true
                        }, () => {
                            this.refresh()
                        })
                    })
                })
            })
            
        } else {

            this.setState({
                loading: true
            })

            getToken().then((idToken) => {
                this.setState({
                    camp: {
                        ...this.state.camp,
                        token: idToken
                    }
                }, () => {
                    createCamp(this.state.camp)
                    .then((res) => {
                        this.setState({
                            loading: false
                        })
                        this.props.setMessage(res)
                    })
                    .then(() => {
                        this.props.history.push('/manage/camps')
                    })
                    .catch((err) => {
                        this.props.setMessage(err)
                    })
                })
            })

        }
    }

    onDrop = (files) => {
        this.setState({
            toUpload: files
        })
    }

    submitSecondary = () => {

        const { id } = this.props.match.params

        getToken().then((idToken) => {
            this.setState({
                camp: {
                    ...this.state.camp,
                    token: idToken
                }
            }, () => {

                if(this.state.toUpload.length !== 0) {
                    this.uploadImage(this.state.toUpload)
                }

                updateCampSecondary(id, this.state.camp)
                .then((res) => {
                    this.props.setMessage(res)
                    this.setState({
                        loading: true
                    }, () => {
                        this.refresh()
                    })
                })
            })
        })
    }

    uploadImage = (image) => {

        const { id } = this.props.match.params

        this.setState({
			loading: true,
			uploadState: {
				beforeUpload: false,
				isUploading: true,
				uploaded: false
			}
        })
        
        const fd = new FormData();

        fd.append('image', image, image.name);
        
        uploadCampImage(id, fd)
        .then((res) => {
            this.setState({
                loading: false,
                toUpload: []
            }, () => {
                this.refresh()
            })
        })
        .catch((err) => {
            this.props.setMessage(err)
            this.setState({
                loading: false
            })
        })
    }

    _renderMainSettings = () => {

        const { id } = this.props.match.params

        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardHeader>
                            Základní informace
                        </CardHeader>
                        <CardBody>
                            <EditCampForm 
                                loading={this.state.loading}
                                camp={this.state.camp}
                                typesOpts={this.state.typesOpts}
                                loadingTypes={this.state.loadingTypes}
                                onChange={this.onChange}
                                onChangeSelect={this.onChangeSelect}
                                values={this.state.organizationsValues}
                                submit={this.submit}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'>
                    <Card>
                        <CardHeader>
                            Další informace
                        </CardHeader>
                        <CardBody>
                            <CampSecondaryForm 
                                camp={this.state.camp}
                                loading={this.state.loading}
                                isNew={id === 'new' ? true : false}
                                onDropFile={this.onDrop}
                                onChangeSelect={this.onChangeSelect}
                                submit={this.submitSecondary}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

    _tabs = (type) => {

        var panes = []

        if(type === 'new') {
            panes = [
                {
                  menuItem: 'Upravit tábor',
                  render: () => <Tab.Pane attached={false}>{this._renderMainSettings()}</Tab.Pane>,
                }
            ] 
        } else {
            panes = [
                {
                  menuItem: 'Upravit tábor',
                  render: () => <Tab.Pane attached={false}>{this._renderMainSettings()}</Tab.Pane>,
                }
            ]
        }

        return panes
    }

    render() {

        const { id } = this.props.match.params

        return (
            <Tab menu={{ secondary: true, pointing: true }} panes={this._tabs(id)} />
        )
    }

}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(EditCampView)