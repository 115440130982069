import React, { useState, useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { getDates } from '../../../api/employees';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import DatesTableFilter from './DatesTableFilter';
import { jsonToFormData } from '../../../tools';
import { useSelector } from "react-redux";

function DatesTable({ action, getData, filterPath, displayFilter, datePicker, employeeDetail, admin_detail, admin_id_user }) {

    let history = useHistory();
    let location = useLocation();
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(false);

    const user = useSelector((state) => state.user.user);
    const { id_user } = user;

    const parsed = queryString.parse(location.search);

    useEffect(() => {

        _getData()

    }, []);
    
    function _getData() {
        
        const data = {
            query: parsed,
            id_user: admin_detail ? admin_id_user : id_user
        }

        const payload = jsonToFormData(data);

        /*getDates(payload)
        .then((res) => {
            setDates(res.data)
        });*/

        getData(payload)
        .then((res) => {
            setDates(res.data)
            setLoading(false)
        });
    }

    function _action(id) {
        setLoading(true)
        action(id)
        _getData()
    }

    function _actionEmployeesDetail(new_state, id) {
        setLoading(true)
        action(new_state, id)
        _getData()
    }

    return (
        <>
        {displayFilter &&
        <DatesTableFilter path={filterPath} />
        }
        <Table striped className='main-table mobile' unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Druh
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Začátek
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Konec
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Lokalita
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {!loading &&
            <Table.Body>
                {dates.map((item) => {
                    return (
                        <Table.Row key={item.id_date} className={item.is_full === 1 && 'full_date'}>
                            <Table.Cell>
                                {item.camp_name}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.date_from).format("DD/MM/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.date_to).format("DD/MM/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                                <strong>{item.location_name}</strong><br />
                                {item.location_address}
                            </Table.Cell>
                            <Table.Cell>
                                {datePicker &&
                                <Button primary onClick={() => _action(item.id_date)} disabled={item.date_status !== 'Vybrat termín'}>{item.date_status}</Button>
                                }
                                {employeeDetail &&
                                <>
                                    <Button primary onClick={() => _actionEmployeesDetail(1, item.id)} disabled={item.state === 1}>Potvrdit</Button>
                                    <Button onClick={() => _actionEmployeesDetail(0, item.id)} disabled={item.state === 0}>Zamítnout</Button>
                                </>
                                }
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
            }
        </Table>
        </>
    )
}

export default DatesTable