import React from 'react'
import { Button, Icon, Table } from 'semantic-ui-react'
import _ from 'loadsh';
import moment from 'moment';
import { StateIcon } from '../../../styles';
import { resendIncreases } from '../../../api';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../actions/messageActions';

function IncreasesTable({ data, refresh }) {

    let dispatch = useDispatch();

    function _resendIncreases(id_payment)
    {
        resendIncreases(id_payment)
        .then((res) => {
            dispatch(setMessage(res));
            refresh()
        })
        .catch((err) => {
            refresh()
            dispatch(setMessage({type: 'error', text: 'Nepodařilo se odeslat email. Zkuste to později.'}));
        })
    }

    return (
        <Table striped className='main-table mobile' unstackable>
            <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            width={1}
                        >
                            #
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            ID přihlášky
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Částka
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Vytvořeno
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Odesláno
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Zaplaceno
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Akce
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {_.map(data, ({ id_payment, amount, created, is_price_increase_sent, paid, id_application }) => (
                    <Table.Row key={id_payment}>
                        <Table.Cell>
                            {id_payment}
                        </Table.Cell>
                        <Table.Cell>
                            {id_application}
                        </Table.Cell>
                        <Table.Cell>
                            {amount}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(created).format('D.M.YYYY H:mm')}
                        </Table.Cell>
                        <Table.Cell>
                            <StateIcon state={is_price_increase_sent} />
                        </Table.Cell>
                        <Table.Cell>
                            <StateIcon state={paid} />
                        </Table.Cell>
                        <Table.Cell>
                            <Button title='Odeslat znovu' icon onClick={() => _resendIncreases(id_payment)}><Icon name='envelope' /></Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
        </Table>
    )
}

export default IncreasesTable