import React, { useState } from 'react'
import { Button, Form } from 'semantic-ui-react'
import { resetPassword } from '../../../api/employees'

function ResetPasswordForm({ button, type }) {

    const [ data, setData ] = useState({ email: '' });
    const [ loading, setLoading ] = useState(false);

    function submit() {

        setLoading(true);

        resetPassword(data.email, type)
        .then((res) => {
            setLoading(false);
        })
    }

    function onChange(e, data) {
        setData({...data, [e.target.name]: e.target.value})
    }

    return (
        <Form onSubmit={submit} loading={loading}>
            <Form.Input 
                type="email"
                name="email"
                label="Email"
                placeholder="Zadejte Váš email"
                required
                onChange={onChange}
            />
            <Button fluid primary>{button ? button : 'Resetovat heslo'}</Button>
        </Form>
    )
}

export default ResetPasswordForm