import React from 'react'
import { Modal } from 'semantic-ui-react'

function ImageModal({ imgSrc, trigger }) {

    return (
        <Modal trigger={trigger} className='image_modal'>
            <Modal.Content>
                <img src={imgSrc} className='img-fluid' alt='Uploaded' /> 
            </Modal.Content>
        </Modal>
    )
}

export default ImageModal