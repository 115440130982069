import React from "react";
import { Row, Col } from "reactstrap";
import { applicationsLang } from "../../lang/lang";
import { Flags } from "../../config/Flags";
import { LanguageSwitcherBlock } from "../../styles";

function LanguageSwitcherBig({
  setLang,
  displayHeading,
  marginBottom,
  position,
}) {
  return (
    <>
      <LanguageSwitcherBlock marginBottom={marginBottom}>
        {displayHeading && (
          <Row>
            <Col sm={12} className="mb-5 text-center">
              <h2>{applicationsLang.choose_language}</h2>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            sm={position === "right" ? 8 : 6}
            className={
              position === "center"
                ? "offset-sm-3"
                : position === "right"
                ? "offset-sm-4"
                : ""
            }
          >
            <Row className="m-0">
              <Col className="pr-0">
                <div onClick={() => setLang("cs")}>
                  <img src={Flags.cs} className="flag" alt="česká vlajka" />
                </div>
              </Col>
              <Col className="pr-0">
                <div onClick={() => setLang("en")}>
                  <img src={Flags.en} className="flag" alt="britská vlajka" />
                </div>
              </Col>
              <Col className="pr-0">
                <div onClick={() => setLang("ua")}>
                  <img
                    src={Flags.ua}
                    className="flag"
                    alt="ukrajinská vlajka"
                  />
                </div>
              </Col>
              <Col className="pr-0">
                <div onClick={() => setLang("ru")}>
                  <img src={Flags.ru} className="flag" alt="ruská vlajka" />
                </div>
              </Col>
              <Col className="pr-0">
                <div onClick={() => setLang("vn")}>
                  <img
                    src={Flags.vn}
                    className="flag"
                    alt="vietnamská vlajka"
                  />
                </div>
              </Col>
              <Col className="pr-0">
                <div onClick={() => setLang("es")}>
                  <img src={Flags.es} className="flag" alt="španělská vlajka" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </LanguageSwitcherBlock>
    </>
  );
}

export default LanguageSwitcherBig;
