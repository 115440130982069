import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { setIsLeader } from '../../../api/employees';
import { jsonToFormData } from '../../../tools'
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../actions/messageActions';

function LeaderSwitcher({ value, id_date, id_user, refresh }) {

    let dispatch = useDispatch();

    function onChangeSelect(e, val) {
        const toSend = {
            is_leader: val.value,
            id_date: id_date,
            id_user: id_user
        }

        const payload = jsonToFormData(toSend);

        setIsLeader(payload)
        .then((res) => {
            dispatch(setMessage(res.data));
            refresh()
        })
    }

    return (
        <Dropdown
            fluid
            options={[{key: 0, value: 0, text: 'Ne'}, {key: 1, value: 1, text: 'Ano'}]}
            value={value}
            onChange={onChangeSelect}
        />
    )
}

export default LeaderSwitcher