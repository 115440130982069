import React, { Component } from "react";
import { connect } from "react-redux";
import { setMessage } from "../../actions/messageActions";
import {
  Card,
  CardControls,
  CardBody,
  CardHeader,
  CardHeaderSeparator,
  CardHeaderSeparatorLabel,
} from "../../styles";
import {
  getDate,
  getUsersOrganization,
  getToken,
  getLocationsValues,
  createDate,
  updateDate,
  getCampsValues,
  updateDateSecondary,
  getDateApplications,
  getDateSubsApplications,
  deleteDate,
} from "../../api";
import { DateSecondaryForm, EditDateForm } from "../forms";
import { Tab, Button, Divider, Icon } from "semantic-ui-react";
import { DateApplicationsTable } from "../containers";
import _ from "loadsh";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BrowserView, MobileView } from "react-device-detect";
import DateEmails from "../containers/date-email/DateEmails";
import AddDateModal from "../modals/AddDateModal";
import EmployeesTable from "../containers/employees/EmployeesTable";
import AttendanceContainer from "../containers/AttendanceContainer";

const categories = [
  { key: 0, value: 0, text: "Příměstský tábor" },
  { key: 1, value: 1, text: "Kroužek" },
  { key: 2, value: 2, text: "Kurz" },
  { key: 3, value: 3, text: "Školka" },
];

class EditDatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: {
        date_state: 1,
        category: 1,
        more_locations: [],
      },
      organizationsValues: [],
      locationsValues: [],
      campsValues: [],
      applications: [],
      subs: [],
      kids_sum: 0,
      avg_age: 0,
      total_price: 0,
      column: "id_date",
      direction: "descending",
      user_admin: 0,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.refresh();

    getUsersOrganization().then((data) =>
      this.setState({ organizationsValues: data })
    );

    getLocationsValues().then((data) =>
      this.setState({ locationsValues: data })
    );

    {
      !this.props.loadingUser &&
        getCampsValues(this.props.user.id_organization).then((data) =>
          this.setState({ campsValues: data })
        );
    }

    if (id !== "new") {
      getDateApplications(id).then((data) => {
        this.setState({
          applications: data.data,
          kids_sum: data.kids_sum,
          avg_age: data.avg_age,
          total_price: data.total_price,
        });
      });
    }

    getDateSubsApplications(id).then((data) => {
      this.setState({
        subs: data,
      });
    });

    this.setState({
      user_admin:
        this.props.user.user_admin !== undefined
          ? this.props.user.user_admin
          : 0,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      getCampsValues(this.props.user.id_organization).then((data) =>
        this.setState({ campsValues: data })
      );

      this.setState(
        {
          user_admin: this.props.user.user_admin,
        },
        () => {
          console.log(this.state.user_admin);
        }
      );
    }
  }

  refresh = () => {
    const { id } = this.props.match.params;

    if (id !== "new") {
      getDateApplications(id).then((data) => {
        this.setState({
          applications: data.data,
        });
      });

      getDateSubsApplications(id).then((data) => {
        this.setState({
          subs: data,
        });
      });

      getDate(id)
        .then((data) => {
          this.setState(
            {
              date: data,
            },
            () => console.log(this.state.date)
          );
        })
        .then(() => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onChange = (e) =>
    this.setState({
      date: {
        ...this.state.date,
        [e.target.name]: e.target.value,
      },
    });

  onChangeSelect = (e, data) =>
    this.setState({
      date: {
        ...this.state.date,
        [data.name]: data.value,
      },
    });

  submit = () => {
    const { id } = this.props.match.params;

    if (id !== "new") {
      this.setState({
        loading: true,
      });

      getToken().then((idToken) => {
        this.setState(
          {
            date: {
              ...this.state.date,
              date_from: moment(this.state.date.date_from).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              date_to: moment(this.state.date.date_to).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              token: idToken,
            },
          },
          () => {
            updateDate(id, this.state.date).then((res) => {
              this.props.setMessage(res);
              this.setState(
                {
                  loading: true,
                },
                () => {
                  this.refresh();
                }
              );
            });
          }
        );
      });
    } else {
      this.setState({
        loading: true,
      });

      getToken().then((idToken) => {
        this.setState(
          {
            date: {
              ...this.state.date,
              token: idToken,
            },
          },
          () => {
            createDate(this.state.date)
              .then((res) => {
                this.setState({
                  loading: false,
                });
                this.props.setMessage(res);
              })
              .then(() => {
                this.props.history.push("/manage/dates");
              })
              .catch((err) => {
                this.props.setMessage(err);
              });
          }
        );
      });
    }
  };

  submitSecondary = () => {
    this.setState({
      loading: true,
    });

    const { id } = this.props.match.params;

    getToken().then((idToken) => {
      this.setState(
        {
          date: {
            ...this.state.date,
            token: idToken,
          },
        },
        () => {
          updateDateSecondary(id, this.state.date).then((res) => {
            this.props.setMessage(res);
            this.setState(
              {
                loading: false,
              },
              () => {
                this.refresh();
              }
            );
          });
        }
      );
    });
  };

  handleSort = (clickedColumn) => () => {
    const { column, applications, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        applications: _.sortBy(applications, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: applications.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  exportRegistrationHandler = () => {
    const { id } = this.props.match.params;

    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-registration/" +
          id +
          "/" +
          token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportTshirtsHandler = (type, remaining) => {
    const { id } = this.props.match.params;

    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      if (type === "all") {
        window.open(
          "https://api.sportjoy.cz/export/export/?type=camp-age-groups&id_date=" +
            id +
            "&token=" +
            token +
            "&remaining=" +
            remaining,
          "_blank"
        );
      } else if (type === "babyjoy") {
        window.open(
          "https://api.sportjoy.cz/export/export/?type=camp-age-groups-babyjoy&id_date=" +
            id +
            "&token=" +
            token +
            "&remaining=" +
            remaining,
          "_blank"
        );
      }

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportList = (type, id) => {
    const url =
      "https://api.sportjoy.cz/export/export?type=" + type + "&app_type=" + id;

    this.setState({
      loadingExport: true,
    });
    getToken()
      .then((token) => {
        window.open(url + "&token=" + token, "_blank");

        this.setState({
          loadingExport: false,
        });
      })
      .catch((err) => {
        alert(
          "Nepodařilo se získat bezpečnostní certifikát s chybou:" +
            err.message +
            ". Zkuste to prosím znovu."
        );
        this.setState({
          loadingExport: false,
        });
      });
  };

  panes = [
    {
      menuItem: "Seznam přihlášených",
      render: () => (
        <Tab.Pane attached={false}>{this.renderApplicationsView()}</Tab.Pane>
      ),
    },
    {
      menuItem: "Upravit termín",
      render: () => (
        <Tab.Pane attached={false}>{this.renderEditView()}</Tab.Pane>
      ),
    },
  ];

  renderPanes = (type) => {
    var panes = [];

    if (type === "new") {
      panes = [
        {
          menuItem: "Upravit termín",
          render: () => (
            <Tab.Pane attached={false}>{this.renderEditView()}</Tab.Pane>
          ),
        },
      ];
    } else {
      panes = [
        {
          menuItem: "Seznam přihlášených",
          render: () => (
            <Tab.Pane attached={false}>
              {this.renderApplicationsView()}
            </Tab.Pane>
          ),
        },
        {
          menuItem: "Docházka",
          render: () => (
            <Tab.Pane attached={false}>{this.renderAttendance()}</Tab.Pane>
          ),
        },
        {
          menuItem: "Instruktoři",
          render: () => (
            <Tab.Pane attached={false}>{this.renderEmployeesTable()}</Tab.Pane>
          ),
        },
        {
          menuItem: "Všechny termíny",
          render: () => (
            <Tab.Pane attached={false}>{this.renderAllDates()}</Tab.Pane>
          ),
        },
        {
          menuItem: "Upravit termín",
          render: () => (
            <Tab.Pane attached={false}>{this.renderEditView()}</Tab.Pane>
          ),
        },
        {
          menuItem: "Emaily",
          render: () => (
            <Tab.Pane attached={false}>{this.renderDateEmails()}</Tab.Pane>
          ),
        },
      ];
    }

    return panes;
  };

  renderAttendance = () => {
    return <AttendanceContainer />;
  };

  renderEmployeesTable = () => {
    const { id } = this.props.match.params;

    return (
      <>
        <Card className="mb-5">
          <CardHeader>Seznam instruktorů</CardHeader>
          <EmployeesTable id_date={id} />
        </Card>
        <Card>
          <CardHeader>Nevybraní instruktoři</CardHeader>
          <EmployeesTable id_date={id} showRejected={1} />
        </Card>
      </>
    );
  };

  exportEmployees = (type) => {
    const { id } = this.props.match.params;

    var url =
      "https://api.sportjoy.cz/export/employees" + type + "?id_date=" + id;
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(url + "&token=" + token, "_blank");

      this.setState({
        loadingExport: false,
      });
    });
  };

  cardHeaderInfo = () => {
    return (
      <Row>
        <div className="col-sm-6 card_header_box text-left row pr-0">
          <CardHeaderSeparator className="col-sm-4">
            <CardHeaderSeparatorLabel>Termín</CardHeaderSeparatorLabel>
            <h2>
              {moment(this.state.date.date_from).format("DD.")} -{" "}
              {moment(this.state.date.date_to).format("DD. MM. YYYY")}
            </h2>
          </CardHeaderSeparator>
          <CardHeaderSeparator className="col-sm-3">
            <CardHeaderSeparatorLabel>Tábor</CardHeaderSeparatorLabel>
            <h2>{this.state.date.camp_name}</h2>
          </CardHeaderSeparator>
          <CardHeaderSeparator className="col-sm-5">
            <CardHeaderSeparatorLabel>Lokalita</CardHeaderSeparatorLabel>
            <h2>{this.state.date.location_name}</h2>
          </CardHeaderSeparator>
        </div>
        <div className="col-sm-6 card_header_box text-right row p-0">
          <CardHeaderSeparator className="col-sm-4">
            <CardHeaderSeparatorLabel>Počet dětí</CardHeaderSeparatorLabel>
            <h2>
              {this.state.kids_sum}/{this.state.date.date_capacity}
            </h2>
          </CardHeaderSeparator>
          <CardHeaderSeparator className="col-sm-4">
            <CardHeaderSeparatorLabel>Průmerný věk</CardHeaderSeparatorLabel>
            <h2>{this.state.avg_age.toFixed(1)} let</h2>
          </CardHeaderSeparator>
          {this.state.user_admin === 1 && (
            <CardHeaderSeparator className="col-sm-4">
              <CardHeaderSeparatorLabel>
                Celkem vybráno
              </CardHeaderSeparatorLabel>
              <h2>{this.state.total_price.toLocaleString()} Kč</h2>
            </CardHeaderSeparator>
          )}
        </div>
      </Row>
    );
  };

  cardHeaderInfoMobile = () => {
    return (
      <>
        <Row>
          <Col
            style={{
              borderRight: 1,
              borderColor: "#ececec",
              borderRightStyle: "solid",
            }}
          >
            <CardHeaderSeparatorLabel>Termín</CardHeaderSeparatorLabel>
            <h2>
              {moment(this.state.date.date_from).format("DD.")} -{" "}
              {moment(this.state.date.date_to).format("DD. MM. YYYY")}
            </h2>
          </Col>
          <Col>
            <CardHeaderSeparatorLabel>Tábor</CardHeaderSeparatorLabel>
            <h2>{this.state.date.camp_name}</h2>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <CardHeaderSeparatorLabel>Lokalita</CardHeaderSeparatorLabel>
            <h2>{this.state.date.location_name}</h2>
          </Col>
        </Row>
      </>
    );
  };

  renderApplicationsView = () => {
    const { id } = this.props.match.params;
    return (
      <div className="row">
        <div className="col-sm-12 mb-5">
          <Card>
            <CardHeader>
              <BrowserView>{this.cardHeaderInfo()}</BrowserView>

              <MobileView>{this.cardHeaderInfoMobile()}</MobileView>
            </CardHeader>
            <CardControls>
              <a
                href={`https://api.sportjoy.cz/export/export-groups/${id}`}
                target="_blank"
              >
                <Button>Export - Skupiny</Button>
              </a>
              <Button onClick={() => this.exportRegistrationHandler()}>
                Export - Registrace
              </Button>
              <Button onClick={() => this.exportList("date-phones", id)}>
                Export - Telefony
              </Button>
              <a
                href={`https://api.sportjoy.cz/export/export-coaches/${id}`}
                target="_blank"
              >
                <Button>Export - Pro trenéry</Button>
              </a>
              <Button onClick={() => this.exportTshirtsHandler("all", 0)}>
                Export - Trička
              </Button>
              <Button onClick={() => this.exportTshirtsHandler("babyjoy", 0)}>
                Export - Trička - Babyjoy
              </Button>
              <Button onClick={() => this.exportTshirtsHandler("all", 1)}>
                Export - Trička - zbytek
              </Button>
              <Button onClick={() => this.exportTshirtsHandler("babyjoy", 1)}>
                Export - Trička - Babyjoy - zbytek
              </Button>
              <Button onClick={() => this.exportEmployees("")}>
                Export - Instruktoři
              </Button>
              <Button onClick={() => this.exportEmployees("-dates")}>
                Export - Instruktoři - Termíny
              </Button>
              <Button onClick={() => this.exportEmployees("-wages")}>
                Export - Instruktoři - Mzdy
              </Button>
              <Button onClick={() => this.exportEmployees("-camps")}>
                Export - Instruktoři - Tábory
              </Button>
            </CardControls>
            <DateApplicationsTable
              userAdmin={this.state.user_admin}
              refresh={this.refresh}
              items={this.state.applications}
              sub={false}
              id_date={this.state.date.id_date}
              date={this.state.date.date_from}
              sort={this.handleSort}
              column={this.state.column}
              direction={this.state.direction}
            />
          </Card>
        </div>
        {this.state.subs.length !== 0 && (
          <div className="col-sm-12">
            <Card>
              <CardHeader>
                <div className="row">
                  <div className="col-sm-12 text-left">
                    <h2>Náhradníci</h2>
                  </div>
                </div>
              </CardHeader>
              <DateApplicationsTable
                userAdmin={this.state.user_admin}
                items={this.state.subs}
                id_date={id}
                sub={true}
                refresh={this.refresh}
                date={this.state.date.date_from}
                sort={this.handleSort}
                column={this.state.column}
                direction={this.state.direction}
              />
            </Card>
          </div>
        )}
      </div>
    );
  };

  renderEditView = () => {
    const { id } = this.props.match.params;

    return (
      <div className="row">
        <div className="col-sm-8">
          <Card>
            <CardHeader>
              <div className="row">
                <div className="col-sm-6 text-left">
                  <h2>Základní informace</h2>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <EditDateForm
                loading={this.state.loading}
                date={this.state.date}
                onChange={this.onChange}
                onChangeSelect={this.onChangeSelect}
                camps={this.state.campsValues}
                locations={this.state.locationsValues}
                submit={this.submit}
                categories={categories}
              />
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-4">
          <Card>
            <CardHeader>
              <div className="row">
                <div className="col-sm-12 text-left">
                  <h2>Další informace</h2>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <DateSecondaryForm
                date={this.state.date}
                loading={this.state.loading}
                isNew={id === "new" ? true : false}
                onChangeSelect={this.onChangeSelect}
                submit={this.submitSecondary}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };

  deleteDateHandler = (id) => {
    this.setState({
      ["loading" + id]: true,
    });

    getToken().then((token) => {
      deleteDate(id, token)
        .then((res) => {
          this.props.setMessage(res);
          this.refresh();

          this.setState({
            ["loading" + id]: false,
          });
        })
        .catch((err) => {
          this.props.setMessage(err.message);
          this.setState({
            ["loading" + id]: false,
          });
        });
    });
  };

  renderAllDates = () => {
    return (
      <Row>
        <Col sm={8}>
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              Všechny termíny
              <AddDateModal
                refresh={this.refresh}
                group_date={this.state.date.group_date}
              />
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm={12}>
                  {this.state.date.all_dates.map((item) => {
                    return (
                      <Row className="mb-3 pb-3">
                        <Col sm={8}>
                          <h3 style={{ fontSize: 1.3 + "rem" }}>
                            {moment(item.date_from).format("D. M. YYYY")}{" "}
                            {moment(item.start_time).format("H:mm")}
                          </h3>
                        </Col>
                        <Col sm={4} className="text-right">
                          <Link to={"/manage/dates/edit/" + item.id_date}>
                            <Button icon>
                              <Icon name="eye" />
                            </Button>
                          </Link>
                          <Button
                            onClick={() => this.deleteDateHandler(item.id_date)}
                            loading={this.state["loading" + item.id_date]}
                            icon
                          >
                            <Icon name="trash" />
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  renderDateEmails = () => {
    const { id } = this.props.match.params;

    return <DateEmails id={id} />;
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={this.renderPanes(id)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default connect(mapStateToProps, { setMessage })(EditDatesView);
