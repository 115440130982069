import React from 'react'
import { EmbedNavigation } from '../../containers'
import { Container } from 'reactstrap'
import { SuccessMessageWrapper } from '../../../styles/instructors'
import { Button, Icon } from 'semantic-ui-react'

function InstructorSuccessView() {
    return (
        <>
            <EmbedNavigation text='Přihláška instruktora' />
            <div className='push_from_nav application'>
                <Container>
                    <SuccessMessageWrapper>
                        <div className='text-center'>
                            <Icon name='thumbs up' />
                            <span className='bold'>Děkujeme!</span>
                            <p>Vaše přihláška byla úspěšně odeslána! O dalším postupu Vás budeme informovat emailem.</p>
                            <Button as='a' href='https://www.sportjoy.cz'>Zpět na SPORTJOY</Button>
                        </div>
                    </SuccessMessageWrapper>
                </Container>
            </div>
        </>
    )
}

export default InstructorSuccessView