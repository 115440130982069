import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { setMessage } from "../../../../actions/messageActions";
import { updateCheckboxes } from "../../../../api/instructors";
import { jsonToFormData } from "../../../../tools";
import DefaultModal from "../../../modals/DefaultModal";
import EditNameForm from "./EditNameForm";
import { StyledInterestValue } from "./StyledInterestValue";

function InterestValue({ name, id, refresh, column }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const Form = () => {
    return (
      <EditNameForm
        name="name"
        type="checkbox"
        value={name}
        id={id}
        label="Text položky"
        placeholder="Zadejte text položky"
        refresh={refresh}
        column={column}
        newItem={0}
      />
    );
  };

  function deleteItem() {
    setLoading(true);
    const payload = {
      id_item: id,
      name: name,
      type: "checkbox",
      state: 0,
    };
    const fd = jsonToFormData(payload);
    updateCheckboxes(fd).then((res) => {
      setLoading(false);
      refresh();
      dispatch(setMessage(res.data));
    });
  }

  return (
    <StyledInterestValue>
      <span>{name}</span>
      <div>
        <DefaultModal
          size="tiny"
          trigger={
            <Button icon>
              <Icon name="pencil" />
            </Button>
          }
          children={<Form />}
          heading="Úprava položky"
        />
        <Button icon loading={loading} onClick={() => deleteItem()}>
          <Icon name="trash" />
        </Button>
      </div>
    </StyledInterestValue>
  );
}

export default InterestValue;
