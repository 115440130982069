import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { Button, Loader } from 'semantic-ui-react'
import { EmptyContainer } from '../../../styles'

function EmptyTableMessage({ message, loading, showButton, buttonText, link, target }) {

    function renderMessage() {
        if(showButton) {
            return (
                <div>
                    <span className='message'>{message}</span>
                    <Link to={link} target={target ? target : '_self'}><Button primary>{buttonText}</Button></Link>
                </div>
            )
        } else {
            return (
                <span className='message'>{message}</span>
            )
        }
    }

    return (
        <>
            <EmptyContainer>
                {!loading ?
                renderMessage()
                :
                <Loader active />
                }
            </EmptyContainer>
        </>
    )
}

export default EmptyTableMessage