import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import { createUser, getEmailFromToken } from '../../../api/employees';
import { jsonToFormData } from '../../../tools';
import { setMessage } from '../../../actions/messageActions';
import { useHistory, useLocation } from 'react-router';

function EmployeeCreateAccountForm({ token }) {

    let dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    const [ data, setData ] = useState({});
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getEmail()
    }, [])
    
    function getEmail() {
        getEmailFromToken(token)
        .then((res) => {
            setData({...data, email: res.data.email})
            setLoading(false)
        })
    }

    function _createUser() {

        setLoading(true);

        const toSend = {
            token: token,
            password: data.password,
            password2: data.password2
        }

        const payload = jsonToFormData(toSend);

        createUser(payload)
        .then((res) => {
            if(res.data.type === 'success') {
                dispatch(setMessage(res.data));
                history.push('/');
                //location.replace('https://app.sportjoy.cz')
                setLoading(false);
            } else {
                dispatch(setMessage(res.data));
                setLoading(false);
            }
        })
        .catch((err) => {
            dispatch(setMessage({type: 'error', message: 'Vyskytla se chyba. Zkuste to prosím později.'}));
        })
    }

    function onChange(e, val) {
        setData({...data, [e.target.name]: e.target.value})
    }

    return (
        <Form loading={loading} onSubmit={_createUser}>
            <Form.Input 
                type="email"
                name="email"
                label="Email"
                value={data.email}
                onChange={onChange}
                required
                disabled
            />
            <Form.Input 
                type="password"
                name="password"
                label="Heslo"
                value={data.password}
                onChange={onChange}
                required
            />
            <Form.Input 
                type="password"
                name="password2"
                label="Heslo znovu"
                value={data.password2}
                onChange={onChange}
                required
            />
            <Button primary fluid>Vytvořit účet</Button>
        </Form>
    )

}

export default EmployeeCreateAccountForm