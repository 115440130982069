import React from "react";
import { useSelector } from "react-redux";
import { Button, Loader } from "semantic-ui-react";

const UserBox = () => {
  let { user, loading } = useSelector((state) => state.user);

  if (!loading && user !== null) {
    return (
      <span>
        Přihlášen/a jako <strong>{user.user_email}</strong>
      </span>
    );
  }

  if (!loading && user === null) {
    return (
      <Button fluid as="a" href="/login">
        Přihlásit se
      </Button>
    );
  }

  if (loading) {
    return <Loader active size="small" />;
  }
};

export default UserBox;
