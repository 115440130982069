import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Button, Form } from "semantic-ui-react";
import { setMessage } from "../../../actions/messageActions";
import { getToken } from "../../../api";
import { changeEmail } from "../../../api/parents";
import { jsonToFormData } from "../../../tools";

function ChangeEmailForm() {
  let { id } = useParams();
  let dispatch = useDispatch();
  const [data, setData] = useState({
    id_user: id,
  });
  const [loading, setLoading] = useState(false);

  function onChange(e, target) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function submit() {
    setLoading(true);

    getToken().then((token) => {
      const payload = {
        ...data,
        token: token,
      };

      const fd = jsonToFormData(payload);

      changeEmail(fd)
        .then((res) => {
          dispatch(setMessage(res.data));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    });
  }

  return (
    <Form onSubmit={submit} loading={loading}>
      <Form.Input
        label="Nový email"
        type="email"
        name="new_email"
        placeholder="Zadejte nový email"
        required
        onChange={onChange}
      />
      <input type="hidden" name="id_user" value={id} />
      <div className="text-center">
        <Button primary>Změnit email</Button>
      </div>
    </Form>
  );
}

export default ChangeEmailForm;
