import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getCheckboxes } from '../../../../api/instructors';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom'

function InstructorsDropdownFilter({ group, onChange, placeholder }) {

    const [ options, setOptions ] = useState([]);
    const [ isLoading, setLoading ] = useState(true);
    const location = useLocation();
    const parsed = queryString.parse(location.search);
    

    useEffect(() => {
        getOptions();
    }, [])

    function getOptions() {
        getCheckboxes(group)
        .then((res) => {
            setOptions(res.data.data);
            setLoading(false)
        })
        .catch((err) => {
            alert('Nepodařilo se získat data pro filter ' + group + ' z důvodu:' + err.message);
            setLoading(false)
        })
    }

    return (
        <Dropdown
            name={group}
            options={options}
            placeholder={placeholder}
            selection
            onChange={onChange}
            value={parsed[group] ? parsed[group] : null}
            fluid
            clearable
            loading={isLoading}
        />
    )

}

export default InstructorsDropdownFilter