import React, { useState } from 'react';
import { Modal, Form, Button } from 'semantic-ui-react';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';
import "react-datepicker/dist/react-datepicker.css";
import { addDate, getToken } from '../../api';
import moment from 'moment';
import { jsonToFormData } from '../../tools';

function AddDateModal({ group_date, refresh }) {

    const [ data, setData ] = useState({});
    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    function onChangeDate(date) {
        setData({
            ...data,
            date: date
        })
    }

    function submit() {

        setLoading(true);

        getToken().then((token) => {
            const submitData = {
                group_date: group_date,
                date: moment(data.date).format('YYYY-MM-DD'),
                token: token
            }

            const fd = jsonToFormData(submitData);

            addDate(fd)
            .then((res) => {
                console.log(res);
                setLoading(false);
                refresh();
                setOpen(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err.message);
                refresh();
            })
        })
        
    }

    return (
        <Modal trigger={<Button primary onClick={() => setOpen(true)}>Přidat termín</Button>} open={open} onClose={() => setOpen(false)} style={{overflow: 'visible'}}>
            <Modal.Header>Přidat termín</Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Field>
                        <label>Zvolte datum</label>
                        <DatePicker 
                            selected={data.date}
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            locale="cs"
                            dateFormat="dd.MM.yyyy"
                            onChange={date => onChangeDate(date)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Zavřít</Button>
                <Button primary onClick={submit} loading={loading} disabled={loading}>Uložit</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddDateModal