import axios from "axios";
import { url } from "../config";

export function getEmailTypes() {
  return axios({
    method: "get",
    url: url.api + `emails/get-types-list/?timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getTypeContent(id) {
  return axios({
    method: "get",
    url:
      url.api +
      `emails/get-type-content/?id_email=${id}&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function updateEmailType(data) {
  return axios({
    method: "post",
    url: url.api + "emails/update-email-type/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}
