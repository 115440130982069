import { url } from "../config";
import axios from "axios";
import firebase from "firebase";

export function getToken() {
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then((idToken) => {
      return idToken;
    })
    .catch((error) => {
      return error;
    });
}

export function intToBool(int) {
  return int === 0 ? false : true;
}

export function shortText(str, length) {
  var emp = "";
  if (str.length > length) emp = "...";

  return str.substring(0, length) + emp;
}

export function formatMoney(number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;

  var sign = number < 0 ? "-" : "";
  var i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
}

//Users
export function getUserInfo(id) {
  return fetch(url.api + "users/get-user/" + id + "/0/").then((data) => {
    return data.json();
  });
}

//Locations
export function getLocations() {
  return fetch(url.api + "locations/get/").then((data) => {
    return data.json();
  });
}

export function getCampsFilter(type, admin = 0) {
  return fetch(url.api + "camps/get-camps-filter/" + type + "?admin=" + admin, {
    credentials: "include",
  }).then((data) => {
    return data.json();
  });
}

export function getCampsFilterAdmin() {
  return fetch(url.api + "camps/get-camps-filter-admin/").then((data) => {
    return data.json();
  });
}

export function getLocationsFilterAdmin() {
  return fetch(url.api + "locations/get-filter-admin/").then((data) => {
    return data.json();
  });
}

export function getLocationItem(id) {
  return fetch(url.api + "locations/item/" + id).then((data) => {
    return data.json();
  });
}

export function toggleLocationState(id) {
  return fetch(url.api + "locations/toggle-state/" + id).then((data) => {
    return data.json();
  });
}

export function deleteLocation(id, token) {
  return fetch(url.api + "locations/delete/" + id + "?token=" + token).then(
    (data) => {
      return data.json();
    }
  );
}

export function getEmbedDatesFilterAdmin(type) {
  return fetch(
    url.api +
      "dates/get-embed-filter-admin/" +
      type +
      `&timestamp=${new Date().getTime()}`
  ).then((data) => {
    return data.json();
  });
}

export function uploadLocationImage(id, file) {
  return axios.post(url.api + "locations/upload/" + id, file);
}

export function updateLocation(id, data) {
  return fetch(url.api + "locations/update/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateLocationEmails(id, data) {
  return fetch(url.api + "locations/update-emails/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateLocationSecondary(id, data) {
  return fetch(url.api + "locations/update-secondary/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function createLocation(data) {
  return fetch(url.api + "locations/create/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Organizations
export function getOrganizations() {
  return fetch(url.api + "organizations/get/").then((data) => {
    return data.json();
  });
}

export function getOrganizationItem(id) {
  return fetch(url.api + "organizations/item/" + id).then((data) => {
    return data.json();
  });
}

export function updateOrganization(id, data) {
  return fetch(url.api + "organizations/update/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function createOrganization(data) {
  return fetch(url.api + "organizations/create/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function toggleOrganizationState(id) {
  return fetch(url.api + "organizations/toggle-state/" + id).then((data) => {
    return data.json();
  });
}

export function updateOrganizationSecondary(id, data) {
  return fetch(url.api + "organizations/update-secondary/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function deleteOrganization(id) {
  return fetch(url.api + "organizations/delete/" + id).then((data) => {
    return data.json();
  });
}

// Users
export function getUsersOrganization() {
  return fetch(url.api + "users/get-organizations/").then((data) => {
    return data.json();
  });
}

export function logUser(data) {
  return fetch(url.api + "users/create/test/0", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateUser(data) {
  return axios({
    method: "post",
    url: url.api + "users/update/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getUsers(query) {
  return fetch(
    url.api + `users/get-users/?${query}&timestamp=${new Date().getTime()}`
  ).then((data) => {
    return data.json();
  });
}

export function getUser(uid) {
  return fetch(url.api + "users/get-user/" + uid + "/0").then((data) => {
    return data.json();
  });
}

// Camps
export function getCamps() {
  return fetch(url.api + "camps/get/").then((data) => {
    return data.json();
  });
}

export function getCamp(id) {
  return fetch(url.api + "camps/item/" + id).then((data) => {
    return data.json();
  });
}

export function getTypesOpts() {
  return fetch(url.api + "camps/get-camps-types/").then((data) => {
    return data.json();
  });
}

export function createCamp(data) {
  return fetch(url.api + "camps/create/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateCamp(id, data) {
  return fetch(url.api + "camps/update/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function toggleCampState(id) {
  return fetch(url.api + "camps/toggle-state/" + id).then((data) => {
    return data.json();
  });
}

export function uploadCampImage(id, file) {
  return axios.post(url.api + "camps/upload/" + id, file);
}

export function updateCampSecondary(id, data) {
  return fetch(url.api + "camps/update-secondary/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Dates emails
export function getPreviousEmail(data) {
  return axios({
    method: "post",
    url: url.api + "dates/email-previous/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getEmailsList(data) {
  return axios({
    method: "post",
    url: url.api + "dates/emails-list/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function sendDateEmails(data) {
  return axios({
    method: "post",
    url: url.api + "dates/send-email/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function deleteAttachment(data) {
  return axios({
    method: "post",
    url: url.api + "dates/delete-email-attachment/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

//Dates
export function getDates(access) {
  return fetch(url.api + "dates/get/?access=" + access, {
    credentials: "include",
  }).then((data) => {
    return data.json();
  });
}

export function deleteDate(id, token) {
  return fetch(url.api + "dates/delete/" + id + "?token=" + token).then(
    (data) => {
      return data.json();
    }
  );
}

export function getMinAge(type) {
  return fetch(url.api + "dates/get-min/" + type).then((data) => {
    return data.json();
  });
}

export function getMaxAge(type) {
  return fetch(url.api + "dates/get-max/" + type).then((data) => {
    return data.json();
  });
}

export function getDatesHobby(type, camp = null) {
  return fetch(url.api + "dates/get-hobby/" + type + "?camp=" + camp).then(
    (data) => {
      return data.json();
    }
  );
}

export function addDate(data) {
  return axios({
    method: "post",
    url: url.api + "dates/add-date/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getDatesMultiple(type) {
  return fetch(url.api + "dates/get-multiple/" + type).then((data) => {
    return data.json();
  });
}

export function getDatesAdmin(year) {
  return fetch(url.api + "dates/get-admin/" + year).then((data) => {
    return data.json();
  });
}

export function getDate(id) {
  return fetch(
    url.api + "dates/item/" + id + `?timestamp=${new Date().getTime()}`
  ).then((data) => {
    return data.json();
  });
}

export function dateFilterFrom(date, admin = 0, type = 1) {
  return fetch(
    url.api +
      "dates/filter-from/?date=" +
      date +
      "&admin=" +
      admin +
      "&type=" +
      type,
    {
      credentials: "include",
    }
  ).then((data) => {
    return data.json();
  });
}

export function dateFilterTo(date) {
  return fetch(url.api + "dates/filter-to/?date=" + date).then((data) => {
    return data.json();
  });
}

export function getLocationsValues(id) {
  return fetch(url.api + "dates/get-locations/").then((data) => {
    return data.json();
  });
}

export function getCampsValues(id) {
  return fetch(url.api + "dates/get-camps?id=" + id).then((data) => {
    return data.json();
  });
}

export function selectCamp(id) {
  return fetch(url.api + "dates/select/" + id).then((data) => {
    return data.json();
  });
}

export function createDate(data) {
  return fetch(url.api + "dates/create/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function toggleDateState(id) {
  return fetch(url.api + "dates/toggle-state/" + id).then((data) => {
    return data.json();
  });
}

export function updateDate(id, data) {
  return fetch(url.api + "dates/update/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateDateSecondary(id, data) {
  return fetch(url.api + "dates/update-secondary/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

//Applications
export function isAppPaid(id) {
  return axios({
    method: "get",
    url:
      url.api +
      "applications/is-paid/" +
      id +
      `&timestamp=${new Date().getTime()}`,
  });
}

export function getDots(id) {
  return axios({
    method: "get",
    url:
      url.api +
      "applications/get-dots/" +
      id +
      `&timestamp=${new Date().getTime()}`,
  });
}

export function updateDot(data) {
  return fetch(
    url.api + `applications/set-dot/?timestamp=${new Date().getTime()}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  ).then((response) => {
    return response.json();
  });
}

export function createApplication(data, type) {
  return fetch(url.api + "applications/create/" + type, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function unsetSub(data) {
  return fetch(url.api + "applications/unset-sub/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function toggleNewsletter(data) {
  return fetch(url.api + "applications/toggle-newsletter/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function togglePhotos(data) {
  return fetch(url.api + "applications/toggle-photos/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function getDateApplications(id) {
  return fetch(url.api + "applications/get-date/" + id).then((data) => {
    return data.json();
  });
}

export function getDateSubsApplications(id) {
  return fetch(url.api + "applications/get-date-subs/" + id).then((data) => {
    return data.json();
  });
}

export function getApplications(type, showAll, query) {
  return fetch(
    url.api + "applications/get/" + type + "/" + showAll + "?" + query
  ).then((data) => {
    return data.json();
  });
}

export function getDisabled(type) {
  return fetch(url.api + "applications/get-disabled/" + type).then((data) => {
    return data.json();
  });
}

export function togglePaid(id) {
  return fetch(url.api + "applications/toggle-paid/" + id).then((data) => {
    return data.json();
  });
}

export function getApplicationDetail(id) {
  return fetch(
    url.api +
      "applications/get-item/" +
      id +
      `?timestamp=${new Date().getTime()}`
  ).then((data) => {
    return data.json();
  });
}

export function getCouponOld(val) {
  return fetch(url.api + "coupons/get/" + val).then((data) => {
    return data.json();
  });
}

export function getRecommend(val) {
  return fetch(url.api + "coupons/recommend/" + val).then((data) => {
    return data.json();
  });
}

export function testCookie() {
  return fetch(url.api + "dates/test-cookie/", {
    credentials: "include",
  }).then((data) => {
    return data.json();
  });
}

/*export function cancelDate(id, idKid, email) {
    return fetch(url.api + 'applications/delete-date/' + id + '?id=' + idKid + '&email=' + email)
    .then((data) => { return data.json() })
}*/

export function updatePrice(data) {
  return fetch(url.api + "applications/update-price/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function cancelDate(data) {
  return fetch(url.api + "applications/delete-date/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function restoreDate(data) {
  return fetch(url.api + "applications/restore-date/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function setPaid(data) {
  return fetch(url.api + "applications/set-paid/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function sendPaymentInfo(id, token) {
  return fetch(
    url.api + "applications/send-payment-info/" + id + "/" + token
  ).then((data) => {
    return data.json();
  });
}

export function sendReminder(id, token) {
  return fetch(url.api + "applications/reminder/" + id + "/" + token).then(
    (data) => {
      return data.json();
    }
  );
}

export function sendConfirm(id, token) {
  return fetch(url.api + "applications/confirm/" + id + "/" + token).then(
    (data) => {
      return data.json();
    }
  );
}

export function sendConfirmAdd(data) {
  return fetch(url.api + "applications/confirm-additional/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function sendReminderAdd(data) {
  return fetch(url.api + "applications/reminder-additional/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateCoupon(data) {
  return fetch(url.api + "applications/update-coupon/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function disableApplication(data) {
  return fetch(url.api + "applications/disable/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function multipleDisable(data) {
  return fetch(url.api + "applications/multiple-disable/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function toggleFlag(id) {
  return fetch(url.api + "applications/toggle-flag/" + id).then((data) => {
    return data.json();
  });
}

export function toggleInvoice(data) {
  return fetch(url.api + "applications/toggle-invoice/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function addKid(data) {
  return fetch(url.api + "applications/add-kid/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function enableApplication(id) {
  return fetch(url.api + "applications/enable/" + id).then((data) => {
    return data.json();
  });
}

export function changeKidsDate(data) {
  return fetch(url.api + "applications/change-date/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function addKidsDate(data) {
  return fetch(url.api + "applications/add-date/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateParents(data) {
  return fetch(url.api + "applications/update-parents/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function updateKids(data) {
  return fetch(url.api + "applications/update-kids/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function searchApplications(q, state) {
  return fetch(url.api + "applications/search?q=" + q + "&state=" + state).then(
    (data) => {
      return data.json();
    }
  );
}

export function sendMultipleReminders(data) {
  return fetch(url.api + "applications/multiple-reminders/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function sendMultipleConfirms(data) {
  return fetch(url.api + "applications/multiple-confirms/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function lastTenApplications(data) {
  return fetch(url.api + "applications/get-last-ten/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Contacts
export function getContacts(data, id) {
  return fetch(url.api + "contacts/get/" + id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function searchContact(q) {
  return fetch(url.api + "contacts/search?q=" + q).then((data) => {
    return data.json();
  });
}

export function sendNewsletter(data) {
  return fetch(url.api + "messages/send-newsletter/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function getRecommendations() {
  return fetch(url.api + "recommendations/get/").then((data) => {
    return data.json();
  });
}

export function itemEmailHistory(data) {
  return fetch(url.api + "messages/get-item-email-history/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Stats
export function countApplications() {
  return fetch(url.api + "stats/count-applications/").then((data) => {
    return data.json();
  });
}

export function countApplicationsByType(type, sub) {
  return fetch(
    url.api + "stats/count-applications-by-type/" + type + "?sub=" + sub
  ).then((data) => {
    return data.json();
  });
}

export function countKids() {
  return fetch(url.api + "stats/count-kids/").then((data) => {
    return data.json();
  });
}

export function countKidsByType(type, sub) {
  return fetch(
    url.api + "stats/count-kids-by-type/" + type + "?sub=" + sub
  ).then((data) => {
    return data.json();
  });
}

export function todayApplications() {
  return fetch(url.api + "stats/today-applications/").then((data) => {
    return data.json();
  });
}

export function todayApplicationsByType(type, sub) {
  return fetch(
    url.api + "stats/today-applications-by-type/" + type + "?sub=" + sub
  ).then((data) => {
    return data.json();
  });
}

export function todayKids() {
  return fetch(url.api + "stats/today-kids/").then((data) => {
    return data.json();
  });
}

export function todayKidsByType(type, sub) {
  return fetch(
    url.api + "stats/today-kids-by-type/" + type + "?sub=" + sub
  ).then((data) => {
    return data.json();
  });
}

export function paidUnpaid() {
  return fetch(url.api + "stats/paid/").then((data) => {
    return data.json();
  });
}

export function paidUnpaidByType(type) {
  return fetch(url.api + "stats/paid-by-type/" + type).then((data) => {
    return data.json();
  });
}

export function getNumberPaid() {
  return fetch(url.api + "stats/number-paid/").then((data) => {
    return data.json();
  });
}

export function getNumberPaidByType(type) {
  return fetch(url.api + "stats/number-paid-by-type/" + type).then((data) => {
    return data.json();
  });
}

export function unsubscribe(data) {
  return fetch(url.api + "messages/unsubscribe/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Coupons
export function createCouponOld(data) {
  return fetch(url.api + "coupons/create/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function deleteCoupon(data) {
  return fetch(url.api + "coupons/delete/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function getCouponsList() {
  return fetch(url.api + "coupons/get-list/").then((data) => {
    return data.json();
  });
}

export function getCouponsStats() {
  return fetch(url.api + "coupons/get-stats/").then((data) => {
    return data.json();
  });
}

// Payment
export function requestPaymentLink(data) {
  return fetch(url.api + "/payment/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Additional payments
export function getAddPayments(data) {
  return fetch(url.api + "applications/get-add-payments", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function createAddPayment(data) {
  return fetch(url.api + "applications/create-add-payment", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function deleteAddPayment(data) {
  return fetch(url.api + "applications/delete-add-payment", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function confirmAddPayment(data) {
  return fetch(url.api + "applications/confirm-add-payment", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

// Vouchers
export function getVoucherTypes() {
  return fetch(url.api + "vouchers/get-voucher-types/").then((data) => {
    return data.json();
  });
}

export function createVoucherOrder(data) {
  return fetch(url.api + "vouchers/create-voucher-order", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function getVouchersOrders(data) {
  return fetch(url.api + "vouchers/get-vouchers-orders", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function toggleVoucherPaid(data) {
  return fetch(url.api + "vouchers/toggle-paid", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function deleteVoucher(data) {
  return fetch(url.api + "vouchers/delete-voucher", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

export function searchVouchers(q) {
  return fetch(url.api + "vouchers/search?q=" + q).then((data) => {
    return data.json();
  });
}

// Export data
export function exportPhotoAgreement(data) {
  return fetch(url.api + "export/export-photo", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
}

/*** Pdf */
export function getApplicationBills(id, token) {
  return axios({
    method: "get",
    url: url.api + "pdf/get-bills/" + id + "/" + token,
  });
}

export function createBill(data) {
  return axios({
    method: "post",
    url: url.api + "pdf/create-bill",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function sendBill(id, token) {
  return axios({
    method: "get",
    url: url.api + "messages/send-bill/" + id + "/" + token,
  });
}

export function getApplicationInvoices(id, token) {
  return axios({
    method: "get",
    url: url.api + "pdf/get-invoices/" + id + "/" + token,
  });
}

export function sendInvoice(id, token) {
  return axios({
    method: "get",
    url: url.api + "messages/send-invoice/" + id + "/" + token,
  });
}

export function createInvoice(data) {
  return axios({
    method: "post",
    url: url.api + "pdf/create-invoice",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

/*** Versions */
export function checkVersion() {
  return axios({
    method: "get",
    url: url.api + "versions/check/",
  });
}

export function updateEcomail(url) {
  return axios({
    method: "get",
    url: url,
  });
}

export function getEmailRecipientsHistory(data) {
  return axios({
    method: "post",
    url: url.api + "dates/get-email-history/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

/** Types emails */
export function getTypeEmail(data) {
  return axios({
    method: "post",
    url: url.api + "types/get-email-data/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function saveTypeEmail(data) {
  return axios({
    method: "post",
    url: url.api + "types/save-email-data/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

/** Coupons */
export function createCoupon(data) {
  return axios({
    method: "post",
    url: url.api + "coupons/create/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getCoupon(data) {
  return axios({
    method: "post",
    url: url.api + "coupons/get/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

/*** Filters */
export function getLocationsFilter(data) {
  return axios({
    method: "post",
    url: url.api + "locations/get-filter/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getEmbedDatesFilter(data) {
  return axios({
    method: "post",
    url: url.api + "dates/get-embed-filter/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getLocationsFilterOld(type) {
  return fetch(url.api + "locations/get-filter/" + type).then((data) => {
    return data.json();
  });
}

/*** Calendar */
export function getCalendar(data) {
  return axios({
    method: "post",
    url: url.api + "dates/get-calendar/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

/*** Invoicing */
export function prefillCompanyInfo(data) {
  return axios({
    method: "post",
    url: url.api + "invoicing/prefill-company/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function updateInvoiceInfo(data) {
  return axios({
    method: "post",
    url: url.api + "invoicing/update-data/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getPriceIncreases() {
  return fetch(url.api + "payment/get-increases/").then((data) => {
    return data.json();
  });
}

export function sendIncreases() {
  return fetch(url.api + "payment/send-increase/").then((data) => {
    return data.json();
  });
}

export function resendIncreases(id) {
  return fetch(url.api + "payment/resend-increase/" + id).then((data) => {
    return data.json();
  });
}

export function getAttendanceList(id) {
  return fetch(
    url.api +
      "attendance/get-list/?id_date=" +
      id +
      `&timestamp=${new Date().getTime()}`
  ).then((data) => {
    return data.json();
  });
}

export function updateAttendance(data) {
  return axios({
    method: "post",
    url: url.api + "attendance/update-data/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function validateReferral(code) {
  return fetch(
    url.api +
      `applications/validate-referral/?timestamp=${new Date().getTime()}&code=${code}`,
    {
      credentials: "include",
    }
  ).then((data) => {
    return data.json();
  });
}

export function disableUser(data) {
  return axios({
    method: "post",
    url: url.api + "employees/disable-user/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function updateReferral(data) {
  return axios({
    method: "post",
    url: url.api + "employees/update-referral/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}