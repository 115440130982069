import React, { useState } from "react";
import {
  Popup,
  Table,
  Button,
  Icon,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import _ from "loadsh";
import moment from "moment";
import { StateIcon } from "../../../styles";
import { BubbleCheckValue, BulkActionBar } from "../../../styles/instructors";
import { bulkAction } from "../../../api/instructors";
import { jsonToFormData } from "../../../tools";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../actions/messageActions";
import DeleteInstructorsApplication from "../../modals/DeleteInstructorsApplication";

function InstructorsListTable({ data, loading, refresh }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({});
  const [isCheckedAll, setCheckedAll] = useState(false);

  function onChangeCheckbox(e, val) {
    setChecked({
      ...checked,
      [val.name]: val.checked,
    });
  }

  function checkAll() {
    let ids = {};
    data.map((item) => {
      ids = {
        ...ids,
        [item.id]: !isCheckedAll,
      };
    });
    setChecked(ids);
    setCheckedAll(!isCheckedAll);
  }

  function renderUni(data) {
    var uni = "";

    if (data.ftvs === "true") {
      uni += "FTVS, ";
    }

    if (data.pedf_uk === "true") {
      uni += "PedF UK, ";
    }

    if (data.pedf_mu === "true") {
      uni += "PedF MU, ";
    }

    if (data.other === "true") {
      uni += "jiná: " + data.other_value;
    }

    if (data.none === "true") {
      return <BubbleCheckValue>Žádná</BubbleCheckValue>;
    }

    return uni;
  }

  function bulkUpdate(type, value) {
    const prepData = {
      type: type,
      value: value,
      items: Object.fromEntries(
        Object.entries(checked).filter(([key, value]) => value === true)
      ),
    };

    const dataToSend = {
      data: [prepData],
    };

    const fd = jsonToFormData(dataToSend);

    bulkAction(fd).then((res) => {
      dispatch(setMessage(res.data));
      refresh();
    });
  }

  function renderInterests(data, count) {
    return (
      <Popup
        trigger={<Button icon>{count}</Button>}
        on="click"
        position="left center"
        content={
          <div>
            {count !== 0 &&
              data.map((item, index) => {
                return (
                  <>
                    <span>{item}</span>
                    {index !== data.length - 1 && <Divider />}
                  </>
                );
              })}
          </div>
        }
      />
    );
  }

  return (
    <>
      <Table striped className="main-table mobile" unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              <Checkbox onChange={checkAll} />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>#</Table.HeaderCell>
            <Table.HeaderCell>Jméno</Table.HeaderCell>
            <Table.HeaderCell>Telefon</Table.HeaderCell>
            <Table.HeaderCell>Univerzita</Table.HeaderCell>
            <Table.HeaderCell>Věk</Table.HeaderCell>
            <Table.HeaderCell>Zájem</Table.HeaderCell>
            <Table.HeaderCell>Vytvořeno</Table.HeaderCell>
            <Table.HeaderCell>Stav</Table.HeaderCell>
            <Table.HeaderCell>Akce</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(
            data,
            ({
              id,
              user_first_name,
              user_last_name,
              user_email,
              phone,
              title,
              created,
              state,
              university,
              interest_count,
              interest,
              age,
            }) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <Checkbox
                    onChange={(e, val) => onChangeCheckbox(e, val)}
                    name={id}
                    checked={checked[id]}
                  />
                </Table.Cell>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>
                  <span>
                    <strong>
                      {title} {user_first_name} {user_last_name}
                    </strong>
                  </span>
                  <br />
                  <span>{user_email}</span>
                </Table.Cell>
                <Table.Cell>{phone}</Table.Cell>
                <Table.Cell>{renderUni(university)}</Table.Cell>
                <Table.Cell>{age}</Table.Cell>
                <Table.Cell>
                  {renderInterests(interest, interest_count)}
                </Table.Cell>
                <Table.Cell>
                  {moment(created).format("D.M.YYYY H:mm")}
                </Table.Cell>
                <Table.Cell>
                  <StateIcon state={state} />
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    trigger={
                      <DeleteInstructorsApplication
                        displayType="icon"
                        id={id}
                        refresh={refresh}
                      />
                    }
                    content="Smazat přihlášku"
                  />
                  <Popup
                    trigger={
                      <Button
                        icon
                        href={
                          `${process.env.PUBLIC_URL}/manage/instructors/detail/` +
                          id
                        }
                      >
                        <Icon name="pencil alternate" />
                      </Button>
                    }
                    content="Detail přihlášky"
                  />
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
      <BulkActionBar
        visible={
          !_.isEmpty(checked) &&
          !Object.values(checked).every((item) => item === false)
        }
      >
        <Button onClick={() => bulkUpdate("state-change", 1)} color="green">
          Změna stavu: Aktivní
        </Button>
        <Button onClick={() => bulkUpdate("state-change", 0)} color="red">
          Změna stavu: Storno
        </Button>
        <Button onClick={() => bulkUpdate("state-change", -1)} color="yellow">
          Změna stavu: Možná
        </Button>
      </BulkActionBar>
    </>
  );
}

export default InstructorsListTable;
