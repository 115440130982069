import React, { Component } from 'react'
import { Card, CardHeader, CardBody, FormButtonGroup } from '../../styles'
import { Form, Input, Dropdown, Button, Checkbox } from 'semantic-ui-react'
import RichTextEditor from 'react-rte'
import { getToken, sendNewsletter } from '../../api'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'

const contactGroups = [
    {key: '1', value: 0, text: 'Všem (i ručně přidaným)'},
    {key: '2', value: 1, text: 'Všem přihlášeným (se souhlasem)'},
    {key: '3', value: 2, text: 'Všem přihlášeným'},
    {key: '4', value: 4, text: 'Všem včetně stornovaných'}
]

class NewsletterView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            newsletter: {
                heading: '',
                subject: '',
                message: RichTextEditor.createEmptyValue(),
                send_test: false,
                test_email: '',
                contact_group: ''
            },
            errors: [],
            loadingSending: false
        })
    }

    onChangeRte = (value) => {
        this.setState({
            newsletter: {
                ...this.state.newsletter,
                message: value
            }
        });
    }

    onChangeNewsletter = (e) => this.setState({
        newsletter: {
            ...this.state.newsletter,
            [e.target.name]: e.target.value
        }
    })

    handleCheckbox = (e, data) => {
        this.setState({
            newsletter: {
                ...this.state.newsletter,
                [data.name]: data.checked
            }
        })
    }

    onChangeSelect = (e, data) => {
        this.setState({
            newsletter: {
                ...this.state.newsletter,
                [data.name]: data.value
            }
        })
    }

    submitNewsletter = () => {

        this.setState({
            loadingSending: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                subject: this.state.newsletter.subject,
                heading: this.state.newsletter.heading,
                message: this.state.newsletter.message.toString('html')
            }

            sendNewsletter(data)
            .then((res) => { 
                this.props.setMessage(res)
                this.closeNewsletter()

                this.setState({
                    newsletter: {
                        heading: '',
                        subject: '',
                        message: RichTextEditor.createEmptyValue()
                    },
                    loadingSending: false
                })
             })
        })

    }

    handleSubmit = () => {

        this.setState({
            loadingSending: true
        })

        if(this.state.newsletter.send_test && this.state.newsletter.test_email === '') {
            const msg = {
                type: 'error',
                text: 'Vyplňte prosím adresu testovacího emailu.'
            }
            this.props.setMessage(msg)

            this.setState({
                loadingSending: false
            })

        } else if(this.state.newsletter.subject === '') {
            const msg = {
                type: 'error',
                text: 'Vyplňte prosím předmět emailu.'
            }
            this.props.setMessage(msg)

            this.setState({
                loadingSending: false
            })

        } else if(this.state.newsletter.heading === '') {
            const msg = {
                type: 'error',
                text: 'Vyplňte prosím nadpis emailu.'
            }
            this.props.setMessage(msg)

            this.setState({
                loadingSending: false
            })

        } else if(this.state.newsletter.contact_group === '' && !this.state.newsletter.send_test) {
            const msg = {
                type: 'error',
                text: 'Vyberte prosím, komu chcete newsletter odeslat.'
            }
            this.props.setMessage(msg)

            this.setState({
                loadingSending: false
            })

        } else if(this.state.newsletter.message.toString('html') === '') {
            const msg = {
                type: 'error',
                text: 'Vyplňte prosím zprávu emailu.'
            }
            this.props.setMessage(msg)

            this.setState({
                loadingSending: false
            })

        } else {
            getToken().then((token) => {
                const data = {
                    token: token,
                    subject: this.state.newsletter.subject,
                    heading: this.state.newsletter.heading,
                    send_test: this.state.newsletter.send_test,
                    test_email: this.state.newsletter.test_email,
                    message: this.state.newsletter.message.toString('html'),
                    contact_group: this.state.newsletter.contact_group
                }
    
                console.log(data)
                sendNewsletter(data)
                .then((res) => { 
                    this.props.setMessage(res)

                    this.setState({
                        loadingSending: false
                    })
                })
            })
        }
        
    }

    clearInputs = () => {
        this.setState({
            newsletter: {
                heading: '',
                subject: '',
                send_test: false,
                test_email: '',
                contact_group: '',
                message: RichTextEditor.createEmptyValue()
            },
            loadingSending: false
        })
    }

    _renderSelectedGroupMessage = () => {
        if(this.state.newsletter.contact_group === 0) {
            return (
                <p className="small mt-1">Vybrali jste veškeré kontakty (včetně ručně importovaných), které souhlasí se zasíláním newsletterů.</p>
            )
        } else if(this.state.newsletter.contact_group === 1) {
            return (
                <p className="small mt-1">Vybrali jste přihlášené kontakty, které souhlasí se zasíláním newsletterů.</p>
            )
        } else if(this.state.newsletter.contact_group === 2) {
            return (
                <p className="small mt-1">Vybrali jste veškeré přihlášené kontakty</p>
            )
        }
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <h2>Nový newsletter</h2>
                </CardHeader>
                <CardBody>
                    <Form loading={this.state.loadingSending}>
                        <Form.Field>
                            <label>Předmět newsletteru</label>
                            <Input 
                                type='text'
                                name='subject'
                                onChange={this.onChangeNewsletter}
                                value={this.state.newsletter.subject}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Nadpis</label>
                            <Input 
                                type='text'
                                name='heading'
                                onChange={this.onChangeNewsletter}
                                value={this.state.newsletter.heading}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Zpráva</label>
                            <RichTextEditor
                                value={this.state.newsletter.message}
                                onChange={this.onChangeRte}
                                editorClassName="demo-editor"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Komu odeslat</label>
                            <Dropdown
                                name='contact_group'
                                options={contactGroups}
                                placeholder='Vyberte skupinu kontaktů'
                                selection
                                onChange={this.onChangeSelect}
                                fluid
                                value={this.state.newsletter.contact_group}
                            />
                            {this.state.newsletter.contact_group !== '' && this._renderSelectedGroupMessage()}
                        </Form.Field>
                        <hr />
                        <Form.Field>
                        <label>Zkušební email</label>
                        <div class="row align-items-center">
                            <div className="col-sm-6 ">
                                <Form.Field>
                                    <Checkbox onChange={this.handleCheckbox} name='send_test' label={'Odeslat zkušební email'} checked={this.state.newsletter.send_test} />
                                </Form.Field>
                            </div>
                            <div className="col-sm-6">
                                <Form.Field>
                                    <Input 
                                        type='text'
                                        name='test_email'
                                        placeholder='Zadejte email pro zkušební odeslání'
                                        onChange={this.onChangeNewsletter}
                                        value={this.state.newsletter.test_email}
                                    />
                                </Form.Field>
                            </div>
                        </div>
                        </Form.Field>
                        <hr />
                        <FormButtonGroup>
                            <Button onClick={this.clearInputs} ghost>Vymazat</Button>
                            <Button onClick={this.handleSubmit} positive>Odeslat newsletter</Button>
                        </FormButtonGroup>
                    </Form>
                </CardBody>
            </Card>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(NewsletterView)