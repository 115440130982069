import React from 'react'
import { useParams } from 'react-router'
import { Card, CardBody, CardHeader } from '../../../styles'
import { FormWrapper, PageWrapper } from '../../../styles/employees'
import EmployeeCreateAccountForm from '../../forms/employees/EmployeeCreateAccountForm'
import EmployeeNewPassword from '../../forms/employees/EmployeeNewPassword'
import ResetPasswordForm from '../../forms/employees/ResetPasswordForm'

function EmployeeNewPasswordView() {

    return (
        <>
        <PageWrapper>
            <FormWrapper>
                <Card>
                    <CardHeader>
                        <span>Zvolte si nové heslo</span>
                    </CardHeader>
                    <CardBody>
                        <EmployeeNewPassword />
                    </CardBody>
                </Card>
            </FormWrapper>
        </PageWrapper>
        </>
    )
}

export default EmployeeNewPasswordView