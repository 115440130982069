import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import AddCreditForm from "../forms/parents/AddCreditForm";

const AddCreditModal = ({ id_user, refresh, type }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Modal
      open={isOpen}
      trigger={
        <Button onClick={() => setOpen(true)} fluid primary={type !== "remove"}>
          {type !== "remove" ? "Připsat kredity" : "Odebrat kredity"}
        </Button>
      }
      onClose={() => setOpen(false)}
      size="tiny"
    >
      <Modal.Header>
        {type !== "remove" ? "Připsat" : "Odebrat"} kredity
      </Modal.Header>
      <Modal.Content>
        <AddCreditForm
          id_user={id_user}
          refresh={refresh}
          close={() => setOpen(false)}
          type={type}
        />
      </Modal.Content>
    </Modal>
  );
};

export default AddCreditModal;
