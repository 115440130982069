import * as types from './types'
import { getUserInfo } from '../api'

export function setMessage(item) {

    const id = Math.floor(Math.random() * 101);

    let newItems = Object.assign({}, item, { id: id })

    return (dispatch, getState) => {
        dispatch({type: types.SET_MESSAGE, items: newItems})

        setTimeout(() => {
            dispatch({type: types.UNSET_MESSAGE, id: id})
        }, 4000);
    }
}

export function saveUser(id) {
    return (dispatch, getState) => {
        dispatch({type: types.USER_LOGGING_IN})

        getUserInfo(id)
        .then((data) => {
            dispatch({type: types.USER_LOGGED_IN, user: data})
        })
    }
}

export function setNotLogged() {
    return (dispatch, getState) => {
        dispatch({type: types.USER_NOT_LOGGED_IN})
    } 
}