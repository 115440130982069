import React, { Component } from "react";
import { togglePaid, toggleFlag } from "../../api";
import { Table, Button, Icon, Modal } from "semantic-ui-react";
import _ from "loadsh";
import moment from "moment";
import { setMessage } from "../../actions/messageActions";
import { connect } from "react-redux";
import { MultipleActions } from "../../styles";

class LastTenApplicationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      idToDelete: 2,
      toChangePayment: "",
      modalPayment: false,
      selectedIds: [],
      loading: {
        reminder: false,
        confirm: false,
        disable: false,
      },
    };
  }

  setPaidHandler = (id) => {
    this.setState({
      toChangePayment: id,
      modalPayment: true,
    });
  };

  confirmSetPaid = () => {
    if (this.state.toChangePayment !== "") {
      togglePaid(this.state.toChangePayment).then(() => {
        this.props.refresh();
        this.setState({
          toChangePayment: "",
        });
        this.handleCloseModalPayment();
      });
    }
  };

  toggleModal = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      idToDelete: !this.state.showModal ? id : "",
    });
  };

  toggleFlagHandler = (id) => {
    toggleFlag(id).then((res) => {
      this.props.setMessage(res);
      this.props.refresh();
    });
  };

  handleCloseModalPayment = () => this.setState({ modalPayment: false });

  _renderModalPayment = () => {
    return (
      <Modal
        open={this.state.modalPayment}
        onClose={this.handleCloseModalPayment}
      >
        <Modal.Header>Změnit stav platby</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <p>Opravdu chcete změnit stav platby za tuto přihlášku?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={this.handleCloseModalPayment}>
            Zpět
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Ano, chci změnit"
            onClick={() => this.confirmSetPaid()}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  _renderMultipleSelectActions = () => {
    return (
      <MultipleActions>
        <div className="row">
          <div className="col-sm-1">
            <p>Vybráno: {this.state.selectedIds.length}</p>
          </div>
          <div className="col-sm-9">
            <Button
              color="red"
              onClick={() => this.multipleRemindersHandler("reminder")}
              loading={this.state.loading.reminder}
            >
              Odeslat upomínku
            </Button>
            <Button
              color="green"
              onClick={() => this.multipleRemindersHandler("confirm")}
              loading={this.state.loading.confirm}
            >
              Odeslat potvrzení
            </Button>
            <Button
              className="ghost"
              onClick={() => this.multipleRemindersHandler("disable")}
              loading={this.state.loading.disable}
            >
              Stornovat přihlášky
            </Button>
          </div>
          <div className="col-sm-2 text-right">
            <Button onClick={this.clearSelected} className="ghost">
              Vyprázdnit
            </Button>
          </div>
        </div>
      </MultipleActions>
    );
  };

  render() {
    const { items } = this.props;

    return (
      <div>
        <Table striped className="main-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>

              <Table.HeaderCell>Jméno rodiče</Table.HeaderCell>

              <Table.HeaderCell>Kontakt</Table.HeaderCell>

              <Table.HeaderCell>Vytvořeno</Table.HeaderCell>

              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(
              items,
              ({
                id_application,
                parent_name,
                state,
                parent_email,
                parent_phone,
                created,
                priority,
              }) => (
                <Table.Row key={id_application}>
                  <Table.Cell>{Number(id_application)}</Table.Cell>
                  <Table.Cell>{parent_name}</Table.Cell>
                  <Table.Cell>
                    {parent_phone}
                    <br />
                    {this.props.user.user_admin === 1 && parent_email}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(created).format("DD/MM/YYYY HH:mm")}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      icon
                      className={priority === 0 ? "ghost" : "priority-flag"}
                      onClick={() => this.toggleFlagHandler(id_application)}
                    >
                      <Icon name="flag" />
                    </Button>
                    <Button
                      icon
                      href={
                        `${process.env.PUBLIC_URL}/manage/applications/detail/` +
                        id_application
                      }
                    >
                      <Icon name="eye" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
        {this._renderModalPayment()}
        {this.state.selectedIds.length !== 0 &&
          this._renderMultipleSelectActions()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, { setMessage })(
  LastTenApplicationsTable
);
