import React from 'react'
import { Form, Input, Button, Dropdown } from 'semantic-ui-react'
import { FormButtonGroup } from '../../styles'
import moment from 'moment'

const yesNo = [
    { key: '0', value: 0, text: 'Ne' },
    { key: '1', value: 1, text: 'Ano' }
]

const EditDateForm = ({loading, submit, date, camps, locations, onChange, onChangeSelect, categories}) => {
    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <label>Vyberte druh aktivity</label>
                <Dropdown 
                    name='category'
                    placeholder='Vyberte možnost'
                    options={categories}
                    value={date.category}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <Form.Field>
                <label>Vyberte aktivitu</label>
                <Dropdown 
                    name='id_camp'
                    placeholder='Vyberte možnost'
                    options={camps}
                    value={date.id_camp}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    type='date'
                    name='date_from'
                    placeholder='Datum'
                    value={date.date_from ? moment(date.date_from).format("YYYY-MM-DD") : ''}
                    onChange={onChange}
                    required
                    label='Datum od'
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    type='date'
                    name='date_to'
                    placeholder='Datum'
                    value={date.date_to ? moment(date.date_to).format("YYYY-MM-DD") : ''}
                    onChange={onChange}
                    required
                    label='Datum do'
                />
            </Form.Field>
            {date.category !== 0 &&
            <>
            <Form.Field>
                <Form.Input 
                    type='time'
                    name='start_time'
                    placeholder='Čas konání'
                    value={date.start_time}
                    onChange={onChange}
                    label='Začátek konání'
                />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    type='time'
                    name='end_time'
                    placeholder='Čas konání'
                    value={date.end_time}
                    onChange={onChange}
                    label='Konec konání'
                />
            </Form.Field>
            <Form.Field>
                <label>Koná se ve škole</label>
                <Dropdown 
                    name='in_school'
                    placeholder='Vyberte možnost'
                    options={yesNo}
                    value={date.in_school}
                    onChange={onChangeSelect}
                    className={'state-active-' + date.in_school}
                    selection
                    search
                />
            </Form.Field>
            </>}

            {/*<Form.Field>
                <Form.Input 
                    type='number'
                    name='num_of_lectures'
                    placeholder='Počet lekcí'
                    value={date.num_of_lectures}
                    onChange={onChange}
                    required
                    label='Počet lekcí'
                />
            </Form.Field>*/}
            {date.category === 1 &&
            <Form.Field>
                <label>Pouze pro žáky školy</label>
                <Dropdown 
                    name='only_school_kids'
                    placeholder='Vyberte možnost'
                    options={yesNo}
                    value={date.only_school_kids}
                    onChange={onChangeSelect}
                    className={'state-active-' + date.only_school_kids}
                    selection
                />
            </Form.Field>}
            <Form.Field>
            <label>Pouze pro lektory</label>
            <Dropdown 
                name='only_employees'
                placeholder='Vyberte možnost'
                options={yesNo}
                value={date.only_employees}
                onChange={onChangeSelect}
                className={'state-active-' + date.only_employees}
                selection
            />
            </Form.Field>
            <Form.Field>
                <Form.Input 
                    type='number'
                    name='date_capacity'
                    placeholder='Kapacita termínu'
                    value={date.date_capacity}
                    onChange={onChange}
                    required
                    label='Kapacita termínu'
                />
            </Form.Field>
            <Form.Field>
                <label>Lokalita</label>
                <Dropdown 
                    name='id_location'
                    placeholder='Vyberte možnost'
                    options={locations}
                    value={date.id_location}
                    onChange={onChangeSelect}
                    selection
                    search
                />
            </Form.Field>
            <Form.Field>
                <label>Další lokality</label>
                <Dropdown 
                    name='more_locations'
                    placeholder='Vyberte možnost'
                    options={locations}
                    value={date.more_locations}
                    onChange={onChangeSelect}
                    selection
                    search
                    multiple
                />
            </Form.Field>
            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default EditDateForm