import React from "react";
import { useSelector } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";

const CreditsBox = () => {
  const { user } = useSelector((state) => state.user);

  const CreditsInfoModal = () => {
    return (
      <Modal trigger={<Button fluid>Co jsou kredity?</Button>} size="small">
        <Modal.Header>Co jsou kredity?</Modal.Header>
        <Modal.Content>
          <h3>Co jsou kredity?</h3>
          <p>
            Ve svém profilu na app.sportjoy.cz máte přístup ke všem svým
            kreditům, které můžete využít k placení příměstských táborů a
            dalších aktivit. Platí, že 1 kredit = 1 Kč.
          </p>
          <p>Pokud své kredity nevyužijete, můžete je na kohokoliv převést.</p>
          <h3>Jak získat kredity?</h3>
          <p>Kredity získáváte několika způsoby:</p>
          <ul>
            <li>
              Při stornování přihlášky nebo v případě nemoci (viz platební
              podmínky)
            </li>
            <li>
              Za doporučení prostřednictvím vašeho osobního odkazu nebo kódu
            </li>
            <li>
              Za dodatečný doplatek k přihlášce, přihlášku bez obědů a další
              individuální případy
            </li>
          </ul>
          <h3>Jak platit pomocí kreditů?</h3>
          <p>
            Při vyplňování přihlášky stačí v posledním kroku vybrat možnost
            platit kredity.
          </p>
          <p>
            Rovnou uvidíte, kolik kreditů máte k dispozici, a můžete se
            rozhodnout, kolik jich chcete pro platbu využít.
          </p>
          <h3>Jaké jsou druhy kreditů?</h3>
          <p>Ve svém profilu uvidíte 2 druhy kreditů:</p>
          <p>
            <strong>Dostupné kredity</strong> jsou kredity, které máte aktuálně
            k dispozici. Můžete je okamžitě využít k platbě aktivit.
          </p>
          <p>
            <strong>Budoucí kredity</strong> jsou kredity, které jsou na vašem
            účtu nahrané, ale momentálně je nemůžete využít. Tyto kredity mají
            nastavené datum, od kterého platí. Týká se to například kreditů za
            doporučení, kreditů za nevyužité služby a podobně.
          </p>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <>
      <div className="credits_box mb-4">
        <p className="desc">Dostupných kreditů</p>
        <p className="value">{user.credits ? user.credits : 0}</p>
      </div>
      <div className="credits_box future mb-3">
        <p className="desc">Budoucí kredity</p>
        <p className="value">{user.future_credits ? user.future_credits : 0}</p>
      </div>
      <CreditsInfoModal />
    </>
  );
};

export default CreditsBox;
