import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Col, Row } from "reactstrap";
import { Button, Icon } from "semantic-ui-react";
import { updateAttendance } from "../../api";
import { AttendanceItem } from "../../styles";
import { jsonToFormData } from "../../tools";

function AttendanceItemForm({ item, refresh }) {
  const [loading, setLoading] = useState({
    day1: false,
    day2: false,
    day3: false,
    day4: false,
    day5: false,
  });

  function setAttendance(day, value) {
    setLoading({ ...loading, ["day" + day]: true });

    const data = {
      id_date: item.id_date,
      id_kids: item.id_kids,
      value: value,
      day: day,
    };

    const payload = jsonToFormData(data);

    updateAttendance(payload).then((res) => {
      console.log(res);
      setLoading({ ...loading, ["day" + day]: false });
      refresh();
    });
  }

  return (
    <>
      <AttendanceItem>
        <Row>
          <Col sm={12}>
            <span className="attendance_name">{item.name}</span>
          </Col>
        </Row>
        <Row>
          <Col className={isMobile ? "pb-3 w-20 p-0 text-center" : "pb-3"}>
            <Button
              className="attendance_btn"
              icon
              color={item.attendance.day1 === 0 ? "red" : "green"}
              onClick={() =>
                item.attendance.day1 ? setAttendance(1, 0) : setAttendance(1, 1)
              }
              loading={loading.day1}
            >
              {item.attendance.day1 === 0 ? (
                <Icon name="times circle outline" />
              ) : (
                <Icon name="check circle outline" />
              )}
            </Button>
            <p className="attendance_day">1. den</p>
          </Col>
          <Col className={isMobile ? "pb-3 w-20 p-0 text-center" : "pb-3"}>
            <Button
              className="attendance_btn"
              icon
              color={item.attendance.day2 === 0 ? "red" : "green"}
              onClick={() =>
                item.attendance.day2 ? setAttendance(2, 0) : setAttendance(2, 1)
              }
              loading={loading.day2}
            >
              {item.attendance.day2 === 0 ? (
                <Icon name="times circle outline" />
              ) : (
                <Icon name="check circle outline" />
              )}
            </Button>
            <p className="attendance_day">2. den</p>
          </Col>
          <Col className={isMobile ? "pb-3 w-20 p-0 text-center" : "pb-3"}>
            <Button
              className="attendance_btn"
              icon
              color={item.attendance.day3 === 0 ? "red" : "green"}
              onClick={() =>
                item.attendance.day3 ? setAttendance(3, 0) : setAttendance(3, 1)
              }
              loading={loading.day3}
            >
              {item.attendance.day3 === 0 ? (
                <Icon name="times circle outline" />
              ) : (
                <Icon name="check circle outline" />
              )}
            </Button>
            <p className="attendance_day">3. den</p>
          </Col>
          <Col className={isMobile ? "pb-3 w-20 p-0 text-center" : "pb-3"}>
            <Button
              className="attendance_btn"
              icon
              color={item.attendance.day4 === 0 ? "red" : "green"}
              onClick={() =>
                item.attendance.day4 ? setAttendance(4, 0) : setAttendance(4, 1)
              }
              loading={loading.day4}
            >
              {item.attendance.day4 === 0 ? (
                <Icon name="times circle outline" />
              ) : (
                <Icon name="check circle outline" />
              )}
            </Button>
            <p className="attendance_day">4. den</p>
          </Col>
          <Col className={isMobile ? "pb-3 w-20 p-0 text-center" : "pb-3"}>
            <Button
              className="attendance_btn"
              icon
              color={item.attendance.day5 === 0 ? "red" : "green"}
              onClick={() =>
                item.attendance.day5 ? setAttendance(5, 0) : setAttendance(5, 1)
              }
              loading={loading.day5}
            >
              {item.attendance.day5 === 0 ? (
                <Icon name="times circle outline" />
              ) : (
                <Icon name="check circle outline" />
              )}
            </Button>
            <p className="attendance_day">5. den</p>
          </Col>
        </Row>
      </AttendanceItem>
    </>
  );
}

export default AttendanceItemForm;
