import React, { Component } from "react";
import {
  getDates,
  getDatesHobby,
  getCampsFilter,
  getLocationsFilter,
  getEmbedDatesFilter,
  dateFilterFrom,
} from "../../../api";
import {
  DateItem,
  DateSelected,
  EmbedMessage,
  FormButtonGroup,
  FormSection,
  FormSectionHead,
  FormSectionBody,
  DateItemLabel,
} from "../../../styles";
import moment from "moment";
import {
  Button,
  Modal,
  Message,
  Dropdown,
  Popup,
  Icon,
  Loader,
} from "semantic-ui-react";
import { Col, Row } from "reactstrap";
import _ from "loadsh";
import { withCookies } from "react-cookie";
import { isMobile } from "react-device-detect";
import { jsonToFormData } from "../../../tools";
import { applicationsLang as lang } from "../../../lang/lang";

class TermsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      loadingDates: true,
      loadingDatesMessage: "Načítám termíny",
      datesByKids: [],
      kids: [],
      selected: "",
      selectedItems: [],
      selectLoading: false,
      isSelected: false,
      alternate: false,
      canSelect: false,
      modals: {},
      campsOptions: [{ key: "0", value: null, text: lang.all }],
      locOptions: [{ key: "0", value: null, text: lang.all }],
      datesFilter: [{ key: "0", value: null, text: lang.all }],
      filter: false,
      filters: {},
    };
  }

  componentDidMount() {
    this.getFilterValues();
    this.getDatesHandler();
  }

  componentDidUpdate(prevProps) {
    if (this.props.kids !== prevProps.kids) {
      this.setState(
        {
          kids: this.props.kids,
        },
        () => {
          this.checkSelected();
          this.setDatesData();
        }
      );
    }
  }

  getFilterValues = () => {
    const toSend = {
      filters: this.state.filters,
      activity_type: "camps",
    };

    const fd = jsonToFormData(toSend);

    getLocationsFilter(fd).then((data) => {
      this.setState(
        {
          locOptions: [{ key: "0", value: null, text: lang.all }],
        },
        () => {
          this.setState({
            locOptions: this.state.locOptions.concat(data.data),
          });
        }
      );
    });

    getCampsFilter("camps")
      .then((data) => {
        this.setState(
          {
            campsOptions: [{ key: "0", value: null, text: lang.all }],
          },
          () => {
            this.setState({
              campsOptions: this.state.campsOptions.concat(data.data),
            });
          }
        );
      })
      .catch((err) => {
        alert(
          "Nepodařilo se nám získat druhy táborů. Zaktualizujte prosím stránku."
        );
      });

    getEmbedDatesFilter(fd)
      .then((data) => {
        this.setState(
          {
            datesFilter: [{ key: "0", value: null, text: lang.all }],
          },
          () => {
            this.setState({
              datesFilter: this.state.datesFilter.concat(data.data),
            });
          }
        );
      })
      .catch((err) => {
        alert("Nepodařilo se nám získat data. Zaktualizujte prosím stránku.");
      });
  };

  getDatesHandler = () => {
    const { isHobby } = this.props;

    this.setState({
      loadingDates: true,
    });

    if (isHobby !== "hobby") {
      getDates()
        .then((data) => {
          this.setState(
            {
              dates: data,
              baseData: data,
              kids: this.props.kids,
              loadingDates: false,
            },
            () => {
              this.setDatesData();
            }
          );
        })
        .catch((err) => {
          alert("Nepodařilo se nám získat data. Zaktualizujte prosím stránku.");
        });

      this.setState(
        {
          kids: this.props.kids,
        },
        () => {
          this.checkSelected();
          this.setDatesData();
        }
      );
    } else {
      getDatesHobby()
        .then((data) => {
          this.setState(
            {
              dates: data,
              loadingDates: false,
              baseData: data,
            },
            () => {
              this.setDatesData();
            }
          );
        })
        .catch((err) => {
          alert("Nepodařilo se nám získat data. Zaktualizujte prosím stránku.");
        });

      this.setState(
        {
          kids: this.props.kids,
        },
        () => {
          this.checkSelected();
          this.setDatesData();
        }
      );
    }
  };

  onChangeDate = (e, data) => {
    this.setState(
      {
        [data.name]: data.value,
      },
      () => {
        if (
          this.state.filter_date_from !== "" &&
          this.state.filter_date_from !== null
        ) {
          this.getFilteredHandler(this.state.filter_date_from);
        } else {
          this.getDatesHandler();
        }
      }
    );
  };

  getFilteredHandler = (date) => {
    this.setState({
      loadingDates: true,
    });
    dateFilterFrom(date)
      .then((dates) => {
        if (dates.length !== 0) {
          if (_.keys(this.state.filters).length !== 0) {
            this.setState(
              {
                dates: _.filter(dates, this.state.filters),
                loadingDates: false,
                baseData: dates,
                hasSome: true,
              },
              () => this.setDatesData()
            );
          } else {
            this.setState(
              {
                dates: dates,
                loadingDates: false,
                baseData: dates,
                hasSome: true,
              },
              () => this.setDatesData()
            );
          }
        } else {
          this.setState(
            {
              hasSome: false,
              loadingDates: false,
            },
            () => this.setDatesData()
          );
        }
      })
      .catch((err) => {
        alert("Nepodařilo se nám získat data. Zaktualizujte prosím stránku.");
      });
  };

  selectHandler = (date, index) => {
    this.setState({
      selectLoading: true,
    });

    let kids = [...this.state.kids];
    kids[index]["kids_camps"] = kids[index]["kids_camps"].concat(date);
    kids[index]["kids_can_select"] = kids[index]["kids_can_select"].filter(
      (i) => i.id_date !== date.id_date
    );

    this.setState(
      {
        selected: date,
        kids: kids,
        loadingDates: false,
      },
      () => {
        this.toggleModal(index);
        this.setState({
          selectLoading: false,
        });
        this.checkSelected();
        this.removeAfterSelect();
      }
    );
  };

  checkSelected = () => {
    for (var i = 0; i < this.state.kids.length; i++) {
      if (this.state.kids[i]["kids_camps"].length === 0) {
        this.setState({
          isSelected: false,
        });
        break;
      } else {
        this.setState({
          isSelected: true,
        });
      }
    }
  };

  toggleModal = (index) => {
    this.setState({
      modals: {
        ["modal-" + index]:
          this.state.modals["modal-" + index] !== undefined
            ? !this.state.modals["modal-" + index]
            : false,
      },
    });
  };

  closeModal = ({ index }) => {
    this.setState(
      {
        modals: {
          ["modal-" + index]:
            this.state.modals["modal-" + index] !== undefined && false,
        },
      },
      () => this.removeAfterSelect()
    );
  };

  setDatesData = () => {
    const { isHobby } = this.props;

    if (isHobby !== "hobby") {
      let kids = [...this.state.kids];
      var MAXIMUM_BABYJOY = moment().subtract(4, "year");
      var MINIMUM_SPORTJOY = moment().subtract(6, "year");
      var MAXIMUM_BOTH = moment().subtract(5, "year");

      this.state.kids.map((item, index) => {
        var dates = [];

        if (!this.state.loadingDates) {
          this.state.dates.map((item, i) => {
            var diff = moment(kids[index]["kids_birthday"]).diff(
              item.date_from,
              "years",
              true
            );
            var interDiff = -diff;

            if (interDiff >= item.min_age && interDiff < item.max_age + 1) {
              dates[i] = item;
            }
          });

          kids[index]["kids_can_select"] = dates;
        }
      });

      this.setState({
        kids: kids,
        canSelect: true,
      });
    } else {
      let kids = [...this.state.kids];

      this.state.kids.map((item, index) => {
        kids[index]["kids_can_select"] = this.state.dates;
      });

      this.setState({
        kids: kids,
        canSelect: true,
      });
    }
  };

  renderKids = () => {
    return this.state.kids.map((kid, index) => {
      return (
        <FormSection key={index}>
          <FormSectionHead>
            <h5>
              {kid.kids_name} {kid.kids_lastname}
            </h5>
          </FormSectionHead>

          <FormSectionBody>
            {kid.kids_camps.map((camp, idx) => {
              return (
                <React.Fragment key={idx}>
                  {this.selectedItem(camp, index)}
                </React.Fragment>
              );
            })}

            <Button
              onClick={() => this.toggleModal(index)}
              style={{ display: "block", margin: "0 auto" }}
            >
              {lang.add_session}
            </Button>
          </FormSectionBody>

          {this.datesList(
            index,
            this.state.modals["modal-" + index] !== undefined
              ? !this.state.modals["modal-" + index]
              : false
          )}
        </FormSection>
      );
    });
  };

  removeHandler = (index, item) => {
    const itemToDelete = item;
    let kids = [...this.state.kids];

    kids[index]["kids_camps"] = kids[index]["kids_camps"].filter(
      (i) => i !== itemToDelete
    );
    kids[index]["kids_can_select"] =
      kids[index]["kids_can_select"].concat(itemToDelete);

    this.setState(
      {
        kids: kids,
      },
      () => {
        this.checkSelected();
      }
    );
  };

  nextHandler = () => {
    this.props.setTerm(this.state.selected);
  };

  renderPriceCol = (lower_price, full_price) => {
    if (full_price != lower_price) {
      return (
        <>
          <Popup
            content={lang.price_for_individuals}
            position="top center"
            trigger={<Icon name="user" />}
          />{" "}
          <span className="bold">{full_price},-&nbsp;Kč</span>
          <br />
          <Popup
            content={lang.price_for_siblings}
            position="top center"
            trigger={<Icon name="users" />}
          />{" "}
          <span className="bold">{lower_price},-&nbsp;Kč</span>
        </>
      );
    } else if (
      full_price === lower_price &&
      full_price != 0 &&
      lower_price != 0
    ) {
      return <span className="bold">{full_price},-&nbsp;Kč</span>;
    } else if (full_price === 0 || lower_price === 0) {
      return <span className="bold">{lang.free}</span>;
    }
  };

  renderDates = (index) => {
    const { campType } = this.props;

    if (this.state.kids.length !== 0) {
      if (this.state.canSelect) {
        if (this.state.kids[index].kids_can_select.length !== 0) {
          return this.state.kids[index].kids_can_select.map((date, i) => {
            return (
              <DateItem
                key={date.id_date}
                className={
                  date.date_capacity - date.date_signed <= 0
                    ? "row full_date"
                    : "row"
                }
                camp={campType}
              >
                <Col sm={2} className="camp_name_uppercase p-0">
                  <img
                    className="img-fluid"
                    alt={date.camp_name}
                    src={
                      "https://api.sportjoy.cz/media/upload/camp/" +
                      date.id_camp +
                      "/" +
                      date.camp_logo
                    }
                  />
                </Col>
                <Col
                  sm={3}
                  className="pl-sm-5 col-sm-3 mb-3 mb-sm-0 mt-3 mt-sm-0"
                >
                  <span className="bold d-block">{date.camp_name}</span>
                  {date.location_address}
                </Col>
                <Col sm={2} className="mb-3 mb-sm-0">
                  <DateItemLabel>{lang.date}</DateItemLabel>
                  <span className="bold">
                    {moment(date.date_from).format("D. M.")} -{" "}
                    {moment(date.date_to).format("D. M. YYYY")}
                  </span>
                </Col>
                <Col sm={2} className="mb-3 mb-sm-0">
                  <DateItemLabel>{lang.price}</DateItemLabel>
                  {this.renderPriceCol(date.lower_price, date.full_price)}
                </Col>
                <Col sm={1} className="mb-3 mb-sm-0">
                  <DateItemLabel>{lang.availability}</DateItemLabel>
                  <span className="bold">
                    {date.is_full === 0 ? "Volné" : "Obsazené"}
                  </span>
                </Col>
                <Col
                  sm={2}
                  className="d-sm-flex justify-content-end pl-0 pr-0 pr-sm-4"
                >
                  <Button onClick={() => this.selectHandler(date, index)} className={`select_date select_date_${i}`}>
                    {date.date_capacity - date.date_signed <= 0
                      ? lang.as_substitute
                      : lang.select_session}
                  </Button>
                </Col>
              </DateItem>
            );
          });
        } else {
          return (
            <div
              style={{ minHeight: 200 }}
              className="d-flex align-items-center justify-content-center"
            >
              <p>{lang.filter_no_dates}</p>
            </div>
          );
        }
      } else {
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 200 }}
          >
            <Loader inverted>{this.state.loadingDatesMessage}</Loader>
          </div>
        );
      }
    }
  };

  selectedItem = (camp, index) => {
    return (
      <div key={index}>
        <DateSelected
          loading={this.state.selectLoading.toString()}
          full={
            this.state.kids[index].kids_camps.length !== 0 &&
            camp.date_capacity - camp.date_signed <= 0
          }
        >
          {this.state.kids[index].kids_camps.length === 0 ? (
            <p>{lang.use_button_below}</p>
          ) : (
            <div className="row">
              <div className="col-sm-1 camp_name_uppercase">
                {camp.camp_name}
              </div>
              <div className="col-sm-3">
                {moment(camp.date_from).format("D. M.")} -{" "}
                {moment(camp.date_to).format("D. M. YYYY")}
              </div>
              <div className="col-sm-4">{camp.location_address}</div>
              <div className="col-sm-2">
                <span>{camp.is_full !== 1 ? lang.available : lang.full}</span>
              </div>
              <div className="col-sm-2">
                <Button onClick={() => this.removeHandler(index, camp)}>
                  {lang.remove}
                </Button>
              </div>
            </div>
          )}
        </DateSelected>
        {this.state.kids[index].kids_camps.length !== 0 &&
          camp.date_capacity - camp.date_signed <= 0 && (
            <EmbedMessage type="error">
              <p>{lang.session_full}</p>
            </EmbedMessage>
          )}
      </div>
    );
  };

  onChangeSelect = (e, data) => {
    this.setState({
      loadingDates: true,
    });

    if (data.value !== null) {
      this.setState(
        {
          filter: true,
          filters: {
            ...this.state.filters,
            [data.name]: data.value,
          },
        },
        () => {
          this.setState(
            {
              dates: _.filter(this.state.baseData, this.state.filters),
              loadingDates: false,
            },
            () => {
              this.setDatesData();
              this.getFilterValues();
            }
          );
        }
      );
    } else {
      this.setState(
        {
          filters: _.omit(this.state.filters, [data.name]),
        },
        () => {
          this.setState(
            {
              dates:
                _.keys(this.state.filters).length !== 0
                  ? _.filter(this.state.baseData, this.state.filters)
                  : this.state.baseData,
              loadingDates: false,
              filter: _.keys(this.state.filters).length !== 0 ? true : false,
            },
            () => {
              this.setDatesData();
              this.getFilterValues();
            }
          );
        }
      );
    }
  };

  removeAfterSelect = () => {
    this.setState({
      loadingDates: true,
    });

    this.setState(
      {
        filters: {},
        filter_date_from: "",
        dates: this.state.baseData,
        loadingDates: false,
        filter: false,
      },
      () => this.getDatesHandler()
    );
  };

  datesList = (index, show) => {
    const { campType } = this.props;

    return (
      <div>
        <Modal
          open={show}
          onClose={() => this.closeModal(index)}
          size={"fullscreen"}
          closeIcon
        >
          <Modal.Header>{lang.select_session}</Modal.Header>
          <Modal.Content>
            <Row className="mb-4">
              <Col sm={4}>
                <label>{lang.activity}</label>
                <Dropdown
                  name="id_camp"
                  options={this.state.campsOptions}
                  placeholder={lang.select_activity}
                  selection
                  search={isMobile === false ? true : false}
                  onChange={this.onChangeSelect}
                  fluid
                />
              </Col>
              <Col sm={4}>
                <label>{lang.location}</label>
                <Dropdown
                  name="id_location"
                  options={this.state.locOptions}
                  placeholder={lang.select_location}
                  selection
                  search={isMobile === false ? true : false}
                  onChange={this.onChangeSelect}
                  fluid
                />
              </Col>
              <Col sm={4}>
                <label>{lang.filter_date}</label>
                <Dropdown
                  name="filter_date_from"
                  options={this.state.datesFilter}
                  placeholder={lang.select_date}
                  selection
                  onChange={this.onChangeDate}
                  fluid
                />
              </Col>
            </Row>
            {this.renderDates(index)}
          </Modal.Content>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Message
          color="red"
          icon="warning sign"
          header={lang.combine_camps_headline}
          content={lang.combine_camps_message}
        />
        <Message
          color="red"
          icon="warning sign"
          header={lang.missing_date_msg_headline}
          content={lang.missing_date_msg_message}
        />
        {this.renderKids()}
        <FormButtonGroup>
          {!this.state.isSelected && <p>{lang.select_one_session}</p>}
          <Button
            className={this.state.isSelected ? "btn_end pulsing next next_dates" : "btn_end next next_dates"}
            onClick={this.nextHandler}
            disabled={!this.state.isSelected}
          >
            {lang.next}
          </Button>
        </FormButtonGroup>
      </div>
    );
  }
}

export default withCookies(TermsForm);
