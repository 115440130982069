import styled from "styled-components";

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #2f71b8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

export const FormWrapper = styled.div`
  @media (min-width: 768px) {
    width: 500px;
  }
`;
