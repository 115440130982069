import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { setMessage } from "../../../../actions/messageActions";
import { updateCheckboxes } from "../../../../api/instructors";
import { jsonToFormData } from "../../../../tools";

const EditNameForm = ({
  value,
  name,
  id,
  label,
  placeholder,
  type,
  refresh,
  newItem = 0,
  column = "",
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ [name]: value, id: id });
  const [loading, setLoading] = useState(false);

  function onChange(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  function submit({ state = 1 }) {
    setLoading(true);
    const payload = {
      id_item: id,
      name: data[name],
      type: type,
      state: state,
      new: newItem,
      column: column,
    };
    const fd = jsonToFormData(payload);
    updateCheckboxes(fd).then((res) => {
      setLoading(false);
      refresh();
      dispatch(setMessage(res.data));
    });
  }

  return (
    <Form onSubmit={submit}>
      <Form.Input
        label={label}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={data[name]}
      />
      <Button primary loading={loading} disabled={loading}>
        Uložit změny
      </Button>
    </Form>
  );
};

export default EditNameForm;
