import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { StatsCard } from ".";
import {
  getCouponsStats,
  countApplicationsByType,
  todayApplicationsByType,
  countKidsByType,
  todayKidsByType,
  paidUnpaidByType,
  getNumberPaidByType,
} from "../../api";

class StatsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_applications: 0,
      total_kids: 0,
      today_applications: 0,
      applications_difference: 0,
      today_kids: 0,
      kids_difference: 0,
      paidUnpaid: {
        paid: 0,
        unpaid: 0,
        percentage: 0,
      },
      numberPaid: {
        paid: 0,
        total: 0,
        avg: 0,
      },
      lastTenApplications: [],
      couponsStats: [],
    };
  }

  componentDidMount() {
    this.countApplicationsHandler();
    this.countKidsHandler();
    this.todayApplicationsHandler();
    this.todayKidsHandler();
    this.couponsStatsHandler();
    this.paidUnpaidHandler();
    this.numberPaidHandler();
  }

  countApplicationsHandler = () => {
    const { type, sub } = this.props;

    countApplicationsByType(type, sub).then((data) => {
      this.setState({
        total_applications: data.total_applications,
      });
    });
  };

  countKidsHandler = () => {
    const { type, sub } = this.props;
    countKidsByType(type, sub).then((data) => {
      this.setState({
        total_kids: data,
      });
    });
  };

  todayApplicationsHandler = () => {
    const { type, sub } = this.props;
    todayApplicationsByType(type, sub).then((data) => {
      this.setState({
        today_applications: data.today_applications,
        applications_difference: data.difference,
      });
    });
  };

  todayKidsHandler = () => {
    const { type, sub } = this.props;
    todayKidsByType(type, sub).then((data) => {
      this.setState({
        today_kids: data.today_kids,
        kids_difference: data.difference,
      });
    });
  };

  paidUnpaidHandler = () => {
    const { type } = this.props;
    paidUnpaidByType(type).then((data) => {
      this.setState({
        paidUnpaid: data,
      });
    });
  };

  couponsStatsHandler = () => {
    getCouponsStats().then((data) => {
      this.setState({
        couponsStats: data,
      });
    });
  };

  numberPaidHandler = () => {
    const { type } = this.props;
    getNumberPaidByType(type).then((data) => {
      console.log("paid", data);

      this.setState({
        numberPaid: data,
      });
    });
  };

  _renderHeadline = (type, sub = "") => {
    if (type === "camp") {
      if (sub === "free") {
        return "Tábory zdarma";
      }
      if (sub === "paid") {
        return "Tábory";
      }
    } else if (type === "hobby") {
      return "Kroužky";
    } else if (type === "kurzy") {
      return "Kurzy";
    } else if (type === "skolka") {
      return "Školka";
    }
  };

  render() {
    const { type, sub } = this.props;

    return (
      <>
        <h2 className="stats_title">{this._renderHeadline(type, sub)}</h2>
        <Row className="dashboard-row">
          <Col sm={3}>
            <StatsCard
              type="today-applications"
              value={this.state.today_applications}
              diff={this.state.applications_difference}
            />
          </Col>
          <Col sm={3}>
            <StatsCard
              type="today-kids"
              value={this.state.today_kids}
              diff={this.state.kids_difference}
            />
          </Col>
          <Col sm={3}>
            <StatsCard
              type="total-applications"
              value={this.state.total_applications}
            />
          </Col>
          <Col sm={3}>
            <StatsCard type="total-kids" value={this.state.total_kids} />
          </Col>
        </Row>
        {sub !== "free" && (
          <Row className="dashboard-row">
            <Col sm={6}>
              <StatsCard type="paid-unpaid" value={this.state.paidUnpaid} />
            </Col>
            <Col sm={6}>
              <StatsCard
                type="number-paid"
                campType={type}
                value={this.state.numberPaid}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default StatsSection;
