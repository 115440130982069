import React from "react";
import { intToBool } from "../../api";
import { Link } from "react-router-dom";
import { Button, Icon, Table } from "semantic-ui-react";
import _ from "loadsh";
import { roles } from "../../helpers";
import DisableUserModal from "../modals/DisableUserModal";
import { StateIcon } from "../../styles";

function UsersTable({ items, column, direction, sort, refresh }) {
  return (
    <>
      <Table sortable striped className="main-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "id_user" ? direction : null}
              onClick={sort("id_user")}
              width={1}
            >
              #
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === "user_first_name" ? direction : null}
              onClick={sort("user_first_name")}
              width={2}
            >
              Jméno
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === "user_last_name" ? direction : null}
              onClick={sort("user_last_name")}
              width={2}
            >
              Příjmení
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === "user_email" ? direction : null}
              onClick={sort("user_email")}
              width={3}
            >
              E-mail
            </Table.HeaderCell>

            <Table.HeaderCell>Role</Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === "credits" ? direction : null}
              onClick={sort("credits")}
            >
              Počet kreditů
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === "future_credits" ? direction : null}
              onClick={sort("future_credits")}
            >
              Budoucí kredity
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === "user_state" ? direction : null}
              onClick={sort("user_state")}
              width={1}
            >
              Stav
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(
            items,
            ({
              id_user,
              credits,
              user_first_name,
              user_last_name,
              user_email,
              user_state,
              user_admin,
              future_credits
            }) => (
              <Table.Row key={id_user}>
                <Table.Cell>{id_user}</Table.Cell>
                <Table.Cell>{user_first_name}</Table.Cell>
                <Table.Cell>{user_last_name}</Table.Cell>
                <Table.Cell>{user_email}</Table.Cell>
                <Table.Cell>{roles(user_admin)}</Table.Cell>
                <Table.Cell>{credits}</Table.Cell>
                <Table.Cell>{future_credits}</Table.Cell>
                <Table.Cell>
                  <StateIcon state={user_state} />
                </Table.Cell>
                <Table.Cell className="text-right">
                  <DisableUserModal trigger={"icon"} id_user={id_user} />
                  <Link
                    to={
                      `${process.env.PUBLIC_URL}/manage/users/edit/` + id_user
                    }
                  >
                    <Button icon>
                      <Icon name="pencil" />
                    </Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </>
  );
}

export default UsersTable;
