import React from 'react'
import { Form, Button, Dropdown } from 'semantic-ui-react'
import { CustomDropzone, FormButtonGroup, InnerImage } from '../../styles'
import { url } from '../../config'
import { useDropzone } from 'react-dropzone'

const options = [
    { key: 'no', value: 0, text: 'Nezveřejněno' },
    { key: 'yes', value: 1, text: 'Zveřejněno' },
]

const CampSecondaryForm = ({ camp, onChangeSelect, loading, onDropFile, isNew, submit }) => {

    const maxSize = 524288;

    function onDrop(acceptedFiles) {
        
        acceptedFiles.map((file) => {
            console.log(file)
            onDropFile(file)
        })

    }


    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        onDrop, 
        accept: '.png, .jpg, .gif, .jpeg',
        multiple: false,
    })

    return (
        <Form loading={loading} onSubmit={submit}>
            <Form.Field>
                <Dropdown 
                    name='camp_state'
                    placeholder='Vyberte možnost'
                    options={options}
                    value={camp.camp_state}
                    onChange={onChangeSelect}
                    selection
                    className={'state-active-' + camp.camp_state}
                />
            </Form.Field>

            <Form.Field>
                <label>Ilustrační obrázek</label>
                <CustomDropzone {...getRootProps()} style={{padding: 20}}>
                    <input {...getInputProps()} />
                    {acceptedFiles.length === 0 ?
                    <>
                    {
                        isDragActive ?
                        <p>Přetáhněte soubory sem ...</p> :
                        <p>Přetáhněte sem soubory, nebo klikněte pro vybrání z počítače<br /><span class='small'>Podporované soubory: .png, .jpg, .gif, .jpeg (max. 9 MB)</span></p>
                    }
                    </>
                    :
                    <>
                        {
                            acceptedFiles.map(acceptedFile => (
                                <p>{acceptedFile.name}</p>
                            ))
                        }
                    </>
                    }
                </CustomDropzone>
                {/*
                <Dropzone onDrop={onDrop} accept="image/png, image/jpeg, image/gif" minSize={0} maxSize={maxSize} multiple={false}>
                    {({getRootProps, getInputProps, rejectedFiles, isDragReject, acceptedFiles}) => {
                        const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                        return (
                            <section className="">
                                <div {...getRootProps({className: 'dropzone p-3 text-center rad'})}>
                                    <input {...getInputProps()} />
                                    <i class="icofont-upload-alt"></i>
                                    {acceptedFiles.length > 0 ? acceptedFiles.map(acceptedFile => (
                                        <p>{acceptedFile.name}</p>
                                    )) : <p>Drag &amp; drop, or click to select files</p>}
                                    
                                    {isFileTooLarge && (
                                        <div className="text-danger mt-2">
                                        Obrázek je moc velký.
                                        </div>
                                    )}

                                    {isDragReject && (
                                        <div className="text-danger mt-2">
                                        Tento formát není podporován.
                                        </div>
                                    )}
                                </div>
                            </section>
                        )
                    }}
                </Dropzone>
                */}
                {camp.camp_logo !== '' && !isNew &&
                    <InnerImage src={url.images + 'camp/' + camp.id_camp + '/' + camp.camp_logo} className='img_preview' />
                }
            </Form.Field>

            <FormButtonGroup>
                <Button primary>Uložit změny</Button>
            </FormButtonGroup>
        </Form>
    )
}

export default CampSecondaryForm