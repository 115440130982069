import React from "react";
import { Button, Flag, Table } from "semantic-ui-react";
import _ from "loadsh";
import { Link } from "react-router-dom";

function EmailsListTable({ data, loading }) {
  return (
    <>
    {!loading &&
    <Table striped className="main-table mobile" unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Typ odpovědi</Table.HeaderCell>
          <Table.HeaderCell>Předmět</Table.HeaderCell>
          <Table.HeaderCell>Jazyk</Table.HeaderCell>
          <Table.HeaderCell>Akce</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(data, ({ id, type, subject, flag }) => (
          <Table.Row key={id}>
            <Table.Cell>{type}</Table.Cell>
            <Table.Cell>{subject}</Table.Cell>
            <Table.Cell>
              <Flag name={flag} />
            </Table.Cell>
            <Table.Cell>
              <Link to={`/manage/emails/edit/${id}`}>
                <Button>Editovat</Button>
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
    }
    </>
  );
}

export default EmailsListTable;
