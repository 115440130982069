import React, { useState, useEffect } from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import { getApplications } from '../../../api/parents';
import moment from 'moment';
import { Link } from "react-router-dom";
import { jsonToFormData } from '../../../tools';
import EmptyTableMessage from './EmptyTableMessage';
import { formatMoney, getToken } from '../../../api';
import { Col, Row } from 'reactstrap';
import { StateIcon } from '../../../styles';

function ApplicationsTableDashboard({ state }) {

    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        _getData()

    }, []);
    
    function _getData() {

        setLoading(true);

        getToken().then((token) => {
            const data = {
                token: token
            }
    
            const payload = jsonToFormData(data);
    
            getApplications(payload)
            .then((res) => {
                setLoading(false);
                setDates(res.data)
            });
        })
        
    }

    const _renderDates = (dates) => {
        return (
            dates.map((item) => {
                return (
                    <>
                    <Row>
                        <Col sm={4}>
                            {item.camp_name}
                        </Col>
                        <Col sm={4}>
                            {moment(item.date_from).format('D. M.')}-{moment(item.date_to).format('D. M.')}
                        </Col>
                        <Col sm={4}>
                            {item.location_name}
                        </Col>
                    </Row>
                    </>
                )
            })
        )
    }

    return (
        <>
        {dates.length === 0 ?
        <EmptyTableMessage 
            message='Nejsou k dispozici žádné přihlášky k zobrazení' 
            loading={loading}
            buttonText='Vytvořit přihlášku'
            link='/embed/prihlasky'
            target='_blank'
            showButton={true}
        />
        :
        <div className='mobile-overflow'>
        <Table striped className='main-table mobile' unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        ID
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Vytvořeno
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Celková cena
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Termíny
                    </Table.HeaderCell>
                    <Table.HeaderCell  className='text-center'>Zaplaceno</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {dates.map((item) => {
                    return (
                        <Table.Row key={item.id_application}>
                            <Table.Cell>
                                {item.id_application}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.created).format("DD.MM.YYYY H:m")}
                            </Table.Cell>
                            <Table.Cell>
                                {formatMoney(item.total_price, 0, ',', ' ')},- Kč
                            </Table.Cell>
                            <Table.Cell>
                                <Popup
                                    content={_renderDates(item.dates)}
                                    wide='very'
                                    on='click'
                                    pinned
                                    position='bottom right'
                                    trigger={<Button content={item.dates_count} />}
                                />
                            </Table.Cell>
                            <Table.Cell className='text-center'>
                                <StateIcon state={item.paid} />
                            </Table.Cell>
                            <Table.Cell className='text-right'>
                                <Link to={`/parents/applications/${item.id_application}`}><Button primary>Detail přihlášky</Button></Link>
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
        </div>
        }
        </>
    )
}

export default ApplicationsTableDashboard