import React, { Component } from "react";
import moment from "moment";
import { Form, Checkbox, Button, Popup, Dropdown } from "semantic-ui-react";
import {
  FormButtonGroup,
  FormSection,
  FormSectionHead,
  EmbedMessage,
  FormSectionBody,
  DateSelected,
} from "../../../styles";
import { getCoupon, getRecommend } from "../../../api";
import { jsonToFormData } from "../../../tools";
import { applicationsLang as lang } from "../../../lang/lang";
import { connect } from "react-redux";

const yesNo = [
  { key: "0", value: 0, text: lang.no },
  { key: "1", value: 1, text: lang.yes },
];

class ConfirmForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfTerms: 0,
      data: {
        parent_tos: false,
        parent_gdpr: false,
        parent_photos: false,
        parent_newsletter: false,
        coupon: false,
        coupon_value: 0,
        id_coupon: null,
        id_voucher: null,
        recommend: "",
        id_recommended: 0,
        application_note: "",
        additional: 0,
        numberOfSubs: 0,
        is_voucher: false,
        coupon_submitted: false,
        invoice: false,
        reg_num: "",
        credits: null,
      },
      kids: [],
      submit: true,
    };
  }

  componentDidMount() {
    const { data, isHobby } = this.props;
    this.setNumOfTerms();
    this.countPrice(
      this.state.numberOfTerms,
      data.kids.length,
      this.state.data.additional,
      isHobby,
      data.kids
    );
    this.checkInputs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const { data, isHobby } = this.props;
      this.setNumOfTerms();
      this.countPrice(
        this.state.numberOfTerms,
        data.kids.length,
        this.state.data.additional,
        isHobby,
        data.kids
      );
      this.checkInputs();
    }
  }

  submitHandler = () => {
    const { isHobby } = this.props;

    this.props.setConfirm(this.state.data, this.state.total_price);
  };

  checkInputs = () => {
    var values = [0, 1];

    this.setState({
      submit: true,
    });

    this.props.data.kids.map((item, index) => {
      var needMoreInfo = false;
      for (var i = 0; i < item.kids_camps.length; i++) {
        if (
          item.kids_camps[i].type === 2 &&
          item.kids_camps[i].in_school === 1
        ) {
          if (item.kids_school && item.kids_school !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (item.kids_class && item.kids_class !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.go_from_school)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.go_from_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.return_to_school)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.can_go_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }
        }

        if (
          item.kids_camps[i].type === 3 &&
          item.kids_camps[i].in_school === 1
        ) {
          if (item.kids_school && item.kids_school !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (item.kids_class && item.kids_class !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.go_from_school)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.go_from_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.return_to_school)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.can_go_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }
        }

        if (
          item.kids_camps[i].type === 2 &&
          item.kids_camps[i].in_school === 0
        ) {
          if (item.kids_school && item.kids_school !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (item.kids_class && item.kids_class !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.go_from_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.can_go_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }
        }

        if (
          item.kids_camps[i].type === 3 &&
          item.kids_camps[i].in_school === 0
        ) {
          if (item.kids_school && item.kids_school !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (item.kids_class && item.kids_class !== "") {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.go_from_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }

          if (values.includes(item.can_go_home)) {
            this.setState({
              submit: true,
            });
          } else {
            this.setState({
              submit: false,
            });
            break;
          }
        }
      }
    });
  };

  parentsBlock = () => {
    const { data } = this.props;
    return data.parents.map((item, index) => {
      return (
        <div className="col-sm-6" key={index}>
          <p className="mb-1">{lang.name}</p>
          <p>
            <strong>
              {item.parent_name} {item.parent_lastname}
            </strong>
          </p>
          <p className="mb-1">Email</p>
          <p>
            <strong>{item.parent_email}</strong>
          </p>
          <p className="mb-1">{lang.phone}</p>
          <p>
            <strong>{item.parent_phone}</strong>
          </p>
        </div>
      );
    });
  };

  onChangeSelectMoreInfo = (e, data, item, index) => {
    const newData = {
      ...item,
      [data.name]: data.value,
    };

    let items = [...this.props.data.kids];
    items[index] = newData;

    this.props.updateKid(items);
  };

  onChangeMoreInfo = (e, item, index) => {
    const newData = {
      ...item,
      [e.target.name]: e.target.value,
    };

    let items = [...this.props.data.kids];
    items[index] = newData;

    this.props.updateKid(items);
  };

  kidsBlock = () => {
    const { data } = this.props;
    return data.kids.map((item, index) => {
      let needMoreInfoHobbySchool = false;
      let needMoreInfoKurzySchool = false;
      let needMoreInfoHobby = false;
      let needMoreInfoKurzy = false;
      let needMoreInfo = false;

      for (let i = 0; i < item.kids_camps.length; i++) {
        if (
          item.kids_camps[i].type === 2 &&
          item.kids_camps[i].in_school === 1
        ) {
          needMoreInfoHobbySchool = true;
          needMoreInfo = true;
        }

        if (
          item.kids_camps[i].type === 3 &&
          item.kids_camps[i].in_school === 1
        ) {
          needMoreInfoKurzySchool = true;
          needMoreInfo = true;
        }

        if (
          item.kids_camps[i].type === 2 &&
          item.kids_camps[i].in_school === 0
        ) {
          needMoreInfoHobby = true;
          needMoreInfo = true;
        }

        if (
          item.kids_camps[i].type === 3 &&
          item.kids_camps[i].in_school === 0
        ) {
          needMoreInfoKurzy = true;
          needMoreInfo = true;
        }
      }

      return (
        <div className="col-sm-6" key={index}>
          <p className="mb-1">{lang.name}</p>
          <p>
            <strong>
              {item.kids_name} {item.kids_lastname}
            </strong>
          </p>
          <p className="mb-1">{lang.birthday_2}</p>
          <p>
            <strong>{moment(item.kids_birthday).format("D. M. YYYY")}</strong>
          </p>
          <p className="mb-1">{lang.health_restrictions_placeholder}</p>
          <p>
            <strong>
              {item.kids_health_issues === ""
                ? "Nemá"
                : item.kids_health_issues}
            </strong>
          </p>
          <Form>
            {needMoreInfo && (
              <>
                <p className="mb-1">{lang.attending_school}</p>
                <Form.Input
                  name="kids_school"
                  type="text"
                  placeholder={lang.attending_school_placeholder}
                  id="kids_school"
                  onChange={(e) => this.onChangeMoreInfo(e, item, index)}
                />
              </>
            )}

            {needMoreInfo && (
              <>
                <p className="mb-1">{lang.which_grade}</p>
                <Form.Input
                  name="kids_class"
                  type="text"
                  placeholder="1.a, 2.c, 5.a, ..."
                  id="kids_class"
                  onChange={(e) => this.onChangeMoreInfo(e, item, index)}
                />
              </>
            )}

            {needMoreInfo && (
              <>
                <Form.Field className="mb-3">
                  <p className="mb-1">{lang.go_from_home}</p>
                  <Dropdown
                    name="go_from_home"
                    placeholder={lang.select_option}
                    id="go_from_home"
                    options={yesNo}
                    onChange={(e, data) =>
                      this.onChangeSelectMoreInfo(e, data, item, index)
                    }
                    className="go_from_home"
                    selection
                  />
                </Form.Field>
              </>
            )}
            {needMoreInfo && (
              <>
                <Form.Field>
                  <p className="mb-1">{lang.can_go_home}</p>
                  <Dropdown
                    name="can_go_home"
                    placeholder={lang.select_option}
                    id="can_go_home"
                    options={yesNo}
                    onChange={(e, data) =>
                      this.onChangeSelectMoreInfo(e, data, item, index)
                    }
                    className="can_go_home"
                    selection
                  />
                </Form.Field>
              </>
            )}

            {needMoreInfo && !needMoreInfoHobby && !needMoreInfoKurzy && (
              <>
                <Form.Field>
                  <p className="mb-1">{lang.go_from_school}</p>
                  <Dropdown
                    name="go_from_school"
                    placeholder={lang.select_option}
                    id="go_from_school"
                    options={yesNo}
                    onChange={(e, data) =>
                      this.onChangeSelectMoreInfo(e, data, item, index)
                    }
                    className="go_from_school"
                    selection
                  />
                </Form.Field>
              </>
            )}

            {needMoreInfo && !needMoreInfoHobby && !needMoreInfoKurzy && (
              <Form.Field>
                <p className="mb-1">{lang.return_to_school}</p>
                <Dropdown
                  name="return_to_school"
                  placeholder={lang.select_option}
                  id="return_to_school"
                  options={yesNo}
                  onChange={(e, data) =>
                    this.onChangeSelectMoreInfo(e, data, item, index)
                  }
                  className="return_to_school"
                  selection
                />
              </Form.Field>
            )}
          </Form>
          {this.renderTerms(item.kids_camps)}
        </div>
      );
    });
  };

  setNumOfTerms = () => {
    const { data } = this.props;
    let num = 0;
    let subs = 0;
    data.kids.map((item) => {
      num = num + item.kids_camps.length;

      item.kids_camps.map((item) => {
        num = num - item.is_full;
        subs = subs + item.is_full;
      });
    });

    this.setState({
      numberOfTerms: num,
      numberOfSubs: subs,
    });
  };

  setNumOfSubs = () => {
    const { data } = this.props;

    let num = 0;

    data.kids.map((item) => {
      item.kids_camps.map((item) => {
        num = num + item.is_full;
      });
    });

    this.setState({
      numberOfSubs: num,
    });
  };

  onChangeCoupon = (e) => {
    const { data, isHobby } = this.props;

    const toSend = {
      coupon: e.target.value,
      type: isHobby,
    };

    const fd = jsonToFormData(toSend);

    if (e.target.value !== "") {
      getCoupon(fd).then((res) => {
        if (res.data.isCoupon) {
          this.setState(
            {
              data: {
                ...this.state.data,
                coupon: true,
                is_voucher: res.data.is_voucher,
                id_voucher: res.data.is_voucher ? res.data.id_voucher : null,
                coupon_value: res.data.coupon_value,
                id_coupon: res.data.id_coupon,
                coupon_submitted: true,
              },
            },
            () => {
              this.countPrice(
                this.state.numberOfTerms,
                data.kids.length,
                this.state.data.additional,
                isHobby,
                data.kids
              );
            }
          );
        } else {
          this.setState(
            {
              data: {
                ...this.state.data,
                coupon: false,
                coupon_value: "",
                id_coupon: null,
                id_voucher: null,
                is_voucher: false,
                coupon_submitted: true,
              },
            },
            () => {
              this.countPrice(
                this.state.numberOfTerms,
                data.kids.length,
                this.state.data.additional,
                isHobby,
                data.kids
              );
            }
          );
        }
      });
    } else {
      this.setState(
        {
          data: {
            ...this.state.data,
            coupon: false,
            coupon_value: "",
            id_coupon: null,
            id_voucher: null,
            is_voucher: false,
            coupon_submitted: false,
          },
        },
        () => {
          this.countPrice(
            this.state.numberOfTerms,
            data.kids.length,
            this.state.data.additional,
            isHobby,
            data.kids
          );
        }
      );
    }
  };

  onChangeRecommendId = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        id_recommended: Number(e.target.value),
      },
    });

    getRecommend(e.target.value).then((res) => {
      if (res.exists) {
        this.setState({
          data: {
            ...this.state.data,
            additional: 0,
          },
        });
      } else {
        this.setState({
          data: {
            ...this.state.data,
            additional: 0,
            id_recommended: 0,
          },
        });
      }
    });
  };

  onChangeRecommend = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        recommend: e.target.value,
      },
    });
  };

  onChangeCredits = (e) => {
    console.log(
      this.state.data.credits,
      e.target.value,
      this.props.user.credits
    );
    this.setState(
      {
        data: {
          ...this.state.data,
          credits:
            e.target.value <= this.props.user.credits
              ? e.target.value
              : this.props.user.credits,
        },
      },
      () => {
        this.countPrice(
          this.state.numberOfTerms,
          this.props.data.kids.length,
          this.state.data.additional,
          this.props.isHobby,
          this.props.data.kids
        );
      }
    );
  };

  countPrice = (dates, num_of_kids, add, isHobby, kids) => {
    let price = 0;
    let free = false;

    kids.map((item, index) => {
      item.kids_camps.map((camp, index_camp) => {
        if (camp.full_price === 0 && camp.lower_price === 0) {
          free = true;
        }

        if (camp.is_full === 0) {
          if (num_of_kids === 1) {
            price = price + camp.full_price * camp.num_of_lectures;
          } else if (num_of_kids > 1) {
            price = price + camp.lower_price * camp.num_of_lectures;
          }
        } else {
          price = price + 0;
        }
      });
    });

    if (this.state.data.coupon) {
      price = price - this.state.data.coupon_value;
    }

    if (this.state.data.credits) {
      price = price - this.state.data.credits;
    }

    this.setState({
      total_price: price,
      isFree: free,
    });
  };

  renderTerms = (items) => {
    return items.map((term, index) => {
      return (
        <div key={index}>
          <DateSelected full={term.is_full === 1}>
            <div className="row">
              <div className="col-sm-4">{term.camp_name}</div>
              <div className="col-sm-4">
                {moment(term.date_from).format("D. M.")} -{" "}
                {moment(term.date_to).format("D. M. YYYY")}
                <br />
                {moment(term.date_from).format("dddd")}{" "}
                {moment(term.start_time).format("H:mm")}
                {term.end_time && (
                  <> - {moment(term.end_time).format("H:mm")}</>
                )}
              </div>
              <div className="col-sm-4">{term.location_address}</div>
            </div>
          </DateSelected>
          {term.is_full === 1 && (
            <EmbedMessage type="error">
              <p>Na tento termín se přihlašujete jako náhradník</p>
            </EmbedMessage>
          )}
        </div>
      );
    });
  };

  renderPrice = () => {
    const { data, isHobby } = this.props;
    const NORMAL_PRICE = 1800;
    const LOWER_PRICE = 1800;

    return (
      <div>
        <label>{lang.sessions}</label>
        {data.kids.map((item, index) => {
          return <div key={index}>{this.renderTerms(item.kids_camps)}</div>;
        })}
        <Form>
          <Form.Field>
            <label>{lang.number_of_activities}</label>
            <input type="text" value={this.state.numberOfTerms} disabled />
          </Form.Field>
          <Form.Field>
            <label>{lang.number_of_children}</label>
            <input type="text" value={data.kids.length} disabled />
          </Form.Field>
          <Form.Field>
            <label>{lang.note}</label>
            <textarea
              value={this.state.data.note}
              name="application_note"
              onChange={this.onChange}
            />
          </Form.Field>
          {!this.props.loadingUser && this.props.user && (
            <Form.Input
              type="number"
              label={`Použít kredity (na Vašem kontě je ${this.props.user.credits} kreditů)`}
              placeholder={`Můžete použít až ${this.props.user.credits} kreditů`}
              max={this.props.user.credits ? this.props.user.credits : 0}
              min="0"
              onChange={this.onChangeCredits}
              value={this.state.data.credits}
            />
          )}
          <Form.Input
            type="text"
            name="coupon"
            id="coupon"
            placeholder={lang.discount_code}
            label={lang.discount_code}
            className={
              !this.state.data.coupon_submitted
                ? "empty"
                : this.state.data.coupon_submitted && this.state.data.coupon
                ? "coupon_check"
                : "coupon_x"
            }
            icon={
              !this.state.data.coupon_submitted
                ? "question"
                : this.state.data.coupon_submitted && this.state.data.coupon
                ? "check"
                : "times"
            }
            onChange={this.onChangeCoupon}
          />
          <Form.Field>
            <label>{lang.total_price}</label>
            <label className="danger">
              {this.state.numberOfSubs !== 0 && lang.summary_substutite}
            </label>
            <h3>{this.state.total_price},- Kč</h3>
          </Form.Field>
        </Form>
      </div>
    );
  };

  lastItems = () => {
    return (
      <Form>
        <Form.Field>
          <Checkbox
            onChange={this.handleCheckbox}
            name="parent_tos"
            label={
              <label>
                <a
                  href="https://www.sportjoy.cz/cz/vseobecne-podminky-krouzky"
                  target="_blank"
                >
                  {lang.terms_and_conditions}
                </a>
              </label>
            }
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            onChange={this.handleCheckbox}
            name="parent_gdpr"
            label={
              <label>
                <a
                  href="https://www.sportjoy.cz/cz/ochrana-osobnich-udaju-a-gdpr"
                  target="_blank"
                >
                  {lang.agree_gdpr}
                </a>
              </label>
            }
          />
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={
              <Checkbox
                onChange={this.handleCheckbox}
                name="parent_photos"
                label={<label>{lang.agree_photos}</label>}
              />
            }
            wide
          >
            <span className="form-desc">{lang.agree_photos_desc}</span>
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={
              <Checkbox
                onChange={this.handleCheckbox}
                name="parent_newsletter"
                label={lang.agree_newsletter}
              />
            }
            wide
          >
            <span className="form-desc">{lang.agree_newsletter_desc}</span>
          </Popup>
        </Form.Field>
      </Form>
    );
  };

  invoicingFields = () => {
    return (
      <Form>
        <Form.Field>
          <Checkbox
            onChange={this.handleCheckbox}
            name="invoice"
            label={<label>{lang.i_want_invoice}</label>}
          />
        </Form.Field>
        {this.state.data.invoice && (
          <>
            <Form.Input
              name="reg_num"
              type="text"
              label={lang.employer_reg_num}
              value={this.state.data.reg_num}
              onChange={this.onChange}
              required
            />
            <Form.TextArea
              name="invoice_text"
              type="text"
              label={lang.invoice_text}
              placeholder={lang.invoice_text_placeholder}
              value={this.state.data.invoice_text}
              onChange={this.onChange}
              required
            />
            <Form.Input
              name="due_date"
              type="text"
              label={lang.invoice_due_date}
              placeholder={lang.invoice_due_date_placeholder}
              value={this.state.data.due_date}
              onChange={this.onChange}
              required
            />
          </>
        )}
      </Form>
    );
  };

  onChange = (e) =>
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

  handleCheckbox = (e, data) => {
    this.setState({
      data: {
        ...this.state.data,
        [data.name]: data.checked,
      },
    });
  };

  render() {
    const { loading } = this.props;

    return (
      <div>
        <FormSection>
          <FormSectionHead>
            <h5>{lang.parents_contact_info}</h5>
          </FormSectionHead>
          <FormSectionBody>
            <div className="row">{this.parentsBlock()}</div>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.children_registered}</h5>
          </FormSectionHead>
          <FormSectionBody>
            <div className="row">{this.kidsBlock()}</div>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.sessions_summary}</h5>
          </FormSectionHead>
          <FormSectionBody>{this.renderPrice()}</FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.invoicing}</h5>
          </FormSectionHead>
          <FormSectionBody>{this.invoicingFields()}</FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.conclusion}</h5>
          </FormSectionHead>
          <FormSectionBody>{this.lastItems()}</FormSectionBody>
        </FormSection>

        <FormButtonGroup>
          {this.state.submit && (
            <Button
              className="btn_end"
              loading={loading}
              disabled={
                !this.state.data.parent_gdpr ||
                !this.state.data.parent_tos ||
                loading
                  ? true
                  : false
              }
              onClick={this.submitHandler}
            >
              {lang.submit}
            </Button>
          )}
          {!this.state.submit && (
            <span
              className="bold"
              style={{
                textAlign: "center",
                fontWeight: "900",
                color: "red",
                display: "block",
              }}
            >
              {lang.fill_additional_before_submit}
            </span>
          )}
        </FormButtonGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default connect(mapStateToProps, {})(ConfirmForm);
