import axios from "axios";
import { url } from "../config";

export function selectDate(data) {
  return axios({
    method: "post",
    url:
      url.api + "employees/select-date/" + `&timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getDates(data) {
  return axios({
    method: "post",
    url:
      url.api + "employees/get-dates/" + `&timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getEmployeesDates(data) {
  return axios({
    method: "post",
    url:
      url.api +
      "employees/get-employees-dates/" +
      `&timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function switchDateState(data) {
  return axios({
    method: "post",
    url:
      url.api +
      "employees/switch-date-state/" +
      `&timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getEmployeesProfile(data) {
  return axios({
    method: "post",
    url:
      url.api +
      "employees/get-employees-profile/" +
      `&timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function updateProfile(data) {
  return axios({
    method: "post",
    url:
      url.api +
      "employees/update-profile/" +
      `&timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function deleteDate(data) {
  return axios({
    method: "post",
    url: url.api + `employees/delete-date?timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getEmployees(id_date, showRejected = 0, filters) {
  return axios({
    method: "get",
    url: `${
      url.api
    }employees/get-employees?id_date=${id_date}&show_rejected=${showRejected}&${filters}&timestamp=${new Date().getTime()}`,
  });
}

export function getEmailFromToken(token) {
  return axios({
    method: "get",
    url: `${
      url.api
    }employees/get-email-from-token?token=${token}&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function createUser(data) {
  return axios({
    method: "post",
    url: url.api + "employees/create-user/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function createEmployee(id_app) {
  return axios({
    method: "get",
    url:
      url.api +
      "employees/create-employee?id_app=" +
      id_app +
      `&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function uploadFile(data) {
  return axios({
    method: "post",
    url: url.api + "employees/upload/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getFilename(type, id_user) {
  return axios({
    method: "get",
    url:
      url.api +
      "employees/get-filename?type=" +
      type +
      "&id_user=" +
      id_user +
      `&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getReferral(id_user) {
  return axios({
    method: "get",
    url:
      url.api +
      "employees/get-referral?&id_user=" +
      id_user +
      `&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function resetPassword(email, type = "reset") {
  return axios({
    method: "get",
    url:
      url.api +
      "employees/forgot-password?email=" +
      email +
      "&type=" +
      type +
      `&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function setNewPassword(data) {
  return axios({
    method: "post",
    url: url.api + "employees/set-new-password/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function setIsLeader(data) {
  return axios({
    method: "post",
    url: url.api + "employees/set-leader/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getNote(id_user) {
  return axios({
    method: "get",
    url:
      url.api +
      "employees/get-note?id_user=" +
      id_user +
      `&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function updateNote(data) {
  return axios({
    method: "post",
    url: url.api + "employees/update-note/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function updateDots(data) {
  return axios({
    method: "post",
    url: url.api + "employees/update-dots/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function sendEmail(data) {
  return axios({
    method: "post",
    url: url.api + `employees/send-email?timestamp=${new Date().getTime()}`,
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getListOfKids(id_date) {
  return axios({
    method: "get",
    url:
      url.api +
      "employees/list-of-kids?id_date=" +
      id_date +
      `&timestamp=${new Date().getTime()}`,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}
