import React from "react";
import { Toasts, Toast } from "../../styles";
import { useSelector } from "react-redux";

const MessagesContainer = () => {
  const messages = useSelector((state) => state.messages.items);

  const renderToasts = () => {
    return messages.map(
      (item: { message?: string; text?: string; type: string }) => {
        return (
          <Toast type={item.type} key={new Date().getTime()}>
            <p>
              {item.text} {item.message}
            </p>
          </Toast>
        );
      }
    );
  };

  return <Toasts>{renderToasts()}</Toasts>;
};

export default MessagesContainer;
