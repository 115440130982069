import React, { Component } from 'react'
import { Card, CardControls, CardHeader, CardBody } from '../../styles'
import { Link } from 'react-router-dom'
import { getToken, getContacts, searchContact, sendNewsletter } from '../../api'
import { connect } from 'react-redux'
import AddressBookTable from '../containers/AddressBookTable'
import _ from 'loadsh'
import { Dimmer, Loader, Input, Modal, Form, Button } from 'semantic-ui-react'
import RichTextEditor from 'react-rte'
import { setMessage } from '../../actions/messageActions'

class AddressBookView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            contacts: [],
            baseData: [],
            loading: true,
            column: 'id_contact',
            direction: 'descending',
            filter: false,
            loadingSearch: false,
            newsletterModal: false,
            newsletter: {
                heading: '',
                subject: '',
                message: RichTextEditor.createEmptyValue()
            },
            loadingSending: false
        })
    }

    componentDidMount() {

        getToken().then((token) => {
            const data = {
                token: token
            }

            if(!this.props.loadingUser) {

                getContacts(data, this.props.user.id_organization)
                .then((data) => {
                    this.setState({
                        contacts: data,
                        baseData: data,
                        loading: false
                    })
                })
            }
            
        })
    }

    onChangeRte = (value) => {
        this.setState({
            newsletter: {
                ...this.state.newsletter,
                message: value
            }
        });
    }

    onChangeNewsletter = (e) => this.setState({
        newsletter: {
            ...this.state.newsletter,
            [e.target.name]: e.target.value
        }
    })

    handleSort = (clickedColumn) => () => {
        const { column, contacts, direction } = this.state
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            contacts: _.sortBy(contacts, [clickedColumn]),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            contacts: contacts.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    openNewsletter = () => {
        this.setState({
            newsletterModal: true
        })
    }

    closeNewsletter = () => {
        this.setState({
            newsletterModal: false
        })
    }

    searchHandler = (q) => {

        this.setState({
            loadingSearch: true
        })

        searchContact(q)
        .then((data) => {
            this.setState({
                contacts: data,
                loadingSearch: false
            })
        })
    }

    onChange = (e) => this.setState({
        [e.target.name]: e.target.value
    }, () => {
        this.searchHandler(this.state.search_query)
    })

    submitNewsletter = () => {

        this.setState({
            loadingSending: true
        })

        getToken().then((token) => {
            const data = {
                token: token,
                subject: this.state.newsletter.subject,
                heading: this.state.newsletter.heading,
                message: this.state.newsletter.message.toString('html')
            }

            sendNewsletter(data)
            .then((res) => { 
                this.props.setMessage(res)
                this.closeNewsletter()

                this.setState({
                    newsletter: {
                        heading: '',
                        subject: '',
                        message: RichTextEditor.createEmptyValue()
                    },
                    loadingSending: false
                })
             })
        })

    }

    _renderNewsletterModal = () => {
        return (
            <Modal open={this.state.newsletterModal} onClose={this.closeNewsletter}>
                <Modal.Header>Nový newsletter</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Předmět newsletteru</label>
                            <Input 
                                type='text'
                                name='subject'
                                onChange={this.onChangeNewsletter}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Nadpis</label>
                            <Input 
                                type='text'
                                name='heading'
                                onChange={this.onChangeNewsletter}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Zpráva</label>
                            <RichTextEditor
                                value={this.state.newsletter.message}
                                onChange={this.onChangeRte}
                            />
                        </Form.Field>
                    </Form>
                    <Modal.Description>
                        <p>Zpráva bude odeslána všem uživatelům, kteří souhlasili s odesíláním newsletterů na jejich email.</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.closeNewsletter}>
                        Zpět
                    </Button>
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content="Odeslat newsletter"
                        onClick={this.submitNewsletter}
                        loading={this.state.loadingSending}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <div className='row'>
                        <div className='col-sm-9 text-left'>
                            <h2>Kontakty</h2>
                        </div>
                        <div className='col-sm-3 text-right'>
                            <Link to={`${process.env.PUBLIC_URL}/user/contacts/newsletter`}>
                                <Button icon="\ef14">Nový newsletter</Button>
                            </Link>
                        </div>
                    </div>
                </CardHeader>
                <CardControls>
                    <div className='row'>
                        <div className='col-sm-6 text-left'>
                            <Input 
                                placeholder='Hledat...'
                                fluid
                                icon='search'
                                name='search_query'
                                onChange={this.onChange}
                                loading={this.state.loadingSearch}
                            />
                        </div>
                    </div>
                </CardControls>
                <AddressBookTable items={this.state.contacts} sort={this.handleSort} column={this.state.column} direction={this.state.direction} />
                {this._renderNewsletterModal()}
                <Dimmer active={this.state.loading}>
                    <Loader>Načítám data</Loader>
                </Dimmer>
            </Card>
        )
    }

}


const mapStateToProps = state => ({
    user: state.user.user,
    loadingUser: state.user.loading
})
  
export default connect(mapStateToProps, { setMessage })(AddressBookView)