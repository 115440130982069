import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import { Form } from "semantic-ui-react";
import { formatMoney, getToken, itemEmailHistory } from "../../../api";
import { getApplication } from "../../../api/parents";
import { convertEmailType } from "../../../helpers";
import { Card, CardHeader, CardBody, EmailItem } from "../../../styles";
import { jsonToFormData } from "../../../tools";
import KidsDates from "../../containers/parents/KidsDates";
import PaymentContainer from "../../containers/parents/PaymentContainer";

const ParentsApplicationDetailView = () => {
  const { id } = useParams();
  const [application, setApplication] = useState({});
  const [loading, setLoading] = useState(true);
  const [emailHistory, setEmailHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [hasHistory, setHasHistory] = useState(false);

  document.title = `Detail přihlášky #${id} - SPORTJOY`;

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    setLoadingHistory(true);

    getToken().then((token) => {
      const data = {
        id_application: id,
        token: token,
        id_send_to: id,
      };

      const payload = jsonToFormData(data);

      getApplication(payload).then((res) => {
        setApplication(res.data);
        setLoading(false);
      });

      itemEmailHistory(data).then((data) => {
        setEmailHistory(data);
        setHasHistory(data.type && data.type === "error" ? false : true);
        setLoadingHistory(false);
      });
    });
  }

  function renderEmailsHistory() {
    return (
      <div>
        {!loadingHistory &&
          hasHistory &&
          emailHistory.map((item) => {
            return (
              <EmailItem key={item.id_email}>
                <div className="row align-items-center">
                  <div className={"col-sm-3"}>
                    <p className="bold">{convertEmailType(item.email_type)}</p>
                  </div>

                  <div className="col-sm-5">
                    <p>{item.email_send_to}</p>
                  </div>

                  <div className={"col-sm-4"}>
                    <p>{moment(item.created).format("LLL")}</p>
                  </div>
                </div>
              </EmailItem>
            );
          })}
        {loadingHistory && (
          <EmailItem>
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>Načítám historii</p>
              </div>
            </div>
          </EmailItem>
        )}
        {!loadingHistory && !hasHistory && (
          <EmailItem>
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>U této přihlášky není historie zpráv dostupná</p>
              </div>
            </div>
          </EmailItem>
        )}
      </div>
    );
  }

  return (
    <>
      <Row className="mb-5">
        <Col sm={8}>
          <Card className="mb-5">
            <CardHeader>Přihláška #{id}</CardHeader>
            <CardBody>
              <Form loading={loading}>
                <Form.Input
                  name="id"
                  label="ID přihlášky"
                  value={id}
                  readOnly
                  disabled
                  type="text"
                />
                <Form.Input
                  name="created"
                  label="Vytvořeno"
                  value={moment(application?.created).format("DD.MM.YYYY H:mm")}
                  readOnly
                  disabled
                  type="text"
                />
                <Form.Input
                  name="total_price"
                  label="Celková cena"
                  value={`${formatMoney(
                    application?.total_price,
                    0,
                    ",",
                    " "
                  )},- Kč`}
                  readOnly
                  disabled
                  type="text"
                />
                <Form.Input
                  type="text"
                  name="parent_name"
                  label="Jméno rodiče"
                  value={application?.parent_name}
                  readOnly
                  disabled
                />
                <Form.Input
                  type="text"
                  name="parent_lastname"
                  label="Příjmení rodiče"
                  value={application?.parent_lastname}
                  readOnly
                  disabled
                />
                <Form.Input
                  type="email"
                  name="parent_email"
                  label="Email"
                  value={application?.parent_email}
                  readOnly
                  disabled
                />
                <Form.Input
                  type="text"
                  name="parent_phone"
                  label="Telefon"
                  value={application?.parent_phone}
                  readOnly
                  disabled
                />
                <Form.Input
                  type="text"
                  name="invoicing"
                  label="Žádost o fakturu"
                  value={application?.invoicing ? "ANO" : "NE"}
                  readOnly
                  disabled
                />
                <Form.TextArea
                  name="note"
                  label="Poznámka"
                  value={application?.note}
                  readOnly
                  disabled
                />
              </Form>
            </CardBody>
          </Card>
          {!loading &&
            application?.kids.map((item) => {
              return <KidsDates key={item.id_kids} kid={item} refresh={getData} />;
            })}
        </Col>
        <Col sm={4}>
          <Card className="mb-5">
            <CardHeader>Platba</CardHeader>
            <CardBody>
              <PaymentContainer
                payment={application.payment}
                loading={loading}
              />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Historie emailů</CardHeader>
            <CardBody>{renderEmailsHistory()}</CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ParentsApplicationDetailView;
