import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { AppDotBtn } from "../../../styles";

function Dot({ id_user, value, name, onChange, loadingDots }) {
  const [dotColor, setColor] = useState("");
  const [loading, setLoading] = useState(loadingDots);

  useEffect(() => {
    color();
  }, []);

  useEffect(() => {
    setLoading(loadingDots);
  }, [loadingDots]);

  function color() {
    switch (name) {
      case "dot4":
        return setColor("#2dce89");
      case "dot1":
        return setColor("#f5365c");
      case "dot2":
        return setColor("#fb6340");
      case "dot3":
        return setColor("#5e72e4");
      default:
        return setColor("#000");
    }
  }

  function onclick() {
    setLoading(true);
    let newVal = value === 1 ? 0 : 1;
    onChange(id_user, name, newVal);
  }

  function iconName() {
    if (!loading && value === 1) {
      return "circle";
    } else if (!loading && !value) {
      return "circle outline";
    } else if (loading) {
      return "spinner";
    }
  }

  return (
    <>
      <AppDotBtn icon onClick={() => onclick()}>
        <Icon
          className={loading ? "spinning" : ""}
          style={{ color: dotColor }}
          name={iconName()}
        />
      </AppDotBtn>
    </>
  );
}

export default Dot;
