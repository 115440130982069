import styled from "styled-components";

export const BillsEmptyContainer = styled.div`
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  background: #f6f6f6;
`;
