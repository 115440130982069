import React, { Component } from "react";
import { Card, CardBody, CardHeader, EmptyContainer } from "../../styles";
import { EditUserForm } from "../forms";
import {
  getUsersOrganization,
  logUser,
  getToken,
  getUser,
  updateUser,
  updateReferral,
} from "../../api";
import { setMessage } from "../../actions/messageActions";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import AddCreditModal from "../modals/AddCreditModal";
import { Button, Form, Table } from "semantic-ui-react";
import moment from "moment";
import { jsonToFormData } from "../../tools";
import ChangeEmailForm from "../forms/parents/ChangeEmailForm";

class EditUserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationsValues: [],
      user: {
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        user_admin: 2,
        user_organization: "",
        user_passwd: "",
        user_passwd_check: "",
        credits: "",
      },
      errors: {
        user_first_name: false,
        user_last_name: false,
        user_email: false,
        user_admin: false,
        user_organization: false,
        user_passwd: false,
        user_passwd_check: false,
      },
      isNew: false,
      loading: true,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    getUsersOrganization().then((data) =>
      this.setState({ organizationsValues: data })
    );

    this.refresh();

    id === "new" && this.setState({ isNew: true, loading: false });
  }

  refresh = () => {
    const { id } = this.props.match.params;

    if (id !== "new") {
      getUser(id)
        .then((data) => {
          this.setState({
            user: data,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  onChange = (e) => {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...this.state.errors,
        [e.target.name]: false,
      },
    });
  };

  onChangeSelect = (e, data) =>
    this.setState({
      user: {
        ...this.state.user,
        [data.name]: data.value,
      },
      errors: {
        ...this.state.errors,
        [data.name]: false,
      },
    });

  submit = () => {
    const { setMessage } = this.props;
    const { user } = this.state;
    const { id } = this.props.match.params;

    if (user.user_email !== "") {
      if (user.user_passwd !== "" || user.user_passwd_check !== "") {
        if (user.user_passwd === user.user_passwd_check) {
          if (user.user_first_name !== "") {
            if (user.user_last_name !== "") {
              if (user.user_organization !== "") {
                getToken().then((idToken) => {
                  this.setState(
                    {
                      user: {
                        ...this.state.user,
                        token: idToken,
                      },
                    },
                    () => {
                      if (id === "new") {
                        logUser(this.state.user).then((res) => {
                          setMessage(res);
                        });
                      } else {
                        const data = {
                          ...this.state.user,
                          token: idToken,
                          id_user: id,
                        };
                        const payload = jsonToFormData(data);

                        updateUser(payload).then((res) => {
                          this.refresh();
                        });
                      }
                    }
                  );
                });
              } else {
                setMessage({ type: "error", text: "Není vybrána organizace" });
                this.setState({
                  errors: { ...this.state.errors, user_organization: true },
                });
              }
            } else {
              setMessage({
                type: "error",
                text: "Příjmení uživatele není vyplněné",
              });
              this.setState({
                errors: { ...this.state.errors, user_last_name: true },
              });
            }
          } else {
            setMessage({
              type: "error",
              text: "Jméno uživatele není vyplněné",
            });
            this.setState({
              errors: { ...this.state.errors, user_first_name: true },
            });
          }
        } else {
          setMessage({ type: "error", text: "Hesla se neshodují" });
          this.setState({
            errors: { ...this.state.errors, user_passwd_check: true },
          });
        }
      } else {
        setMessage({ type: "error", text: "Heslo není vyplněné" });
        this.setState({ errors: { ...this.state.errors, user_passwd: true } });
      }
    } else {
      setMessage({ type: "error", text: "Email uživatele není vyplněný" });
      this.setState({ errors: { ...this.state.errors, user_email: true } });
    }
  };

  _updateReferral = () => {
    const { id } = this.props.match.params;
    const payload = {
      id_user: id,
      referral: this.state.user.referral,
    };
    const fd = jsonToFormData(payload);
    updateReferral(fd)
      .then((res) => {
        this.props.setMessage(res.data);
      })
      .catch((err) => {
        this.props.setMessage({ type: "error", text: err.message });
      });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Row>
        <Col sm={8}>
          <Card className="mb-5">
            <CardHeader>Základní informace</CardHeader>
            <CardBody>
              <EditUserForm
                loading={this.state.loading}
                isNew={this.state.isNew}
                user={this.state.user}
                errors={this.state.errors}
                onChange={this.onChange}
                onChangeSelect={this.onChangeSelect}
                values={this.state.organizationsValues}
                submit={this.submit}
              />
            </CardBody>
          </Card>
          {id !== "new" && (
            <Card>
              <CardHeader>Změnit email</CardHeader>
              <CardBody>
                <ChangeEmailForm />
              </CardBody>
            </Card>
          )}
        </Col>
        <Col sm={4}>
          {id !== "new" && (
            <>
              <Card className="mb-5">
                <CardHeader>Kód doporučení</CardHeader>
                <CardBody>
                  <Form onSubmit={this._updateReferral}>
                    <Form.Input
                      name="referral"
                      type="text"
                      label="Kód doporučení"
                      value={this.state.user.referral}
                      onChange={this.onChange}
                    />
                    <Button fluid primary>
                      Uložit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
              <Card className="mb-5">
                <CardHeader>Kredity</CardHeader>
                <CardBody>
                  <Row className="m-0">
                    <Form className="w-100 mb-3">
                      <Form.Input
                        type="text"
                        label="Stav využitelných kreditů"
                        value={this.state.user.credits}
                        disabled
                      />
                    </Form>
                    <Form className="w-100 mb-3">
                      <Form.Input
                        type="text"
                        label="Stav budoucích kreditů"
                        value={this.state.user.future_credits}
                        disabled
                      />
                    </Form>
                  </Row>
                  <Row className="m-0">
                    <Col sm={12} className="p-0 mb-2">
                      <AddCreditModal
                        refresh={this.refresh}
                        id_user={id}
                        type="add"
                      />
                    </Col>
                    <Col sm={12} className="p-0 mb-3">
                      <AddCreditModal
                        refresh={this.refresh}
                        id_user={id}
                        type="remove"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>Historie stavu kreditů</CardHeader>
                {!this.state.loading &&
                this.state.user.credits_log.length !== 0 ? (
                  <Table striped className="main-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Datum</Table.HeaderCell>
                        <Table.HeaderCell>Počet</Table.HeaderCell>
                        <Table.HeaderCell>Typ</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {!this.state.loading &&
                        this.state?.user?.credits_log.map((item) => {
                          return (
                            <Table.Row>
                              <Table.Cell>
                                {moment(item.datetime).format(
                                  "DD.MM.YYYY H:mm"
                                )}
                              </Table.Cell>
                              <Table.Cell>{item.amount}</Table.Cell>
                              <Table.Cell>
                                {item.type === "add" ? "Přidáno" : "Odebráno"}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                ) : (
                  <EmptyContainer>
                    <span>Žádná data k zobrazení</span>
                  </EmptyContainer>
                )}
              </Card>
            </>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setMessage })(EditUserView);
