import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

type Props = {
  referral: string;
};

export const QRModal: React.FC<Props> = ({ referral }) => {
  return (
    <Modal
      trigger={
        <Button content="Váš QR kód" icon="qrcode" labelPosition="left" />
      }
      size="mini"
    >
      <Modal.Header>
        <span>QR kód</span>
      </Modal.Header>
      <Modal.Content>
        <img
          src={`https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=https://app.sportjoy.cz/embed/signup/camps/cs?referral=${referral}`}
          alt="QR kód"
          style={{ maxWidth: "100%" }}
        />
      </Modal.Content>
    </Modal>
  );
};
