import React from "react";
import { Card, CardHeader, CardBody } from "../../../styles";
import { Row, Col } from "reactstrap";
import EmployeeProfileForm from "../../forms/employees/EmployeeProfileForm";
import { useSelector } from "react-redux";

function EmployeeProfileView() {
  const user = useSelector((state) => state.user.user);
  const { id_user } = user;

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardHeader>Profil uživatele</CardHeader>
          <CardBody>
            <EmployeeProfileForm id_user={id_user} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default EmployeeProfileView;
