import React, { Component } from "react";
import { toggleDateState } from "../../api";
import { Table, Button, Popup, Icon } from "semantic-ui-react";
import _ from "loadsh";
import moment from "moment";
import { Col, Row } from "reactstrap";
import LocationInfoModal from "../modals/LocationInfoModal";
import { isMobile } from "react-device-detect";
import DateItemMobile from "./mobile/DateItemMobile";

class DateItemsHobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idToDelete: 2,
      show: props.type === "homepage" ? false : true,
      items: [],
      baseItems: [],
    };
  }

  toggleStateHandler = (id) => {
    toggleDateState(id).then(() => {
      this.props.refresh();
    });
  };

  toggleModal = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      idToDelete: !this.state.showModal ? id : "",
    });
  };

  handleShow = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  fullClass = (is_full, only_school) => {
    if (is_full === 1) {
      return "full_date";
    }

    if (only_school === 1) {
      return "full_date";
    }
  };

  render() {
    const { items, sort, column, direction } = this.props;

    return (
      <div className="relative">
        <div
          className={
            this.state.show
              ? "collapsable-table show"
              : "collapsable-table hidden"
          }
        >
          {!isMobile ? (
            <Table sortable striped className="embed-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === "camp_name" ? direction : null}
                    onClick={sort("camp_name")}
                  >
                    Aktivita
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "date_from" ? direction : null}
                    onClick={sort("date_from")}
                  >
                    Od
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "date_to" ? direction : null}
                    onClick={sort("date_to")}
                  >
                    Do
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "camp_age" ? direction : null}
                    onClick={sort("camp_age")}
                  >
                    Věk
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "location_name" ? direction : null}
                    onClick={sort("location_name")}
                  >
                    Lokalita
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "display_price" ? direction : null}
                    onClick={sort("display_price")}
                  >
                    Cena
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    sorted={column === "date_capacity" ? direction : null}
                    onClick={sort("date_capacity")}
                  >
                    Volná místa
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(
                  items,
                  ({
                    id_date,
                    camp_name,
                    display_price,
                    end_time,
                    camp_logo,
                    min_age,
                    max_age,
                    camp_description,
                    id_camp,
                    only_school_kids,
                    date_from,
                    date_to,
                    date_signed,
                    location_name,
                    date_capacity,
                    is_full,
                    location_address,
                    num_of_lectures,
                    start_time,
                    lat,
                    long,
                  }) => (
                    <Table.Row
                      key={id_date}
                      className={
                        is_full === 1
                          ? "full_date"
                          : only_school_kids
                          ? "full_date"
                          : ""
                      }
                    >
                      <Table.Cell width="2">
                        <Popup
                          trigger={
                            <img
                              className="img-fluid"
                              alt={camp_name}
                              src={
                                "https://api.sportjoy.cz/media/upload/camp/" +
                                id_camp +
                                "/" +
                                camp_logo
                              }
                            />
                          }
                        >
                          {camp_description}
                        </Popup>
                      </Table.Cell>
                      <Table.Cell className="date date_from">
                        <span className="only-phone display-block">Od: </span>
                        {moment(date_from).format("DD.MM.YYYY")}
                        <br />
                        {moment(date_from).format("dddd")}
                        <br />
                        {moment(start_time).format("H:mm")}
                        {end_time && <> - {moment(end_time).format("H:mm")}</>}
                      </Table.Cell>
                      <Table.Cell className="date date_to">
                        <span className="only-phone display-block">Do: </span>
                        {moment(date_to).format("DD.MM.YYYY")}
                      </Table.Cell>
                      <Table.Cell className="date date_to">
                        <span className="only-phone display-block">Věk: </span>
                        {min_age}-{max_age} let
                      </Table.Cell>
                      <Table.Cell>
                        <Row>
                          <Col>
                            <span className="only-phone display-block">
                              Adresa:{" "}
                            </span>
                            <p>
                              <Popup
                                trigger={
                                  <strong className="location_name_trigger">
                                    <Icon name="map marker alternate" />{" "}
                                    {location_name}
                                  </strong>
                                }
                                content={
                                  <LocationInfoModal
                                    lat={lat}
                                    long={long}
                                    loc_name={location_name}
                                  />
                                }
                                on="click"
                              />
                              <br />
                              {location_address}
                            </p>
                          </Col>
                        </Row>
                      </Table.Cell>
                      <Table.Cell className="capacity">
                        {display_price}&nbsp;Kč
                      </Table.Cell>
                      <Table.Cell className="capacity">
                        <span className="only-phone display-block">
                          Volná místa:{" "}
                        </span>{" "}
                        {
                          /*(date_capacity - date_signed) <= 0 ? '0/' + date_capacity : (date_capacity - date_signed) + '/' + date_capacity}*/ is_full ===
                          0
                            ? "Volné"
                            : "Obsazené"
                        }
                        {only_school_kids === 1 && (
                          <p className="small">Pouze pro žáky školy</p>
                        )}
                      </Table.Cell>
                      <Table.Cell className="application_button">
                        <Button
                          className="btn"
                          href="https://app.sportjoy.cz/embed/prihlaska/hobby/"
                          target="_blank"
                        >
                          {is_full === 0 ? "Přihláška" : "Jako náhradník"}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          ) : (
            <>
              {items.map((item) => {
                return <DateItemMobile date={item} isWeekly={true} />;
              })}
            </>
          )}
          {this.props.type === "homepage" && (
            <div className="table-buttons">
              <Button onClick={this.handleShow}>
                {!this.state.show ? "Ukázat vše" : "Schovat"}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DateItemsHobby;
