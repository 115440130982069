import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { NavLink } from "react-router-dom";

const NavbarContainerMobile = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function renderLinks() {
    switch (props.userRole) {
      case 4:
        return (
          <>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/parents/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/parents/applications`}
                activeClassName="active"
              >
                Přihlášky
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/parents/profile`}
                activeClassName="active"
              >
                Můj profil
              </NavLink>
            </NavItem>
          </>
        );

      case 3:
        return (
          <>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/employee/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/employee/dates`}
                activeClassName="active"
              >
                Výběr termínů
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/employee/profile`}
                activeClassName="active"
              >
                Můj profil
              </NavLink>
            </NavItem>
          </>
        );

      default:
        return (
          <>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/dashboard`}
                activeClassName="active"
              >
                Nástěnka
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Přihlášky
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/camp/0`}
                    activeClassName="active"
                  >
                    Tábory
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/camp/1`}
                    activeClassName="active"
                  >
                    Tábory zdarma
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/hobby`}
                    activeClassName="active"
                  >
                    Kroužky
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/kurzy`}
                    activeClassName="active"
                  >
                    Kurzy
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/applications/skolka`}
                    activeClassName="active"
                  >
                    Školka
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/instructors?state=1`}
                    activeClassName="active"
                  >
                    Instruktoři
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/dates?date_state=1&type=1&year=2023`}
                activeClassName="active"
              >
                Termíny
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/locations`}
                activeClassName="active"
              >
                Lokace
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/camps`}
                activeClassName="active"
              >
                Tábory
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/manage/vouchers`}
                activeClassName="active"
              >
                Vouchery
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={`${process.env.PUBLIC_URL}/user/contacts`}
                activeClassName="active"
              >
                Kontakty
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Nastavení
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/organizations`}
                    activeClassName="active"
                  >
                    Správa organizací
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/users`}
                    activeClassName="active"
                  >
                    Správa uživatelů
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/settings/coupons`}
                    activeClassName="active"
                  >
                    Správa slevových kuponů
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/manage/referrals`}
                    activeClassName="active"
                  >
                    Přehled doporučení
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
    }
  }

  return (
    <Navbar
      color="light"
      fixed="top"
      light
      expand="md"
      className="mobile_navbar"
    >
      <NavbarBrand href="/">SPORTJOY</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {renderLinks()}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarContainerMobile;
