import React, { useState, useEffect } from 'react';
import { getPriceIncreases, sendIncreases } from '../../api';
import { Card, CardHeader } from '../../styles';
import IncreasesTable from '../tables/Increases/IncreasesTable';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../actions/messageActions';
import { Button } from 'semantic-ui-react';

function PriceIncreasesView() {

    const dispatch = useDispatch();
    const [ data, setData ] = useState([]);
    const [ loadingSend, setLoadingSend ] = useState(false);

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        getPriceIncreases()
        .then((res) => {
            setData(res)
        })
    }

    function sendEmails() {

        setLoadingSend(true);

        sendIncreases()
        .then((res) => {
            dispatch(setMessage(res));
            setLoadingSend(false);
        })
        .catch((err) => {
            dispatch(setMessage({type: 'error', text: 'Emaily se nepodařilo odeslat'}));
            setLoadingSend(false);
        })
    }

    return (
        <>
            <Card>
                <CardHeader className='d-flex justify-content-between'>Seznam plateb <Button loading={loadingSend} onClick={sendEmails}>Odeslat emaily</Button></CardHeader>
                <IncreasesTable data={data} refresh={getData} />
            </Card>
        </>
    )
}

export default PriceIncreasesView