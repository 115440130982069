import React, { Component } from "react";
import { StatsCard } from "../containers";
import {
  countApplications,
  countKids,
  todayApplications,
  todayKids,
  lastTenApplications,
  getToken,
  getCouponsStats,
  paidUnpaid,
  getNumberPaid,
} from "../../api";
import { LastTenApplicationsTable, CouponsStatsTable } from "../containers";
import { Card, CardHeader } from "../../styles";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import StatsSection from "../containers/StatsSection";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_applications: 0,
      total_kids: 0,
      today_applications: 0,
      applications_difference: 0,
      today_kids: 0,
      kids_difference: 0,
      paidUnpaid: {
        paid: 0,
        unpaid: 0,
        percentage: 0,
      },
      numberPaid: {
        paid: 0,
        total: 0,
        avg: 0,
      },
      lastTenApplications: [],
      couponsStats: [],
    };
  }

  componentDidMount() {}

  countApplicationsHandler = () => {
    countApplications().then((data) => {
      this.setState({
        total_applications: data.total_applications,
      });
    });
  };

  countKidsHandler = () => {
    countKids().then((data) => {
      this.setState({
        total_kids: data,
      });
    });
  };

  todayApplicationsHandler = () => {
    todayApplications().then((data) => {
      this.setState({
        today_applications: data.today_applications,
        applications_difference: data.difference,
      });
    });
  };

  todayKidsHandler = () => {
    todayKids().then((data) => {
      this.setState({
        today_kids: data.today_kids,
        kids_difference: data.difference,
      });
    });
  };

  paidUnpaidHandler = () => {
    paidUnpaid().then((data) => {
      this.setState({
        paidUnpaid: data,
      });
    });
  };

  lastTenApplicationsHandler = () => {
    getToken().then((token) => {
      const data = {
        token: token,
      };

      lastTenApplications(data).then((data) => {
        this.setState({
          lastTenApplications: data,
        });
      });
    });
  };

  couponsStatsHandler = () => {
    getCouponsStats().then((data) => {
      this.setState({
        couponsStats: data,
      });
    });
  };

  numberPaidHandler = () => {
    getNumberPaid().then((data) => {
      this.setState({
        numberPaid: data,
      });
    });
  };

  render() {
    return (
      <div>
        <StatsSection type="camp" sub="paid" />
        {/*
                <StatsSection type='hobby' sub='paid' />
                <StatsSection type='kurzy' sub='paid' />
                <StatsSection type='skolka' sub='paid' />
                 
                <div className='row dashboard-row'>
                    <div className={ user.user_admin ? 'col-sm-8' : 'col-sm-12'}>
                        <Card>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-sm-9 text-left'>
                                        <h2>Posledních 10 přihlášek</h2>
                                    </div>
                                    <div className='col-sm-3 text-right'>
                                        <Link to={`${process.env.PUBLIC_URL}/manage/applications`}><Button>Přejít na přihlášky</Button></Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <LastTenApplicationsTable items={this.state.lastTenApplications} />
                        </Card>
                    </div>
                    {user.user_admin &&
                    <div className='col-sm-4'>
                       <StatsCard type='paid-unpaid' value={this.state.paidUnpaid} />
                        <StatsCard type='number-paid' value={this.state.numberPaid} />
                        <Card>
                            <CardHeader>
                                <div className='row'>
                                    <div className='col-sm-6 text-left'>
                                        <h2>Statistiky kuponů</h2>
                                    </div>
                                    <div className='col-sm-6 text-right'>
                                        <Link to={`${process.env.PUBLIC_URL}/settings/coupons`}><Button>Přejít na kupony</Button></Link>
                                    </div>
                                </div>
                            </CardHeader>
                            <CouponsStatsTable items={this.state.couponsStats} />
                        </Card>
                    </div>  
                </div>
                */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default connect(mapStateToProps, {})(Dashboard);
