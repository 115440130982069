import axios from "axios";
import { url } from "../config";

export function getDates(data) {
  return axios({
    method: "post",
    url: url.api + "parents/get-dates/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getApplications(data) {
  return axios({
    method: "post",
    url: url.api + "parents/get-applications-list/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getApplication(data) {
  return axios({
    method: "post",
    url: url.api + "parents/get-application/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function changePassword(data) {
  return axios({
    method: "post",
    url: url.api + "parents/change-password/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function addCredits(data) {
  return axios({
    method: "post",
    url: url.api + "parents/add-credits/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function changeEmail(data) {
  return axios({
    method: "post",
    url: url.api + "employees/change-email/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}

export function getDatesToChange(data) {
  return axios({
    method: "post",
    url: url.api + "parents/get-changable-dates/",
    data: data,
    headers: {
      Token: "XFKBe%a+rKcrZeF!jVYsj6G23v7FS^f@DqG456$rmLSWEpM-Z8fYKGLLNUhK+#c4",
    },
  });
}