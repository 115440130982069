import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody } from "../../../styles";
import { Row, Col } from "reactstrap";
import DatesTable from "../../containers/employees/DatesTable";
import {
  getEmployeesDates,
  getEmployeesProfile,
  getReferral,
  switchDateState,
} from "../../../api/employees";
import { useParams } from "react-router-dom";
import { jsonToFormData } from "../../../tools";
import EmployeeProfileForm from "../../forms/employees/EmployeeProfileForm";
import EmployeeAddDateModal from "../../modals/EmployeeAddDateModal";
import InstructorsSignupForm from "../../forms/instructors/InstructorsSignupForm";
import { getItem } from "../../../api/instructors";
import EmployeeNoteForm from "../../forms/employees/EmployeeNoteForm";
import SendEmailModal from "../../modals/SendEmailModal";
import { Form } from "semantic-ui-react";

function EmployeeDetailView() {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [profile, setProfile] = useState({});
  const [referral, setRefferal] = useState("");

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  function datesHandler(state, id_date) {
    const data = {
      id_date: id_date,
      new_state: state,
    };

    const payload = jsonToFormData(data);

    switchDateState(payload).then((res) => {
      console.log(res.data);
    });
  }

  function getData() {
    setLoading(true);
    getItem(id, 1).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });

    getReferral(id).then((res) => {
      setRefferal(res.data.referral);
    });

    getProfile();
  }

  function getProfile() {
    const payload = jsonToFormData({ id_user: id });

    getEmployeesProfile(payload).then((res) => {
      setProfile(res.data);
      //setLoading(false);
    });
  }

  return (
    <Row>
      <Col sm={8}>
        <Card className="mb-5">
          <CardBody>
            <EmployeeNoteForm id_user={id} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Vybrané termíny</span>{" "}
            <EmployeeAddDateModal id_user={id} setLoading={setLoading} />
          </CardHeader>
          {!loading && (
            <DatesTable
              getData={getEmployeesDates}
              filterPath={"manage/employees/detail/" + id}
              displayFilter={false}
              employeeDetail={true}
              action={datesHandler}
              admin_detail={true}
              admin_id_user={id}
            />
          )}
        </Card>
        {!loading && (
          <div className="mt-5">
            <InstructorsSignupForm
              submitType="update"
              withRedirect={false}
              initData={data}
              refresh={getData}
            />
          </div>
        )}
      </Col>
      <Col sm={4}>
        <Card className="mb-5">
          <CardBody>
            <SendEmailModal displayType={"button"} id={id} />
          </CardBody>
        </Card>
        {!loading && (
          <div className="credits_box mb-5">
            <p className="desc">Kredity za doporučení</p>
            <p className="value">{profile.referrals ? profile.referrals : 0}</p>
          </div>
        )}
        <Card className="mb-5">
          <CardHeader>Doporučení</CardHeader>
          <CardBody>
            <Form>
              <Form.Input label="Kód" type="text" value={referral} />
              <Form.Input
                label="Odkaz"
                type="text"
                value={`https://app.sportjoy.cz/embed/signup/camps/cs?referral=${referral}`}
              />
            </Form>
          </CardBody>
        </Card>
        <Card className="mb-5">
          <CardHeader>QR kód</CardHeader>
          <CardBody>
            {!loading && (
              <img
                src={`https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=https://app.sportjoy.cz/embed/signup/camps/cs?referral=${referral}`}
                alt="qr"
                style={{ maxWidth: "100%" }}
              />
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Profil uživatele</CardHeader>
          <CardBody>
            <EmployeeProfileForm id_user={id} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default EmployeeDetailView;
