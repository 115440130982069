import React from 'react'
import { Col, Row } from 'reactstrap'
import { Card, CardBody, CardHeader } from '../../../styles'
import TypeEmailForm from '../../forms/type-emails/TypeEmailForm'

function TypesEmailsView() {

    return (
        <>
            <Row className='mb-5'>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            Tábory - Potvrzení přihlášky
                        </CardHeader>
                        <CardBody>
                            <TypeEmailForm type='confirm' id={1} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            Kroužky - Potvrzení přihlášky
                        </CardHeader>
                        <CardBody>
                            <TypeEmailForm type='confirm' id={2} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            Kurzy - Potvrzení přihlášky
                        </CardHeader>
                        <CardBody>
                            <TypeEmailForm type='confirm' id={3} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            Školka - Potvrzení přihlášky
                        </CardHeader>
                        <CardBody>
                            <TypeEmailForm type='confirm' id={4} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )

}

export default TypesEmailsView