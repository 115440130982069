import React, { useState, useEffect } from 'react'
import { checkVersion } from '../../api'
import { config } from '../../config/Build'
import { NewVersion } from '../../styles'

function VersionChecker() {

    const [newVer, setNewVer] = useState(false)

    useEffect(() => {
        setInterval(() => {
			/*checkVersion()
			.then((res) => {
				if(config.build < res.data.data.build_number) {
					setNewVer(true)
				}
			})*/
		}, 30000)
    })

    if(newVer) {
        return (
            <NewVersion type='error'><p>Je k dispozici nová verze aplikace. Aktualizujte prosím stránku, tato verze již nemusí fungovat správně.</p></NewVersion>
        )
    } else {
        return <></>
    }
    
}

export default VersionChecker