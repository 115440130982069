import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import DatesTable from '../containers/employees/DatesTable'
import { selectDate, getDates } from '../../api/employees';
import { useDispatch } from "react-redux";
import { jsonToFormData } from '../../tools';
import { setMessage } from '../../actions/messageActions';

function EmployeeAddDateModal({ id_user, setLoading }) {

    const dispatch = useDispatch();

    function _selectDate(id_date) {

        setLoading(true)

        const data = {
            id_date: id_date,
            id_user: id_user
        }

        const payload = jsonToFormData(data);

        selectDate(payload)
        .then((res) => {
            dispatch(setMessage(res.data))
            setLoading(false)
        })
    }

    return (
        <Modal trigger={<Button>Přidat termín</Button>}>
            <Modal.Content>
                <DatesTable 
                    getData={getDates}
                    datePicker={true}
                    action={_selectDate}
                    admin_detail={true}
                    admin_id_user={id_user}
                />
            </Modal.Content>
        </Modal>
    )
}

export default EmployeeAddDateModal