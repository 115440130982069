import styled from "styled-components";

export const FormGroup = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;

  .field > label {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
`;

export const SuccessMessageWrapper = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  i.icon {
    font-size: 92px;
    color: #2f71b8;
    margin-bottom: 40px;
  }

  span {
    font-size: 48px;
    font-weight: 700;
    display: block;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }
`;

export const BubbleCheckValue = styled.span`
  background: #f5365c;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  padding: 5px;
  font-size: 12px;
`;

export const BulkActionBar = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);

  position: fixed;
  bottom: 30px;
  right: 30px;
  left: calc(20% + 30px);
  border-radius: 6px;

  ${(props) => !props.visible && "display: none;"}
`;
