import styled from "styled-components";
import { Row } from "reactstrap";
import { Popup } from "semantic-ui-react";

export const Event = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const MoreArrow = styled.div`
  ::before {
    ${(props) =>
      props.loading && 'content: "\\eff5"; animation: spin 5s infinite;'}
    ${(props) => !props.loading && 'content: "\\ea99";'}
        display: inline-block;
  }

  font-family: IcoFont !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  z-index: 9999;
  justify-content: center;
  font-size: 50px;

  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );

  ${(props) => (!props.visible ? "opacity: 0;" : "opacity: 1;")}

  transition: all .3s ease-out;

  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`;

export const EventTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
`;

export const EventRow = styled(Row)`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #ececec;
  margin-bottom: 1.5rem;

  .fw-bold {
    font-weight: 700 !important;
  }
`;

export const EventPopup = styled(Popup)`
  padding: 1.5rem !important;
`;
