import LocalizedStrings from "react-localization";

export let applicationsLang = new LocalizedStrings({
  en: {
    activity_application: "ACTIVITY APPLICATION FORM",
    select_language: "Select language",
    phone: "Phone",
    parents: "Parents",
    parents_desc: "Enter parents' information",
    child: "Child",
    child_desc: "Enter the child's information",
    date: "Session",
    date_desc: "Select the session",
    application_complete: "Completion",
    legal_guardian: "Legal guardian",
    legal_guardian_name: "First name of legal guardian",
    enter_legal_guardian_name: "Enter first name of legal guardian",
    legal_guardian_last_name: "Last name of legal guardian",
    enter_legal_guardian_last_name: "Enter last name of legal guardian",
    legal_guardian_email: "E-mail of legal guardian",
    enter_legal_guardian_email: "Enter e-mail of legal guardian",
    legal_guardian_phone: "Phone of legal guardian",
    enter_legal_guardian_phone: "Enter phone of legal guardian",
    legal_guardian_add: "Add another guardian",
    next: "Next",
    child_message_headline:
      "If you are registering siblings, add all of them into one registration form",
    child_message:
      'If you do not list all siblings on this page, you will not be given the discount. Please use the "Add Siblings" button at the bottom.',
    add_sibling: "Add sibling",
    name: "Name",
    name_placeholder: "Enter child's name",
    last_name: "Last name",
    last_name_placeholder: "Enter child's last name",
    birthday: "Date of birth in the format (DD.MM.YYYYY)",
    birthday_2: "Date of birth",
    birthday_placeholder: "Enter date of birth",
    address: "Address",
    address_placeholder: "Enter address",
    city: "City",
    city_placeholder: "Enter city",
    zip_code: "Zip code",
    zip_code_placeholder: "Enter zip code",
    health_insurance_company: "Health insurance company",
    health_insurance_company_placeholder: "Enter health insurance company",
    health_restrictions: "Health restrictions",
    health_restrictions_placeholder:
      "pollen allergy, bee sting allergy, scoliosis, dioptric glasses…",
    dietary_restrictions: "Dietary restrictions",
    dietary_restrictions_placeholder: "milk allergy, gluten-free diet...",
    too_young: "Child cannot be younger than {years} years",
    too_old: "Child cannot be older than {years} years",
    age_info:
      "The child must be at least {young} years old and no more than {old} years old at the end of the camps",
    desire_group: "Wants to be in the same group with...",
    desire_group_placeholder: "Type in names of friends",
    add_session: "Add session",
    select_session: "Select session",
    select_one_session: "Select at least one session for each child",
    remove: "Remove",
    session_full:
      "This session is already full, your child will be registered as a substitute. You can choose another session.",
    use_button_below: "Please add your sessions using the button below.",
    activity_type: "Activity type",
    activity: "Activity",
    location: "Location",
    select_activity_type: "Select activity type",
    select_activity: "Select activity",
    select_location: "Select location",
    select_date: "Select date",
    as_substitute: "As substitute",
    availability: "Availability",
    available: "Available",
    full: "Full",
    all: "All",
    parents_contact_info: "Parents' contact information",
    children_registered: "Children registered",
    attending_school: "Attending school *",
    attending_school_placeholder: "Type name of the school",
    which_grade: "Which grade is the child in *",
    go_from_home:
      "The child will arrive for the activity on his/her own at the agreed meeting point *",
    select_option: "Choose option",
    can_go_home: "The child may leave on his/her own after the activity *",
    go_from_school:
      "The child needs to be picked up by the tutor from after care (družina) *",
    return_to_school:
      "The child shall be returned to after care (družina) after the activity *",
    sessions_summary: "Sessions summary and price",
    sessions: "Sessions",
    number_of_activities: "Number of activities",
    number_of_dates: "Number of dates",
    number_of_children: "Number of children",
    note: "Note",
    discount_code: "Discount code",
    total_price: "Total price",
    price: "Price",
    conclusion: "Conclusion",
    terms_and_conditions:
      "I agree to the general terms and conditions and payment terms",
    agree_gdpr: "I agree to the processing of personal data I provided",
    agree_photos: "I agree to having photographs taken during the activities",
    agree_photos_desc:
      "During the activity we take photos and videos of the children playing sports and enjoying themselves.",
    agree_newsletter: "I agree to the newsletter subscription",
    agree_newsletter_desc:
      "You don't have to worry about a pile of e-mails. Occasionally we will send a discount :) or we'll send some information about a new camp, event, workout or vacation. We also may send a request for feedback or something. You will be able to unsubscribe later.",
    fill_additional_before_submit:
      "To submit your registration form, please fill out the additional information for your children first",
    submit: "Submit form",
    success_message: "The registration form has been successfully submitted!",
    your_id: "Your ID",
    confirm_message:
      "We have just sent confirmation and further information to the e-mail address provided in the registration form. If you don't receive the e-mail within a few minutes, check your spam and promo folders.",
    payment_deadline:
      "You can pay by payment gateway or bank transfer within 7 business days.",
    pay_by_card: "Pay by card",
    bank_transfer: "Pay by bank transfer",
    bank_acc_num_label: "Please send payment to:",
    variable_symbol: "Variable symbol:",
    back_to_sportjoy: "Back to SPORTJOY",
    summary_substutite:
      "We will get back to you about the session for which you are registered as a substitute.",
    filter_date: "Date",
    combine_camps_message:
      "You can combine different camps in one application. Camps will be offered based on the age of the child.",
    combine_camps_headline: "You can combine camps",
    filter_no_dates: "No dates available for this filter combination",
    price_for_individuals: "Price for individuals",
    price_for_siblings: "Price for siblings",
    free: "Free",
    yes: "Yes",
    no: "No",
    i_recommend: "I recommend...",
    i_recommend_placehoder: "Enter: Full name - age",
    i_was_recommended: "I was recommended by...",
    i_was_recommended_placeholder: "Enter the participant ID",
    camps_application: "Camps application",
    once_place_for_child:
      "As soon as spot becomes available, we will notify you by e-mail and send you payment information. We recommend that you make payment as soon as possible after receiving the e-mail so that we can reserve your spot for you.",
    payment_after_conf:
      "Once confirmed, you can pay by payment gateway or bank transfer.",
    application_success_substitute:
      "Submitting my application as an substitute was successful!",
    choose_language: "Choose your language",
    choose_activity: "Choose activity",
    playgroups_courses_kindergarden_label: "Clubs, classes and Day Care",
    invoicing: "Invoicing",
    i_want_invoice: "I want to invoice my employer",
    employer_reg_num: "Employer's registration number",
    missing_date_msg_message:
      "If the activity you're looking for is not showing up, your child's age may not match the age allowed for the activity. Please contact us.",
    invoice_text: "Text",
    invoice_text_placeholder:
      "We invoice you the amount of 2950 CZK for the summer camp from 12/07 - 16/07/2021 for the child Jana Nováková, issued to Tibor Novák, Komerční banka a.s. (xxxx@xx.cz)",
    invoice_due_date: "Invoice due date",
    invoice_due_date_placeholder: "2 weeks, 3 weeks, ...",
  },
  cs: {
    activity_application: "PŘIHLÁŠKA NA AKTIVITU",
    select_language: "Vyberte jazyk",
    phone: "Telefon",
    parents: "Rodiče",
    parents_desc: "Zadejte informace o rodičích",
    child_desc: "Zadejte informace o dítěti",
    date: "Termín",
    date_desc: "Vyberte termín aktivity",
    application_complete: "Dokončení přihlášky",
    legal_guardian: "Zákonný zástupce",
    legal_guardian_name: "Křestní jméno zákonného zástupce",
    enter_legal_guardian_name: "Vyplňte jméno zákonného zástupce",
    legal_guardian_last_name: "Příjmení zákonného zástupce",
    enter_legal_guardian_last_name: "Vyplňte příjmení zákonného zástupce",
    legal_guardian_email: "E-mail zákonného zástupce",
    enter_legal_guardian_email: "Zadejte e-mail zákonného zástupce",
    legal_guardian_phone: "Telefon zákonného zástupce",
    enter_legal_guardian_phone: "Zadejte telefon zákonného zástupce",
    legal_guardian_add: "Přidat druhého zástupce",
    next: "Další",
    child_message_headline:
      "Pokud přihlašujete sourozence, přidejte všechny do jedné přihlášky",
    child_message:
      'V případě, že na této stránce nepřidáte všechny sourozence, nebude Vám započítána sleva. Využijte prosím tlačítko "Přidat sourozence" v dolní části.',
    add_sibling: "Přidat sourozence",
    child: "Dítě",
    name: "Jméno",
    name_placeholder: "Zadejte jméno dítěte",
    last_name: "Příjmení",
    last_name_placeholder: "Zadejte příjmení dítěte",
    birthday: "Datum narození ve formátu (DD.MM.YYYYY)",
    birthday_2: "Datum narození",
    birthday_placeholder: "Vyplňte datum narození",
    address: "Adresa",
    address_placeholder: "Zadejte adresu",
    city: "Město",
    city_placeholder: "Zadejte město",
    zip_code: "PSČ",
    zip_code_placeholder: "Zadejte PSČ",
    health_insurance_company: "Zdravotní pojišťovna",
    health_insurance_company_placeholder: "Zadejte zdravotní pojišťovnu",
    health_restrictions: "Zdravotní omezení",
    health_restrictions_placeholder:
      "Alergie na pyl, včelí bodnutí, skolióza, silné dipotrické brýle, ...",
    dietary_restrictions: "Stravovací omezení",
    dietary_restrictions_placeholder:
      "Alergie na mléko, bezlepková dieta, alergie na ořechy, ...",
    too_young: "Dítě nesmí být mladší než {years} let",
    too_old: "Dítě nesmí být starší než {years} let",
    age_info:
      "Dítěti musí být alespoň {young} roky a ne více než {old} let v době konce táborů",
    desire_group: "S kým chce být ve skupině",
    desire_group_placeholder: "Napište jména kamarádů",
    add_session: "Přidat termín",
    select_session: "Vybrat termín",
    select_one_session: "Vyberte ke každému dítěti alespoň jeden termín",
    remove: "Odebrat",
    session_full:
      "Tento termín je již plný, vaše dítě bude přihlášeno jako náhradník. Můžete vybrat jiný termín.",
    use_button_below: "Přidejte prosím termín tlačítkem níže.",
    activity_type: "Druh aktivity",
    activity: "Aktivita",
    location: "Lokalita",
    select_activity_type: "Vyberte druh aktivity",
    select_activity: "Vyberte aktivitu",
    select_location: "Vyberte lokalitu",
    select_date: "Vyberte termín",
    as_substitute: "Jako náhradník",
    date: "Termín",
    availability: "Obsazenost",
    available: "Volné",
    full: "Obsazené",
    all: "Vše",
    parents_contact_info: "Kontaktní údaje rodičů",
    children_registered: "Přihlášené děti",
    attending_school: "Chodí do školy *",
    attending_school_placeholder: "Napište školu",
    which_grade: "Do které třídy dítě chodí *",
    go_from_home: "Dítě na aktivitu přijde samo na domluvené místo srazu *",
    select_option: "Vyberte možnost",
    can_go_home: "Po skončení aktivity může dítě odejít samo *",
    go_from_school: "Dítě na aktivitu převezmeme z družiny *",
    return_to_school: "Po skončení aktivity se dítě vrací do družiny *",
    sessions_summary: "Souhrn termínů a cena",
    sessions: "Termíny",
    number_of_activities: "Počet aktivit",
    number_of_dates: "Počet termínů",
    number_of_children: "Počet dětí",
    note: "Poznámka k přihlášce",
    discount_code: "Slevový kód",
    total_price: "Celková cena",
    price: "Cena",
    conclusion: "Závěrem",
    terms_and_conditions:
      "Souhlasím se všeobecnými podmínkami a platebními podmínkami",
    agree_gdpr: "Souhlasím se zpracováním osobních údajů",
    agree_photos: "Souhlasím s pořizováním fotografií v průběhu aktivit",
    agree_photos_desc:
      "V průběhu aktivity pořižujeme fotografie a videa, jak děti sportují a radují se.",
    agree_newsletter: "Souhlasím s odběrem newsletteru",
    agree_newsletter_desc:
      "Hromady e-mailů se nemusíte bát. Občas pošleme slevu :) Občas pošleme nějakou informaci o novém táboře, akci, cvičení nebo dovolené. Občas pošleme žádost o zpětnou vazbu a tak. Z odběru se budete moci později odhlásit.",
    fill_additional_before_submit:
      "Pro odeslání přihlášky nejdříve vyplňte doplňující informace k dětem",
    submit: "Odeslat přihlášku",
    success_message: "Odeslání přihlášky proběhlo v pořádku",
    your_id: "Vaše ID",
    confirm_message:
      "Na e-mail uvedený v přihlášce jsme právě odeslali potvrzení a další informace. Pokud vám e-mail nedorazí během pár minut, zkontrolujte si spam a hromadné zprávy. Všechny své přihlášky můžete spravovat v administraci SPORTJOY. Informace k vašemu účtu najdete v e-mailu.",
    payment_deadline:
      "Platbu můžete uhradit platební bránou nebo převodem na účet, a to nejpozději do 7 pracovních dnů.",
    pay_by_card: "Zaplatit kartou",
    bank_transfer: "Platba bankovním převodem",
    bank_acc_num_label: "Platbu prosím zašlete na číslo účtu:",
    variable_symbol: "Variabilní symbol:",
    back_to_sportjoy: "Zpět na SPORTJOY",
    summary_substutite:
      "Ohledně termínu, na který jste přihlášeni jako náhradník, se Vám ještě ozveme.",
    filter_date: "Termín",
    combine_camps_message:
      "V jedné přihlášce můžete kombinovat různé druhy táborů. Tábory se vám nabídnou na základě věku dítěte.",
    combine_camps_headline: "Tábory můžete kombinovat",
    filter_no_dates:
      "Pro tuto kombinaci filtru nejsou pro Vaše dítě dostupné žádné termíny",
    price_for_individuals: "Cena pro jednotlivce",
    price_for_siblings: "Cena pro sourozence",
    free: "Zdarma",
    yes: "Ano",
    no: "Ne",
    i_recommend: "Doporučuji na tábor",
    i_recommend_placehoder: "Zadejte: jméno příjmení - věk",
    i_was_recommended: "Doporučil/a mě",
    i_was_recommended_placeholder: "Zadejte ID účastníka",
    camps_application: "Přihláška na tábor",
    once_place_for_child:
      "Jakmile se místo na táboře uvolní, informujeme vás o tom e-mailem a zároveň zašleme informace k platbě. Doporučujeme platbu uhradit co nejdříve po obdržení e-mailu, abychom vám mohli místo rezervovat.",
    payment_after_conf:
      "Po potvrzení platbu můžete uhradit platební bránou nebo převodem na účet.",
    application_success_substitute:
      "Odeslání přihlášky jako náhradník proběhlo v pořádku!",
    missing_date_msg_headline: "Nevidíte požadovanou aktivitu?",
    missing_date_msg_message:
      "Pokud se vám nezobrazuje aktivita, kterou hledáte, je možné, že se věk vašeho dítěte neshoduje s věkem povoleným u aktivity. Prosím kontaktujte nás.",
    choose_language: "Vyberte Váš jazyk",
    choose_activity: "Vyberte aktivitu",
    playgroups_courses_kindergarden_label:
      "Přihláška na kroužky, kurzy, školku",
    invoicing: "Fakturace",
    i_want_invoice: "Chci vystavit fakturu na zaměstnavatele",
    employer_reg_num: "IČO zaměstnavatele",
    invoice_text: "Text",
    invoice_text_placeholder:
      "Fakturujeme Vám částku 2950 Kč za letní tábor v termínu 12/07 - 16/07/2021 pro dítě Janu Novákovou, vystaveno pro Tibora Nováka, Komerční banka a.s. (xxxx@xx.cz)",
    invoice_due_date: "Datum splatnosti",
    invoice_due_date_placeholder: "2 týdny, 3 týdny, ...",
  },
  ru: {
    activity_application: "PŘIHLÁŠKA NA AKTIVITU",
    phone: "Телефон",
    parents: "Родители",
    parents_desc:
      "Напишите информацию о родителях(законных представителях ребенка)",
    child: "Ребенок",
    child_desc: "Напишите информацию о ребенке",
    date: "Дата",
    date_desc: "Выберите дату мероприятия",
    application_complete: "Окончание регистрации",
    legal_guardian: "Законный представитель",
    legal_guardian_name: "Имя законного представителя",
    enter_legal_guardian_name: "Заполните имя законного представителя",
    legal_guardian_last_name: "Фамилия законного представителя",
    enter_legal_guardian_last_name: "Заполните фамилию законного представителя",
    legal_guardian_email: "Электронная почта",
    enter_legal_guardian_email: "Введите адрес э-почты законного представителя",
    legal_guardian_phone: "Номер телефона",
    enter_legal_guardian_phone:
      "Введите номер телефона законного представителя",
    legal_guardian_add: "Добавить второго законного представителя",
    next: "Следующий шаг",
    child_message_headline:
      "Если регистрируете нескольких детей, запишите всех в одно заявление",
    child_message:
      "В случае, если вы зарегистрируете каждого ребенка отдельно, скидка не засчитается. Используйте, пожалуйста, значок «Добавить еще одного ребенка»",
    add_sibling: "Добавить еще одного ребенка",
    name: "Имя",
    name_placeholder: "Введите имя ребенка",
    last_name: "Фамилия",
    last_name_placeholder: "Введите фамилию ребенка",
    birthday: "Дата рождения (DD.MM.YYYYY)",
    birthday_2: "Дата рождения",
    birthday_placeholder: "Заполнить дату рождения",
    address: "Адрес",
    address_placeholder: "Введите адрес",
    city: "Город",
    city_placeholder: "Введите город",
    zip_code: "Индекс",
    zip_code_placeholder: "Введите ваш почтовый индекс",
    health_insurance_company: "Страховая компания",
    health_insurance_company_placeholder:
      "Введите вашу медицинскую страховую компанию",
    health_restrictions: "Ограничения по здоровью ребенка",
    health_restrictions_placeholder:
      "Аллергия на пыльцу, укусы пчел, сколиоз, сильные дипотрические очки, ...",
    dietary_restrictions: "Ограничения по еде( аллергия, специальная диета )",
    dietary_restrictions_placeholder:
      "Аллергия на молоко, безглютеновая диета, аллергия на орехи, ...",
    too_young: "Dítě nesmí být mladší než {years} let",
    too_old: "Dítě nesmí být starší než {years} let",
    age_info:
      "Ребенку должно быть как минимум {young} года и не более {old} лет в момент окончания лагерей",
    desire_group: "С кем ребенок хочет быть в группе",
    desire_group_placeholder: "Напишите имена друзей",
    add_session: "Добавить дату проведения лагеря",
    select_session: "Выберите дату",
    select_one_session:
      "Выберите для каждого ребенка хотя бы одну дату проведения лагеря",
    remove: "Удалить",
    session_full:
      "Tento termín je již plný, vaše dítě bude přihlášeno jako náhradník. Můžete vybrat jiný termín.",
    use_button_below: "Přidejte prosím termín tlačítkem níže.",
    activity_type: "Вид активности",
    activity: "Активность",
    location: "Местонахождение",
    select_activity_type: "Vyberte druh aktivity",
    select_activity: "Vyberte aktivitu",
    select_location: "Vyberte lokalitu",
    select_date: "Vyberte termín",
    as_substitute: "Jako náhradník",
    date: "Дата",
    availability: "Вместимость",
    available: "Volné",
    full: "Obsazené",
    all: "Все",
    parents_contact_info: "Контакты родителей",
    children_registered: "Зарегистрированные дети",
    attending_school: "Ходят в школу *",
    attending_school_placeholder: "Напишите школу",
    which_grade: "В какой класс ходит ребенок *",
    go_from_home: "Ребенок придет один в назначенное место встречи *",
    select_option: "Выберите",
    can_go_home: "По окончании ребенок может уйти один *",
    go_from_school: "Ребенка заберем из группы продленного дня *",
    return_to_school:
      "По окончании ребенок возвращается в группу продленного дня *",
    sessions_summary: "Заявленные даты и цена",
    sessions: "Даты",
    number_of_activities: "Количество кружков(лагерей)",
    number_of_dates: "Количество сроков",
    number_of_children: "Количество детей",
    note: "Примечание к заявлению",
    discount_code: "Код скидки",
    total_price: "цена",
    price: "цена",
    conclusion: "Итог",
    terms_and_conditions: "Я согласен с общими и платежными условиями",
    agree_gdpr: "Я согласен на обработку личных данных",
    agree_photos: "Я согласен с фотосъёмкой",
    agree_photos_desc:
      "V průběhu aktivity pořižujeme fotografie a videa, jak děti sportují a radují se.",
    agree_newsletter: "Я согласен с рассылкой новостей",
    agree_newsletter_desc:
      "Hromady e-mailů se nemusíte bát. Občas pošleme slevu :) Občas pošleme nějakou informaci o novém táboře, akci, cvičení nebo dovolené. Občas pošleme žádost o zpětnou vazbu a tak. Z odběru se budete moci později odhlásit.",
    fill_additional_before_submit:
      "Перед тем как отправить регистрацию, заполните дополнительную информацию о детях",
    submit: "Отправить регистрацию",
    success_message: "Отправление прошло успешно",
    your_id: "Ваша идентификация",
    confirm_message:
      "На почту,которую вы написали в регистрации, вам было отправлено подтверждение и последующая информация. Если вам не придет подтверждение в течение нескольких минут, проверьте папку спам.",
    payment_deadline:
      "Оплатить вы сможете, используя оплату картой на сайте, или переводом на счет",
    pay_by_card: "Заплатить картой",
    bank_transfer: "Оплата переводом на счет",
    bank_acc_num_label: "Оплату пошлите на счет:",
    variable_symbol: "Вариабельный символ:",
    back_to_sportjoy: "Вернуться на SPORTJOY",
    summary_substutite:
      "Ohledně termínu, na který jste přihlášeni jako náhradník, se Vám ještě ozveme.",
    filter_date: "Дата",
    combine_camps_message:
      "В одной регистрации вы можете комбинировать лагеря. Лагеря будут автоматически указаны в зависимости от возраста ребенка.",
    combine_camps_headline: "Лагеря можно комбинировать",
    filter_no_dates:
      "Pro tuto kombinaci filtru nejsou pro Vaše dítě dostupné žádné termíny",
    price_for_individuals: "Cena pro jednotlivce",
    price_for_siblings: "Cena pro sourozence",
    free: "Бесплатно",
    yes: "Да",
    no: "Нет",
    i_recommend: "Doporučuji na tábor",
    i_recommend_placehoder: "Zadejte: jméno příjmení - věk",
    i_was_recommended: "Doporučil/a mě",
    i_was_recommended_placeholder: "ID зарегистрированного участника",
    camps_application: "Заявка на участие в лагере",
    once_place_for_child:
      "Jakmile se místo na táboře uvolní, informujeme vás o tom e-mailem a zároveň zašleme informace k platbě. Doporučujeme platbu uhradit co nejdříve po obdržení e-mailu, abychom vám mohli místo rezervovat.",
    payment_after_conf:
      "Po potvrzení platbu můžete uhradit platební bránou nebo převodem na účet.",
    application_success_substitute:
      "Odeslání přihlášky jako náhradník proběhlo v pořádku!",
    missing_date_msg_headline: "Не видите нужный вам вид деятельности?",
    missing_date_msg_message:
      "Если вы не видите определенный вид кружка или лагеря, то возможно, возраст ребенка не подходит для этого кружка или лагеря. В этом случае вы можете позвонить нам и мы поможем разобраться.",
    choose_language: "Выберите язык",
    choose_activity: "Выберите вид деятельности",
    playgroups_courses_kindergarden_label: "Игровые группы, курсы, детский сад",
    invoicing: "Выставление счетов-фактур",
    i_want_invoice: "Я хочу выставить счет-фактуру своему работодателю",
    employer_reg_num: "Идентификационный номер работодателя",
  },
  vn: {
    activity_application: "ACTIVITY APPLICATION FORM",
    select_language: "Chọn ngôn ngữ",
    phone: "Điện thoại",
    parents: "Phụ huynh",
    parents_desc: "EHãy điền thông tin về phụ huynh",
    child: "Trẻ",
    child_desc: "Hãy điền thông tin về trẻ",
    date: "Phiên họp",
    date_desc: "Chọn phiên",
    application_complete: "Hoàn tất đơn đăng ký",
    legal_guardian: "Người giám hộ hợp pháp",
    legal_guardian_name: "Tên của người đại diện theo pháp luật",
    enter_legal_guardian_name: "Enter first name of legal guardian",
    legal_guardian_last_name: "Họ của người đại diện theo pháp luật",
    enter_legal_guardian_last_name: "Họ của người đại diện theo pháp luật",
    legal_guardian_email: "E-mail của người đại diện theo pháp luật",
    enter_legal_guardian_email: "E-mail của người đại diện theo pháp luật",
    legal_guardian_phone: "Số điện thoại của người đại diện theo pháp luật ",
    enter_legal_guardian_phone:
      "Số điện thoại của người đại diện theo pháp luật ",
    legal_guardian_add: "Thêm người đại diện theo pháp luật thứ hai",
    next: "Tiếp",
    child_message_headline:
      "Nếu bạn đăng ký cho cả anh chị em ruột của trẻ, hãy điền tất cả vào một đơn đăng ký",
    child_message:
      "Trong trường hợp Bạn không thêm tất cả anh chị em ruột của trẻ tại trang này, khuyến mại sẽ không được tính. Xin hãy bấm nút “Thêm anh chị em ruột” ở phần dưới.",
    add_sibling: "Thêm anh chị em ruột",
    name: "Tên",
    name_placeholder: "Tên",
    last_name: "Họ",
    last_name_placeholder: "Họ",
    birthday: "Ngày tháng năm sinh theo định dạng DD.MM.YYYY",
    birthday_2: "Ngày tháng năm sinh",
    birthday_placeholder: "Ngày tháng năm sinh",
    address: "Địa chỉ",
    address_placeholder: "Địa chỉ",
    city: "Thành phố",
    city_placeholder: "Thành phố",
    zip_code: "Mã bưu chính",
    zip_code_placeholder: "Mã bưu chính",
    health_insurance_company: "Hãng bảo hiểm y tế",
    health_insurance_company_placeholder: "Hãng bảo hiểm y tế",
    health_restrictions: "Hạn chế về sức khoẻ",
    health_restrictions_placeholder:
      "pollen allergy, bee sting allergy, scoliosis, dioptric glasses…",
    dietary_restrictions: "Hạn chế trong ăn uống",
    dietary_restrictions_placeholder: "milk allergy, gluten-free diet...",
    too_young: "Child cannot be younger than {years} years",
    too_old: "Child cannot be older than {years} years",
    age_info:
      "Tối thiểu trẻ phải {young} tuổi và không quá {old} tuổi trong thời điểm các cuộc cắm trại kết thúc",
    desire_group: "Trẻ muốn cùng nhóm với ai",
    desire_group_placeholder: "Hãy viết tên của bạn bè",
    add_session: "Thêm lịch",
    select_session: "Select session",
    select_one_session: "Bạn hãy chọn cho mỗi trẻ tối thiểu một lịch",
    remove: "Xoá",
    session_full:
      "This session is already full, your child will be registered as a substitute. You can choose another session.",
    use_button_below: "Please add your sessions using the button below.",
    activity_type: "Activity type",
    activity: "Activity",
    location: "Location",
    select_activity_type: "Select activity type",
    select_activity: "Select activity",
    select_location: "Select location",
    select_date: "Select date",
    as_substitute: "As substitute",
    availability: "Khả dụng",
    available: "Có sẵn",
    full: "Đầy",
    all: "Tất cả",
    parents_contact_info: "Thông tin liên lạc của phụ huynh",
    children_registered: "Các trẻ được đăng ký",
    attending_school: "Học ở trường *",
    attending_school_placeholder: "Hãy viết tên trường",
    which_grade: "Trẻ học lớp nào *",
    go_from_home:
      "Trẻ tự đến hoạt động ở địa điểm tập trung theo như thoả thuận *",
    select_option: "Hãy tuỳ chọn",
    can_go_home: "Trẻ có thể tự về khi hoạt động kết thúc *",
    go_from_school: "Chúng tôi đưa trẻ đến hoạt động từ lớp bán trú *",
    return_to_school: "Trẻ quay lại lớp bán trú khi hoạt động kết thúc *",
    sessions_summary: "Toàn bộ lịch và giá tiền",
    sessions: "Lịch",
    number_of_activities: "Số lượng hoạt động",
    number_of_dates: "Number of dates",
    number_of_children: "Số lượng trẻ",
    note: "Mục ghi chú của đơn đăng ký",
    discount_code: "Mã khuyến mại",
    total_price: "Giá",
    price: "Giá",
    conclusion: "Phần cuối",
    terms_and_conditions:
      "Tôi đồng ý với các điều khoản chung và các điều khoản thanh toán",
    agree_gdpr: "Tôi đồng ý với việc xử lý dữ liệu cá nhân",
    agree_photos:
      "Tôi đồng ý với việc chụp ảnh trong quá trình của các hoạt động",
    agree_photos_desc:
      "During the activity we take photos and videos of the children playing sports and enjoying themselves.",
    agree_newsletter: "Tôi đồng ý đăng ký nhận các thông tin cập nhật",
    agree_newsletter_desc:
      "You don't have to worry about a pile of e-mails. Occasionally we will send a discount :) or we'll send some information about a new camp, event, workout or vacation. We also may send a request for feedback or something. You will be able to unsubscribe later.",
    fill_additional_before_submit:
      "Để gửi đơn đăng ký trước hết hãy điền thông tin bổ sung về trẻ",
    submit: "Gửi đơn đăng ký",
    success_message: "Đơn được gửi thành công",
    your_id: "ID của bạn",
    confirm_message:
      "Chúng tôi vừa gửi xác nhận và các thông tin khác vào e-mail được nêu trong đơn đăng ký này. Nếu bạn không nhận được e-mail trong vài phút, hãy kiểm tra lại mục thư spam và thư hàng loạt.",
    payment_deadline:
      "Bạn có thể thanh toán qua cổng thanh toán hoặc chuyển khoản, muộn nhất là trong vòng 7 ngày làm việc.",
    pay_by_card: "Trả bằng thẻ",
    bank_transfer: "Thanh toán bằng chuyển khoản",
    bank_acc_num_label: "Xin hãy chuyển khoản tới số tài khoản:",
    variable_symbol: "Mã tham chiếu:",
    back_to_sportjoy: "Quay lại SPORTJOY",
    summary_substutite:
      "We will get back to you about the session for which you are registered as a substitute.",
    filter_date: "Date",
    combine_camps_message:
      "Bạn có thể kết hợp các chuyến cắm trại trong một đơn đăng ký. Các chuyến cắm trại sẽ hiển thị dựa trên độ tuổi của trẻ.",
    combine_camps_headline: "Bạn có thể kết hợp các chuyến cắm trại",
    filter_no_dates: "No dates available for this filter combination",
    price_for_individuals: "Price for individuals",
    price_for_siblings: "Price for siblings",
    free: "Free",
    yes: "Yes",
    no: "No",
    i_recommend: "I recommend...",
    i_recommend_placehoder: "Enter: Full name - age",
    i_was_recommended: "I was recommended by...",
    i_was_recommended_placeholder: "Enter the participant ID",
    camps_application: "Đơn đăng ký trại",
    once_place_for_child:
      "As soon as spot becomes available, we will notify you by e-mail and send you payment information. We recommend that you make payment as soon as possible after receiving the e-mail so that we can reserve your spot for you.",
    payment_after_conf:
      "Once confirmed, you can pay by payment gateway or bank transfer.",
    application_success_substitute:
      "Submitting my application as an substitute was successful!",
    choose_language: "Chọn ngôn ngữ của bạn",
    choose_activity: "Chọn hoạt động",
    playgroups_courses_kindergarden_label: "Nhóm chơi, khóa học, mẫu giáo",
    missing_date_msg_message:
      "Nếu hoạt động mà bạn tìm kiếm không hiển thị, đó có thể là vì tuổi của con bạn không khớp với độ tuổi yêu cầu để tham dự hoạt động. Xin hãy liên lạc với chúng tôi",
    missing_date_msg_headline: "Không thể thấy hoạt động bạn đang tìm kiếm?",
  },
  es: {
    activity_application: "PŘIHLÁŠKA NA AKTIVITU",
    select_language: "Seleccione el idioma",
    phone: "Teléfono",
    parents: "Padres",
    parents_desc: "Información sobre los padres",
    child_desc: "Información del niño",
    date: "Fecha",
    date_desc: "Seleccione la fecha de la actividad",
    application_complete: "Completar el registro",
    legal_guardian: "Tutor legal",
    legal_guardian_name: "Nombre del tutor legal",
    enter_legal_guardian_name: "Vyplňte jméno zákonného zástupce",
    legal_guardian_last_name: "Apellido del tutor legal",
    enter_legal_guardian_last_name: "Vyplňte příjmení zákonného zástupce",
    legal_guardian_email: "E-mail del tutor legal",
    enter_legal_guardian_email: "Escriba el correo electrónico del tutor legal",
    legal_guardian_phone: "Número de teléfono del tutor legal",
    enter_legal_guardian_phone: "Escriba el número de teléfono del tutor legal",
    legal_guardian_add: "Añadir un segundo tutor",
    next: "Siguiente",
    child_message_headline:
      "Si está registrando a varios hermanos, agréguelos a todos en una sola aplicación",
    child_message:
      'Si no añade todos los hermanos en esta página, no se le acreditará el descuento. Por favor, utilice el botón "Añadir hermanos" en la parte de abajo.',
    add_sibling: "Añadir hermanos",
    child: "Niño",
    name: "Nombre",
    name_placeholder: "Escriba el nombre del niño",
    last_name: "Apellido",
    last_name_placeholder: "Escriba el apellido del niño",
    birthday: "Fecha de nacimiento en el formato DD.MM.YYYYY",
    birthday_2: "Fecha de nacimiento",
    birthday_placeholder: "Rellene la fecha de nacimiento",
    address: "Domilicio",
    address_placeholder: "Escriba el domicilio",
    city: "Ciudad",
    city_placeholder: "Escriba la ciudad",
    zip_code: "Código postal",
    zip_code_placeholder: "Escriba el código postal",
    health_insurance_company: "Aseguradora médica",
    health_insurance_company_placeholder: "Escriba la aseguradora médica",
    health_restrictions: "Restricciones de salud",
    health_restrictions_placeholder:
      "Alergia al polen, a las picaduras de abeja, escoliosis, gafas dipotricas fuertes, ...",
    dietary_restrictions: "Restricciones dietéticas",
    dietary_restrictions_placeholder:
      "Alergia a la leche, dieta sin gluten, alergia a los frutos secos, ...",
    too_young: "El niño no debe tener menos de {years} años",
    too_old: "El niño no debe tener más de {years} años",
    age_info:
      "El niño debe tener al menos {young} años y no más de {old} al final de los campamentos (31 de agosto de 2022)",
    desire_group: "Con quién quiere estar en un grupo",
    desire_group_placeholder: "Escribe los nombres de sus amigos",
    add_session: "Añadir una fecha",
    select_session: "Seleccione una fecha",
    select_one_session:
      "Seleccione una fecha, como mínimo, para cada uno de los niños",
    remove: "Eliminar",
    session_full:
      "Esta actividad ya está llena, su niño será registrado como un sustituto. Puede seleccionar otra actividad",
    use_button_below:
      "Por favor, añada sus fechas utilizando el botón de abajo.",
    activity_type: "Tipo de actividad",
    activity: "Actividad",
    location: "Lugar",
    select_activity_type: "Seleccione el tipo de actividad",
    select_activity: "Seleccione su actividad",
    select_location: "Seleccione un lugar",
    select_date: "Seleccione una fecha",
    as_substitute: "Como sustituto",
    date: "Fecha",
    availability: "Disponibilidad",
    available: "Disponible",
    full: "Ocupado",
    all: "Todo",
    parents_contact_info: "Información de contacto de los padres",
    children_registered: "Niños registrados",
    attending_school: "Estudia en escuela *",
    attending_school_placeholder: "Escriba la escuela",
    which_grade: "A qué clase va el niño *",
    go_from_home:
      "El niño llega a la actividad por su cuenta al lugar de encuentro acordado *",
    select_option: "Seleccione una opción",
    can_go_home: "Después de la actividad, el niño puede irse por su cuenta *",
    go_from_school: "Vamos a llevar al niño a la actividad de la escuela *",
    return_to_school: "Después de la actividad, el niño vuelve a la escuela *",
    sessions_summary: "Sumario de las fechas y el precio",
    sessions: "Fechas",
    number_of_activities: "Número de actividades",
    number_of_dates: "Número de fechas",
    number_of_children: "Número de niños",
    note: "Notas",
    discount_code: "Código de descuento",
    total_price: "Precio",
    price: "Precio",
    conclusion: "Conclusión",
    terms_and_conditions:
      "Acepto las términos y condiciones generales y las condiciones de pago",
    agree_gdpr: "Estoy de acuerdo con el tratamiento de los datos personales",
    agree_photos:
      "Estoy de acuerdo con que se tomen fotografías durante las actividades",
    agree_photos_desc:
      "Durante las actividades tomamos fotos y vídeos de los niños haciendo deportes y disfrutando",
    agree_newsletter: "Acepto recibir el boletín de noticias",
    agree_newsletter_desc:
      "No tiene que preocuparse por una pila de correos electrónicos. A veces enviamos un descuento :) Ocasionalmente enviaremos información sobre unos nuevos campamentos, eventos, entrenamientos o vacaciones. A veces, enviamos una solicitud de comentarios o algo así. Podrá cancelar la suscripción más tarde.",
    fill_additional_before_submit:
      "Para enviar su registro, complete la información adicional para los niños",
    submit: "Enviar registro",
    success_message: "Odeslání přihlášky proběhlo v pořádku",
    your_id: "Su ID",
    confirm_message:
      "Hemos enviado la confirmación y más información al correo electrónico del registro.  Si no recibes un correo electrónico en unos minutos, comprueba el spam y los mensajes masivos.",
    payment_deadline:
      "Puede pagar por pasarela de pago o por transferencia bancaria en un plazo de 7 días laborables.",
    pay_by_card: "Pagar con tarjeta",
    bank_transfer: "Pago por transferencia",
    bank_acc_num_label:
      "Por favor, haga el pago al siguiente número de cuenta:",
    variable_symbol: "Símbolo variable:",
    back_to_sportjoy: "Volver a SPORTJOY",
    summary_substutite:
      "Nos pondremos en contacto con usted para informarle de la fecha en la que su niño está registrado como sustituto.",
    filter_date: "Fecha",
    combine_camps_message:
      "Puede combinar diferentes campamentos en una sola aplicación. Los campamentos se ofrecerán en función de la edad del niño.",
    combine_camps_headline: "Puede combinar los campamentos",
    filter_no_dates:
      "No hay fechas disponibles para esta combinación de filtros para su niño",
    price_for_individuals: "Precio para una persona",
    price_for_siblings: "Precio para hermanos",
    free: "Gratis",
    yes: "Sí",
    no: "No",
    i_recommend: "Lo recomiendo para el campamento",
    i_recommend_placehoder: "Zadejte: jméno příjmení - věk",
    i_was_recommended: "Me recomendaron",
    i_was_recommended_placeholder: "ID de participante",
    camps_application: "Registrarse para el campamento",
    once_place_for_child:
      "Tan pronto como haya espacio disponible, se lo notificaremos por correo electrónico y le enviaremos la información de pago. Le recomendamos que realice el pago lo antes posible tras recibir el correo electrónico para que podamos reservarle su actividad.",
    payment_after_conf:
      "Tras la confirmación, puede pagar mediante la pasarela de pago o la transferencia bancaria",
    application_success_substitute:
      "Ha sido registrado como sustituto sin problémas!",
    missing_date_msg_headline: "¿No ve la actividad que desea?",
    missing_date_msg_message:
      "Si la actividad que busca no aparece, es posible que la edad de su niño no coincida con la edad permitida para la actividad. Póngase en contacto con nosotros por favor.",
    choose_language: "Seleccione su idioma",
    choose_activity: "Seleccione una actividad",
    playgroups_courses_kindergarden_label:
      "Registrarse para los grupos de interés, los cursos y la guardería",
    invoicing: "Facturación",
    i_want_invoice: "Quiero facturar a mi empleador",
    employer_reg_num: "Número de identificación del empleador (IČ)",
    invoice_text: "Texto",
    invoice_text_placeholder:
      "Le facturamos la cantidad de 2950 coronas checas por el campamento de verano del 12/07 al 16/07/2021 para la niña Jana Nováková, expedido a nombre de Tibor Novák, Komerční banka a.s. (xxxx@xx.cz)",
    invoice_due_date: "Fecha de vencimiento",
    invoice_due_date_placeholder: "2 semanas, 3 semanas …",
  },
  ua: {
    activity_application: "Заявка на участь у занятті",
    select_language: "Виберіть мову",
    phone: "Телефон",
    parents: "Батьки",
    parents_desc: "Введіть інформацію про батьків",
    child_desc: "Введіть інформацію про дитину",
    date: "Дати",
    date_desc: "Виберіть дату акції",
    application_complete: "Повне заповнення форми заявки",
    legal_guardian: "Законний представник",
    legal_guardian_name: "Ім'я законного представника",
    enter_legal_guardian_name: "Введіть  ім’я законного представника",
    legal_guardian_last_name: "Прізвище законного представника",
    enter_legal_guardian_last_name: "Введіть прізвище законного представника",
    legal_guardian_email: "Електронна адреса законного представника",
    enter_legal_guardian_email:
      "Введіть електронну адресу законного представника",
    legal_guardian_phone: "Номер телефону законного представника",
    enter_legal_guardian_phone:
      "Введіть  номер телефону  законного представника",
    legal_guardian_add: "Додати другого представника",
    next: "Далі",
    child_message_headline:
      "Якщо ви подаєте заявку на братів та сестер, додайте їх усіх в одну заявкову форму",
    child_message:
      'Якщо ви не додасте всіх братів та сестер на цій сторінці, знижка вам не буде нарахована. Будь ласка, скористайтесь кнопкою "Додати братів та сестер" унизу.',
    add_sibling: "Дадати братів та сестер",
    child: "Дитина",
    name: "Iм’я",
    name_placeholder: "Введіть ім'я дитини",
    last_name: "Прізвище",
    last_name_placeholder: "Введіть прізвище  дитини",
    birthday: "Дата народження у формі ДД.ММ.РРРР",
    birthday_2: "Дата народження",
    birthday_placeholder: "Введіть дату народження",
    address: "Адреса",
    address_placeholder: "Введіть адресу",
    city: "Місто",
    city_placeholder: "Введіть місто",
    zip_code: "Поштовий індекс",
    zip_code_placeholder: "Введіть поштовий індекс",
    health_insurance_company: "Медичне страхування",
    health_insurance_company_placeholder: "Введіть назву медичного страхування",
    health_restrictions: "Обмеження щодо здоров'я",
    health_restrictions_placeholder:
      "Алергія на пилок, укуси бджіл, сколіоз, міцні дипотричні окуляри, ...",
    dietary_restrictions: "Обмеження щодо харчування",
    dietary_restrictions_placeholder:
      "Алергія на молоко, дієта без глютену, алергія на горіхи, ...",
    too_young: "Дитина не може бути молодше {years} років",
    too_old: "Дитина не може бути старшою {years} років",
    age_info:
      "Дитині має бути не менше {young} років та не більше {old} років на момент закінчення перебування в таборі",
    desire_group: "З ким  хоче бути в групі",
    desire_group_placeholder: "Напишіть імена друзів",
    add_session: "Додайте дату",
    select_session: "Виберіть дату",
    select_one_session: "Виберіть принаймні одну дату для кожної дитини",
    remove: "Видалити",
    session_full:
      "Ця  дата   вже заповнена, ваша дитина буде зареєстрована як заміна. Ви можете вибрати іншу дату.",
    use_button_below: "Будь ласка, додайте дату за допомогою кнопки нижче.",
    activity_type: "Вид заняття",
    activity: "Заняття",
    location: "Місце",
    select_activity_type: "Виберіть вид",
    select_activity: "Виберіть заняття",
    select_location: "Виберіть місце",
    select_date: "Виберіть дату",
    as_substitute: "Як заміна",
    date: "дату",
    availability: "Доступність",
    available: "Вільно",
    full: "Зайнято",
    all: "Все",
    parents_contact_info: "Контактні дані батьків",
    children_registered: "Зареєстровані діти",
    attending_school: "Відвідує школу *",
    attending_school_placeholder: "Напишіть назву школи",
    which_grade: "В якому класі навчається дитина *",
    go_from_home:
      "Дитина приходить на заняття самостійно до обумовленого місця зустрічі *",
    select_option: "Виберіть варіант",
    can_go_home: "Після заняття дитина може піти самостійно *",
    go_from_school: "Ми заберемо дитину на заняття із групи продовженого дня *",
    return_to_school:
      "Після закінчення занять дитина повернеться до групи продовженого дня *",
    sessions_summary: "Підсумок дат і ціни",
    sessions: "Дати",
    number_of_activities: "Кількість занять",
    number_of_dates: "Кількість дат",
    number_of_children: "Кількість дітей",
    note: "Примітка до заявки",
    discount_code: "Код на знижку",
    total_price: "Ціна",
    price: "Ціна",
    conclusion: "Підсумок",
    terms_and_conditions:
      "Я згоден/a із загальними умовами та положеннями та умовами оплати",
    agree_gdpr: "Я згоден/a на обробку персональних даних",
    agree_photos: "Я згоден/a на фотографування під час заходів",
    agree_photos_desc:
      "Під час заходу ми робимо фото та відео, як діти займаються спортом і радіють.",
    agree_newsletter: "Я згоден/a отримувати розсилку новин",
    agree_newsletter_desc:
      "Вам не потрібно турбуватися про купу електронних листів. Іноді ми надсилаємо знижку :) Іноді ми надсилаємо якусь інформацію про новий табір, подію, заняття чи канікули. Час від часу ми надсилаємо запит на зворотній зв’язок тощо. Ви зможете скасувати підписку пізніше.",
    fill_additional_before_submit:
      "Щоб надіслати заявку, спочатку заповніть додаткову інформацію щодо дітей",
    submit: "Відправити заявку",
    success_message: "Відправка пройшла успішно",
    your_id: "Ваше посвідчення особи (ID)",
    confirm_message:
      "Ми щойно надіслали підтвердження та іншу інформацію на електронну пошту, зазначену в заявці. Якщо ви не отримаєте листа за кілька хвилин, перевірте спам і масові повідомлення.",
    payment_deadline:
      "Ви можете оплатити платіжним шлюзом або банківським переказом не пізніше 7 робочих днів.",
    pay_by_card: "Оплатити картою",
    bank_transfer: "Оплата банківським переказом",
    bank_acc_num_label: "Надішліть платіж на номер рахунку:",
    variable_symbol: "Варіабельний символ:",
    back_to_sportjoy: "Повернутись до SPORTJOY",
    summary_substutite:
      "Ми зв’яжемося з вами щодо дати, на яку ви зареєстровані як замінник.",
    filter_date: "Дати",
    combine_camps_message:
      "Ви можете комбінувати різні табори в одній заявці. Табори вам запропонують з урахуванням віку дитини.",
    combine_camps_headline: "Можна поєднувати табори",
    filter_no_dates:
      "Немає доступних дат для цієї комбінації фільтрів для вашої дитини",
    price_for_individuals: "Ціна за одну дитину",
    price_for_siblings: "Ціна за брата/сестру",
    free: "Безкоштовно",
    yes: "Так",
    no: "Ні",
    i_recommend: "Я рекомендую до табору",
    i_recommend_placehoder: "Введіть: ім'я прізвище - вік",
    i_was_recommended: "Мене рекомендував/рекомендувала",
    i_was_recommended_placeholder: "Ідентифікатор учасника",
    camps_application: "Заявка для таборів",
    once_place_for_child:
      "Як тільки в таборі з'являться вільні місця, ми повідомимо вас про це електронною поштою, і ми також надішлемо вам інформацію про оплату. Ми рекомендуємо вам якомога швидше сплатити після отримання електронного листа, щоб ми могли зарезервувати для вас місце.",
    payment_after_conf:
      "Після підтвердження платежу ви можете оплатити платіжним шлюзом або банківським переказом.",
    application_success_substitute: "Відправка заявки на заміну пройшла добре!",
    missing_date_msg_headline: "Не бачите заняття, яке шукаєте?",
    missing_date_msg_message:
      "Якщо заняття, яке ви шукаєте, не відображається, можливо, вік вашої дитини не відповідає віку, дозволеному для цього заняття. Будь ласка, зв'яжіться з нами.",
    choose_language: "Виберіть мову",
    choose_activity: "Виберіть заняття",
    playgroups_courses_kindergarden_label:
      "Заявки на гуртки, курси, дитячий садок",
    invoicing: "Виставлення рахунків",
    i_want_invoice: "Я хочу виставити рахунок-фактуру на ім'я мого роботодавця",
    employer_reg_num: "ОДРН роботодавця",
    invoice_text: "Текст рахунку-фактури",
    invoice_text_placeholder:
      "Виставляємо вам рахунок на суму 2950 чеських крон за літній табір з 12 по 16 липня для дитини Яни Новакової, виписаної на ім'я Тібора Новака, Komerční banka a.s.",
    invoice_due_date: "Строк оплати",
    invoice_due_date_placeholder: "2 тижні, 3 тижні, ...",
  },
});
