import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import { Col, Row } from "reactstrap";
import { Button, Form } from "semantic-ui-react";
import { jsonToFormData } from "../../../tools";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../actions/messageActions";
import { useDropzone } from "react-dropzone";
import { CustomDropzone } from "../../../styles";
import AttachmentsViewer from "../../containers/date-email/AttachmentsViewer";
import { getTypeContent, updateEmailType } from "../../../api/emails";

function EmailForm({ id }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    content: RichTextEditor.createEmptyValue(),
    subject: "",
    heading: "",
    attachments: [],
    oldAttachments: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    getTypeContent(id).then((res) => {
      setData({
        subject: res.data.subject,
        content: res.data.content
          ? RichTextEditor.createValueFromString(res.data.content, "html")
          : RichTextEditor.createEmptyValue(),
        oldAttachments: res.data.attachments,
        attachments: [],
        heading: res.data.heading,
      });
      setLoading(false);
    });
  }

  function update() {
    const payload = {
      content: data.content.toString("html"),
      subject: data.subject,
      heading: data.heading,
      attachments: data.attachments,
      id_email: id,
    };
    const fd = jsonToFormData(payload);
    updateEmailType(fd).then((res) => {
      dispatch(setMessage(res.data));
      getData();
    });
  }

  function onChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function onChangeRte(value) {
    setData({ ...data, content: value });
  }

  function onDrop(acceptedFiles) {
    acceptedFiles.map((file) => {
      var oldAttachments = data.attachments;
      oldAttachments.push(file);

      setData({
        ...data,
        attachments: oldAttachments,
      });
    });
  }

  function removeAttachment(del) {
    var oldAttachments = data.attachments;
    oldAttachments.splice(del, 1);

    setData({
      ...data,
      attachments: oldAttachments,
    });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".png, .jpg, .gif, .pdf, .doc, .jpeg, .webp, .docx",
    multiple: true,
  });

  return (
    <Form loading={loading} onSubmit={update}>
      <Form.Input
        type="text"
        name="subject"
        placeholder="Zadejte předmět emailu"
        label="Předmět"
        value={data.subject}
        required
        onChange={onChange}
      />
      <Form.Field>
        <label>Zpráva</label>
        <RichTextEditor
          value={data.content}
          editorClassName="demo-editor"
          required
          onChange={onChangeRte}
        />
      </Form.Field>
      <Form.Field>
        <label>Přílohy</label>
        <CustomDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Přetáhněte soubory sem ...</p>
          ) : (
            <p>
              Přetáhněte sem soubory, nebo klikněte pro vybrání z počítače
              <br />
              <span class="small">
                Podporované soubory: .png, .jpg, .gif, .pdf, .doc, .jpeg, .webp,
                .docx (max. 9 MB)
              </span>
            </p>
          )}
        </CustomDropzone>
      </Form.Field>
      <div>
        <AttachmentsViewer
          attachments={data.attachments}
          refresh={getData}
          id_email={id}
          remove={removeAttachment}
          oldAttachments={data.oldAttachments}
        />
      </div>
      <Row>
        <Col sm={12} className="text-center pt-4">
          <Button className="ml-2" primary>
            Uložit změny
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default EmailForm;
