import React from "react";
import { useParams } from "react-router";
import { Card, CardBody, CardHeader } from "../../../styles";
import EmailForm from "../../forms/emails/EmailForm";

function EmailsEditView() {

  const { id } = useParams();

  return (
    <>
      <Card>
        <CardHeader>Úprava emailu</CardHeader>
        <CardBody>
          <EmailForm id={id} />
        </CardBody>
      </Card>
    </>
  );
}

export default EmailsEditView;
