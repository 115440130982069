import React, { Component } from "react";
import {
  getApplications,
  disableApplication,
  getDisabled,
  enableApplication,
  searchApplications,
  countApplicationsByType,
  countKidsByType,
  getToken,
  updateEcomail,
} from "../../api";
import ApplicationsTable from "../containers/ApplicationsTable";
import { Card, CardControls, CardHeader } from "../../styles";
import {
  Button,
  Input,
  Dropdown,
  Modal,
  Dimmer,
  Loader,
  Pagination,
  Form,
  Icon,
} from "semantic-ui-react";
import _ from "loadsh";
import { connect } from "react-redux";
import { setMessage } from "../../actions/messageActions";
import { KidsSearchTable } from "../containers";
import queryString from "query-string";
import ExportByDate from "../modals/ExportByDate";
import { isMobileOnly } from "react-device-detect";
import ExportModal from "../modals/ExportModal";

const paymentOptions = [
  { key: "1", value: null, text: "Vše" },
  { key: "2", value: 0, text: "Nezaplaceno" },
  { key: "3", value: 1, text: "Zaplaceno" },
];

const disabledOptions = [
  { key: "1", value: 1, text: "Aktivní přihlášky" },
  { key: "2", value: 0, text: "Stornované přihlášky" },
];

class ApplicationsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      baseData: [],
      kids: [],
      column: "id_application",
      direction: "descending",
      filter: false,
      disableModalOpen: false,
      state: 1,
      loadingSearch: false,
      search_query: "",
      loading: true,
      total_applications: 0,
      total_kids: 0,
      emptyApplications: false,
      emptyKids: true,
      loadingExport: false,
      pages: 1,
      loadingEcomailUpdate: false,
    };
  }

  componentDidMount() {
    this.countApplicationsHandler();
    this.countKidsHandler();
    this.getApplicationsHandler();
  }

  countApplicationsHandler() {
    const { type, free } = this.props.match.params;

    var sub = "paid";
    if (free === "1") {
      sub = "free";
    }

    countApplicationsByType(type, sub).then((data) => {
      this.setState({
        total_applications: data.total_applications,
      });
    });
  }

  countKidsHandler() {
    const { type, free } = this.props.match.params;
    var sub = "paid";
    if (free === "1") {
      sub = "free";
    }
    countKidsByType(type, sub).then((data) => {
      this.setState({
        total_kids: data.total_kids,
      });
    });
  }

  getApplicationsHandler = () => {
    const parsed = queryString.parse(this.props.location.search);
    const { type, free } = this.props.match.params;
    var showAll = parsed.show_all ? parsed.show_all : null;

    getToken()
      .then((token) => {
        const params = {
          ...parsed,
          token: token,
          app_type: free === "1" ? "free" : "paid",
        };

        const url = queryString.stringify(params, {
          skipNull: true,
        });

        getApplications(type, showAll, url)
          .then((data) => {
            this.setState({
              applications: data.items,
              baseData: data.items,
              hasSome: true,
              loading: false,
              pages: data.pages,
              kids: data.searchkids,
            });
          })
          .catch((err) => {
            alert("Nepodařilo se získat data s chybou: " + err.message);
          });
      })
      .catch((err) => {
        alert(
          "Nepodařilo se získat bezpečnostní token. Aktualizujte prosím stránku"
        );
      });
  };

  getDisabledHandler = () => {
    const { type } = this.props.match.params;

    this.setState({
      loading: true,
    });

    getDisabled(type).then((data) => {
      this.setState({
        applications: data,
        baseData: data,
        hasSome: true,
        loading: false,
      });

      const parsed = queryString.parse(this.props.location.search);

      if (parsed.paid) {
        const data = {
          name: "paid",
          value: Number(parsed.paid),
        };
        this.filterByQuery(data);
      }
    });
  };

  handleSort = (clickedColumn) => () => {
    const { column, applications, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        applications: _.sortBy(applications, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: applications.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  filterByQuery = (data) => {
    if (data.value !== null) {
      this.setState(
        {
          filter: true,
          filters: {
            ...this.state.filters,
            [data.name]: data.value,
          },
        },
        () => {
          this.setState({
            applications: _.filter(this.state.baseData, this.state.filters),
          });
        }
      );
    } else {
      this.setState(
        {
          filters: _.omit(this.state.filters, [data.name]),
        },
        () => {
          this.setState({
            applications:
              _.keys(this.state.filters).length !== 0
                ? _.filter(this.state.baseData, this.state.filters)
                : this.state.baseData,
            filter: _.keys(this.state.filters).length !== 0 ? true : false,
          });
        }
      );
    }
  };

  onChangeSelect = (e, data) => {
    const { type, free } = this.props.match.params;
    const parsed = queryString.parse(this.props.location.search);

    const params = {
      ...parsed,
      [data.name]: [data.value],
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    this.props.history.push(
      `${process.env.PUBLIC_URL}/manage/applications/${type}/${free}?${url}`
    );
  };

  toggleShowAll = () => {
    const { type, free } = this.props.match.params;
    const parsed = queryString.parse(this.props.location.search);

    var toggle = "";

    if (parsed.show_all) {
      if (parsed.show_all === "1") {
        toggle = 0;
      } else if (parsed.show_all === "0") {
        toggle = 1;
      }
    } else {
      toggle = 1;
    }

    const params = {
      ...parsed,
      show_all: toggle,
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    this.props.history.push(
      `${process.env.PUBLIC_URL}/manage/applications/${type}/${free}?${url}`
    );
  };

  onChangeState = (e, data) => {
    this.setState({
      state: data.value,
    });

    if (data.value === 1) {
      this.getApplicationsHandler();
    } else {
      this.getDisabledHandler();
    }
  };

  disableApplicationHandler = (id) => {
    disableApplication(id).then((res) => {
      this.props.setMessage(res);
      this.handleCloseDisableModal();
      this.getApplicationsHandler();
    });
  };

  enableApplicationHandler = (id) => {
    enableApplication(id).then((res) => {
      this.props.setMessage(res);

      if (this.state.state === 1) {
        this.getApplicationsHandler();
      } else {
        this.getDisabledHandler();
      }
    });
  };

  handleCloseDisableModal = () => {
    this.setState({
      toDisable: "",
      disableModalOpen: false,
    });
  };

  handleOpenDisableModal = (id) => {
    this.setState({
      toDisable: id,
      disableModalOpen: true,
    });
  };

  searchHandler = (q) => {
    const parsed = queryString.parse(this.props.location.search);

    this.setState({
      loadingSearch: true,
    });

    if (q === "") {
      this.getApplicationsHandler();
      this.setState({
        loadingSearch: false,
        kids: [],
        emptyKids: true,
      });
    }

    if (Number(parsed.state)) {
      var state = Number(parsed.state);
    } else {
      state = 1;
    }

    searchApplications(q, state).then((data) => {
      this.setState({
        applications: data.applications,
        baseData: data.applications,
        loadingSearch: false,
        emptyApplications: data.applications.length === 0 ? true : false,
        kids: q !== "" ? data.kids : [],
        emptyKids: data.kids.length === 0 || q === "" ? true : false,
      });
    });
  };

  onSearch = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  onSearchSubmit = () => {
    const parsed = queryString.parse(this.props.location.search);

    var query = this.state.search_query ? this.state.search_query : null;

    const params = {
      ...parsed,
      search: query,
      page: null,
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    const { type, free } = this.props.match.params;

    this.props.history.push(
      `${process.env.PUBLIC_URL}/manage/applications/${type}/${free}?${url}`
    );
  };

  _renderDisableModal = () => {
    return (
      <Modal
        open={this.state.disableModalOpen}
        onClose={this.handleCloseDisableModal}
      >
        <Modal.Header>Smazat termín</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <p>Jste si jistí, že chcete stornovat tuto přihlášku?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={this.handleCloseDisableModal}>
            Zpět
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Ano, chci stornovat"
            onClick={() => this.disableApplicationHandler(this.state.toDisable)}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  exportPhotoHandler = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-photo/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportEmailsHandler = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-emails/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportDatesNewHandler = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-dates-new/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportDatesAllHandler = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-dates-all/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportDatesHobbyHandler = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-dates-hobby/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportKidsByLocationHandler = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-by-location/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  _exportAgeAll = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-age/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  _exportDates = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-dates/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  _exportParents = () => {
    this.setState({
      loadingExport: true,
    });
    getToken().then((token) => {
      window.open(
        "https://api.sportjoy.cz/export/export-parents/" + token,
        "_blank"
      );

      this.setState({
        loadingExport: false,
      });
    });
  };

  exportList = (state, range = null) => {
    const { type, free } = this.props.match.params;

    var url = "";
    if (free === "1") {
      url =
        "https://api.sportjoy.cz/export/export?type=" +
        state +
        "&app_type=" +
        type +
        "_free" +
        "&remaining=" +
        range;
      if (range) {
        url =
          "https://api.sportjoy.cz/export/export?type=" +
          state +
          "&app_type=" +
          type +
          "_free" +
          "&app_range=" +
          range +
          "&remaining=" +
          range;
      }
    } else {
      url =
        "https://api.sportjoy.cz/export/export?type=" +
        state +
        "&app_type=" +
        type +
        "_paid" +
        "&remaining=" +
        range;
      if (range) {
        url =
          "https://api.sportjoy.cz/export/export?type=" +
          state +
          "&app_type=" +
          type +
          "_paid" +
          "&app_range=" +
          range +
          "&remaining=" +
          range;
      }
    }
    this.setState({
      loadingExport: true,
    });
    getToken()
      .then((token) => {
        window.open(url + "&token=" + token, "_blank");

        this.setState({
          loadingExport: false,
        });
      })
      .catch((err) => {
        alert(
          "Nepodařilo se získat bezpečnostní certifikát s chybou:" +
            err.message +
            ". Zkuste to prosím znovu."
        );
        this.setState({
          loadingExport: false,
        });
      });
  };

  handlePaginationChange = (e, { activePage }) => {
    const parsed = queryString.parse(this.props.location.search);

    const params = {
      ...parsed,
      page: activePage,
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    const { type, free } = this.props.match.params;

    this.props.history.push(
      `${process.env.PUBLIC_URL}/manage/applications/${type}/${free}?${url}`
    );
  };

  _renderExportDropdown = () => {
    return (
      <Dropdown
        text="Export"
        icon="download"
        floating
        labeled
        button
        className="icon"
        loading={this.state.loadingExport}
      >
        <Dropdown.Menu>
          <Dropdown.Header icon="file outline" content="Typ exportu" />
          <Dropdown.Divider />
          <ExportByDate />
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => this.exportList("canceled")}
            icon="users"
            text="Stornované přihlášky"
          />
          <Dropdown.Item
            onClick={() => this.exportList("active")}
            icon="users"
            text="Aktivní přihlášky"
          />
          <Dropdown.Item
            onClick={() => this.exportList("2021")}
            icon="users"
            text="Přihlášky - 2021"
          />
          <Dropdown.Item
            onClick={() => this.exportList("all")}
            icon="users"
            text="Přihlášky - Vše"
          />
          <Dropdown.Item
            onClick={() => this.exportList("paid-email")}
            icon="at"
            text="Emaily"
          />
          <Dropdown.Item
            onClick={() => this.exportList("sms")}
            icon="phone"
            text="Telefony"
          />
          <Dropdown.Item
            onClick={() => this.exportList("camp-age-groups", 0)}
            icon="add"
            text="Trička"
          />
          <Dropdown.Item
            onClick={() => this.exportList("camp-age-groups-babyjoy", 0)}
            icon="add"
            text="Trička - BABYJOY"
          />
          <Dropdown.Item
            onClick={() => this.exportList("camp-age-groups", 1)}
            icon="add"
            text="Trička - zbytek"
          />
          <Dropdown.Item
            onClick={() => this.exportList("camp-age-groups-babyjoy", 1)}
            icon="add"
            text="Trička - BABYJOY - zbytek"
          />
          <Dropdown.Item
            onClick={() => this.exportList("addresses")}
            icon="users"
            text="Adresy"
          />
          <ExportModal {...this.props} />

          <Dropdown.Header icon="file outline" content="Staré exporty" />
          <Dropdown.Item
            onClick={this.exportPhotoHandler}
            icon="camera"
            text="Nesouhlas s focením"
          />
          <Dropdown.Item
            onClick={this.exportKidsByLocationHandler}
            icon="users"
            text="Děti podle lokality"
          />
          <Dropdown.Item
            onClick={this._exportAgeAll}
            icon="users"
            text="Věk dětí"
          />
          <Dropdown.Item
            onClick={this._exportDates}
            icon="calendar"
            text="Termíny"
          />
          <Dropdown.Item
            onClick={this.exportDatesNewHandler}
            icon="calendar"
            text="Termíny 2"
          />
          <Dropdown.Item
            onClick={this._exportParents}
            icon="users"
            text="Rodiče"
          />
          <Dropdown.Item
            onClick={this.exportEmailsHandler}
            icon="at"
            text="Emaily"
          />
          <Dropdown.Item
            onClick={this.exportDatesAllHandler}
            icon="users"
            text="Vše - včetně stornovaných"
          />
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={this.exportDatesHobbyHandler}
            icon="calendar"
            text="Termíny - kroužky"
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  _updateEcomail = () => {
    const { type, free } = this.props.match.params;

    this.setState({
      loadingEcomailUpdate: true,
    });

    getToken()
      .then((token) => {
        var url = "";
        if (free === "1") {
          url =
            "https://api.sportjoy.cz/integrations/update-ecomail?app_type=" +
            type +
            "_free&token=" +
            token;
        } else {
          url =
            "https://api.sportjoy.cz/integrations/update-ecomail?app_type=" +
            type +
            "_paid&token=" +
            token;
        }

        updateEcomail(url)
          .then((res) => {
            this.props.setMessage(res.data);
            this.setState({
              loadingEcomailUpdate: false,
            });
          })
          .catch((err) => {
            this.props.setMessage(err.data);
            this.setState({
              loadingEcomailUpdate: false,
            });
          });
      })
      .catch((err) => {
        this.props.setMessage({
          type: "error",
          message: "Nepodařilo se získat bezpečnostní token",
        });
        this.setState({
          loadingEcomailUpdate: false,
        });
      });
  };

  ecomailModal = () => {
    return (
      <Modal open={this.state.loadingEcomailUpdate} className="loading_modal">
        <Modal.Content>
          <Loader active inline="centered" inverted>
            Načítám nové kontakty
          </Loader>
          <p style={{ textAlign: "center" }}>
            Může pár minut trvat. Nezavírejte stránku, dokud aktualizace
            neskončí.
          </p>
        </Modal.Content>
      </Modal>
    );
  };

  renderTitle = () => {
    const { type } = this.props.match.params;

    switch (type) {
      case "camp":
        return "tábory";

      case "hobby":
        return "kroužky";

      case "kurzy":
        return "kurzy";

      case "skolka":
        return "školka";

      default:
        return "";
    }
  };

  render() {
    const parsed = queryString.parse(this.props.location.search);

    return (
      <div>
        <Card>
          <CardHeader className="display-flex align-items-center">
            <div className="row">
              <div className="col-sm-3 text-left">
                <h2>Správa přihlášek - {this.renderTitle()}</h2>
              </div>
              <div className="col-sm-2 text-right card-stats">
                <p>Počet přihlášek</p>
                <p>{this.state.total_applications}</p>
              </div>
              <div className="col-sm-1 text-right card-stats">
                <p>Počet dětí</p>
                <p>{this.state.total_kids}</p>
              </div>
              <div className="col-sm-6 text-right">
                <Button onClick={this._updateEcomail}>
                  Aktualizovat Ecomail
                </Button>
                <Button onClick={this.toggleShowAll}>
                  {parsed.show_all === "1"
                    ? "Zobrazit letošek"
                    : "Zobrazit vše"}
                </Button>
                {this._renderExportDropdown()}
                <Button
                  primary
                  href="https://app.sportjoy.cz/embed/signup/sportjoy"
                  target="_blank"
                >
                  <Icon name="plus" /> Nový
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardControls>
            <div className="row">
              <div className="col-sm-6 text-left">
                <Form onSubmit={this.onSearchSubmit}>
                  <Input
                    placeholder="Hledat..."
                    fluid
                    icon="search"
                    name="search_query"
                    loading={this.state.loadingSearch}
                    defaultValue={parsed.search ? parsed.search : null}
                    onChange={this.onSearch}
                  />
                </Form>
              </div>
              <div className="col-sm-3 text-left">
                <Dropdown
                  name="paid"
                  options={paymentOptions}
                  placeholder="Zaplaceno / nezaplaceno"
                  selection
                  onChange={this.onChangeSelect}
                  value={parsed.paid ? Number(parsed.paid) : null}
                  fluid
                />
              </div>
              <div className="col-sm-3 text-left">
                <Dropdown
                  name="state"
                  options={disabledOptions}
                  placeholder="Aktivní / stornované"
                  selection
                  onChange={this.onChangeSelect}
                  fluid
                  value={parsed.state ? Number(parsed.state) : null}
                />
              </div>
            </div>
          </CardControls>
          {!this.state.hasSome ? (
            <p>Nemáte žádné přihlášky</p>
          ) : (
            <div>
              {!this.state.emptyApplications && (
                <>
                  <ApplicationsTable
                    items={this.state.applications}
                    disable={this.handleOpenDisableModal}
                    enable={this.enableApplicationHandler}
                    refresh={this.getApplicationsHandler}
                    sort={this.handleSort}
                    column={this.state.column}
                    direction={this.state.direction}
                  />
                </>
              )}
              {this._renderDisableModal()}
            </div>
          )}
          <Dimmer active={this.state.loading}>
            <Loader>Načítám data</Loader>
          </Dimmer>
        </Card>

        {this.state.pages !== 1 && (
          <div className="mt-3 justify-content-center d-flex">
            <Pagination
              activePage={parsed.page ? Number(parsed.page) : 1}
              onPageChange={this.handlePaginationChange}
              totalPages={this.state.pages}
              boundaryRange={isMobileOnly ? 0 : 1}
            />
          </div>
        )}

        {this.state.kids.length !== 0 && (
          <Card className="mt-5">
            <CardHeader className="display-flex align-items-center">
              <div className="row">
                <div className="col-sm-7 text-left">
                  <h2>Výsledky vyhledávání - děti</h2>
                </div>
              </div>
            </CardHeader>
            <KidsSearchTable items={this.state.kids} />
          </Card>
        )}
        {this.ecomailModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setMessage })(ApplicationsView);
