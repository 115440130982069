import { Table } from "semantic-ui-react";
import styled from "styled-components";

export const ApplicationRow = styled(Table.Row)`
  position: relative;

  ${(props) =>
    props.seen === 0 &&
    '::after { content: ""; box-shadow: 0 0 0 0 rgba(255, 82, 82, 1); animation: pulse-red 2s infinite; width: 13px; height: 13px; top: 50%; transform: translateY(-50%); background: rgba(255, 82, 82, 1); border-radius: 500px; position: absolute; right: 20px; }'}

  @keyframes pulse-red {
    0% {
      transform: scale(0.95) translateY(-50%);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1) translateY(-50%);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95) translateY(-50%);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
`;
