import React, { useEffect, useState } from "react";
import { Form, Checkbox, Button, Popup, Icon } from "semantic-ui-react";
import {
  addInstructor,
  getCheckboxesApp,
  updateInstructor,
} from "../../../api/instructors";
import { jsonToFormData } from "../../../tools";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormGroup } from "../../../styles/instructors";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMessage } from "../../../actions/messageActions";

function InstructorsSignupForm({
  withRedirect,
  submitType,
  initData,
  refresh,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isLoadingData, setLoadingData] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({});

  const [data, setData] = useState({
    title: "",
    first_name: "",
    last_name: "",
    email: "",
    email_again: "",
    phone: "",
    university: {
      ftvs: false,
      pedf_uk: false,
      pdf_mu: false,
      other: false,
      other_value: "",
      none: false,
    },
    major: "",
    experience: "",
    experience_sportjoy: {
      sportjoy_camp: false,
      kidsjoy_camp: false,
      babyjoy_camp: false,
      adventure_camp: false,
      schooljoy_camp: false,
      adventure_hobby: false,
      more_info: "",
    },
    interest: {
      chief_camps: false,
      instructor_advanture_camp: false,
      instructor_babyjoy: false,
      instructor_kidsjoy: false,
      instructor_sportjoy: false,
      instructor_czech_camp: false,
      nanny: false,
      individual_tutoring: false,
      individual_tutoring_class: "",
      logistics: false,
      backoffice: false,
      adventure_hobby: false,
      czech_course: false,
      english_course: false,
      spanish_course: false,
      kindergarden: false,
      newspapers_hobby: false,
      magic_hobby: false,
      tablegames_hobby: false,
      hlavni_trener_sportovni_pohybove_akademie: false,
      pomocny_trener_sportovni_pohybove_akademie: false,
      pomocny_lektor_krouzku: false,
      pomocny_lektor_skolka: false,
      other: false,
      other_more: "",
    },
    workshops: {
      sportjoy_akademie: false,
      volnocasova_akademie: false,
    },
    first_aid: null,
    sportjoy_academy: 1,
    note: "",
    languages: [{ language: "", level: "" }],
    knows_languages: null,
    workshop: null,
  });

  useEffect(() => {
    _getCheckboxesApp();
    if (initData) {
      setData(initData);
      setLoadingData(false);
    } else {
      setLoadingData(false);
    }
  }, [initData]);

  function onChangeCheckbox(e, val, group) {
    setData({
      ...data,
      [group]: {
        ...data[group],
        [val.name]: val.checked,
      },
    });
  }

  function _getCheckboxesApp() {
    getCheckboxesApp().then((res) => {
      setCheckboxValues(res.data);
    });
  }

  function onChangeRadio(e) {
    setData({
      ...data,
      [e.target.name]: Number(e.target.value),
    });
  }

  function onChange(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  function onChangeGroup(e, group) {
    setData({
      ...data,
      [group]: {
        ...data[group],
        [e.target.name]: e.target.value,
      },
    });
  }

  function onSubmit() {
    setLoading(true);

    const prepData = {
      ...data,
      birthday: moment(data.birthday).format("YYYY-MM-DD"),
    };

    const dataToSend = {
      data: [prepData],
    };

    const fd = jsonToFormData(dataToSend);

    if (submitType === "update") {
      updateInstructor(fd)
        .then((res) => {
          dispatch(setMessage(res.data));
          setLoading(false);
          refresh();
        })
        .catch((err) => {
          alert("Přihláška nebyla odeslána z důvodu: " + err.message);
          setLoading(false);
          refresh();
        });
    } else {
      addInstructor(fd)
        .then((res) => {
          if (withRedirect && res.data.type === "success") {
            history.push("/embed/instruktor/uspech");
          }

          setLoading(false);
        })
        .catch((err) => {
          alert("Přihláška nebyla odeslána z důvodu: " + err.message);
          setLoading(false);
        });
    }
  }

  function setDate(date) {
    setData({
      ...data,
      birthday: date,
    });
  }

  function stringToBoolean(string) {
    switch (string) {
      case "true":
      case "yes":
      case "1":
        return true;
      case "false":
      case "no":
      case "0":
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }

  function addLanguage() {
    const langs = [...data.languages, { language: "", level: "" }];

    setData({
      ...data,
      languages: langs,
    });
  }

  function onChangeLang(e, index) {
    let langs = [...data.languages];
    langs[index] = {
      ...langs[index],
      [e.target.name]: e.target.value,
    };

    setData({
      ...data,
      languages: langs,
    });
  }

  return (
    <>
      {!isLoadingData && (
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Form.Input
              type="text"
              name="title"
              id="title"
              label="Titul"
              placeholder="Zadejte Váš titul"
              value={data.title}
              onChange={onChange}
            />
            <Form.Input
              type="text"
              name="first_name"
              id="first_name"
              label="Jméno"
              placeholder="Zadejte Vaše křestní jméno"
              required
              value={data.first_name}
              onChange={onChange}
            />
            <Form.Input
              type="text"
              name="last_name"
              id="last_name"
              label="Příjmení"
              placeholder="Zadejte Vaše příjmení"
              required
              value={data.last_name}
              onChange={onChange}
            />

            <Form.Field>
              <label>
                Datum narození <span className="red">*</span>
              </label>
              <DatePicker
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                locale="cs"
                dateFormat="dd.MM.yyyy"
                id="birthday"
                onChange={(date) => setDate(date)}
                selected={data.birthday && new Date(data.birthday)}
                required
                placeholderText="Zadejte datum narození"
              />
            </Form.Field>

            <Form.Input
              type="email"
              name="email"
              id="email"
              label="Email"
              placeholder="Zadejte Váš email"
              required
              value={data.email}
              onChange={onChange}
            />
            {submitType !== "update" && (
              <Form.Input
                type="email"
                name="email_again"
                id="email_again"
                label="Email znovu"
                placeholder="Zadejte znovu Váš email"
                required
                value={data.email_again}
                onChange={onChange}
              />
            )}
            <Form.Input
              type="text"
              name="phone"
              id="phone"
              label="Telefon"
              placeholder="Zadejte Váš telefon"
              required
              value={data.phone}
              onChange={onChange}
            />
          </FormGroup>

          <FormGroup>
            <div className="field">
              <label>Co studujete nebo máte vystudováno</label>
            </div>
            <Form.Field>
              <Checkbox
                label="FTVS"
                name="ftvs"
                checked={stringToBoolean(data.university.ftvs)}
                onChange={(e, val) => onChangeCheckbox(e, val, "university")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="PedF UK"
                name="pedf_uk"
                checked={stringToBoolean(data.university.pedf_uk)}
                onChange={(e, val) => onChangeCheckbox(e, val, "university")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="PdF MU"
                name="pdf_mu"
                checked={stringToBoolean(data.university.pdf_mu)}
                onChange={(e, val) => onChangeCheckbox(e, val, "university")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Jiná pedagogická fakulta"
                name="other"
                checked={stringToBoolean(data.university.other)}
                onChange={(e, val) => onChangeCheckbox(e, val, "university")}
              />
            </Form.Field>
            {stringToBoolean(data.university.other) && (
              <Form.Input
                type="text"
                name="other_value"
                id="other_value"
                label="Uveďte"
                placeholder="Uveďte jinou školu"
                required
                value={data.university.other_value}
                onChange={(e) => onChangeGroup(e, "university")}
              />
            )}
            <Form.Field>
              <Checkbox
                label="Žádné"
                name="none"
                checked={data.university.none}
                onChange={(e, val) => onChangeCheckbox(e, val, "university")}
              />
            </Form.Field>
            {!stringToBoolean(data.university.none) && (
              <Form.Input
                type="text"
                name="major"
                id="major"
                label="Jaký obor"
                placeholder="Uveďte vystudovaný obor"
                required
                value={data.major}
                onChange={onChange}
              />
            )}
            <Form.TextArea
              name="experience"
              id="experience"
              label="Jaké máte zkušenosti s prací s dětmi"
              placeholder="např. Pracuji jako učitelka angličtiny na 1. stupni základní školy, 2 roky jsem vedla sportovní kroužek pro děti od 8 do 10 let…"
              required
              value={data.experience}
              onChange={onChange}
            />
            <div className="field">
              <label>Máte zkušenosti s prací ve SPORTJOY, z. s.?</label>
            </div>
            <Form.Field>
              <Checkbox
                label="Ano, na sportovním příměstském táboře"
                name="sportjoy_camp"
                checked={stringToBoolean(
                  data.experience_sportjoy.sportjoy_camp
                )}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Ano, na kreativním příměstském táboře"
                name="kidsjoy_camp"
                checked={stringToBoolean(data.experience_sportjoy.kidsjoy_camp)}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Ano, na pohybovém příměstském táboře pro předškoláky"
                name="babyjoy_camp"
                checked={stringToBoolean(data.experience_sportjoy.babyjoy_camp)}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Ano, na dobrodružném příměstském táboře"
                name="adventure_camp"
                checked={stringToBoolean(
                  data.experience_sportjoy.adventure_camp
                )}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Ano, na pohádkovém příměstském táboře"
                name="schooljoy_camp"
                checked={stringToBoolean(
                  data.experience_sportjoy.fairytale_camp
                )}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Ano, na pokémonském příměstkém táboře"
                name="adventure_hobby"
                checked={stringToBoolean(data.experience_sportjoy.pokemon_camp)}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>
            {!stringToBoolean(data.experience_sportjoy.none) && (
              <Form.Field>
                <Form.TextArea
                  name="more_info"
                  id="more_info"
                  label="Rozveďte"
                  placeholder="např. 2018-2021 jsem byla každý rok na táboře pro předškoláky, dohromady na sedmi termínech"
                  value={data.experience_sportjoy.more_info}
                  onChange={(e) => onChangeGroup(e, "experience_sportjoy")}
                  required
                />
              </Form.Field>
            )}
            <Form.Field>
              <Checkbox
                label="Nemám"
                name="none"
                checked={stringToBoolean(data.experience_sportjoy.none)}
                onChange={(e, val) =>
                  onChangeCheckbox(e, val, "experience_sportjoy")
                }
              />
            </Form.Field>

            <Form.Field>
              <label className="required">Máte kurz první pomoci?</label>
              <div className="d-flex">
                <input
                  type="radio"
                  id="first_aid_1"
                  name="first_aid"
                  value={1}
                  onChange={onChangeRadio}
                  required
                  checked={data.first_aid === 1}
                />
                <label htmlFor="first_aid_1" className="m-0 pl-2">
                  Ano
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  id="first_aid_2"
                  name="first_aid"
                  value={0}
                  onChange={onChangeRadio}
                  required
                  checked={data.first_aid === 0}
                />
                <label htmlFor="first_aid_2" className="m-0 pl-2">
                  Ne
                </label>
              </div>
            </Form.Field>

            <Form.Field>
              <label className="required">Ovládáte cizí jazyky?</label>
              <div className="d-flex">
                <input
                  type="radio"
                  id="knows_languages_1"
                  name="knows_languages"
                  value={1}
                  onChange={onChangeRadio}
                  required
                  checked={data.knows_languages === 1}
                />
                <label htmlFor="knows_languages_1" className="m-0 pl-2">
                  Ano
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  id="knows_languages_2"
                  name="knows_languages"
                  value={0}
                  onChange={onChangeRadio}
                  required
                  checked={data.knows_languages === 0}
                />
                <label htmlFor="knows_languages_2" className="m-0 pl-2">
                  Ne
                </label>
              </div>
            </Form.Field>

            {data.knows_languages === 1 && (
              <>
                {data.languages.map((item, index) => {
                  return (
                    <Row className="mb-3" key={index}>
                      <Col sm={6}>
                        <Form.Input
                          name="language"
                          type="text"
                          label="Jazyk"
                          placeholder="Zadejte cizí jazyk"
                          value={item.language}
                          onChange={(e) => onChangeLang(e, index)}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Input
                          name="level"
                          type="text"
                          label="Úroveň"
                          placeholder="Zadejte úroveň"
                          value={item.level}
                          onChange={(e) => onChangeLang(e, index)}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <Button onClick={addLanguage} as="span">
                  Přidat jazyk
                </Button>
              </>
            )}
          </FormGroup>

          <FormGroup>
            <div className="field">
              <label>O jaké aktivity máte předběžně zájem?</label>
            </div>
            <Row>
              {Object.keys(checkboxValues).map((key, index) => {
                return (
                  <Col sm={6} key={index} className="mb-4">
                    <div className="field">
                      <label>{key}:</label>
                    </div>
                    {checkboxValues[key].map((item) => {
                      return (
                        <Form.Field key={item.key}>
                          <Checkbox
                            label={item.text}
                            name={item.key}
                            checked={stringToBoolean(
                              data.interest[item.key]
                                ? data.interest[item.key]
                                : false
                            )}
                            onChange={(e, val) =>
                              onChangeCheckbox(e, val, "interest")
                            }
                          />
                        </Form.Field>
                      );
                    })}
                  </Col>
                );
              })}
            </Row>
          </FormGroup>

          <FormGroup>
            <Form.Field>
              <label className="required">
                Ve kterých městech máte zájem pracovat?
              </label>
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Praha"
                name="praha"
                checked={stringToBoolean(data?.cities?.praha)}
                onChange={(e, val) => onChangeCheckbox(e, val, "cities")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Brno"
                name="brno"
                checked={stringToBoolean(data?.cities?.brno)}
                onChange={(e, val) => onChangeCheckbox(e, val, "cities")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Plzeň"
                name="plzen"
                checked={stringToBoolean(data?.cities?.plzen)}
                onChange={(e, val) => onChangeCheckbox(e, val, "cities")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Hradec Králové"
                name="hradec_kralove"
                checked={stringToBoolean(data?.cities?.hradec_kralove)}
                onChange={(e, val) => onChangeCheckbox(e, val, "cities")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Mladá Boleslav"
                name="mlada_boleslav"
                checked={stringToBoolean(data?.cities?.mlada_boleslav)}
                onChange={(e, val) => onChangeCheckbox(e, val, "cities")}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Liberec/České Budějovice"
                name="liberec"
                checked={stringToBoolean(data?.cities?.liberec)}
                onChange={(e, val) => onChangeCheckbox(e, val, "cities")}
              />
            </Form.Field>
          </FormGroup>

          <FormGroup>
            <Form.Field>
              <Form.TextArea
                name="note"
                id="note"
                label="Poznámka k přihlášce"
                placeholder="Zde můžete napsat svou poznámku"
                value={data.note}
                onChange={onChange}
              />
            </Form.Field>
          </FormGroup>

          <Row>
            <Col sm={12} className="text-center mb-5">
              <Button primary loading={isLoading} disabled={isLoading}>
                {submitType === "update" ? "Uložit změny" : "Odeslat přihlášku"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default InstructorsSignupForm;
