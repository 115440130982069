import React, { useEffect, useState } from "react";
import { getList } from "../../../api/instructors";
import { Card, CardControls, CardHeader } from "../../../styles";
import InstructorsListTable from "../../tables/Instructors/InstructorsListTable";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Col, Row } from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import InstructorsDropdownFilter from "../../forms/instructors/filters/InstructorsDropdownFilter";

function InstructorsListView() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const parsed = queryString.parse(search);

  const stateOptions = [
    { key: "0", value: 0, text: "Neaktivní" },
    { key: "1", value: 1, text: "Aktivní" },
    { key: "-1", value: -1, text: "Možná" },
  ];

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);

    getList(search)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        alert("Data nebyla načtena z důvodu chyb: " + err.message);
      });
  }

  function onChangeFilter(e, data) {
    if (data.value === "") {
      data.value = null;
    }
    const params = {
      ...parsed,
      [data.name]: [data.value],
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    history.push(`${process.env.PUBLIC_URL}/manage/instructors?${url}`);
  }

  return (
    <>
      <Card>
        <CardHeader>Seznam instruktorů</CardHeader>
        <CardControls>
          <Row>
            <Col sm={3}>
              <InstructorsDropdownFilter
                group="interest"
                onChange={onChangeFilter}
                placeholder="Zájem"
              />
            </Col>
            <Col sm={3}>
              <InstructorsDropdownFilter
                group="experience_sportjoy"
                onChange={onChangeFilter}
                placeholder="Zkušenosti se SPORTJOY"
              />
            </Col>
            <Col sm={3}>
              <Dropdown
                name="state"
                options={stateOptions}
                placeholder="Stav přihlášky"
                selection
                onChange={onChangeFilter}
                value={parsed.state ? Number(parsed.state) : null}
                fluid
              />
            </Col>
          </Row>
        </CardControls>
        <InstructorsListTable
          data={data}
          loading={isLoading}
          refresh={getData}
        />
      </Card>
    </>
  );
}

export default InstructorsListView;
