import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Button, Dropdown, Form } from "semantic-ui-react";
import { Card, CardControls, CardHeader } from "../../../styles";
import EmployeesTable from "../../containers/employees/EmployeesTable";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import { yesNoMaybeOptions } from "../../../helpers";

function EmployeesListView() {
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search);
  const [filters, setFilters] = useState({ query: "" });

  useEffect(() => {
    setFilters(parsed);
  }, []);

  function submitFilters() {
    const params = {
      ...parsed,
      ...filters,
    };

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    history.push(`${process.env.PUBLIC_URL}/manage/employees?${url}`);
  }

  function onChange(e, target) {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  }

  function onChangeSelect(e, val) {
    const params = {
      ...parsed,
      [val.name]: val.value,
    };

    setFilters(params);

    const url = queryString.stringify(params, {
      skipNull: true,
    });

    history.push(`${process.env.PUBLIC_URL}/manage/employees?${url}`);
  }

  return (
    <>
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between">
          Seznam lektorů
          <div>
            <Button
              as="a"
              href="https://api.sportjoy.cz/export/employees"
              target="_blank"
            >
              Export lektorů
            </Button>
            <Button
              as="a"
              href="https://api.sportjoy.cz/export/employees-dates"
              target="_blank"
            >
              Export lektorů - termíny
            </Button>
            <Button
              as="a"
              href="https://api.sportjoy.cz/export/employees-wages"
              target="_blank"
            >
              Export lektorů - mzdy
            </Button>
            <Button
              as="a"
              href="https://api.sportjoy.cz/export/employees-camps"
              target="_blank"
            >
              Export lektorů - tábory
            </Button>
          </div>
        </CardHeader>
        <CardControls>
          <Form onSubmit={submitFilters}>
            <Row>
              <Col sm={6}>
                <Form.Input
                  type="text"
                  name="query"
                  icon="search"
                  fluid
                  placeholder="Hledat..."
                  onChange={onChange}
                  value={filters.query ? filters.query : ""}
                />
              </Col>
              <Col sm={3}>
                <Dropdown
                  placeholder="- Chci pokračovat -"
                  options={yesNoMaybeOptions}
                  value={parsed?.want_continue && Number(parsed.want_continue)}
                  name="want_continue"
                  onChange={onChangeSelect}
                  selection
                  fluid
                />
              </Col>
            </Row>
          </Form>
        </CardControls>
        <EmployeesTable leaderSwitcher={false} filters={parsed} />
      </Card>
    </>
  );
}

export default EmployeesListView;
