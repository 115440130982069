import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { getInvoicesList } from '../../../api/invoices';
import { Card, CardBody, CardHeader } from '../../../styles'
import InvoicesList from '../../containers/invoices/InvoicesList'

function InvoicesListView() {

    const [invoices, setInvoices] = useState([]);
    const [hasInvoices, setHasInvoices] = useState(false);
    const [loadingInvoices, setLoadingInvoices] = useState(true);

    useEffect(() => {
        _getInvoicesList()
    }, [])

    function _getInvoicesList() {

        setLoadingInvoices(true);

        getInvoicesList()
        .then((res) => {
            if(res.data.length !== 0) {
                setHasInvoices(true);
                setInvoices(res.data);
            } else {
                setHasInvoices(false);
            }

            setLoadingInvoices(false);
        })
        .catch((err) => {
            alert('Nepodařilo se získat faktury');
            setLoadingInvoices(false);
        })
    }

    return (
        <>
            <Row>
                <Col sm={6}>
                    <Card>
                        <CardHeader>
                            Faktury
                        </CardHeader>
                        <CardBody>
                            <InvoicesList 
                                invoices={invoices}
                                hasBills={hasInvoices}
                                loading={loadingInvoices}
                                showAppId={true}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default InvoicesListView