import React, { useState, useEffect } from "react";
import { EmbedNavigation } from "../containers";
import { Row, Col, Container } from "reactstrap";
import { LandingCatItem, LangingCatLabel } from "../../styles";
import { Images } from "../../config";
import { Link, useHistory, useParams } from "react-router-dom";
import LanguageSwitcherBig from "../containers/LanguageSwitcherBig";
import { applicationsLang } from "../../lang/lang";

function ApplicationsLandingView() {
  let { utm, lang } = useParams();
  const history = useHistory();

  const [loadedLang, setLoadedLang] = useState(false);

  useEffect(() => {
    document.body.classList.add(utm);
    document.body.classList.add("body-application");

    setLoadedLang(false);

    if (!lang) {
      history.push(`/embed/prihlasky/sportjoy/cs`);
    } else {
      applicationsLang.setLanguage(lang);
      setLoadedLang(true);
    }
  }, []);

  function setLang(lang) {
    setLoadedLang(false);
    history.push(`/embed/prihlasky/sportjoy/${lang}`);
  }

  function renderContent() {
    return (
      <>
        <LanguageSwitcherBig
          setLang={setLang}
          marginBottom={70}
          displayHeading={true}
          position="center"
        />

        <Row>
          <Col sm={12} className="mb-5 text-center">
            <h2 style={{ fontWeight: "900" }}>
              {applicationsLang.choose_activity}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Link to={`/embed/signup/camps/${lang}`}>
              <LandingCatItem bg={Images.cat_camps}>
                <LangingCatLabel>
                  <h2>{applicationsLang.camps_application}</h2>
                </LangingCatLabel>
              </LandingCatItem>
            </Link>
          </Col>
          {lang === "cs" && (
            <Col sm={6}>
              <Link to={`/embed/instruktor`}>
                <LandingCatItem bg={Images.pracuj_s_detmi}>
                  <LangingCatLabel>
                    <h2>Pracuj s dětmi</h2>
                  </LangingCatLabel>
                </LandingCatItem>
              </Link>
            </Col>
          )}
        </Row>
      </>
    );
  }

  return (
    <div className="embed" utm={utm}>
      <EmbedNavigation text="Vyberte si kategorii" setLang={setLang} />
      <div className="push_from_nav application">
        <Container>{renderContent()}</Container>
      </div>
    </div>
  );
}

export default ApplicationsLandingView;
