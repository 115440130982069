import React, { Component } from 'react'
import { toggleDateState } from '../../api'
import { Table, Button, Popup, Icon } from 'semantic-ui-react'
import _ from 'loadsh'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import DateItemMobile from './mobile/DateItemMobile'

class DateItems extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            idToDelete: 2,
            show: props.type === 'homepage' ? false : true,
            items: [],
            baseItems: []
        })
    }

    toggleStateHandler = (id) => {
        toggleDateState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    handleShow = () => {
        this.setState({ 
            show: !this.state.show,
        })
    }

    renderPriceCol = (lower_price, full_price) => {
        if(full_price != lower_price) {
            return (
                <><Popup content='Cena pro jednotlivce' position='top center' trigger={<Icon name='user' />} /> {full_price},-&nbsp;Kč<br /><Popup content='Cena pro sourozence' position='top center' trigger={<Icon name='users' />} /> {lower_price},-&nbsp;Kč</>
            )
        } else if(full_price === lower_price && full_price != 0 && lower_price != 0) {
            return (
                <>{full_price},-&nbsp;Kč</>
            )
        } else if(full_price === 0 || lower_price === 0) {
            return (
                <>Zdarma</>
            )
        }
    }

    render() {

        const { items, sort, column, direction, campType, type } = this.props

        return (
            <div className='relative'>
                <div className={this.state.show ? 'collapsable-table show' : 'collapsable-table hidden'}>
                {!isMobile ?
                <Table sortable striped className='embed-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === 'camp_name' ? direction : null}
                                onClick={sort('camp_name')}
                                width='2'
                            >
                                {campType !== 'hobby' ? 'Tábor' : 'Kroužek'}
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_from' ? direction : null}
                                onClick={sort('date_from')}
                                width='1'
                            >
                                Od
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_to' ? direction : null}
                                onClick={sort('date_to')}
                                width='1'
                            >
                                Do
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'full_price' ? direction : null}
                                onClick={sort('full_price')}
                                width='2'
                            >
                                Cena
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'camp_age' ? direction : null}
                                onClick={sort('camp_age')}
                                width='1'
                            >
                                Věk
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                                width='3'
                            >
                                Lokalita
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'date_capacity' ? direction : null}
                                onClick={sort('date_capacity')}
                                width='1'
                            >
                                Volná místa
                            </Table.HeaderCell>
                            <Table.HeaderCell width='3'></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {_.map(items, ({ id_date, lower_price, camp_name, camp_logo, min_age, max_age, camp_age, camp_description, id_camp, date_from, date_to, date_signed, location_name, date_capacity, is_full, location_address, full_price }) => (
                    <Table.Row key={id_date} className={is_full === 1 && 'full_date'}>
                        <Table.Cell>
                            <Popup trigger={<img className='img-fluid' alt={camp_name} src={'https://api.sportjoy.cz/media/upload/camp/' + id_camp + '/' + camp_logo} />}>
                                {camp_description}
                            </Popup>
                        </Table.Cell>
                        <Table.Cell className='date date_from' style={{paddingRight: 0}}>
                            <span className='only-phone display-block'>Od: </span>{moment(date_from).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell className='date date_to' style={{paddingRight: 0}}>
                            <span className='only-phone display-block'>Do: </span>{moment(date_to).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell className='date date_to'>
                            <span className='only-phone display-block'>Cena: </span>{this.renderPriceCol(lower_price, full_price)}
                        </Table.Cell>
                        <Table.Cell className='date date_to'>
                            <span className='only-phone display-block'>Věk: </span>{id_camp !== 14 ? <>{min_age}-{max_age}</> : <>1.-9. třída</>}
                        </Table.Cell>
                        <Table.Cell style={{paddingRight: 0}}>
                            <span className='only-phone display-block'>Adresa: </span><p><strong>{location_name}</strong><br />{location_address}</p>
                        </Table.Cell>
                        <Table.Cell className='capacity'>
                            <span className='only-phone display-block'>Volná místa: </span> {/*(date_capacity - date_signed) <= 0 ? '0/' + date_capacity : (date_capacity - date_signed) + '/' + date_capacity}*/ is_full === 0 ? 'Volné' : 'Obsazené'}
                        </Table.Cell>
                        <Table.Cell className='application_button'>
                            <Button className="btn" href="https://app.sportjoy.cz/embed/signup/camps" target="_blank">{is_full === 0 ? 'Přihláška' : 'Jako náhradník'}</Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>
            :
            <>
                {items.map((item) => {
                    return (
                        <DateItemMobile date={item} />
                    )
                })}
            </>
            }
            {this.props.type === 'homepage' &&
            <div className='table-buttons'>
                <Button onClick={this.handleShow}>{!this.state.show ? 'Ukázat vše' : 'Schovat'}</Button>
            </div>
            }
            </div>
            </div>
        )
    }
}

export default DateItems