import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Button, Icon, Loader, Popup } from "semantic-ui-react";
import { getToken, sendBill } from "../../api";
import { url } from "../../config";
import { BillsEmptyContainer } from "../../styles/bills";
import { jsonToFormData } from "../../tools";
import { useDispatch } from "react-redux";
import { setMessage } from "../../actions/messageActions";
import { deleteBill } from "../../api/invoices";

function BillsListContainer({ id, hasBills, loading, bills, refresh }) {
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState();
  const dispatch = useDispatch();

  /*useEffect(() => {
        getData()
    }, [id])

    function getData() {
        getToken().then((token) => {
            getApplicationBills(id, token)
            .then((res) => {
                if(res.data.type === 'success') {
                    setFiles(res.data.data)
                    setLoading(false)
                    if(res.data.data.length !== 0) {
                        setHasSome(true)
                    } else {
                        setHasSome(false)
                    }
                } else {
                    setLoading(false)
                    setHasSome(false)
                }
            })
            .catch((err) => {
                console.log(err)
                alert('Nepodařilo se získat seznam pokladních dokladů')
            })
        })
    }*/

  function _sendBill(id) {
    setLoadingSend(true);
    getToken()
      .then((token) => {
        sendBill(id, token)
          .then((res) => {
            dispatch(setMessage(res.data));
            setLoadingSend(false);
          })
          .catch((err) => {
            alert(err.message);
            setLoadingSend(false);
          });
      })
      .catch((err) => {
        alert(err.message);
        setLoadingSend(false);
      });
  }

  function _deleteBill(id) {
    setLoadingDelete(id);
    const data = {
      id_bill: id,
    };
    const payload = jsonToFormData(data);

    deleteBill(payload)
      .then((res) => {
        dispatch(setMessage(res.data));
        setLoadingDelete(0);
        refresh();
      })
      .catch((err) => {
        alert(err.message);
        setLoadingDelete(0);
        refresh();
      });
  }

  function renderList() {
    return bills.map((item) => {
      return (
        <Row className="mb-3 align-items-center" key={item.id_bill}>
          <Col sm={7}>
            <span style={{ fontSize: 10, display: "block" }}>
              {moment(item.created).format("DD.MM.YYYY")}
            </span>
            {item.title}.pdf
          </Col>
          <Col sm={5} className="text-right">
            <Popup
              position="top right"
              trigger={
                <Button
                  icon
                  onClick={() => _deleteBill(item.id_bill)}
                  loading={item.id_bill === loadingDelete}
                >
                  <Icon name="trash" />
                </Button>
              }
              content="Smazat doklad"
            />
            <Popup
              position="top right"
              trigger={
                <a href={url.api + "pdf/download/" + item.id_bill}>
                  <Button icon>
                    <Icon name="download" />
                  </Button>
                </a>
              }
              content="Stáhnout doklad"
            />
            <Popup
              position="top right"
              trigger={
                <Button
                  icon
                  onClick={() => _sendBill(item.id_bill)}
                  loading={loadingSend}
                >
                  <Icon name="envelope" />
                </Button>
              }
              content="Odeslat doklad"
            />
          </Col>
        </Row>
      );
    });
  }

  function renderLoading() {
    return (
      <BillsEmptyContainer>
        <Loader active />
      </BillsEmptyContainer>
    );
  }

  function renderEmpty() {
    return (
      <BillsEmptyContainer>Přihláška nemá žádné doklady</BillsEmptyContainer>
    );
  }

  if (loading) {
    return <>{renderLoading()}</>;
  } else if (!loading && !hasBills) {
    return <>{renderEmpty()}</>;
  } else if (!loading && hasBills) {
    return <>{renderList()}</>;
  }
  return <></>;
}

export default BillsListContainer;
