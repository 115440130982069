import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Button, Form, Message } from 'semantic-ui-react'
import { setNewPassword } from '../../../api/employees';
import { jsonToFormData } from '../../../tools';

function EmployeeNewPassword() {

    let history = useHistory();
    let { token } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');

    function onChange(e, val) {
        setData({...data, [e.target.name]: e.target.value})
    }

    function submit() {

        setLoading(true)

        if(data.password !== data.password2) {
            setLoading(false);
            setIsError(true);
            setMessage('Hesla se neshodují');
        }

        let toSend = {
            ...data,
            token: token
        }
        let payload = jsonToFormData(toSend);

        setNewPassword(payload)
        .then((res) => {
            setMessage(res.data.text)
            setLoading(false)
            history.push('/login');
        })

    }

    return (
        <Form loading={loading} onSubmit={submit}>
            {message !== '' &&
            <Message negative={isError}>{message}</Message>
            }
            <Form.Input 
                type="password"
                name="password"
                label="Nové heslo"
                placeholder="Zadejte nové heslo"
                required
                value={data.password}
                onChange={onChange}
            />
            <Form.Input 
                type="password"
                name="password2"
                label="Nové heslo znovu"
                placeholder="Zadejte nové heslo znovu"
                required
                value={data.password2}
                onChange={onChange}
            />
            <Button primary fluid>Nastavit heslo</Button>
        </Form>
    )
}

export default EmployeeNewPassword