import React, { Component } from 'react'
import { getLocationItem, uploadLocationImage, updateLocation, updateLocationSecondary, createLocation, getToken, updateLocationEmails } from '../../api'
import { Card, CardControls, CardBody, CardHeader } from '../../styles'
import { EditLocation, LocationSecondaryForm } from '../forms'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'
import { Button, Tab } from 'semantic-ui-react'
import EmailsForm from '../forms/locations/EmailsForm'
import RichTextEditor from 'react-rte'

class EditLocationView extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            location: {
                location_name: '',
                location_description: '',
                location_address: '',
                location_image: '',
                location_state: 0,
                location_confirm_email: RichTextEditor.createEmptyValue()
            },
            toUpload: [],
            loading: true,
            uploadState: {
				beforeUpload: true,
				isUploading: false,
				uploaded: false
			}
        })
    }

    componentDidMount() {

        this.refresh()

    }

    refresh = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {
            getLocationItem(id)
            .then((data) => {
                this.setState({
                    location: {
                        location_name: data.location_name,
                        location_description: data.location_description,
                        location_address: data.location_address,
                        location_image: data.location_image,
                        location_state: data.location_state,
                        location_confirm_email: data.location_confirm_email !== null ? RichTextEditor.createValueFromString(data.location_confirm_email, 'html') : RichTextEditor.createEmptyValue()
                    }
                })
            })
            .then(() => {
                this.setState({
                    loading: false
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    onChange = (e) => this.setState({
        location: {
            ...this.state.location,
            [e.target.name]: e.target.value
        }
    })

    onChangeSelect = (e, data) => this.setState({
        location: {
            ...this.state.location,
            [data.name]: data.value
        }
    })

    exportEmployees = (type) => {

        const { id } = this.props.match.params

        var url = 'https://api.sportjoy.cz/export/employees' + type + '?id_date=' + id;
        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            window.open(url + '&token=' + token, "_blank")

            this.setState({
                loadingExport: false
            })
        })
    }

    submitEmails = () => {
        const { id } = this.props.match.params

        this.setState({
            loading: true
        })

        if(id !== 'new') {
            getToken().then((token) => {
                const data = {
                    token: token,
                    location_confirm_email: this.state.location.location_confirm_email.toString('html'),
                    id_organization: this.props.user.id_organization
                }
                updateLocationEmails(id, data)
                .then((res) => {
                    this.props.setMessage(res)
                    this.setState({
                        loading: true
                    }, () => {
                        this.refresh()
                    })
                })
                .catch((err) => {
                    this.props.setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později'})
                    this.setState({
                        loading: false
                    })
                })
            })
        }
    }

    submit = () => {
        const { id } = this.props.match.params

        if(id !== 'new') {

            getToken().then((idToken) => {
                this.setState({
                    location: {
                        ...this.state.location,
                        token: idToken,
                        id_organization: this.props.user.id_organization
                    }
                }, () => {
                    updateLocation(id, this.state.location)
                    .then((res) => {
                        this.props.setMessage(res)
                        this.setState({
                            loading: true
                        }, () => {
                            //this.refresh()
                            window.location.reload();
                        })
                    })
                    .catch((err) => {
                        this.props.setMessage({type: 'error', text: 'Něco se pokazilo, zkuste to prosím později'})
                        this.setState({
                            loading: false
                        })
                    })
                })
            })
            
        } else {

            this.setState({
                loading: true,
                location: {
                    ...this.state.location,
                    id_organization: this.props.user.id_organization
                }
            }, () => {
                createLocation(this.state.location)
                .then((res) => {
                    this.setState({
                        loading: false
                    })
                    this.props.setMessage(res)
                })
                .then(() => {
                    this.props.history.push('/manage/locations')
                })
                .catch((err) => {
                    this.props.setMessage(err)
                })
            })

        }
        
        
    }

    submitSecondary = () => {

        const { id } = this.props.match.params

        this.uploadImage(this.state.toUpload)
        updateLocationSecondary(id, this.state.location)
        .then((res) => {
            this.props.setMessage(res)
            this.setState({
                loading: true
            }, () => {
                this.refresh()
            })
        })
    }

    onDrop = (files) => {
        this.setState({
            toUpload: files
        })
    }

    onChangeRte = (value) => {
        this.setState({
            location: {
                ...this.state.location,
                location_confirm_email: value
            }
        });
    }

    uploadImage = (image) => {

        const { id } = this.props.match.params

        this.setState({
			loading: true,
			uploadState: {
				beforeUpload: false,
				isUploading: true,
				uploaded: false
			}
        })
        
        const fd = new FormData();

        image.forEach(file => {
            fd.append('image', file, file.name);
        })
        
        uploadLocationImage(id, fd)
        .then((res) => {
            this.setState({
                loading: false,
                toUpload: []
            }, () => {
                this.refresh()
            })
        })
        .catch((err) => {
            this.props.setMessage(err)
            this.setState({
                loading: false
            })
        })
    }

    renderPanes = () => {

        var panes = []

        panes = [
            {
              menuItem: 'Základní informace',
              render: () => <Tab.Pane attached={false}>{this.renderEditView()}</Tab.Pane>,
            },
            {
              menuItem: 'Emaily',
              render: () => <Tab.Pane attached={false}>{this.renderEmailView()}</Tab.Pane>,
            },
        ]

        return panes
    }

    exportList = (id) => {

        const url = 'https://api.sportjoy.cz/export/export-insurance?id_loc='+id;

        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            window.open(url + '&token=' + token, "_blank")

            this.setState({
                loadingExport: false
            })
        })
        .catch((err) => {
            alert('Nepodařilo se získat bezpečnostní certifikát s chybou:' + err.message +'. Zkuste to prosím znovu.')
            this.setState({
                loadingExport: false
            })
        })
    }

    exportTshirtsHandler = (type, remaining) => {

        const { id } = this.props.match.params

        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            if(type === 'all') {
                window.open('https://api.sportjoy.cz/export/export/?type=camp-age-location&id_location=' + id + '&token=' + token + '&remaining=' + remaining, "_blank")
            } else if(type === 'babyjoy') {
                window.open('https://api.sportjoy.cz/export/export/?type=camp-age-location-babyjoy&id_location=' + id + '&token=' + token + '&remaining=' + remaining, "_blank")
            }
            
            this.setState({
                loadingExport: false
            })
        })
    }

    exportDiplomasHandler = () => {

        const { id } = this.props.match.params

        this.setState({
            loadingExport: true
        })
        getToken().then((token) => {
            window.open('https://api.sportjoy.cz/export/export/?type=diplomas&id_location=' + id + '&token=' + token, "_blank")
            
            this.setState({
                loadingExport: false
            })
        })
    }

    renderEditView = () => {

        const { id } = this.props.match.params

        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardHeader>
                            Základní informace
                        </CardHeader>
                        <CardBody>
                            <EditLocation 
                                location={this.state.location}
                                onChange={this.onChange}
                                loading={this.state.loading}
                                submit={this.submit}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'>
                    <Card>
                        <CardHeader>
                            <div className='row'>
                                <div className='col-sm-12 text-left'>
                                    <h2>Další informace</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <LocationSecondaryForm 
                                location={this.state.location}
                                onChangeSelect={this.onChangeSelect}
                                loading={this.state.loading}
                                submit={this.submitSecondary}
                                onDrop={this.onDrop}
                                fileName={this.state.toUpload.length !== 0 ? this.state.toUpload[0].name : false}
                                isNew={id === 'new' ? true : false}
                            />
                        </CardBody>
                    </Card>
                    <Card className='mt-5'>
                        <CardHeader>
                            <div className='row'>
                                <div className='col-sm-12 text-left'>
                                    <h2>Export</h2>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Button className='mb-2' loading={this.state.loadingExport} fluid onClick={() => this.exportList(id)}>Exportovat děti</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportTshirtsHandler('all', 0)}>Exportovat trička</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportTshirtsHandler('babyjoy', 0)}>Exportovat trička - Babyjoy</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportTshirtsHandler('all', 1)}>Exportovat trička - zbytek</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportTshirtsHandler('babyjoy', 1)}>Exportovat trička - Babyjoy - zbytek</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportDiplomasHandler()}>Exportovat diplomy</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportEmployees('')}>Export - Instruktoři</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportEmployees('-dates')}>Export - Instruktoři - Termíny</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportEmployees('-wages')}>Export - Instruktoři - Mzdy</Button>
                            <Button className='mb-2' fluid onClick={() => this.exportEmployees('-camps')}>Export - Instruktoři - Tábory</Button>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }

    renderEmailView = () => {

        const { id } = this.props.match.params

        return (
            <div className='row'>
                <div className='col-sm-8'>
                    <Card>
                        <CardHeader>
                            Emaily
                        </CardHeader>
                        <CardBody>
                            <EmailsForm 
                                item={this.state.location}
                                onChange={this.onChangeRte}
                                loading={this.state.loading}
                                submit={this.submitEmails}
                                val={this.state.location.location_confirm_email}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className='col-sm-4'></div>
            </div>
        )
    }
    
    render() {

        const { id } = this.props.match.params

        return (
            <Tab menu={{ secondary: true, pointing: true }} panes={this.renderPanes()} />
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user
})
  
export default connect(mapStateToProps, { setMessage })(EditLocationView)