import React, { useState } from 'react'
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from 'reactstrap';
import { getToken } from '../../api';

function ExportByDate() {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    function submit() {

        var start_date = moment(startDate).format('YYYY-MM-DD')
        var end_date = moment(endDate).format('YYYY-MM-DD')

        getToken().then((token) => {
            window.open('https://api.sportjoy.cz/export/export-with-districts/?token=' + token + '&date_from=' + start_date + '&date_to=' + end_date, "_blank")
        })
    }

    return (
        <Modal style={{overflow: 'visible'}} trigger={<Dropdown.Item icon='calendar' text='Městské části' />}>
            <Modal.Header>Export podle data</Modal.Header>
            <Modal.Content>
                <Form>
                    <Row>
                        <Col sm={6}>
                            <Form.Field>
                                <label>Počáteční datum</label>
                                <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                            </Form.Field>
                        </Col>
                        <Col sm={6}>
                            <Form.Field>
                                <label>Konečné datum</label>
                                <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                            </Form.Field>
                        </Col>
                    </Row>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => submit()}>Exportovat</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ExportByDate