import React, { useEffect, useState } from "react";
import { Card, CardHeader } from "../../../styles";
import { getEmailTypes } from "../../../api/emails";
import EmailsListTable from "../../tables/Emails/EmailsListTable";

function EmailsListView() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEmailTypes().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <Card>
      <CardHeader>
        <span>Automatické odpovědi</span>
      </CardHeader>
      <EmailsListTable data={data} loading={loading} />
    </Card>
  );
}

export default EmailsListView;