import React from "react";
import { Form, Button, Dropdown } from "semantic-ui-react";
import { FormButtonGroup } from "../../styles";

const options = [
  { key: "no", value: 0, text: "Nezveřejněno" },
  { key: "yes", value: 1, text: "Zveřejněno" },
];

const LocationSecondaryForm = ({
  location,
  onChangeSelect,
  loading,
  onDrop,
  isNew,
  submit,
}) => {
  const maxSize = 524288;

  return (
    <Form loading={loading} onSubmit={submit}>
      <Form.Field>
        <Dropdown
          name="location_state"
          placeholder="Vyberte možnost"
          options={options}
          value={location.location_state}
          onChange={onChangeSelect}
          selection
          className={"state-active-" + location.location_state}
        />
      </Form.Field>
      <FormButtonGroup>
        <Button primary>Uložit změny</Button>
      </FormButtonGroup>
    </Form>
  );
};

export default LocationSecondaryForm;
