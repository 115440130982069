import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { Button, Icon } from "semantic-ui-react";
import { setMessage } from "../../../actions/messageActions";
import { getCheckboxesApp, updateCheckboxes } from "../../../api/instructors";
import { Card, CardBody, CardHeader } from "../../../styles";
import { jsonToFormData } from "../../../tools";
import DefaultModal from "../../modals/DefaultModal";
import EditNameForm from "./_parts/EditNameForm";
import InterestValue from "./_parts/InterestValue";

function InterestSettingsView() {
  const dispatch = useDispatch();
  const [checkboxValues, setCheckboxValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _getCheckboxesApp();
  }, []);

  function _getCheckboxesApp() {
    getCheckboxesApp().then((res) => {
      setCheckboxValues(res.data);
    });
  }

  const NewItemForm = ({ column }) => {
    return (
      <EditNameForm
        name="name"
        type="checkbox"
        value={""}
        id={0}
        label="Text položky"
        placeholder="Zadejte text položky"
        refresh={_getCheckboxesApp}
        column={column}
        newItem={1}
      />
    );
  };

  const NewColumnForm = () => {
    return (
      <EditNameForm
        name="name"
        type="column"
        value={""}
        id={0}
        label="Název sloupce"
        placeholder="Zadejte název sloupce"
        refresh={_getCheckboxesApp}
        newItem={1}
      />
    );
  };

  const EditColumnForm = ({ value }) => {
    return (
      <EditNameForm
        name="name"
        type="column"
        value={value}
        id={0}
        column={value}
        label="Název sloupce"
        placeholder="Zadejte název sloupce"
        refresh={_getCheckboxesApp}
      />
    );
  };

  function deleteItem(columnName) {
    setLoading(true);
    const payload = {
      id_item: 0,
      name: columnName,
      type: "column",
      state: 0,
      new: 0,
      column: columnName,
    };
    const fd = jsonToFormData(payload);
    updateCheckboxes(fd).then((res) => {
      setLoading(false);
      _getCheckboxesApp();
      dispatch(setMessage(res.data));
    });
  }

  return (
    <Row>
      <Col sm={11}>
        <Row>
          {Object.keys(checkboxValues).map((key, index) => {
            return (
              <Col
                style={{
                  width: 100 / Object.keys(checkboxValues).length + "%",
                }}
                key={index}
              >
                <Card>
                  <CardHeader className="justify-content-between d-flex align-items-center">
                    <span>{key}</span>
                    <div>
                      <DefaultModal
                        trigger={
                          <Button icon>
                            <Icon name="pencil" />
                          </Button>
                        }
                        size={"tiny"}
                        children={<EditColumnForm value={key} />}
                        heading='Úprava sloupce'
                      />
                      <Button
                        icon
                        onClick={() => deleteItem(key)}
                        loading={loading}
                        disabled={loading}
                      >
                        <Icon name="trash" />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {checkboxValues[key].map((item) => {
                      return (
                        <InterestValue
                          name={item.text}
                          id={item.id}
                          refresh={_getCheckboxesApp}
                          column={key}
                          key={item.id}
                        />
                      );
                    })}
                    <Row className="justify-content-center mt-4">
                      <DefaultModal
                        size="tiny"
                        trigger={
                          <Button>
                            <Icon name="plus" /> Přidat další
                          </Button>
                        }
                        children={<NewItemForm column={key} />}
                        heading="Nová položka"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col>
        <DefaultModal
          size="tiny"
          trigger={
            <Button icon>
              <Icon name="plus" />
            </Button>
          }
          children={<NewColumnForm />}
          heading="Nový sloupec"
        />
      </Col>
    </Row>
  );
}

export default InterestSettingsView;
