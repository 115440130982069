import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react'
import { setMessage } from '../../actions/messageActions';
import { deleteApplication } from '../../api/instructors';

function DeleteInstructorsApplication({ displayType, id, refresh }) {

    const dispatch = useDispatch();
    const [ open, setOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    function _delete() {
        setLoading(true);

        deleteApplication(id)
        .then((res) => {
            dispatch(setMessage(res.data));
            setLoading(false);
            refresh();
            setOpen(false);
        })
        .catch((err) => {
            dispatch(setMessage({type: 'error', text: 'Přihlášku se nepodařilo smazat: ' + err.message}));
            setLoading(false);
            refresh();
            setOpen(false);
        })
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} trigger={displayType === 'icon' ? <Button loading={loading} icon onClick={() => setOpen(true)}><Icon name='trash' /></Button> : <Button fluid color='red' loading={loading} onClick={() => setOpen(true)}>Smazat přihlášku</Button>}>
            <Modal.Header>
                Opravdu chcete přihlášku smazat?
            </Modal.Header>
            <Modal.Content>
                Tato akce již nelze vrátit zpět.
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Zavřít</Button>
                <Button color='red' loading={loading} onClick={_delete}>Smazat přihlášku</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeleteInstructorsApplication