import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col } from "reactstrap";
import { Dropdown, Icon, Modal } from "semantic-ui-react";
import { setMessage } from "../../../actions/messageActions";
import { changeKidsDate } from "../../../api";
import { getDatesToChange } from "../../../api/parents";
import { DateItem, DateItemLabel } from "../../../styles";
import { jsonToFormData } from "../../../tools";
import { StyledButton } from "./styled";

const ChangeDate = ({ id_kids, id_date, type, refresh }) => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    _getDates();
  }, []);

  function _getDates() {
    setLoading(true);
    const payload = {
      id_kids: id_kids,
      id_date: id_date,
      type: type,
    };

    const fd = jsonToFormData(payload);

    getDatesToChange(fd).then((res) => {
      setDates(res.data);
      setLoading(false);
    });
  }

  function changeDate(new_date) {
    setLoadingBtn(new_date);
    const payload = {
      kid: id_kids,
      old: id_date,
      new: new_date,
    };
    changeKidsDate(payload)
      .then((res) => {
        setLoadingBtn(0);
        setOpen(false);
        dispatch(setMessage(res));
        _getDates();
        refresh();
      })
      .catch((err) => {
        console.log(err);
        setLoadingBtn(0);
        setOpen(false);
        dispatch(
          setMessage({ text: "Nepodařilo se změnit termín", type: "error" })
        );
        _getDates();
        refresh();
      });
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      trigger={
        <Dropdown.Item text="Změnit termín" onClick={() => setOpen(true)} />
      }
    >
      <Modal.Header>
        <span>Změna termínu</span>
      </Modal.Header>
      <Modal.Content>
        {!loading &&
          dates.map((date) => {
            return (
              <DateItem
                key={date.id_date}
                className={
                  date.date_capacity - date.date_signed <= 0
                    ? "row full_date"
                    : "row"
                }
                camp={type}
              >
                <Col sm={2} className="camp_name_uppercase p-0">
                  <img
                    className="img-fluid"
                    alt={date.camp_name}
                    src={
                      "https://api.sportjoy.cz/media/upload/camp/" +
                      date.id_camp +
                      "/" +
                      date.camp_logo
                    }
                  />
                </Col>
                <Col
                  sm={5}
                  className="pl-sm-5 col-sm-3 mb-3 mb-sm-0 mt-3 mt-sm-0"
                >
                  <span className="bold d-block">{date.camp_name}</span>
                  <span className="bold">{date.location_address}</span>
                </Col>
                <Col sm={2} className="mb-3 mb-sm-0">
                  <DateItemLabel>Datum</DateItemLabel>
                  <span className="bold">
                    {moment(date.date_from).format("D.")}-
                    {moment(date.date_to).format("D. M. YYYY")}
                  </span>
                </Col>
                <Col sm={1} className="mb-3 mb-sm-0">
                  <DateItemLabel>Obsazenost</DateItemLabel>
                  <span className="bold">
                    {date.is_full === 0 ? "Volné" : "Obsazené"}
                  </span>
                </Col>
                <Col
                  sm={2}
                  className="d-sm-flex justify-content-end pl-0 pr-0 pr-sm-4"
                >
                  <StyledButton
                    icon
                    onClick={() => changeDate(date.id_date)}
                    loading={loadingBtn === date.id_date}
                  >
                    <Icon name="check" />
                  </StyledButton>
                </Col>
              </DateItem>
            );
          })}
      </Modal.Content>
    </Modal>
  );
};

export default ChangeDate;
