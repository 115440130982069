import { Col, Row } from "reactstrap";
import { Menu, Tab, Button as SUIbutton } from "semantic-ui-react";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoginFormWrapper = styled.div`
  display: block;
  width: 33.333%;
  position: relative;
  padding: 3rem;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  text-align: center;
  background: #fff;

  label {
    text-align: left;
  }

  @media (max-width: 567.5px) {
    width: 90%;
  }

  h1 {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 30px;
  }
`;

export const LoginLogo = styled.img`
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 3rem;
`;

export const Wrapper = styled.div`
  ${(props) =>
    !props.isMobile
      ? "width: 80%; margin-left: 20%; padding: 3rem; padding-top: 0;"
      : "width: 100%; margin-left: 0; padding: 1rem; padding-top: 5rem;"}
`;

export const Sidebar = styled.div`
  width: 20%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 0 1rem 0rem 1rem;
  z-index: 0;
  overflow-y: hidden;

  :hover {
    overflow: auto;
    padding-right: calc(1rem - 6px);
  }

  ::-webkit-scrollbar-track {
    background-color: #f6f6f6;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: #f6f6f6;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #525f7f;
  }
`;

export const SidebarNav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const SidebarItem = styled.li`
  margin-bottom: 1rem;

  a,
  span.divider {
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 1.2rem;
    border-radius: 0.3rem;
    font-weight: 700;
  }

  a:hover,
  a.active,
  span.divider:hover {
    color: rgba(0, 0, 0, 0.9);
    background: #f6f9fc;
  }

  span.divider:hover {
    cursor: pointer;
  }

  > a:before,
  > span.divider:before {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    content: "${(props) => props.icon}";
    color: ${(props) => props.iconColor};
    font-size: 1.3rem;
    margin-right: 10px;
  }

  > span.divider:after {
    transition: all 0.3s ease-out;
  }

  > span.divider.inactive:after {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    content: "\\ea99";
    color: ${(props) => props.iconColor};
    font-size: 1.3rem;
    margin-right: 10px;
    position: absolute;
    right: 1.2rem;
  }

  > span.divider.active:after {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    content: "\\eaa1";
    color: ${(props) => props.iconColor};
    font-size: 1.3rem;
    margin-right: 10px;
    position: absolute;
    right: 1.2rem;
  }

  .dropdown {
    list-style: none;
    margin-left: calc(1.3rem + 10px);
    padding: 0;
  }

  .dropdown.inactive > li {
    display: none;
  }

  .dropdown > li > a {
    font-size: 0.8rem;
  }
`;

export const SidebarDivider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;

export const SidebarHeader = styled.div`
  width: 100%;
  padding: 3rem 1rem;
`;

export const Card = styled.div`
  width: 100%;
  display: block;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  background: #fff;

  border-radius: calc(0.375rem - 1px);

  @media (max-width: 567.5px) {
    margin-bottom: 30px;
  }

  .ui.table.main-table {
    margin-top: 0 !important;
  }
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid #e9ecef;
  width: 100%;
  background: #fff;
  padding: 1.5rem;
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  font-weight: 700;

  h2 {
    font-size: 1.3rem;
  }

  @media (max-width: 567.5px) {
    h2 {
      font-size: 14px;
      font-weight: 900;
    }

    font-size: 14px;
    font-weight: 900;
  }

  .col-sm-9,
  .col-sm-6 {
    display: flex;
    align-items: center;
  }

  .col-sm-6.text-right {
    justify-content: flex-end;
  }

  .col-sm-3.text-right {
    justify-content: flex-end;
  }

  .ui.search.dropdown > .text {
    white-space: nowrap;
  }

  .card_header_box > .row {
    width: calc(100% - 3rem);
  }
`;

export const CardHeaderSeparator = styled.div`
  border-right: 1px solid #e9ecef;
  padding-left: 2rem;
  padding-right: 2rem;

  h2 {
    font-size: 1rem;
  }
`;

export const CardHeaderSeparatorLabel = styled.span`
  font-size: 80%;
`;

export const CardControls = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  @media (max-width: 567.5px) {
    .ui.button,
    .ui.buttons .button,
    .ui.buttons .or {
      font-size: 0.7rem;
    }
  }
`;

export const CardBody = styled.div`
  width: 100%;
  display: block;
  padding: 1.5rem;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 !important;

  tr td {
    padding: 1rem 1.5rem;
  }
`;

export const TableHead = styled.thead`
  tr th {
    background: #f6f9fc;
    text-align: left;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #8898aa;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #e9ecef;
  }
`;

export const Button = styled.a`
  display: inline-block;
  align-items: center;
  font-weight: 700;
  color: #fff !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #2dce89;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: #2dce89;
  ${(props) =>
    props.danger && "background: #f5365c; border: 1px solid #f5365c;"}
  ${(props) =>
    props.outline &&
    "background: transparent; border: 1px solid #ccc; color: #ccc !important;"}

    :hover {
    color: #fff;
    background: #29a570;
    cursor: pointer;
  }

  ${(props) =>
    props.danger && ":hover {color: #fff !important; background: #C62243;}"}
  ${(props) =>
    props.outline && ":hover {color: #fff !important; background: #ccc;}"}

    ${(props) => props.outline && ":hover::before {color: #fff !important;}"}

    ::before {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    content: "${(props) => props.icon}";
    color: #fff;
    ${(props) => props.outline && "color: #ccc;"}
    font-size: 1rem;
    margin-right: 10px;
  }
`;

export const ButtonGroup = styled.div`
  a {
    margin-left: 3px;
    margin-right: 3px;
  }
`;

export const FormButtonGroup = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 1rem;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;

  ${(props) => (props.visible ? "display: flex;" : "display: none;")}
`;

export const ModalContent = styled.div`
  display: block;
  background: #fff;
  width: 33.333%;
  border-radius: calc(0.375rem - 1px);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid #e9ecef;
  width: 100%;
  background: #fff;
  padding: 1.5rem;
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
`;

export const ModalBody = styled.div`
  width: 100%;
  background: #fff;
  padding: 1.5rem;
`;

export const ModalActions = styled.div`
  border-top: 1px solid #e9ecef;
  width: 100%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
  text-align: center;
`;

export const InnerImage = styled.img`
  max-width: 100%;
  border-radius: calc(0.375rem - 1px);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
`;

export const Toasts = styled.div`
  width: 300px;
  position: fixed;
  top: 2rem;
  right: 3rem;
`;

export const Toast = styled.div`
  width: 100%;
  ${(props) => props.type === "success" && "background: #2dce89;"}
  ${(props) => props.type === "error" && "background: #f5365c;"}
    margin-bottom: .5rem;
  padding: 1rem;
  border-radius: calc(0.375rem - 1px);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.55);
  transition: all 0.3s ease-out;

  p {
    color: #fff;
    font-weight: 700;
    text-align: left;
    margin: 0;
  }
`;

export const NewVersion = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  right: 30px;
  text-align: center;
  ${(props) => props.type === "success" && "background: #2dce89;"}
  ${(props) => props.type === "error" && "background: #f5365c;"}
    margin-bottom: .5rem;
  padding: 1rem;
  border-radius: calc(0.375rem - 1px);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.55);
  transition: all 0.3s ease-out;

  p {
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
`;

export const Navbar = styled.div`
  @media (min-width: 768px) {
    width: 80%;
    margin-left: 20%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 5.5rem;
  }

  padding-right: 3rem;
  background: transparent;
`;

export const StepCard = styled.div`
  width: 100%;
  ${(props) => !props.active && "display: none;"}
`;

export const DateItem = styled.div`
  width: 100%;
  /*padding: 1.5rem;*/
  border: 1px solid #efefef;
  display: flex;
  align-items: center;
  margin: 0 0 10px !important;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-out;

  .ui.button {
    background: ${(props) =>
      props.camp === "sportjoy" ? "#2F71B8" : "#2F71B8"};
    border: 1px solid
      ${(props) => (props.camp === "sportjoy" ? "#2F71B8" : "#2F71B8")};
    text-transform: uppercase;
    color: #ffffff;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    border: 0px solid transparent;
    font-size: 12px;
    line-height: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;

    @media (max-width: 567px) {
      width: 100%;
      margin: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0px;
    }
  }

  .ui.button.full {
    background: #9f3a38;
  }

  :hover {
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.2);
  }

  span.bold {
    font-weight: 900;
    font-size: 0.9rem;
  }

  font-weight: 700;
`;

export const DateItemLabel = styled.span`
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
`;

export const DateItemAdmin = styled.div`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #efefef;
  display: flex;
  align-items: center;
  margin: 0 !important;
  font-weight: 700;
  transition: all 0.3s ease-out;

  ${(props) =>
    props.state === -1 &&
    'opacity: 0.5; ::before { content: ""; width: 95%; height: 3px; background: #000; z-index: 999; position: absolute; } :hover { opacity: 1; } :hover::before { display:none; } '}
`;

export const DateHead = styled.div`
  width: 100%;
  background: #f6f6f6;
  color: #000;
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  margin: 0 !important;
`;

export const DateSelected = styled.div`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #efefef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-height: 80px;
  margin-bottom: 20px;
  position: relative;
  animation: fadeIn 0.3s ease-out forwards;
  opacity: 0;
  font-weight: 700;

  p {
    text-align: center;
    margin: 0 !important;
    width: 100%;
    font-weight: 700;
  }

  .row {
    width: 100%;
    margin: 0 !important;
    display: flex;
    align-items: center;
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${(props) =>
    props.loading === "true" &&
    ':before { animation: spin 3s linear infinite; position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; justify-content: center; align-items: center; font-family: IcoFont !important; speak: none; font-style: normal; font-weight: 400; font-feature-settings: normal; font-variant: normal; text-transform: none; white-space: nowrap; word-wrap: normal; direction: ltr; line-height: 1; -webkit-font-feature-settings: "liga"; -webkit-font-smoothing: antialiased; content: "\\eff4"; color: #FF9630; font-size: 1.8rem; }'}
  ${(props) =>
    props.full &&
    "background: #f4edef; margin-bottom: -15px; border-color: #f5365c;"}
    ${(props) => !props.full && "background: #f6f6f6;"}
`;

export const EmbedMessage = styled.div`
  width: 95%;
  margin: 0 auto;
  ${(props) => props.type === "success" && "background: #2dce89;"}
  ${(props) => props.type === "error" && "background: #f5365c;"}
    margin-bottom: 20px;
  padding: 1rem;
  border-radius: calc(0.375rem - 1px);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.55);
  transition: all 0.3s ease-out;
  position: relative;
  z-index: 2;

  p {
    color: #fff;
    font-weight: 700;
    text-align: left;
    margin: 0;
  }
`;

export const TermModal = styled.div`
  position: fixed;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 9999;
  background: #fff;
  overflow-y: auto;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  ${(props) => (props.show ? "display: block;" : "display: none;")}
`;

export const FormSection = styled.div`
  background: #fff;
  margin-bottom: 15px;
  border: 1px solid #e9ecef;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${(props) =>
    props.sticky && "position: sticky; position: -webkit-sticky; top: 180px;"}
`;
export const FormSectionBody = styled.div`
  padding: 1.5rem;
`;

export const FormSectionHead = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  position: relative;
`;

export const ModalHead = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
`;

export const StuckContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 12px 0 12px;
  background: white;
  z-index: 999;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.01);

  .logo a {
    display: inline-block;
    line-height: 1;
    margin: 0;
  }

  .logo-image img.logo-img {
    max-width: 120px;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .col-sm-10 {
    text-align: right;
  }

  h1 {
    text-transform: uppercase;
    font-family: "Montserrat", "sans-serif";
    font-size: 18px;
  }

  @media (max-width: 767px) {
    .col-sm-10 {
      text-align: center;
    }
    .col-sm-2 {
      text-align: center;
    }
  }
`;

export const UserId = styled.div`
  width: 200px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #efefef;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const StatsCardContainer = styled.div`
  background: #fff;
  ${(props) =>
    props.type === "total-applications" &&
    " background: linear-gradient(87deg,#2dce89,#2dcecc);"}
  ${(props) =>
    props.type === "total-kids" &&
    " background: linear-gradient(87deg,#5e72e4,#825ee4);"}
    ${(props) =>
    props.type === "today-applications" &&
    "background: linear-gradient(87deg,#f5365c,#f56036);"}
    ${(props) =>
    props.type === "today-kids" &&
    "background: linear-gradient(87deg,#fb6340,#fbb140);"}
    ${(props) =>
    props.type === "paid-unpaid" &&
    " background: linear-gradient(87deg,#172b4d,#1a174d);"}
    ${(props) =>
    props.type === "number-paid" &&
    " background: linear-gradient(87deg,#172b4d,#1a174d);"}
    border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  margin-bottom: 30px;

  .row {
    display: flex;
    align-items: center;
  }

  .stats-icon-container {
    text-align: right;
  }

  .stats-icon-container .icon {
    font-size: 3rem;
    height: auto;
    margin: 0;
    padding: 0;
    color: #fff;
  }
`;

export const StatsValue = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;

  .small {
    font-size: 60%;
    font-weight: 700;
  }
`;

export const StatsLabel = styled.p`
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0;
  color: #fff;
  opacity: 0.9;
`;

export const EmailItem = styled.div`
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid #e9ecef;
`;

export const MultipleActions = styled.div`
  position: fixed;
  bottom: 0;
  left: calc(20% + 3.5rem);
  right: 3.5rem;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  animation: move-up 0.3s ease-out forwards;

  .row {
    display: flex;
    align-items: center;
  }

  @keyframes move-up {
    0% {
      transform: translateY(70px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const VoucherType = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #ececec;
  transition: all 0.3s ease-out;

  :hover {
    cursor: pointer;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
    border-color: #ff9630;
  }

  ${(props) =>
    props.selected &&
    "background: #FF9630; color: #fff; .voucher_price_container { background: rgba(0,0,0,0.1); color: #fff; border-color: rgba(0,0,0,0.1); }"}
`;

export const FormSummary = styled.div`
  width: 100%;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  margin: 20px 0;
  padding: 20px 0;
  p {
    font-weight: 700;
  }
`;

export const FormConfirm = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  div {
    text-align: center;
  }

  div h2,
  div p {
    margin-bottom: 40px;
  }
`;

export const LandingCatItem = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 4px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  background-size: cover;
  background-position: bottom center;
  min-height: 350px;
  position: relative;

  ${(props) => "background-image: url(" + props.bg + ");"}
`;

export const LangingCatLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4rem 2rem 2rem 2rem;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  h2 {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 900 !important;
  }

  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const EmailPane = styled(Tab.Pane)`
  background-color: #fff !important;
  padding: 1.5rem !important;
  border: 0 !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border-radius: calc(0.375rem - 1px) !important;
`;

export const EmailPaneItem = styled(Menu.Item)`
  margin-bottom: 5px !important;
  ${(props) =>
    props.new
      ? "background: var(--success) !important;"
      : "background: #fff !important;"}
  ${(props) =>
    props.new ? "color: #fff !important;" : "color: inherit !important;"}
    border-radius: .28571429rem !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border: 0 !important;
  transition: all 0.3s ease-out !important;

  ::before {
    display: none !important;
  }

  &.active {
    box-shadow: none !important;
    ${(props) => !props.new && "background: #f6f9fc !important;"}
  }

  :hover {
    box-shadow: none !important;
    ${(props) => !props.new && "background: #f6f9fc !important;"}
  }
`;

export const CustomDropzone = styled.div`
  width: 100%;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f9fc;
  transition: all 0.3s ease-out;

  :hover {
    cursor: pointer;
    background: var(--success);

    p {
      color: #fff;
    }
  }

  p {
    text-align: center;
  }

  p:not(.small) {
    font-weight: 700;
  }
`;

export const EmailAttchItem = styled.div`
  margin-bottom: 0.5rem;
  display: inline-flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: #f6f9fc;
  padding: 10px;
  border: 1px solid #f7f7f7;
  margin-right: 10px;

  .ui.button {
    background: transparent;
  }
`;

export const EmailAttchName = styled.div`
  padding-left: 10px;

  span {
    font-weight: 700;
  }
`;

export const AppDotBtn = styled(SUIbutton)`
  padding: 0 !important;
  background: transparent !important;
  border: 0 !important;

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  & > .icon.spinning {
    animation: spin 3s linear infinite;
  }
`;

export const StateIcon = styled.i`
  font-family: IcoFont !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  font-size: 1.7rem;
  transition: all 0.15s ease-out;

  ${(props) =>
    props.state === 1 && '::before { content: "\\eed7"; } color: #2dce89; '}
  ${(props) =>
    props.state === 0 && '::before { content: "\\eedd"; } color: #f5365c; '}
    ${(props) =>
    props.state === 2 && '::before { content: "\\ef1b"; } color: #FA5B2B; '}
    ${(props) =>
    props.state === -1 && '::before { content: "\\efca"; } color: #ffd600;'}

    ${(props) => props.toggle && ":hover { cursor: pointer; }"}
`;

export const LanguageSwitcherBlock = styled.div`
  margin-bottom: ${(props) => props.marginBottom}px;

  h2 {
    font-weight: 900 !important;
  }
`;

export const EmptyDatesList = styled.div`
  width: 100%;
  background: #f6f6f6;
  border-radius: 4px;
  border: 1px solid #ececec;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 700;
  }
`;

export const SelectedDateWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .name {
    font-weight: 700;
  }
`;

export const ProfileMessage = styled.div`
  width: 100%;
  border-radius: calc(0.375rem - 1px);
  background: #f5365c;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 30px;
  margin-bottom: 3rem !important;

  .message {
    font-weight: 700;
    color: #fff;
    font-size: 16px;
  }

  .ui.fluid.button {
    background: #fff;
    color: #000;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ui.fluid.button:hover {
    background: #000;
    color: #fff;
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  background: #f6f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  position: relative;

  span.message {
    font-weight: 700;
    color: #8898aa;
    display: block;
    text-align: center;
  }

  & > div {
    text-align: center;
  }

  .ui.button {
    margin-top: 20px;
  }
`;

export const AttendanceItem = styled.div`
  border-bottom: 1px solid #ececec;
  margin-bottom: 1rem;
`;
