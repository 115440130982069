import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Button, Dropdown } from 'semantic-ui-react';
import { createCoupon, getCampsFilter, getCampsFilterAdmin, getToken } from '../../../api';
import { jsonToFormData } from '../../../tools';

function CouponForm({ hideModal, setMessage, refresh }) {

    const [data, setData] = useState({
        coupon_text: '',
        coupon_value: '',
        available_for: {
            camps: false,
            hobby: false,
            kurzy: false,
            skolka: false
        },
        available_for_camps: []
    });

    const [ loading, setLoading ] = useState(false);
    const [ camps, setCamps ] = useState(false);
    const [ loadingCamps, setLoadingCamps ] = useState(true);

    useEffect(() => {
        getCamps()
    }, []);

    function getCamps() {
        getCampsFilter(0, 1)
        .then((res) => {
            setCamps(res.data);
            console.log(res.data);
            setLoadingCamps(false)
        })
    }

    function onChangeCheckbox(e, val, group) {
        setData({
            ...data,
            [group]: {
                ...data[group],
                [val.name]: val.checked
            } 
        })
    }

    function onChange(e) {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
       
    }

    function onChangeSelect(e, val) {
        setData({
            ...data,
            [val.name]: val.value
        })
    }

    function stringToBoolean(string) {
        switch(string){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    }

    function submit() {

        setLoading(true)

        getToken().then((token) => {
            const toSend = {
                ...data,
                token: token
            }

            const fd = jsonToFormData(toSend);

            createCoupon(fd)
            .then((res) => {
                setLoading(false)
                setMessage(res.data)
                refresh()
                hideModal()
            })
            .catch((err) => {
                alert(err.message);
                setLoading(false)
            })

        })
    }

    return (
        <Form onSubmit={submit} loading={loading}>
            <Form.Input 
                type='text'
                name='coupon_text'
                value={data.coupon_text}
                onChange={onChange}
                placeholder='Zadejte kód kuponu'
                label='Kód kuponu'
            />
            <Form.Input 
                type='number'
                name='coupon_value'
                value={data.coupon_value}
                onChange={onChange}
                placeholder='Zadejte hodnotu kuponu'
                label='Hodnota kuponu'
            />
            <Form.Field>
                <label>Platené pro</label>
                <Checkbox 
                    label='Tábory' 
                    name='camps' 
                    checked={stringToBoolean(data.available_for.camps)} 
                    onChange={(e, val) => onChangeCheckbox(e, val, 'available_for')} 
                />
                <Checkbox 
                    label='Kroužky' 
                    name='hobby' 
                    checked={stringToBoolean(data.available_for.hobby)} 
                    onChange={(e, val) => onChangeCheckbox(e, val, 'available_for')} 
                />
                <Checkbox 
                    label='Kurzy' 
                    name='kurzy' 
                    checked={stringToBoolean(data.available_for.kurzy)} 
                    onChange={(e, val) => onChangeCheckbox(e, val, 'available_for')} 
                />
                <Checkbox 
                    label='Školka' 
                    name='skolka' 
                    checked={stringToBoolean(data.available_for.skolka)} 
                    onChange={(e, val) => onChangeCheckbox(e, val, 'available_for')} 
                />
            </Form.Field>
            <Form.Field>
                {!loadingCamps &&
                <Dropdown
                    name='available_for_camps'
                    placeholder='Vyberte aktivity'
                    fluid
                    selection
                    options={camps}
                    multiple
                    value={data.available_for_camps}
                    onChange={onChangeSelect}
                />
                }
            </Form.Field>
            
            <Button primary disabled={loading}>Vytvořit kupon</Button>
        </Form>
    )
}

export default CouponForm