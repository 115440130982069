import React, { Component } from "react";
import moment from "moment";
import { Form, Checkbox, Button, Popup, Icon } from "semantic-ui-react";
import {
  FormButtonGroup,
  FormSection,
  FormSectionHead,
  EmbedMessage,
  FormSectionBody,
  DateSelected,
} from "../../../styles";
import { getCoupon, getRecommend, validateReferral } from "../../../api";
import { applicationsLang as lang } from "../../../lang/lang";
import { jsonToFormData } from "../../../tools";
import { connect } from "react-redux";

class ConfirmForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfTerms: 0,
      total_price: 0,
      isFree: false,
      data: {
        referral: "",
        parent_tos: false,
        parent_gdpr: false,
        parent_photos: false,
        parent_newsletter: false,
        coupon: false,
        coupon_value: 0,
        id_coupon: null,
        id_voucher: null,
        recommend: "",
        id_recommended: 0,
        application_note: "",
        additional: 0,
        numberOfSubs: 0,
        is_voucher: false,
        coupon_submitted: false,
        invoice: false,
        reg_num: "",
        credits: null,
      },
      all_dates: [],
      coupon_code: "",
      referralCode: false,
    };
  }

  componentDidMount() {
    const { data, isHobby } = this.props;
    this.setNumOfTerms();
    this.setAllDates();

    validateReferral().then((res) => {
      this.setState(
        {
          referralCode: res.referral,
        },
        () => {
          this.countPrice(
            this.state.numberOfTerms,
            data.kids.length,
            this.state.data.additional,
            isHobby,
            data.kids
          );
        }
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const { data, isHobby } = this.props;
      this.setNumOfTerms();
      this.setAllDates();
      validateReferral().then((res) => {
        this.setState(
          {
            referralCode: res.referral,
          },
          () => {
            this.countPrice(
              this.state.numberOfTerms,
              data.kids.length,
              this.state.data.additional,
              isHobby,
              data.kids
            );
          }
        );
      });
      this.resetCoupon();
    }
  }

  resetCoupon = () => {
    const { data, isHobby } = this.props;

    this.setState(
      {
        data: {
          ...this.state.data,
          coupon: true,
          is_voucher: false,
          id_voucher: null,
          coupon_value: 0,
          id_coupon: null,
          coupon_submitted: false,
        },
        coupon_code: "",
      },
      () => {
        this.countPrice(
          this.state.numberOfTerms,
          data.kids.length,
          this.state.data.additional,
          isHobby,
          data.kids
        );
      }
    );
  };

  setAllDates = async () => {
    this.setState(
      {
        all_dates: [],
      },
      () => {
        this.props.data.kids.map((item) => {
          item.kids_camps.map((item) => {
            let arr = this.state.all_dates;
            arr.push(item);

            this.setState(
              {
                all_dates: arr,
              },
              () => {
                console.log("state", this.state.all_dates);
              }
            );
          });
        });
      }
    );
  };

  submitHandler = () => {
    this.props.setConfirm(
      this.state.data,
      this.state.total_price,
      this.state.isFree
    );
  };

  parentsBlock = () => {
    const { data } = this.props;
    return data.parents.map((item, index) => {
      return (
        <div className="col-sm-6" key={index}>
          <p className="mb-1">{lang.name}</p>
          <p>
            <strong>
              {item.parent_name} {item.parent_lastname}
            </strong>
          </p>
          <p className="mb-1">Email</p>
          <p>
            <strong>{item.parent_email}</strong>
          </p>
          <p className="mb-1">{lang.phone}</p>
          <p>
            <strong>{item.parent_phone}</strong>
          </p>
        </div>
      );
    });
  };

  kidsBlock = () => {
    const { data } = this.props;
    return data.kids.map((item, index) => {
      return (
        <div className="col-sm-6" key={index}>
          <p className="mb-1">{lang.name}</p>
          <p>
            <strong>
              {item.kids_name} {item.kids_lastname}
            </strong>
          </p>
          <p className="mb-1">{lang.birthday_2}</p>
          <p>
            <strong>{moment(item.kids_birthday).format("D. M. YYYY")}</strong>
          </p>
          <p className="mb-1">{lang.health_restrictions}</p>
          <p>
            <strong>
              {item.kids_health_issues === ""
                ? "Nemá"
                : item.kids_health_issues}
            </strong>
          </p>
          <p className="mb-1">{lang.desire_group}</p>
          <p>
            <strong>{item.kids_pairing}</strong>
          </p>
          {this.renderTerms(item.kids_camps)}
        </div>
      );
    });
  };

  setNumOfTerms = () => {
    const { data } = this.props;
    let num = 0;
    let subs = 0;
    data.kids.map((item) => {
      num = num + item.kids_camps.length;

      item.kids_camps.map((item) => {
        num = num - item.is_full;
        subs = subs + item.is_full;
      });
    });

    this.setState({
      numberOfTerms: num,
      numberOfSubs: subs,
    });
  };

  setNumOfSubs = () => {
    const { data } = this.props;

    let num = 0;

    data.kids.map((item) => {
      item.kids_camps.map((item) => {
        num = num + item.is_full;
      });
    });

    this.setState({
      numberOfSubs: num,
    });
  };

  onChangeReferral = (e) => {
    const { data, isHobby } = this.props;
    this.setState(
      {
        data: {
          ...this.state.data,
          referral: e.target.value,
        },
      },
      () => {
        validateReferral(this.state.data.referral).then((res) => {
          this.setState(
            {
              referralCode: res.referral,
            },
            () => {
              this.countPrice(
                this.state.numberOfTerms,
                data.kids.length,
                this.state.data.additional,
                isHobby,
                data.kids
              );
            }
          );
        });
      }
    );
  };

  onChangeCoupon = (e) => {
    const { data, isHobby } = this.props;

    this.setState(
      {
        coupon_code: e.target.value,
      },
      () => {
        if (this.state.coupon_code !== "") {
          let toSend = {
            coupon: this.state.coupon_code,
            type: isHobby,
            dates: this.state.all_dates,
          };

          const fd = jsonToFormData(toSend);

          getCoupon(fd).then((res) => {
            if (res.data.isCoupon) {
              this.setState(
                {
                  data: {
                    ...this.state.data,
                    coupon: true,
                    is_voucher: res.data.is_voucher,
                    id_voucher: res.data.is_voucher
                      ? res.data.id_voucher
                      : null,
                    coupon_value: res.data.coupon_value,
                    id_coupon: res.data.id_coupon,
                    coupon_submitted: true,
                  },
                },
                () => {
                  this.countPrice(
                    this.state.numberOfTerms,
                    data.kids.length,
                    this.state.data.additional,
                    isHobby,
                    data.kids
                  );
                }
              );
            } else {
              this.setState(
                {
                  data: {
                    ...this.state.data,
                    coupon: false,
                    coupon_value: "",
                    id_coupon: null,
                    id_voucher: null,
                    is_voucher: false,
                    coupon_submitted: true,
                  },
                },
                () => {
                  this.countPrice(
                    this.state.numberOfTerms,
                    data.kids.length,
                    this.state.data.additional,
                    isHobby,
                    data.kids
                  );
                }
              );
            }
          });
        } else {
          this.setState(
            {
              data: {
                ...this.state.data,
                coupon: false,
                coupon_value: "",
                id_coupon: null,
                id_voucher: null,
                is_voucher: false,
                coupon_submitted: false,
              },
            },
            () => {
              this.countPrice(
                this.state.numberOfTerms,
                data.kids.length,
                this.state.data.additional,
                isHobby,
                data.kids
              );
            }
          );
        }
      }
    );
  };

  onChangeRecommendId = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        id_recommended: Number(e.target.value),
      },
    });

    getRecommend(e.target.value).then((res) => {
      if (res.exists) {
        this.setState({
          data: {
            ...this.state.data,
            additional: 0,
          },
        });
      } else {
        this.setState({
          data: {
            ...this.state.data,
            additional: 0,
            id_recommended: 0,
          },
        });
      }
    });
  };

  onChangeRecommend = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        recommend: e.target.value,
      },
    });
  };

  onChangeCredits = (e) => {
    console.log(
      this.state.data.credits,
      e.target.value,
      this.props.user.credits
    );
    this.setState(
      {
        data: {
          ...this.state.data,
          credits:
            e.target.value <= this.props.user.credits
              ? e.target.value
              : this.props.user.credits,
        },
      },
      () => {
        this.countPrice(
          this.state.numberOfTerms,
          this.props.data.kids.length,
          this.state.data.additional,
          this.props.isHobby,
          this.props.data.kids
        );
      }
    );
  };

  checkIfSibling(kids) {
    var price_sibling = [];
    var prevIndex = 0;

    kids.map((item, index) => {
      item.kids_camps.map((camp, index_camp) => {
        prevIndex = index === prevIndex ? prevIndex : index;
        if (camp.full_price !== 0 && camp.lower_price !== 0) {
          if (prevIndex === index) {
            price_sibling[index] = true;
            price_sibling = price_sibling.filter(Boolean);
          }
        }
      });
    });

    if (kids.length === 2) {
      if (price_sibling.length === kids.length) {
        return true;
      } else {
        return false;
      }
    } else if (kids.length > 2) {
      if (price_sibling.length >= 2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  countPrice = (dates, num_of_kids, add, isHobby, kids) => {
    if (isHobby !== "hobby") {
      var price = 0;
      var free = false;

      kids.map((item, index) => {
        item.kids_camps.map((camp, index_camp) => {
          if (camp.full_price === 0 && camp.lower_price === 0) {
            free = true;
          }

          if (camp.is_full === 0) {
            if (num_of_kids === 1) {
              price = price + camp.full_price;
            } else if (num_of_kids > 1) {
              if (this.checkIfSibling(kids)) {
                price = price + camp.lower_price;
              } else {
                price = price + camp.full_price;
              }
            }
          } else {
            price = price + 0;
          }
        });
      });

      if (this.state.data.coupon) {
        price = price - this.state.data.coupon_value;
      }

      if (this.state.data.credits) {
        price = price - this.state.data.credits;
      }

      if (this.state.referralCode) {
        price = price - 100;
      }

      this.setState({
        total_price: price,
        isFree: free,
      });
    } else {
      var num_of_lectures = 0;

      kids.map((item) => {
        item.kids_camps.map((camp) => {
          num_of_lectures = num_of_lectures + camp.num_of_lectures;
        });
      });

      this.setState({
        total_price: num_of_lectures * 100,
        isFree: false,
      });

      return num_of_lectures * 100;
    }
  };

  renderTerms = (items) => {
    return items.map((term, index) => {
      return (
        <div key={index}>
          <DateSelected full={term.is_full === 1}>
            <div className="row">
              <div className="col-sm-2">{term.camp_name}</div>
              <div className="col-sm-4">
                {moment(term.date_from).format("D. M.")} -{" "}
                {moment(term.date_to).format("D. M. YYYY")}
              </div>
              <div className="col-sm-6">{term.location_address}</div>
            </div>
          </DateSelected>
          {term.is_full === 1 && (
            <EmbedMessage type="error">
              <p>Na tento termín se přihlašujete jako náhradník</p>
            </EmbedMessage>
          )}
        </div>
      );
    });
  };

  renderPrice = () => {
    const { data } = this.props;

    return (
      <div>
        <label>{lang.sessions}</label>
        {data.kids.map((item, index) => {
          return <div key={index}>{this.renderTerms(item.kids_camps)}</div>;
        })}
        <Form>
          <Form.Field>
            <label>{lang.number_of_dates}</label>
            <input type="text" value={this.state.numberOfTerms} disabled />
          </Form.Field>
          <Form.Field>
            <label>{lang.number_of_children}</label>
            <input type="text" value={data.kids.length} disabled />
          </Form.Field>
          <Form.Field>
            <label>{lang.note}</label>
            <textarea
              value={this.state.data.note}
              name="application_note"
              onChange={this.onChange}
            />
          </Form.Field>
          {!this.props.loadingUser && this.props.user && (
            <Form.Input
              type="number"
              label={`Použít kredity (na Vašem kontě je ${this.props.user.credits} kreditů)`}
              placeholder={`Můžete použít až ${this.props.user.credits} kreditů`}
              max={this.props.user.credits ? this.props.user.credits : 0}
              min="0"
              onChange={this.onChangeCredits}
              value={this.state.data.credits}
            />
          )}
          <Form.Input
            type="text"
            name="coupon"
            id="coupon"
            value={this.state.coupon_code}
            placeholder={lang.discount_code}
            label={lang.discount_code}
            className={
              !this.state.data.coupon_submitted
                ? "empty"
                : this.state.data.coupon_submitted && this.state.data.coupon
                ? "coupon_check"
                : "coupon_x"
            }
            icon={
              !this.state.data.coupon_submitted
                ? "question"
                : this.state.data.coupon_submitted && this.state.data.coupon
                ? "check"
                : "times"
            }
            onChange={this.onChangeCoupon}
          />
          <Form.Input
            type="text"
            name="referral"
            onChange={this.onChangeReferral}
            label="Kód doporučení"
            placeholder="Zadejte kód"
          />
          <Form.Field>
            <label>{lang.total_price}</label>
            <label className="danger">
              {this.state.numberOfSubs !== 0 && lang.summary_substutite}
            </label>
            <h3>{this.state.total_price},- Kč</h3>
          </Form.Field>
        </Form>
      </div>
    );
  };

  lastItems = () => {
    return (
      <Form>
        <Form.Field>
          <Checkbox
            onChange={this.handleCheckbox}
            name="parent_tos"
            id="parent_tos"
            label={
              <label id="parent_tos_lbl">
                {lang.terms_and_conditions}
                <a
                  href={"https://www.sportjoy.cz/cz/vseobecne-podminky-tabory"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="question circle" />
                </a>
              </label>
            }
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            onChange={this.handleCheckbox}
            name="parent_gdpr"
            id="parent_gdpr"
            label={
              <label id="parent_gdpr_lbl">
                {lang.agree_gdpr}
                <a
                  href="https://www.sportjoy.cz/cz/ochrana-osobnich-udaju-a-gdpr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="question circle" />
                </a>
              </label>
            }
          />
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={
              <Checkbox
                onChange={this.handleCheckbox}
                name="parent_photos"
                label={<label>{lang.agree_photos}</label>}
              />
            }
            wide
          >
            <span className="form-desc">{lang.agree_photos_desc}</span>
          </Popup>
        </Form.Field>
        <Form.Field>
          <Popup
            trigger={
              <Checkbox
                onChange={this.handleCheckbox}
                name="parent_newsletter"
                label={lang.agree_newsletter}
              />
            }
            wide
          >
            <span className="form-desc">{lang.agree_newsletter_desc}</span>
          </Popup>
        </Form.Field>
      </Form>
    );
  };

  invoicingFields = () => {
    return (
      <Form>
        <Form.Field>
          <Checkbox
            onChange={this.handleCheckbox}
            name="invoice"
            label={<label>{lang.i_want_invoice}</label>}
          />
        </Form.Field>
        {this.state.data.invoice && (
          <>
            <Form.Input
              name="reg_num"
              type="text"
              label={lang.employer_reg_num}
              value={this.state.data.reg_num}
              onChange={this.onChange}
              required
            />
            <Form.TextArea
              name="invoice_text"
              type="text"
              label={lang.invoice_text}
              placeholder={lang.invoice_text_placeholder}
              value={this.state.data.invoice_text}
              onChange={this.onChange}
              required
            />
            <Form.Input
              name="due_date"
              type="text"
              label={lang.invoice_due_date}
              placeholder={lang.invoice_due_date_placeholder}
              value={this.state.data.due_date}
              onChange={this.onChange}
              required
            />
          </>
        )}
      </Form>
    );
  };

  onChange = (e) =>
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

  handleCheckbox = (e, data) => {
    this.setState({
      data: {
        ...this.state.data,
        [data.name]: data.checked,
      },
    });
  };

  render() {
    const { loading } = this.props;

    return (
      <div>
        <FormSection>
          <FormSectionHead>
            <h5>{lang.parents_contact_info}</h5>
          </FormSectionHead>
          <FormSectionBody>
            <div className="row">{this.parentsBlock()}</div>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.children_registered}</h5>
          </FormSectionHead>
          <FormSectionBody>
            <div className="row">{this.kidsBlock()}</div>
          </FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.sessions_summary}</h5>
          </FormSectionHead>
          <FormSectionBody>{this.renderPrice()}</FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.invoicing}</h5>
          </FormSectionHead>
          <FormSectionBody>{this.invoicingFields()}</FormSectionBody>
        </FormSection>

        <FormSection>
          <FormSectionHead>
            <h5>{lang.conclusion}</h5>
          </FormSectionHead>
          <FormSectionBody>{this.lastItems()}</FormSectionBody>
        </FormSection>

        <FormButtonGroup>
          <Button
            className={
              !this.state.data.parent_gdpr ||
              !this.state.data.parent_tos ||
              loading
                ? "btn_end done"
                : "btn_end pulsing done"
            }
            loading={loading}
            disabled={
              !this.state.data.parent_gdpr ||
              !this.state.data.parent_tos ||
              loading
                ? true
                : false
            }
            onClick={this.submitHandler}
          >
            {lang.submit}
          </Button>
        </FormButtonGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loadingUser: state.user.loading,
});

export default connect(mapStateToProps, {})(ConfirmForm);
