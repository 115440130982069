import React, { Component } from 'react'
import { intToBool, toggleLocationState, shortText, getToken, deleteLocation } from '../../api'
import { AreYouSureModal } from '../modals'
import { Link } from 'react-router-dom'
import { Button, Dropdown, DropdownItem, Icon, Table } from 'semantic-ui-react'
import _ from 'loadsh'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { setMessage } from '../../actions/messageActions'
import { connect } from 'react-redux'

class LocationsTable extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            showModal: false,
            idToDelete: 2,
            loadingDelete: false,
            doneDelete: false
        })
    }

    toggleStateHandler = (id) => {
        toggleLocationState(id)
        .then(() => {
            this.props.refresh()
        })
    }

    toggleModal = (id) => {
        this.setState({
            showModal: !this.state.showModal,
            idToDelete: !this.state.showModal ? id : ''
        })
    }

    _deleteLocation = (id) => {
        this.setState({
            loadingDelete: true,
            doneDelete: false
        })
        getToken().then((token) => {
            deleteLocation(id, token)
            .then((res) => {
                this.props.refresh()
                this.setState({
                    loadingDelete: false,
                    doneDelete: true
                })
                this.props.setMessage(res)
            })
            .catch((err) => {
                this.setState({
                    loadingDelete: false,
                    doneDelete: true
                })
                this.props.setMessage({type: 'error', text: 'Termín se nepodařilo smazat, zkuste to prosím později.'})
            })
        })
        .catch((err) => {
            this.setState({
                loadingDelete: false,
                doneDelete: true
            })
            this.props.setMessage({type: 'error', text: 'Nepodařilo se získat bezpečnostní token, zkuste to prosím později.'})
        })
    }

    render() {

        const { items, column, direction, sort } = this.props

        return (
            <div style={isMobile ? {overflowX: 'auto'} : { overflowX: 'hidden' }}>
                <Table sortable striped className='main-table mobile' unstackable>
                    <Table.Header>
                        <Table.Row>

                            <Table.HeaderCell
                                sorted={column === 'id_location' ? direction : null}
                                onClick={sort('id_location')}
                            >
                                #
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_name' ? direction : null}
                                onClick={sort('location_name')}
                            >
                                Název
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_address' ? direction : null}
                                onClick={sort('location_address')}
                            >
                                Adresa
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'location_state' ? direction : null}
                                onClick={sort('location_state')}
                            >
                                Stav
                            </Table.HeaderCell>

                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {_.map(items, ({ id_location, location_name, location_address, location_state }) => (
                        <Table.Row key={id_location}>
                            <Table.Cell>
                                {id_location}
                            </Table.Cell>
                            <Table.Cell>
                                {location_name}
                            </Table.Cell>
                            <Table.Cell>
                                {shortText(location_address, 60)}
                            </Table.Cell>
                            <Table.Cell>
                                {intToBool(location_state) ? <i class="icofont-check-circled icon-state" onClick={() => this.toggleStateHandler(id_location)}></i> : <i class="icofont-close-circled icon-state" onClick={() => this.toggleStateHandler(id_location)}></i>}
                            </Table.Cell>
                            <Table.Cell>
                                <BrowserView>
                                    <AreYouSureModal
                                        action={this._deleteLocation}
                                        actionText='Smazat lokalitu'
                                        headline='Smazat lokalitu'
                                        message='Opravdu chcete smazat tuto lokalitu?'
                                        icon='trash'
                                        id={id_location}
                                        loading={this.state.loadingDelete}
                                        done={this.state.doneDelete}
                                    />
                                    <Link to={`${process.env.PUBLIC_URL}/manage/locations/edit/` + id_location}>
                                        <Button icon><Icon name='edit'></Icon></Button>
                                    </Link>
                                </BrowserView>
                                <MobileView>
                                    <Dropdown multiple icon='ellipsis vertical' pointing='top right'>
                                        <Dropdown.Menu>
                                            <DropdownItem><Link to={`${process.env.PUBLIC_URL}/manage/locations/edit/` + id_location}>Upravit záznam</Link></DropdownItem>
                                            <Dropdown.Divider />
                                            <DropdownItem text='Smazat záznam' onClick={() => this.toggleStateHandler(id_location)} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </MobileView>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => ({})
  
export default connect(mapStateToProps, { setMessage })(LocationsTable)