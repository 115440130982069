import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Col, Row } from 'reactstrap';
import { Form, Loader } from 'semantic-ui-react';
import { getFilename, uploadFile } from '../../../api/employees';
import { url } from '../../../config';
import { CustomDropzone } from '../../../styles';
import { jsonToFormData } from '../../../tools';
import ImageModal from '../../modals/ImageModal';

function EmployeesUploader({ accept, multiple, type, id_user, label }) {

    const [ files, setFiles ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ filename, setFilename ] = useState('');

    useEffect(() => {
        _getFilename(type, id_user)
    }, [])

    function onDrop(acceptedFiles) {

        setLoading(true)
        
        acceptedFiles.map((file) => {
           /* var oldFiles = files
            oldFiles.push(file)
    
            setFiles(oldFiles)*/

            const data = {
                file: file,
                type: type,
                id_user: id_user
            }

            const payload = jsonToFormData(data);

            uploadFile(payload)
            .then((res) => {
                setLoading(false);
                _getFilename(type, id_user);
            })
        })

    }

    function removeAttachment(del) {
        var oldFiles = files;
        oldFiles.splice(del, 1)

        setFiles(oldFiles)
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, 
        accept: accept,
        multiple: multiple
    })

    function _getFilename() {
        getFilename(type, id_user)
        .then((res) => {
            setFilename(res.data.filename);
        })
    }

    return (
        <Form.Field>
            <label>{label}</label>
            <Row>
                <Col sm={10}>
                    <CustomDropzone {...getRootProps()}>
                        <input {...getInputProps()} />
                            {isDragActive ? <p>Přetáhněte soubory sem ...</p> : (!loading ? <p>Přetáhněte sem soubory, nebo klikněte pro vybrání z počítače<br /><span class='small'>Podporované soubory: {accept}</span></p> : <Loader active />)}
                    </CustomDropzone>
                </Col>
                <Col sm={2}>
                    {!loading &&
                        <ImageModal 
                            trigger={<img 
                                src={url.api + 'employees/get-file?filename='+filename+'&id_user=' + id_user}
                                alt=''
                                className='img-fluid'
                            />}
                            imgSrc={url.api + 'employees/get-file?filename='+filename+'&id_user=' + id_user}
                        />
                        
                    }
                </Col>
            </Row>
        </Form.Field>
    )
}

export default EmployeesUploader